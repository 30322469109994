export enum AvailableProductsSearchMode {
  All = 'All',
  AuthedOnly = 'AuthedOnly',
  NotAuthedOnly = 'NotAuthedOnly',
}

export enum MotorType {
  VariablePiston = 'VariablePiston',
  FixedPiston = 'FixedPiston',
  FixedOrbital = 'FixedOrbital',
}

export enum MotorTypeWarning {
  MultiRatioGearBoxRequired = 'MultiRatioGearBoxRequired',
}

export enum ProjectStatus {
  Draft = 'Draft',
  CalculationsCompleted = 'CalculationsCompleted',
  Configured = 'Configured',
  Deprecated = 'Deprecated',
}

export enum FunctionCode {
  PropelSizing = 'PropelSizing',
  SteeringSystem = 'SteeringSystem',
}

export enum MachineExampleCode {
  WheelLoader = 'WheelLoader',
  MiningVehicle = 'MiningVehicle',
  Telehandler = 'Telehandler',
  Dumper = 'Dumper',
  Custom = 'Custom',
  OnePumpOneMotor = 'OnePumpOneMotor',
  OnePumpMultipleMotors = 'OnePumpMultipleMotors',
  DualPath = 'DualPath',
}

export interface IPrice {
  value: number;
  currency: string;
}

export interface ICalculations {
  vehicle_travel_speed?: number;
  vehicle_travel_speed2?: number;
  vehicle_work_speed?: number;
  vehicle_work_speed2?: number;
}

export interface ISelectedProduct {
  id: string;
  product_id: string;
  name: string;
  series?: string;
  photo_url?: string;
  is_configurable: boolean;
}

export interface ISelectedSteeringProduct extends ISelectedProduct {
  displacement?: number;
  spool?: number;
}

export interface ISteeringUnitAttributes {
  required_l2l_sec?: number;
  actual_l2l_sec?: number;
  required_l2l_turns?: number;
  actual_l2l_turns?: number;
}

export interface ISelectedSteeringProductWithPrice {
  selected_steering_product: ISelectedSteeringProduct;
  price?: IPrice;
}

export interface IRelatedMotorInfo {
  min_displacement: number;
  max_displacement: number;
  max_min_displacement?: number;
  max_pressure: number;
}

export interface ISelectedMotor extends ISelectedProduct {
  rolling_radius?: number;
  final_drive_ratio?: number;
  final_drive_ratio2?: number;
  final_drive_ratio_travel?: number;
  gear_ratio_work?: number;
  gear_ratio_travel?: number;
  mechanical_reduction?: number;
  final_drive_efficiency: number;
  // @TODO [custom] this field with new architectures can't be nullable. FDR always uses recommended value as fallback
  tractive_force_actual: number;
  tractive_force_actual2?: number;
  tractive_force_required: number;
  tractive_force_required2?: number;
  weight_distribution: number;
  min_displacement?: number;
  motor_speed?: number;
  speed_limit_rated_for_displacement?: number;
  is_motor_overspeeding: boolean;
  is2_gear_box_mode: boolean;
  type: MotorType;
  related_motor_info: IRelatedMotorInfo;
  recommended_final_drive_ratio?: number;
  recommended_final_drive_ratio2?: number;
}

export interface ISelectedMotorWithPrice {
  selected_motor: ISelectedMotor;
  price?: IPrice;
}

/**
 * @deprecated [custom]
 */
export interface IGearBoxValues {
  gear_ratio_work: number;
  gear_ratio_travel: number;
  mechanical_reduction: number;
}

export interface IMotorToUpdate
  extends Pick<
    ISelectedMotor,
    'id' | 'product_id' | 'rolling_radius' | 'final_drive_ratio' | 'final_drive_ratio2'
  > {
  /**
   * @deprecated [custom]
   */
  gear_box_stats?: IGearBoxValues;
}

export interface IUpdatedMotorsData {
  items: ISelectedMotor[];
}

export interface IMotorDetails {
  id: string;
  motor_name: string;
  min_displacement?: number;
  speed?: number;
  is_overspeeding: boolean;
  speed_limit_rated_for_displacement?: number;
  tractive_force_actual?: number;
}

export interface IMotorsDisplacementAndSpeed {
  motors: IMotorDetails[];
  calculations: ICalculations;
}

export interface ISelectedPump extends ISelectedProduct {
  max_pressure: number;
  rated_pressure: number;
  continuous_pressure: number;
  speed_limit_max: number;
  speed_limit_rated: number;
  keep_pump: boolean;
}

export interface ISelectedPumpWithPrice {
  selected_pump: ISelectedPump;
  price?: IPrice;
}

export interface IUpdatedPumpsData {
  selected_pumps: ISelectedPump[];
  calculations: ICalculations;
  selected_motors: IMotorDetails[];
}

export interface IPullRatioCalculation {
  machine_function: number;
  drive_configuration: number;
  rolling_resistance: number;
  grade_of_slope: number;
  pull_ratio: number;
}

export enum OperationalEnvironment {
  Highway = 'Highway',
  OffHighway = 'OffHighway',
}

export interface IProjectSystemData {
  hydraulic_dedicated_power?: number;
  engine_speed?: number;
  pressure_limit?: number;
  loaded_weight?: number;
  unloaded_weight?: number;
  operation_environment?: OperationalEnvironment;
}

export enum SystemArchitecture {
  OnePumpOneMotor = 'OnePumpOneMotor',
  OnePumpOneMotorGearBox = 'OnePumpOneMotorGearBox',
  OnePumpTwoMotors = 'OnePumpTwoMotors',
  DualPath = 'DualPath',
  Custom = 'Custom',
}

export interface DefaultRollingRadiuses {
  motor_1?: number;
  motor_2?: number;
  motor_3?: number;
  motor_4?: number;
}

export interface ITwoSpeedGearbox {
  tractive_force?: number;
  pull_ratio?: number;
  max_work_speed: number;
  max_travel_speed: number;
  is_tractive_force_known: boolean;
  is_work_speed_known: boolean;
  pull_ratio_calculation: IPullRatioCalculation | null;
}

export interface IProjectPropelSizingData {
  pump_drive_ratio?: number;
  pump_speed?: number;
  pump_drive_efficiency?: number;
  max_work_speed?: number;
  max_travel_speed?: number;
  tractive_force?: number;
  is_tractive_force_known: boolean;
  is_work_speed_known?: boolean;
  pull_ratio?: number;
  pull_ratio_calculation: IPullRatioCalculation | null;
  two_speed_gear_box: ITwoSpeedGearbox | null;
  initial_system_architecture: SystemArchitecture;
  recommended_motor_type?: {
    motor_type: MotorType;
    warning?: MotorTypeWarning;
  };
  default_rolling_radiuses?: DefaultRollingRadiuses;
}

export enum SteeringArchitecture {
  FourWheels = 'FourWheels',
  FrontWheel = 'FrontWheel',
  RearWheel = 'RearWheel',
  Skid = 'Skid',
  Articulated = 'Articulated',
}

export enum SteeringFunction {
  Conventional = 'Conventional',
  SteerByWire = 'SteerByWire',
  Hybrid = 'Hybrid',
}

export enum SteeringSystem {
  OpenCenter = 'OpenCenter',
  Reaction = 'Reaction',
  LoadSensing = 'LoadSensing',
  Emergency = 'Emergency',
}

export enum SteeringPriority {
  Inline = 'Inline',
  PumpMounted = 'PumpMounted',
  Flanged = 'Flanged',
  None = 'None',
}

export enum AutoUpdateProductsWarning {
  OutOfRequirement = 'OutOfRequirement',
  MotorOverSpeeding = 'MotorOverSpeeding',
  TwoSpeedGearBoxNotSupported = 'TwoSpeedGearBoxNotSupported',
}

export interface IProjectSteeringData {
  architecture?: SteeringArchitecture;
  function: SteeringFunction;
  systems?: SteeringSystem[];
  max_steering_pressure?: number;
  max_steering_wheel_speed?: number;
  priority_valve?: SteeringPriority;
  share_pump?: boolean;
  cylinder_size?: number;
  steering_unit_attributes: ISteeringUnitAttributes;
}

export interface IProject {
  id: string;
  project_name: string;
  project_status: ProjectStatus;
  function_codes: FunctionCode[];
  created_at: string;
  modified_at: string;
  system_data: IProjectSystemData;
  steering_system?: IProjectSteeringData;
  propel_sizing?: IProjectPropelSizingData;
  results?: ICalculations;
  machine_example_code: MachineExampleCode;
  is_recommendation_used: boolean;
}

export interface ISelectedProducts {
  motors: ISelectedMotorWithPrice[];
  pumps: ISelectedPumpWithPrice[];
  steering_products: ISelectedSteeringProductWithPrice[];
}

export interface AutoUpdateProducts extends ISelectedProducts {
  warning: AutoUpdateProductsWarning[];
}

export interface CommonError {
  detail?: string;
  traceId?: string;
  title?: string;
  status: number;
  errors?: Record<string, string[]>;
  type: string;
}

export type AdditionalMotors = 0 | 1 | 2;
