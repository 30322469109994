import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';

import { MeasuringSystemContext, MeasuringUnits } from './MeasuringSystemContext';

interface IMeasureUnitProps {
  metric: string;
  imperial: string;
}

const FormatMeasureUnit = ({ metric, imperial }: IMeasureUnitProps) => {
  const { units } = useContext(MeasuringSystemContext);
  const unit = units === MeasuringUnits.Metric ? metric : imperial;

  return <FormattedMessage id={unit} />;
};

export default FormatMeasureUnit;
