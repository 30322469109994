import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useMeasuringUnitsIntl from 'views/shared/helpers/measuringSystem/useMeasuringUnitsIntl';
import useMeasuringValueConvertor from 'views/shared/helpers/measuringSystem/useMeasuringValueConvertor';
import {
  IProjectSteeringData,
  SteeringArchitecture,
  SteeringFunction,
  SteeringPriority,
  SteeringSystem,
} from 'views/shared/types';

import Parameter from './Parameter';

interface ISteeringProps {
  data: IProjectSteeringData;
  fieldsToShow: Array<keyof IProjectSteeringData> | null;
}

const steeringArchitecture = {
  [SteeringArchitecture.FrontWheel]: 'specs_steering_architecture_front_wheel',
  [SteeringArchitecture.RearWheel]: 'specs_steering_architecture_rear_wheel',
  [SteeringArchitecture.FourWheels]: 'specs_steering_architecture_four_wheels',
  [SteeringArchitecture.Skid]: 'specs_steering_architecture_skid',
  [SteeringArchitecture.Articulated]: 'specs_steering_architecture_articulated',
};

const steeringFunction = {
  [SteeringFunction.Conventional]: 'specs_steering_functions_conventional',
  [SteeringFunction.SteerByWire]: 'specs_steering_functions_steer_by_wire',
  [SteeringFunction.Hybrid]: 'specs_steering_functions_hybrid',
};

const steeringSystem = {
  [SteeringSystem.OpenCenter]: 'specs_steering_system_open_center',
  [SteeringSystem.Reaction]: 'specs_steering_system_reaction',
  [SteeringSystem.LoadSensing]: 'specs_steering_system_load_sensing',
  [SteeringSystem.Emergency]: 'specs_steering_system_emergency',
};

const steeringPriorityValve = {
  [SteeringPriority.Inline]: 'specs_steering_priority_inline',
  [SteeringPriority.PumpMounted]: 'specs_steering_priority_pump_mounted',
  [SteeringPriority.Flanged]: 'specs_steering_priority_flanged',
  [SteeringPriority.None]: 'specs_steering_priority_none',
};

const Steering = ({ data, fieldsToShow }: ISteeringProps) => {
  const { formatMessage, formatNumber } = useIntl();
  const { formatMeasuringUnit } = useMeasuringUnitsIntl();
  const { convertor } = useMeasuringValueConvertor();
  const steeringSystemsValue =
    data.systems &&
    data.systems.map(system => formatMessage({ id: steeringSystem[system] })).join(', ');
  const allFieldsToShow = fieldsToShow || (Object.keys(data) as Array<keyof IProjectSteeringData>);

  const isMaxPressureAvailable = Number.isFinite(data.max_steering_pressure);
  const isMaxWheelSpeedAvailable = Number.isFinite(data.max_steering_wheel_speed);
  const isCylinderSizeAvailable = Number.isFinite(data.cylinder_size);

  return (
    <div data-testid="steering-data">
      {data.architecture && allFieldsToShow.includes('architecture') && (
        <Parameter
          field={<FormattedMessage id="specs_steering_architecture_label" />}
          value={<FormattedMessage id={steeringArchitecture[data.architecture]} />}
        />
      )}
      {allFieldsToShow.includes('function') && (
        <Parameter
          field={<FormattedMessage id="specs_steering_functions_label" />}
          value={<FormattedMessage id={steeringFunction[data.function]} />}
        />
      )}
      {data.systems && allFieldsToShow.includes('systems') && (
        <Parameter
          field={<FormattedMessage id="specs_steering_system_label" />}
          value={steeringSystemsValue}
        />
      )}
      {isMaxPressureAvailable && allFieldsToShow.includes('max_steering_pressure') && (
        <Parameter
          field={<FormattedMessage id="specs_steering_max_pressure" />}
          value={`${convertor('bar', 'psi', data.max_steering_pressure!)} ${formatMeasuringUnit({
            metric: 'measurements_unit_bar',
            imperial: 'measurements_unit_psi',
          })}`}
        />
      )}
      {isMaxWheelSpeedAvailable && allFieldsToShow.includes('max_steering_wheel_speed') && (
        <Parameter
          field={<FormattedMessage id="specs_steering_max_wheel_speed" />}
          value={`${data.max_steering_wheel_speed!} ${formatMessage({
            id: 'measurements_unit_rpm',
          })}`}
        />
      )}
      {data.priority_valve && allFieldsToShow.includes('priority_valve') && (
        <Parameter
          field={<FormattedMessage id="specs_steering_priority_label" />}
          value={<FormattedMessage id={steeringPriorityValve[data.priority_valve]} />}
        />
      )}
      {isCylinderSizeAvailable && allFieldsToShow.includes('cylinder_size') && (
        <Parameter
          field={<FormattedMessage id="specs_steering_cylinder_size" />}
          value={`${formatNumber(
            convertor('cm3', 'in3', data.cylinder_size!),
          )} ${formatMeasuringUnit({
            metric: 'measurements_unit_ccm',
            imperial: 'measurements_unit_cinch',
          })}`}
        />
      )}
    </div>
  );
};

export default memo(Steering);
