const machineFunction = [
  {
    label: '0.90 - Dozing (All Wheel / Track Drive)',
    value: 0.9,
  },
  {
    label: '0.80 - Drawbar (All Wheel / Track Drive)',
    value: 0.8,
  },
  {
    label: '0.60 - Drawbar (Single Axle Drive)',
    value: 0.6,
  },
  {
    label: '0.50 - Dig and Load (All Wheel / Track Drive)',
    value: 0.5,
  },
  {
    label: '0.30 - Propel Forces Main Work Drive',
    value: 0.3,
  },
  {
    label: '0.15 - Stop and Go Shuttle',
    value: 0.15,
  },
  {
    label: '0.00 - Transport (No Work Interaction)',
    value: 0,
  },
];

const driveConfiguration = [
  {
    label: '0.40 - Skid Steer Track',
    value: 0.4,
  },
  {
    label: '0.30 - Skid Steer Wheel',
    value: 0.3,
  },
  {
    label: '0.20 - Dual Path Variable Steer Geometry',
    value: 0.2,
  },
  {
    label: '0.10 - Single Path Track',
    value: 0.1,
  },
  {
    label: '0.00 - Single Path Wheel',
    value: 0,
  },
];

const rollingResistance = [
  {
    label: '0.25 - Sand',
    value: 0.25,
  },
  {
    label: '0.20 - Wet Soil, Mud',
    value: 0.2,
  },
  {
    label: '0.16 - Fresh Deep Snow',
    value: 0.16,
  },
  {
    label: '0.12 - Loose Soil, Gravel',
    value: 0.12,
  },
  {
    label: '0.08 - Grassy Field, Dry Cropland',
    value: 0.08,
  },
  {
    label: '0.05 - Packed Soil, Dirt Roadway',
    value: 0.05,
  },
  {
    label: '0.02 - Pavement',
    value: 0.02,
  },
  {
    label: '0.005 - Steel on Steel Rails',
    value: 0.005,
  },
];

const gradeability = [
  {
    label: '10 - 10% Grade',
    value: 10,
  },
  {
    label: '20 - 20% Grade',
    value: 20,
  },
  {
    label: '29 - 30% Grade',
    value: 29,
  },
  {
    label: '37 - 40% Grade',
    value: 37,
  },
  {
    label: '45 - 50% Grade',
    value: 45,
  },
  {
    label: '51 - 60% Grade',
    value: 51,
  },
];

const values = { machineFunction, driveConfiguration, rollingResistance, gradeability };

export const defaultValues = {
  machine_function: machineFunction[0].value,
  drive_configuration: driveConfiguration[0].value,
  rolling_resistance: rollingResistance[0].value,
  grade_of_slope: gradeability[0].value,
};

export default values;
