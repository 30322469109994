import { ReactComponent as AgSprayer } from 'assets/icons/PullRatio/Ag Sprayer - Loose Soil_035.svg';
import { ReactComponent as CommercialLawnMower } from 'assets/icons/PullRatio/Commercial Lawn Mower - Mow on Grassy Field_037.svg';
import { ReactComponent as CraneTracked } from 'assets/icons/PullRatio/Crane Tracked - Transport in Wet Soil_089.svg';
import { ReactComponent as CraneWheeled } from 'assets/icons/PullRatio/Crane Wheeled - Transport in Wet Soil_049.svg';
import { ReactComponent as CrawlerDozer } from 'assets/icons/PullRatio/Crawler Dozer - Dozing Wet Soil_16.svg';
import { ReactComponent as CrawlerLoader } from 'assets/icons/PullRatio/Crawler Loader - Dig and Load Loose Soil_12.svg';
import { ReactComponent as ExcavatorTracked } from 'assets/icons/PullRatio/Excavator Tracked - Transport in Wet Soil_097.svg';
import { ReactComponent as FarmTractor2WD } from 'assets/icons/PullRatio/Farm Tractor 2WD - Plow in Loose Soil_082.svg';
import { ReactComponent as FarmTractor4WD } from 'assets/icons/PullRatio/Farm Tractor 4WD - Plow in Loose Soil_102.svg';
import { ReactComponent as Grader } from 'assets/icons/PullRatio/Grader - Grading Wet Soil_065.svg';
import { ReactComponent as HarvestingMachineClimbObstacle } from 'assets/icons/PullRatio/Harvesting Machine - Climb Obstacle_045.svg';
import { ReactComponent as HarvestingMachineHighSpeed } from 'assets/icons/PullRatio/Harvesting Machine - High Speed Grassy Field_023.svg';
import { ReactComponent as HarvestingMachineLowSpeed } from 'assets/icons/PullRatio/Harvesting Machine - Low Speed Mud_035.svg';
import { ReactComponent as LiftTruckCushionTire } from 'assets/icons/PullRatio/Lift Truck Cushion Tire - Stop and Go Pavement_022.svg';
import { ReactComponent as LiftTruckPneumaticTire } from 'assets/icons/PullRatio/Lift Truck Pneumatic Tire - Stop and Go Gravel_032.svg';
import { ReactComponent as LiftTruckRoughTerrain } from 'assets/icons/PullRatio/Lift Truck Rough Terrain - Stop and Go Loose Soil_052.svg';
import { ReactComponent as LocomotiveSwitcher } from 'assets/icons/PullRatio/Locomotive Switcher - Shuttle Rail Cars_019.svg';
import { ReactComponent as LogFellerDualPathSteer } from 'assets/icons/PullRatio/Log Feller Dual Path Steer - Stop and Go with Load Wet Soil_065.svg';
import { ReactComponent as LogForwarderWheeled } from 'assets/icons/PullRatio/Log Forwarder Wheeled - Transport in Wet Soil_049.svg';
import { ReactComponent as MEWP } from 'assets/icons/PullRatio/MEWP.svg';
import { ReactComponent as Paver } from 'assets/icons/PullRatio/Paver - Paving on Firm Soil_045.svg';
import { ReactComponent as RoadPlaner } from 'assets/icons/PullRatio/Road Planer - Plane Highway_052.svg';
import { ReactComponent as Roller } from 'assets/icons/PullRatio/Roller - Roll Packed Soil_03.svg';
import { ReactComponent as RollerWheel } from 'assets/icons/PullRatio/Roller Wheel.svg';
import { ReactComponent as SkidSteerLoader } from 'assets/icons/PullRatio/Skid Steer Loader - Dig and Load Loose Soil_11.svg';
import { ReactComponent as SnowGroomer } from 'assets/icons/PullRatio/Snow Groomer - Grooming Snow on Steep Slope_107.svg';
import { ReactComponent as SoilStabilizer } from 'assets/icons/PullRatio/Soil Stabilizer - Stabilize Wet Soil_065.svg';
import { ReactComponent as StreetSweeper } from 'assets/icons/PullRatio/Street Sweeper - Dump and Load in Loose Soil_022.svg';
import { ReactComponent as TrashCompactorBladingUphill } from 'assets/icons/PullRatio/Trash Compactor - Blading Uphill_094.svg';
import { ReactComponent as TrashCompactor } from 'assets/icons/PullRatio/Trash Compactor - Transport in Loose Soil_027.svg';
import { ReactComponent as WheelLoaderArticulated } from 'assets/icons/PullRatio/Wheel Loader Articulated - Dig and Load Loose Soil_065.svg';

export const europePullRatioOptions = [
  {
    value: 0.89,
    label: 'Crane, Tracked - Transport in Wet Soil',
    Icon: CraneTracked,
  },
  {
    value: 0.49,
    label: 'Crane, Wheeled - Transport in Wet Soil',
    Icon: CraneWheeled,
  },
  {
    value: 1.6,
    label: 'Crawler Dozer - Dozing, Wet Soil',
    Icon: CrawlerDozer,
  },
  {
    value: 1.2,
    label: 'Crawler Loader - Dig and Load, Loose Soil',
    Icon: CrawlerLoader,
  },
  {
    value: 0.97,
    label: 'Excavator, Tracked - Transport in Wet Soil',
    Icon: ExcavatorTracked,
  },
  {
    value: 0.82,
    label: 'Farm Tractor, 2WD - Plow in Loose Soil',
    Icon: FarmTractor2WD,
  },
  {
    value: 1.02,
    label: 'Farm Tractor, 4WD - Plow in Loose Soil',
    Icon: FarmTractor4WD,
  },
  {
    value: 0.65,
    label: 'Grader - Grading Wet Soil',
    Icon: Grader,
  },
  {
    value: 0.23,
    label: 'Harvesting Machine - High Speed, Grassy Field',
    Icon: HarvestingMachineHighSpeed,
  },
  {
    value: 0.35,
    label: 'Harvesting Machine - Low Speed, Mud',
    Icon: HarvestingMachineLowSpeed,
  },
  {
    value: 0.45,
    label: 'Harvesting Machine - Climb Obstacle',
    Icon: HarvestingMachineClimbObstacle,
  },
  {
    value: 0.37,
    label: 'Commercial Lawn Mower - Mow on Grassy Field',
    Icon: CommercialLawnMower,
  },
  {
    value: 0.22,
    label: 'Lift Truck, Cushion Tire - Stop and Go, Pavement',
    Icon: LiftTruckCushionTire,
  },
  {
    value: 0.32,
    label: 'Lift Truck, Pneumatic Tire - Stop and Go, Gravel',
    Icon: LiftTruckPneumaticTire,
  },
  {
    value: 0.52,
    label: 'Lift Truck, Rough Terrain - Stop and Go, Loose Soil',
    Icon: LiftTruckRoughTerrain,
  },
  {
    value: 0.19,
    label: 'Locomotive, Switcher - Shuttle Rail Cars',
    Icon: LocomotiveSwitcher,
  },
  {
    value: 0.65,
    label: 'Log Feller, Dual Path Steer - Stop and Go with Load, Wet Soil',
    Icon: LogFellerDualPathSteer,
  },
  {
    value: 0.49,
    label: 'Log Forwarder, Wheeled - Transport in Wet Soil',
    Icon: LogForwarderWheeled,
  },
  {
    value: 0.45,
    label: 'Paver - Paving on Firm Soil',
    Icon: Paver,
  },
  {
    value: 0.52,
    label: 'Road Planer - Plane Highway',
    Icon: RoadPlaner,
  },
  {
    value: 0.3,
    label: 'Roller - Roll Packed Soil',
    Icon: Roller,
  },
  {
    value: 1.1,
    label: 'Skid Steer Loader - Dig and Load, Loose Soil',
    Icon: SkidSteerLoader,
  },
  {
    value: 1.07,
    label: 'Snow Groomer - Grooming Snow on Steep Slope',
    Icon: SnowGroomer,
  },
  {
    value: 0.65,
    label: 'Soil Stabilizer - Stabilize Wet Soil',
    Icon: SoilStabilizer,
  },
  {
    value: 0.22,
    label: 'Street Sweeper - Dump and Load in Loose Soil',
    Icon: StreetSweeper,
  },
  {
    value: 0.27,
    label: 'Trash Compactor - Transport in Loose Soil',
    Icon: TrashCompactor,
  },
  {
    value: 0.94,
    label: 'Trash Compactor - Blading Uphill',
    Icon: TrashCompactorBladingUphill,
  },
  {
    value: 0.65,
    label: 'Wheel Loader, Articulated - Dig and Load, Loose Soil',
    Icon: WheelLoaderArticulated,
  },
  {
    value: 0.35,
    label: 'Ag Sprayer - Loose Soil',
    Icon: AgSprayer,
  },
].sort((item1, item2) => item1.value - item2.value);

export const americaPullRatioOptions = [
  {
    value: 0.75,
    label: 'Wheel loader',
    Icon: TrashCompactorBladingUphill,
  },
  {
    value: 0.4,
    label: 'Dumper',
    Icon: AgSprayer,
  },
  {
    value: 0.8,
    label: 'Telescopic loader',
    Icon: WheelLoaderArticulated,
  },
  {
    value: 0.75,
    label: 'Forwarder',
    Icon: LogForwarderWheeled,
  },
  {
    value: 0.55,
    label: 'Roller drum',
    Icon: Roller,
  },
  {
    value: 0.5,
    label: 'Roller wheel',
    Icon: RollerWheel,
  },
  {
    value: 0.8,
    label: 'Combaine',
    Icon: HarvestingMachineHighSpeed,
  },
  {
    value: 0.8,
    label: 'Forestry tractor',
    Icon: LogFellerDualPathSteer,
  },
  {
    value: 0.65,
    label: 'MEWP',
    Icon: MEWP,
  },
].sort((item1, item2) => item1.value - item2.value);
