interface AppConfig {
  AUTH0_SCOPE: string;
  AUTH0_AUDIENCE: string;
  AUTH0_DOMAIN: string;
  AUTH0_CLIENT_ID: string;
  AUTH0_REDIRECT_URL: string;
  SALESFORCE_COMMUNITY_SUBDOMAIN_URI: string;
  APPLICATION_PREFIX: string;
  DC_URL: string;
  VERSION: string;
  API_URL: string;
  DIP_LOGIN_URL: string;
  STATIC_SITE_DOMAIN: string;
  AZURE_FUNCTIONS_URL: string;
  SPLIT_IO_KEY: string;
}

export const getAppConfig = (): AppConfig => {
  const SALESFORCE_COMMUNITY_SUBDOMAIN_URI = process.env.REACT_APP_FE_SF_DESIGN_CENTER_URI!;

  return {
    AUTH0_SCOPE: process.env.REACT_APP_AUTH0_SCOPE!,
    AUTH0_AUDIENCE: process.env.REACT_APP_AUTH0_AUDIENCE!,
    AUTH0_DOMAIN: process.env.REACT_APP_AUTH0_DOMAIN!,
    AUTH0_CLIENT_ID: process.env.REACT_APP_AUTH0_CLIENT_ID!,
    AUTH0_REDIRECT_URL: process.env.REACT_APP_AUTH0_REDIRECT_URL!,
    DC_URL: `${SALESFORCE_COMMUNITY_SUBDOMAIN_URI}/designcenter`,
    APPLICATION_PREFIX: 'mfd',
    DIP_LOGIN_URL: '/dip',
    VERSION: process.env.VERSION!,
    API_URL: process.env.REACT_APP_API_URL!,
    STATIC_SITE_DOMAIN: process.env.REACT_APP_STATIC_SITE_DOMAIN!,
    AZURE_FUNCTIONS_URL: process.env.REACT_APP_AZURE_FUNCTIONS_URL!,
    SALESFORCE_COMMUNITY_SUBDOMAIN_URI,
    SPLIT_IO_KEY: process.env.REACT_APP_SPLIT_IO_KEY!,
  };
};
