import { TableColumnType, TableRowSelectedEventType } from '@danfoss/mosaic';
import { DfTable } from '@danfoss/mosaic/react';
import { useContext } from 'react';

import {
  MeasuringSystemContext,
  MeasuringUnits,
} from 'views/shared/helpers/measuringSystem/MeasuringSystemContext';
import {
  convertAvailableSteeringProduct,
  convertAvailableSteeringUnit,
} from 'views/shared/helpers/measuringSystem/convertors';

import { IAvailableSteeringProduct, IAvailableSteeringUnit } from '../../../../api/products';
import styles from '../../../components/tables/ProductTable.module.scss';
import { useScrollIntoRow } from '../../../components/tables/helpers/useScrollIntoRow';

interface ISteeringTableProps {
  list: IAvailableSteeringUnit[];
  onRowSelected: (e: CustomEvent<TableRowSelectedEventType>) => void;
  columns: TableColumnType[];
}

const SteeringTable = ({ list, onRowSelected, columns }: ISteeringTableProps) => {
  const { units } = useContext(MeasuringSystemContext);
  const steeringProductsConvertor = (
    steeringProducts: IAvailableSteeringProduct[],
  ): IAvailableSteeringProduct[] => {
    return steeringProducts.map(data => convertAvailableSteeringProduct(data, units));
  };
  const steeringUnitsConvertor = (
    steeringUnits: IAvailableSteeringUnit[],
  ): IAvailableSteeringUnit[] => {
    return steeringUnits.map(data => {
      const convertedSteeringUnit = convertAvailableSteeringUnit(data.steering_unit, units);
      const convertedProducts = steeringProductsConvertor(data.steering_unit.products);

      return {
        ...data,
        steering_unit: {
          ...convertedSteeringUnit,
          products: convertedProducts,
        },
      };
    });
  };
  const convertedList = units === MeasuringUnits.Imperial ? steeringUnitsConvertor(list) : list;

  const getRowId = (originalRow: Record<string, any>) => {
    return (originalRow as IAvailableSteeringUnit).steering_unit.id;
  };

  const { ref, getRowProps } = useScrollIntoRow();

  return (
    <DfTable
      ref={ref}
      className={styles.table}
      columns={columns}
      data={convertedList}
      isSelectable
      isSortable={false}
      isOneRowSelection
      isSelectableOnClick
      selectedRows={[
        convertedList.find(({ recommended }) => recommended)?.steering_unit.id as string,
      ]}
      getRowId={getRowId}
      onRowSelected={onRowSelected}
      getRowProps={getRowProps}
    />
  );
};

export default SteeringTable;
