import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfModal } from '@danfoss/mosaic/react';
import { ChartData, ChartOptions } from 'chart.js';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as SvgInfoWarning } from 'assets/icons/info-warning.svg';

import ExpandedPerformanceGraph from './ExpandedPerformanceGraph';

interface IExpandedModalProps {
  onClose: () => void;
  chartData: ChartData<'line'>;
  chartOptions: ChartOptions<'line'>;
}

const ExpandedModal = ({ onClose, chartData, chartOptions }: IExpandedModalProps) => {
  const { formatMessage } = useIntl();

  return (
    <DfModal
      onModalClose={onClose}
      heading={formatMessage({ id: 'compare_page_performance_cell_modal_title' })}
      data-testid="expanded_graph_modal"
      size="large"
      isOpen
    >
      <div slot="modal-body">
        <div className={utils.mb5}>
          <FormattedMessage id="compare_page_performance_cell_modal_text" />
        </div>
        <ExpandedPerformanceGraph chartOptions={chartOptions} chartData={chartData} />
      </div>
      <div slot="modal-footer">
        <div className={cn(core.flex, core.flexBetween, core.alignEnd, core.fullWidth)}>
          <div className={cn(core.flex, core.alignCenter)}>
            <SvgInfoWarning className={cn(core.icon, utils.mr2)} />
            <FormattedMessage id="compare_page_performance_cell_modal_hint" />
          </div>
          <button className={button.btnPrimary} onClick={onClose} data-testid="close_button">
            <FormattedMessage id="compare_page_performance_cell_modal_close" />
          </button>
        </div>
      </div>
    </DfModal>
  );
};

export default ExpandedModal;
