import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfLoader } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { useEffect, useMemo, useState, memo } from 'react';
import { FieldError, UseFormReturn, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as SvgInfoFilled } from 'assets/icons/info-filled.svg';
import debounce from 'utils/debounce';
import { MotorType, MotorTypeWarning, SystemArchitecture } from 'views/shared/types';

import { IRequirementsForm } from '../../../../types';
import { useOverrideMotorTypeContext } from '../OverrideMotorType.context';
import { TransmissionRatioInfoDialog } from './TransmissionRatioInfoDialog/TransmissionRatioInfoDialog';
import styles from './TrasmissionRatio.module.scss';
import { getTransmissionRatio, ITransmissionRatio } from './api/transmissionRatio';

interface ITransmissionRatioProps {
  form: UseFormReturn<IRequirementsForm>;
  formPart: 'propelSizingData' | 'propelSizingData.two_speed_gear_box';
  onUpdate: (isUpdating: boolean, isError?: boolean) => void;
  dependsOn: {
    tractiveForce?: number;
    maxTravelSpeed?: number;
    maxWorkSpeed?: number;
    hydraulicDedicatedPower: number;
    architecture: SystemArchitecture;
  };
}

function getRecommendationMessage(
  transmissionRatio: ITransmissionRatio,
  architecture: SystemArchitecture,
  isGearboxMode: boolean,
  overrideMotorType: MotorType | null,
) {
  const { recommended_motor_type, value } = transmissionRatio;
  const maxHandledTransmissionRatioValue = 12;
  const { motor_type, warning } = recommended_motor_type;
  const motorType = overrideMotorType ?? motor_type;

  if (isGearboxMode && value >= maxHandledTransmissionRatioValue) {
    return (
      <FormattedMessage id="wizard_requirements_transmission_ratio_description_adjust_requirements" />
    );
  }

  if (
    warning === MotorTypeWarning.MultiRatioGearBoxRequired &&
    architecture === SystemArchitecture.OnePumpOneMotor
  ) {
    return (
      <FormattedMessage id="wizard_requirements_transmission_ratio_description_multi_ratio_gear_box" />
    );
  }

  if (motorType === MotorType.FixedOrbital || motorType === MotorType.FixedPiston) {
    return <FormattedMessage id="wizard_requirements_transmission_ratio_description_fixed" />;
  }

  if (motorType === MotorType.VariablePiston) {
    return <FormattedMessage id="wizard_requirements_transmission_ratio_description_variable" />;
  }
}

const TransmissionRatio = ({ dependsOn, onUpdate, form, formPart }: ITransmissionRatioProps) => {
  const overrideMotorType = useOverrideMotorTypeContext();
  const { formatMessage } = useIntl();

  const isGearboxMode = formPart === 'propelSizingData.two_speed_gear_box';
  const TRGearField = isGearboxMode ? 'transmissionRatioGear2' : 'transmissionRatio';
  const [isCalculating, setIsCalculating] = useState(false);
  const { tractiveForce, maxTravelSpeed, hydraulicDedicatedPower, maxWorkSpeed, architecture } =
    dependsOn;
  const { trigger, setValue, setError, clearErrors, control, formState } = form;
  const { errors } = formState;
  const transmissionRatio = useWatch({
    name: TRGearField,
    control,
  });
  const transmissionRatioValidation = {
    min: 0,
    max: 100,
  };

  const [isInfoDialogOpened, setIsInfoDialogOpened] = useState(false);

  const openDialog = () => setIsInfoDialogOpened(true);
  const closeDialog = () => setIsInfoDialogOpened(false);

  const fetchTransmissionRatio = useMemo(() => debounce(getTransmissionRatio, 1000), []);

  useEffect(() => {
    if (!tractiveForce || !hydraulicDedicatedPower || !maxTravelSpeed || !maxWorkSpeed) {
      return;
    }

    (async () => {
      const areFieldsValid = await trigger([
        `${formPart}.tractive_force`,
        `${formPart}.max_travel_speed`,
        `${formPart}.max_work_speed`,
      ]);

      if (!areFieldsValid) {
        return;
      }

      try {
        setIsCalculating(true);
        clearErrors(TRGearField);
        onUpdate(true);

        const transmissionRatioValue = await fetchTransmissionRatio({
          pump_power: hydraulicDedicatedPower,
          tractive_force: tractiveForce,
          speed_maximum: Math.max(maxTravelSpeed, maxWorkSpeed),
        });

        setValue(TRGearField, transmissionRatioValue);

        if (transmissionRatioValue.value > transmissionRatioValidation.max) {
          setError(TRGearField, {
            type: 'max',
            message: formatMessage({ id: 'wizard_requirements_gearbox_error' }),
          });
          onUpdate(false, true);
        } else {
          onUpdate(false);
        }
      } catch {
        setError(TRGearField, {
          type: 'request',
          message: formatMessage({ id: 'wizard_requirements_transmission_ratio_request_failed' }),
        });
        onUpdate(false, true);
      } finally {
        setIsCalculating(false);
      }
    })();
  }, [tractiveForce, hydraulicDedicatedPower, maxTravelSpeed, maxWorkSpeed]);

  useEffect(() => {
    return () => {
      setValue(TRGearField, null);
    };
  }, []);

  const message =
    transmissionRatio &&
    getRecommendationMessage(
      transmissionRatio,
      architecture,
      isGearboxMode,
      overrideMotorType.motorType,
    );

  return (
    <div className={cn(utils.mt6, utils.mb4)} data-testid={`${formPart}.transmission-ratio`}>
      {isCalculating && (
        <div
          className={cn(core.flex, core.alignCenter)}
          data-testid="transmission-ratio-calculating-loader"
        >
          <div>
            <FormattedMessage id="wizard_requirements_transmission_ratio_calculating" />
          </div>
          <div className={styles['calculating-loader']}>
            <DfLoader isTransparent />
          </div>
        </div>
      )}
      {!isCalculating && transmissionRatio?.value && (
        <>
          <div
            className={cn(core.flex, core.alignCenter, core.textBold)}
            data-testid="transmission-ratio-value"
          >
            <FormattedMessage
              id="wizard_requirements_transmission_ratio"
              values={{ value: transmissionRatio.value }}
            />
            <button
              type="button"
              className={cn(utils.ml1, button.textBtn, button.textBtnSecondary)}
              onClick={openDialog}
              data-testid="transmission-ratio-info-button"
            >
              <SvgInfoFilled className={core.icon} />
            </button>
          </div>
          {message && <p data-testid="transmission-ratio-help-text">{message}</p>}
        </>
      )}
      {errors[TRGearField] && (errors[TRGearField] as FieldError).type === 'request' && (
        <p className={core.error} data-testid="transmission_ratio_request_error">
          {(errors[TRGearField] as FieldError).message}
        </p>
      )}
      {errors[TRGearField] && (errors[TRGearField] as FieldError).type === 'max' && (
        <p className={core.error} data-testid="transmission_ratio_maximum_error">
          {(errors[TRGearField] as FieldError).message}
        </p>
      )}
      {isInfoDialogOpened && <TransmissionRatioInfoDialog onClose={closeDialog} />}
    </div>
  );
};

export default memo(TransmissionRatio);
