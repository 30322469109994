import input from '@danfoss/mosaic/css/components/input.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import cn from 'classnames';
import { forwardRef } from 'react';

export interface IInput extends React.HTMLProps<HTMLInputElement> {
  error?: boolean;
  warning?: boolean;
}

export default forwardRef<HTMLInputElement, IInput>(function Input(
  { className, error, warning, ...props },
  ref,
) {
  return (
    <input
      className={cn(className, input.input, core.textRight, {
        [input.error]: error,
        [input.warning]: warning,
      })}
      ref={ref}
      {...props}
    />
  );
});
