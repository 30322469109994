import backdrop from '@danfoss/mosaic/css/components/backdrop.module.css';
import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import Button from 'views/shared/components/Button/Button';
import { SystemArchitecture } from 'views/shared/types';

import { ArchitectureSelect } from './ArchitectureSelect';
import styles from './ArchitectureSelectTooltip.module.scss';
import { ArchitectureSelectForm } from './types';

export interface ArchitectureSelectTooltipProps {
  onSelect: (architecture: SystemArchitecture) => void;
  onClose: () => void;
}

export function ArchitectureSelectTooltip({ onSelect, onClose }: ArchitectureSelectTooltipProps) {
  const form = useForm<ArchitectureSelectForm>({
    defaultValues: { architecture: SystemArchitecture.OnePumpOneMotor },
  });

  const {
    formState: { isSubmitting },
    handleSubmit,
  } = form;

  const onSubmit = handleSubmit(({ architecture }) => onSelect(architecture));

  const stopPropagation = (event: React.SyntheticEvent) => event.stopPropagation();

  const onBackdropClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onClose();
  };

  return (
    <>
      <div
        className={cn(styles.tooltip, utils.shadowStrong)}
        onClick={stopPropagation}
        data-testid="architecture-select-tooltip"
      >
        <form onSubmit={onSubmit} className={cn(core.fullHeight, utils.p0)}>
          <ArchitectureSelect form={form} />
          <div className={cn(core.flex, core.flexCenter)}>
            <Button
              data-testid="architecture-select-continue-button"
              className={cn(button.btnPrimary, utils.mt2)}
              disabled={isSubmitting}
            >
              <FormattedMessage id="wizard_example_selection_architecture_tooltip_continue" />
            </Button>
          </div>
        </form>
      </div>
      <div
        data-testid="architecture-select-backdrop"
        onClick={onBackdropClick}
        className={cn(backdrop.backdrop, backdrop.open, styles.backdrop)}
      />
    </>
  );
}
