import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';

import ConfigMenu from 'views/base/ConfigMenu/ConfigMenu';
import DocumentList from 'views/shared/components/DocumentList/DocumentList';
import EnteredData from 'views/shared/components/EnteredData/EnteredData';
import ProductList from 'views/shared/components/ProductList';
import SummaryTabs from 'views/shared/components/SummaryTabs/SummaryTabs';
import { ISelectedMotor, ISelectedPump, ISelectedSteeringProduct } from 'views/shared/types';

import { ISharedPrivateProject } from '../types';

interface IPrivateSharedSummaryProps {
  data: ISharedPrivateProject;
}

const PrivateSharedSummary = ({ data }: IPrivateSharedSummaryProps) => {
  const { project, title } = data;
  const motors: ISelectedMotor[] = [];
  const pumps: ISelectedPump[] = [];
  const steeringProducts: ISelectedSteeringProduct[] = [];
  const productIds: string[] = [];
  const prices = {};

  data.motors.forEach(({ selected_motor, price }) => {
    motors.push(selected_motor);
    productIds.push(selected_motor.product_id);

    Object.assign(prices, {
      [selected_motor.product_id]: price,
    });
  });
  data.pumps.forEach(({ selected_pump, price }) => {
    pumps.push(selected_pump);
    productIds.push(selected_pump.product_id);

    Object.assign(prices, {
      [selected_pump.product_id]: price,
    });
  });
  data.steering_products.forEach(({ selected_steering_product, price }) => {
    steeringProducts.push(selected_steering_product);
    productIds.push(selected_steering_product.product_id);

    Object.assign(prices, {
      [selected_steering_product.product_id]: price,
    });
  });

  const products = { motors, pumps, steeringProducts };
  const isProductsAvailable = Boolean(motors.length || pumps.length || steeringProducts.length);

  const motorType = motors[0]?.type;

  return (
    <div className={utils.mb10} data-testid="private-shared-summary-page">
      <div className={cn(core.flex, core.flexBetween, core.alignCenter)}>
        <h1 className={utils.mb4} data-testid="private-shared-summary-page-title">
          {title}
        </h1>
        <ConfigMenu />
      </div>
      <EnteredData
        className={utils.mb4}
        project={project}
        calculations={project.results}
        motorType={motorType}
      />
      {isProductsAvailable && (
        <SummaryTabs
          products={<ProductList products={products} prices={prices} />}
          documents={<DocumentList productIds={productIds} />}
        />
      )}
    </div>
  );
};

export default PrivateSharedSummary;
