import components from '@danfoss/mosaic/css/components.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { HTMLProps, FunctionComponent } from 'react';

interface IPullRatioOptionProps extends HTMLProps<HTMLInputElement> {
  Icon: FunctionComponent;
}

const PullRatioOption = ({ Icon, children, id, className, ...props }: IPullRatioOptionProps) => {
  return (
    <label
      htmlFor={id}
      className={cn(core.flex, core.alignCenter, core.flexBetween, utils.px6, className)}
    >
      <div className={cn(core.flex, core.alignCenter, components.radio)}>
        <input type="radio" {...props} id={id} name="pull-ratio-option" />
        <div className={cn(utils.ml12, utils.mr12, core.flex, core.alignCenter)}>
          <Icon />
        </div>
      </div>
      <p className={core.textRight} data-testid="pull-ratio-title">
        {children}
      </p>
    </label>
  );
};

export default PullRatioOption;
