import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';

import machineExamples from 'views/wizard/example-selection/store/machineExamplesSlice';
import productFilter from 'views/wizard/product-selection/store/productFilterSlice';
import project from 'views/wizard/shared/store/projectSlice';
import sizingFunctions from 'views/wizard/shared/store/sizingFunctionsSlice';
import validation from 'views/wizard/shared/store/validationSlice';

export const configure = (preloadedState = {}) => {
  return configureStore({
    reducer: {
      project,
      productFilter,
      sizingFunctions,
      machineExamples,
      validation,
    },
    preloadedState,
  });
};

export const store = configure();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
