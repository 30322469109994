import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfLoader } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { useEffect, useState, ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { ReactComponent as SvgPropel } from 'assets/icons/SizingFunctions/propel.svg';
import { ReactComponent as SvgSteering } from 'assets/icons/SizingFunctions/steering.svg';
import { useAppSelector, useAppDispatch } from 'configs/store';
import ContactUs from 'views/base/ContactUs/ContactUs';
import Button from 'views/shared/components/Button/Button';
import { FunctionCode } from 'views/shared/types';
import WizardStep from 'views/wizard/shared/components/WizardStep';
import {
  getSelectedFunctions,
  setSelectedFunctions as setSelectedFunctionsAction,
} from 'views/wizard/shared/store/sizingFunctionsSlice';

import { fetchSizingFunctions, getSizingFunctions } from '../shared/store/sizingFunctionsSlice';
import { SizingFunctionsConfig } from '../shared/types';
import WizardOption from './components/WizardOption/WizardOption';

const sizingFunctionsConfig: SizingFunctionsConfig = {
  [FunctionCode.PropelSizing]: { Icon: SvgPropel, intlId: 'propel_function_code' },
  [FunctionCode.SteeringSystem]: {
    Icon: SvgSteering,
    intlId: 'steering_function_code',
  },
};

const FunctionSelection = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const sizingFunctions = useAppSelector(getSizingFunctions);
  const selectedSizingFunctions = useAppSelector(getSelectedFunctions);

  const [selectedFunctions, setSelectedFunctions] = useState<Set<FunctionCode>>(
    new Set(selectedSizingFunctions),
  );
  const isPageLoading = !sizingFunctions;
  const isFunctionSelected = selectedFunctions.size > 0;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target as { name: FunctionCode; checked: boolean };
    setSelectedFunctions(oldSet => {
      if (checked) {
        oldSet.add(name);
      } else {
        oldSet.delete(name);
      }

      return new Set(oldSet);
    });
  };

  const nextStep = async () => {
    dispatch(setSelectedFunctionsAction(Array.from(selectedFunctions)));
    history.push('/example-selection');
  };

  useEffect(() => {
    if (!sizingFunctions) {
      dispatch(fetchSizingFunctions());
    }
  }, []);

  if (isPageLoading) {
    return <DfLoader isGlobal isVisible data-testid="function-selection-page-loader" />;
  }

  return (
    <>
      <WizardStep
        progress={0}
        breadcrumb={<FormattedMessage id="wizard_breadcrumbs_composing" />}
      />
      <h4 className={cn(utils.mt5, core.textCenter)} data-testid="function-selection-sub-title">
        <FormattedMessage id="wizard_function_selection_sub_title" />
      </h4>
      <fieldset className={cn(utils.mt8, core.flex, core.flexColumn, core.alignCenter)}>
        {sizingFunctions.map(func => {
          const data = sizingFunctionsConfig[func];

          if (!data) {
            return null;
          }

          const { Icon, intlId } = data;
          const checked = selectedFunctions.has(func);

          return (
            <WizardOption
              key={func}
              checked={checked}
              name={func}
              id={`sizing-function-${func}`}
              onChange={handleChange}
              data-testid={`function-selection-option-${func}`}
            >
              <Icon />
              <p>
                <FormattedMessage id={intlId} />
              </p>
            </WizardOption>
          );
        })}
      </fieldset>
      <div className={cn(utils.mt8, core.flex, core.flexCenter)}>
        <Button
          className={button.btnPrimary}
          disabled={!isFunctionSelected}
          data-testid="function-selection-button-next"
          onClick={nextStep}
        >
          <FormattedMessage id="wizard_step_start_sizing" />
        </Button>
      </div>
      <ContactUs />
    </>
  );
};

export default FunctionSelection;
