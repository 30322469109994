import { useIntl } from 'react-intl';

import { usePriceFormatter } from 'views/shared/helpers/formatters';
import useMeasuringUnitsIntl from 'views/shared/helpers/measuringSystem/useMeasuringUnitsIntl';
import useMeasuringValueConvertor from 'views/shared/helpers/measuringSystem/useMeasuringValueConvertor';
import { IPrice, ISelectedPump } from 'views/shared/types';

import Header from '../../components/widgets/components/Header/Header';
import StaticAttribute from '../../components/widgets/components/StaticAttributes/StaticAttribute';

export interface PumpWidgetProps {
  data: ISelectedPump;
  onChange: (id: string) => void;
  onRemove?: (id: string) => void;
  price?: IPrice;
}

const PumpWidget = ({ data, onRemove, onChange, price }: PumpWidgetProps) => {
  const { formatMessage, formatNumber } = useIntl();
  const { formatMeasuringUnit } = useMeasuringUnitsIntl();
  const { convertor } = useMeasuringValueConvertor();
  const priceFormatter = usePriceFormatter();
  const {
    id,
    name,
    series,
    rated_pressure,
    max_pressure,
    continuous_pressure,
    speed_limit_max,
    speed_limit_rated,
    photo_url,
  } = data;

  const maxPressure = `${formatNumber(convertor('bar', 'psi', max_pressure))} ${formatMeasuringUnit(
    {
      metric: 'measurements_unit_bar',
      imperial: 'measurements_unit_psi',
    },
  )}`;
  const ratedPressure = `${formatNumber(
    convertor('bar', 'psi', rated_pressure),
  )} ${formatMeasuringUnit({
    metric: 'measurements_unit_bar',
    imperial: 'measurements_unit_psi',
  })}`;
  const mainSpec = <span data-testid="pump-spec">{ratedPressure}</span>;
  const continuousPressure = `${formatNumber(
    convertor('bar', 'psi', continuous_pressure),
  )} ${formatMeasuringUnit({
    metric: 'measurements_unit_bar',
    imperial: 'measurements_unit_psi',
  })}`;
  const maxSpeed = `${formatNumber(speed_limit_max)} ${formatMessage({
    id: 'measurements_unit_rpm',
  })}`;
  const ratedSpeed = `${formatNumber(speed_limit_rated)} ${formatMessage({
    id: 'measurements_unit_rpm',
  })}`;
  const priceLabel = priceFormatter(price);

  return (
    <div data-testid="pump-widget">
      <Header
        id={id}
        photoUrl={photo_url}
        photoAlt={formatMessage({ id: 'wizard_product_pump_widget_image_alt' })}
        name={name}
        series={series}
        spec={mainSpec}
        onChange={onChange}
        onRemove={onRemove}
        isReadOnly={false}
      />
      <div>
        <StaticAttribute
          name={formatMessage({ id: 'wizard_product_pump_widget_max_pressure' })}
          value={maxPressure}
          testId="pump-max-pressure"
          boldTitle
        />
        <StaticAttribute
          name={formatMessage({ id: 'wizard_product_pump_widget_rated_pressure' })}
          value={ratedPressure}
          testId="pump-rated-pressure"
          boldTitle
        />
        <StaticAttribute
          name={formatMessage({ id: 'wizard_product_pump_widget_continuous_pressure' })}
          value={continuousPressure}
          testId="pump-continuous-pressure"
          boldTitle
        />
        <StaticAttribute
          name={formatMessage({ id: 'wizard_product_pump_widget_speed_limit_max' })}
          value={maxSpeed}
          boldTitle
        />
        <StaticAttribute
          name={formatMessage({ id: 'wizard_product_pump_widget_speed_limit_rated' })}
          value={ratedSpeed}
          boldTitle
        />
        <StaticAttribute
          name={formatMessage({ id: 'wizard_product_pump_widget_price' })}
          value={priceLabel}
          testId="pump-price"
          boldTitle
        />
      </div>
    </div>
  );
};

export default PumpWidget;
