import core from '@danfoss/mosaic/css/core.module.css';
import { DfLoader } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { memo, useEffect } from 'react';

import { AutofillStrategy, IPerformanceCalculations } from 'views/shared/api/performance';
import { usePerformance } from 'views/shared/helpers/performance';
import { MotorType } from 'views/shared/types';

import styles from './Performance.module.scss';
import SmallPerformanceGraph from './components/SmallPerformanceGraph/SmallPerformanceGraph';

interface IPerformanceProps {
  projectId: string;
  motorType: MotorType;
  allSelectionsPerformance: IPerformanceCalculations[];
  onLoad: (performance: IPerformanceCalculations) => void;
}

const Performance = ({
  projectId,
  motorType,
  onLoad,
  allSelectionsPerformance,
}: IPerformanceProps) => {
  const isMotorVariable = motorType === MotorType.VariablePiston;
  const performanceStrategy = isMotorVariable ? AutofillStrategy.Variable : AutofillStrategy.Fixed;
  const { isLoading, loadPerformance, performance, error } = usePerformance({
    projectId,
    performanceStrategy,
  });

  useEffect(() => {
    loadPerformance(performanceStrategy);
  }, []);

  useEffect(() => {
    if (performance) {
      onLoad(performance);
    }
  }, [performance]);

  if (error) {
    return (
      <div className={cn(styles.wrapper, core.error)} data-testid="performance-error">
        {error}
      </div>
    );
  }

  if (isLoading || !performance) {
    return (
      <div className={styles.wrapper} data-testid="performance-loader">
        <DfLoader className={styles.loader} />
      </div>
    );
  }

  return (
    <div className={styles.wrapper} data-testid="performance-card">
      <SmallPerformanceGraph currentProjectId={projectId} data={allSelectionsPerformance} />
    </div>
  );
};

export default memo(Performance);
