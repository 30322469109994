import utils from '@danfoss/mosaic/css/utils.module.css';
import { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'configs/store';
import BreadcrumbStep from 'views/base/Breadcrumbs/BreadcrumbStep';
import Breadcrumbs from 'views/base/Breadcrumbs/Breadcrumbs';
import SubHeader from 'views/base/SubHeader/SubHeader';
import Stepper from 'views/shared/components/Stepper/Stepper';
import { FunctionCode, ProjectStatus } from 'views/shared/types';
import {
  updateProject,
  getSelectedMotors,
  getCurrent,
  getSelectedSteeringProducts,
  clearProducts,
} from 'views/wizard/shared/store/projectSlice';

import useConfirmationModal from '../helpers/useConfirmationModal';
import ConfirmationModal from './ConfirmationModal';

interface IWizardStep {
  progress: number;
  breadcrumb: ReactNode;
  children?: ReactNode;
  setLoading?: (state: boolean) => void;
}

const WizardStep = ({ progress, children, breadcrumb, setLoading }: IWizardStep) => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const project = useAppSelector(getCurrent);
  const selectedMotors = useAppSelector(getSelectedMotors);
  const selectedSteeringProducts = useAppSelector(getSelectedSteeringProducts);

  const isSelectionHasPropelProducts =
    project?.function_codes?.includes(FunctionCode.PropelSizing) &&
    selectedMotors &&
    selectedMotors.length;
  const isSelectionHasSteeringProducts =
    project?.function_codes?.includes(FunctionCode.SteeringSystem) &&
    selectedSteeringProducts &&
    selectedSteeringProducts.length;

  const navigateToRequirements = async () => {
    try {
      if (setLoading) {
        setLoading(true);
      }

      await dispatch(
        updateProject({
          id: projectId,
          project: {
            project_status: ProjectStatus.Draft,
          },
        }),
      ).unwrap();
      dispatch(clearProducts());

      if (setLoading) {
        setLoading(false);
      }

      history.push(`/requirements/${projectId}`);
    } catch {
      if (setLoading) {
        setLoading(false);
      }
    }
  };

  const {
    props: changeRequirementsConfirmationModalProps,
    open: openChangeRequirementsConfirmationModal,
    isOpen: isChangeRequirementsConfirmationModalOpen,
  } = useConfirmationModal(navigateToRequirements);

  const handleRequirementsClick = () => {
    if (!isSelectionHasPropelProducts && !isSelectionHasSteeringProducts) {
      navigateToRequirements();
    } else {
      openChangeRequirementsConfirmationModal();
    }
  };

  const handleProductsClick = async () => {
    try {
      if (setLoading) {
        setLoading(true);
      }

      await dispatch(
        updateProject({
          id: projectId,
          project: {
            project_status: ProjectStatus.CalculationsCompleted,
          },
        }),
      ).unwrap();

      if (setLoading) {
        setLoading(false);
      }

      history.push(`/product-selection/${projectId}`);
    } catch {
      if (setLoading) {
        setLoading(false);
      }
    }
  };

  const steps = [
    { title: formatMessage({ id: 'wizard_step_type' }) },
    {
      title: formatMessage({ id: 'wizard_step_requirements' }),
      handler: handleRequirementsClick,
    },
    {
      title: formatMessage({ id: 'wizard_step_products' }),
      handler: handleProductsClick,
    },
    { title: formatMessage({ id: 'wizard_step_summary' }) },
  ];

  return (
    <>
      <SubHeader className={utils.mb2} separator />
      <Breadcrumbs className={utils.mb4}>
        <BreadcrumbStep>{breadcrumb}</BreadcrumbStep>
      </Breadcrumbs>
      {children && <h2 data-testid="wizardstep-title">{children}</h2>}
      <Stepper steps={steps} progress={progress} />
      {isChangeRequirementsConfirmationModalOpen && (
        <ConfirmationModal
          headingLabel={formatMessage({
            id: 'wizard_product_change_requirements_modal_heading',
          })}
          confirmLabel={formatMessage({
            id: 'wizard_product_change_requirements_change',
          })}
          cancelLabel={formatMessage({
            id: 'wizard_product_change_requirements_cancel',
          })}
          {...changeRequirementsConfirmationModalProps}
        >
          <FormattedMessage id="wizard_product_change_requirements_text" />
        </ConfirmationModal>
      )}
    </>
  );
};

export default WizardStep;
