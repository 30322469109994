import { TableRowSelectedEventType, TableColumnType, RowType } from '@danfoss/mosaic';
import core from '@danfoss/mosaic/css/core.module.css';
import { DfTable } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { memo, useContext } from 'react';
import { renderToString } from 'react-dom/server';
import { FormattedMessage, useIntl } from 'react-intl';

import { round } from 'views/shared/helpers/formatters';
import {
  MeasuringSystemContext,
  MeasuringUnits,
} from 'views/shared/helpers/measuringSystem/MeasuringSystemContext';
import { convertAvailableMotor } from 'views/shared/helpers/measuringSystem/convertors';
import useMeasuringUnitsIntl from 'views/shared/helpers/measuringSystem/useMeasuringUnitsIntl';
import { IPrice, MotorType } from 'views/shared/types';

import { IAvailableMotor } from '../../../../api/products';
import styles from '../../../components/tables/ProductTable.module.scss';
import {
  usePriceCellFormatter,
  nameCell,
  capacityCell,
  rpmCell,
} from '../../../components/tables/helpers/formatters';
import { useScrollIntoRow } from '../../../components/tables/helpers/useScrollIntoRow';

interface MotorTableProps {
  list: IAvailableMotor[];
  onRowSelected?: (e: CustomEvent<TableRowSelectedEventType>) => void;
}

const MotorTable = ({ list, onRowSelected }: MotorTableProps) => {
  const { formatMessage } = useIntl();
  const { formatMeasuringUnit } = useMeasuringUnitsIntl();
  const { units } = useContext(MeasuringSystemContext);
  const defaultPriceFormatter = usePriceCellFormatter(useIntl());
  const MIN_ALLOWED_PERFORMANCE_COVERAGE = 50;

  const priceFormatter = (price: IPrice) => {
    const isPriceAvailable = price && price.value !== 0;

    return defaultPriceFormatter(price?.value, isPriceAvailable);
  };
  const motorsConvertor = (motors: IAvailableMotor[]): IAvailableMotor[] => {
    return motors.map(data => ({
      ...data,
      motor: convertAvailableMotor(data.motor, units),
    }));
  };
  const convertedList = units === MeasuringUnits.Imperial ? motorsConvertor(list) : list;
  const hasDisabledRows = convertedList.some(
    motor => Math.round(motor.capacity) < MIN_ALLOWED_PERFORMANCE_COVERAGE,
  );

  const getRowId = (originalRow: Record<string, any>) => (originalRow as IAvailableMotor).motor.id;

  const { ref, getRowProps } = useScrollIntoRow();

  const typeNames: Record<MotorType, string> = {
    [MotorType.VariablePiston]: formatMessage({ id: 'wizard_product_motor_variable_piston' }),
    [MotorType.FixedOrbital]: formatMessage({ id: 'wizard_product_motor_fixed_orbital' }),
    [MotorType.FixedPiston]: formatMessage({ id: 'wizard_product_motor_fixed_piston' }),
  };

  const columns: TableColumnType[] = [
    {
      accessor: 'motor.name',
      id: 'name',
      Header: formatMessage({ id: 'wizard_selection_modal_header_name' }),
      Cell: nameCell(formatMessage),
      getCellProps: () => ({ className: core.flexWrap }),
    },
    {
      accessor: 'motor.type',
      id: 'type',
      Header: formatMessage({ id: 'wizard_selection_modal_header_type' }),
      Cell: (value: MotorType) => typeNames[value],
      width: 140,
    },
    {
      accessor: 'capacity',
      id: 'capacity',
      Header: formatMessage({ id: 'wizard_selection_modal_header_capacity' }),
      getCellProps: () => ({ className: core.flexWrap }),
      Cell: capacityCell(formatMessage),
    },
    {
      accessor: 'motor.max_pressure',
      id: 'max_pressure',
      Header: formatMessage(
        { id: 'wizard_selection_modal_header_max_pressure' },
        {
          units: formatMeasuringUnit({
            metric: 'measurements_unit_bar',
            imperial: 'measurements_unit_psi',
          }),
        },
      ),
      getCellProps: () => ({ className: core.flexEnd }),
      Cell: value =>
        value ? renderToString(<span data-testid="motor-max-pressure">{round(value)}</span>) : '',
      width: 100,
    },
    {
      accessor: 'motor.rated_pressure',
      id: 'rated_pressure',
      Header: formatMessage(
        { id: 'wizard_selection_modal_header_rated_pressure' },
        {
          units: formatMeasuringUnit({
            metric: 'measurements_unit_bar',
            imperial: 'measurements_unit_psi',
          }),
        },
      ),
      getCellProps: () => ({ className: core.flexEnd }),
      Cell: value =>
        value ? renderToString(<span data-testid="motor-rated-pressure">{round(value)}</span>) : '',
      width: 100,
    },
    {
      accessor: 'motor.speed_limit_max',
      id: 'speed_limit_max',
      Header: formatMessage({ id: 'wizard_selection_modal_header_speed_limit_max' }),
      getCellProps: () => ({ className: core.flexEnd }),
      width: 100,
      Cell: rpmCell('motor_speed_limit_max'),
    },
    {
      accessor: 'motor.speed_limit_rated',
      id: 'speed_limit_rated',
      Header: formatMessage({ id: 'wizard_selection_modal_header_speed_limit_rated' }),
      getCellProps: () => ({ className: core.flexEnd }),
      width: 120,
      Cell: rpmCell('motor_speed_limit_rated'),
    },
    {
      accessor: 'price',
      id: 'price',
      Header: formatMessage({ id: 'wizard_selection_modal_header_price' }),
      Cell: priceFormatter,
      width: 150,
    },
  ];

  const getMotorTableRowProps = (row: RowType) => {
    const { className } = getRowProps(row);
    const isSmallCoverage = Math.round(row.original.capacity) < MIN_ALLOWED_PERFORMANCE_COVERAGE;

    return {
      className: cn(className, isSmallCoverage && styles.disabled),
      'data-testid': 'motor-table-row',
    };
  };

  return (
    <div className={core.fullWidth}>
      {hasDisabledRows && (
        <p data-testid="grayed-options-label">
          <FormattedMessage id="wizard_motor_modal_grayed_options_label" />
        </p>
      )}
      <DfTable
        ref={ref}
        className={styles.table}
        columns={columns}
        data={convertedList}
        isSelectable
        isSortable={false}
        isOneRowSelection
        isSelectableOnClick
        selectedRows={[convertedList.find(({ recommended }) => recommended)?.motor.id as string]}
        getRowId={getRowId}
        onRowSelected={onRowSelected}
        getRowProps={getMotorTableRowProps}
      />
    </div>
  );
};

export default memo(MotorTable);
