export const getInitials = ({
  family_name,
  given_name,
}: {
  family_name?: string;
  given_name?: string;
}) => {
  if (!family_name || !given_name) {
    return '';
  }
  return given_name[0] + family_name[0];
};
