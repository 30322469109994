import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { ReactElement } from 'react';

import { ISelectedProduct } from 'views/shared/types';

import ProductImage from '../../ProductImage/ProductImage';
import styles from './ProductItem.module.scss';

interface ProductItemProps {
  data: Pick<ISelectedProduct, 'id' | 'series' | 'name' | 'photo_url'>;
  price?: ReactElement;
  specs: string;
  imageAlt: string;
}

const ProductItem = ({ data, price, specs, imageAlt }: ProductItemProps) => {
  const { id, series, name, photo_url } = data;

  return (
    <div
      className={cn(utils.py5, utils.px4, core.flex, core.alignCenter, styles.container)}
      data-testid={`product-item-${id}`}
    >
      <div className={cn(utils.px4, core.flex, core.alignCenter, core.flexAround)}>
        <ProductImage photoUrl={photo_url} width={100} height={100} alt={imageAlt} />
      </div>
      <div className={cn(utils.ml4, core.flex, core.flexColumn, styles.description)}>
        <p data-testid="product-series">{series}</p>
        <h2 className={styles.title} data-testid="product-name">
          {name}
        </h2>
        <p className={core.textBold} data-testid="product-spec">
          {specs}
        </p>
      </div>
      <span data-testid={`${id}-product-price`}>{price}</span>
    </div>
  );
};

export default ProductItem;
