import tabs from '@danfoss/mosaic/css/components/tabs.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { ReactNode, MouseEvent, useState } from 'react';
import { FormattedMessage } from 'react-intl';

export enum SummaryTabsIds {
  documents = 'documents',
  products = 'products',
  performance = 'performance',
}

interface ISummaryTabsProps {
  products: ReactNode;
  documents: ReactNode;
  performance?: ReactNode;
}

const SummaryTabs = ({ products, documents, performance }: ISummaryTabsProps) => {
  const [selectedTab, setSelectedTab] = useState<SummaryTabsIds>(SummaryTabsIds.products);
  const [activatedTabs, setActivatedTabs] = useState(
    new Set<SummaryTabsIds>([SummaryTabsIds.products]),
  );

  const onTabChange = ({ target }: MouseEvent<HTMLAnchorElement>) => {
    const newTabId = (target as HTMLAnchorElement).dataset.tabid as SummaryTabsIds;

    setActivatedTabs(currentTabs => {
      currentTabs.add(newTabId);

      return new Set(currentTabs);
    });
    setSelectedTab(newTabId);
  };

  return (
    <div className={cn(tabs.tabs, tabs.horizontal)} data-testid="summary-tabs">
      <nav className={tabs.tabsNav}>
        <ul className={tabs.tabsList}>
          <li
            className={cn(tabs.tabHeader, selectedTab === SummaryTabsIds.products && tabs.selected)}
            data-testid={`${SummaryTabsIds.products}-tab`}
          >
            <a
              className={tabs.tab}
              onClick={onTabChange}
              data-tabid={SummaryTabsIds.products}
              data-testid={`${SummaryTabsIds.products}-link`}
            >
              <FormattedMessage id="summary_tabs_products_title" />
            </a>
          </li>
          {performance && (
            <li
              className={cn(
                tabs.tabHeader,
                selectedTab === SummaryTabsIds.performance && tabs.selected,
              )}
              data-testid={`${SummaryTabsIds.performance}-tab`}
            >
              <a
                className={tabs.tab}
                onClick={onTabChange}
                data-tabid={SummaryTabsIds.performance}
                data-testid={`${SummaryTabsIds.performance}-link`}
              >
                <FormattedMessage id="summary_tabs_performance_title" />
              </a>
            </li>
          )}
          <li
            className={cn(
              tabs.tabHeader,
              selectedTab === SummaryTabsIds.documents && tabs.selected,
            )}
            data-testid={`${SummaryTabsIds.documents}-tab`}
          >
            <a
              className={tabs.tab}
              onClick={onTabChange}
              data-tabid={SummaryTabsIds.documents}
              data-testid={`${SummaryTabsIds.documents}-link`}
            >
              <FormattedMessage id="summary_tabs_documents_title" />
            </a>
          </li>
        </ul>
      </nav>
      <div>
        {activatedTabs.has(SummaryTabsIds.products) && (
          <div className={cn(selectedTab !== SummaryTabsIds.products && tabs.hidden)}>
            <div className={utils.my4} data-testid={`${SummaryTabsIds.products}-content`}>
              {products}
            </div>
          </div>
        )}
        {activatedTabs.has(SummaryTabsIds.performance) && (
          <div className={cn(selectedTab !== SummaryTabsIds.performance && tabs.hidden)}>
            <div className={utils.my4} data-testid={`${SummaryTabsIds.performance}-content`}>
              {performance}
            </div>
          </div>
        )}
        {activatedTabs.has(SummaryTabsIds.documents) && (
          <div className={cn(selectedTab !== SummaryTabsIds.documents && tabs.hidden)}>
            <div className={utils.my4} data-testid={`${SummaryTabsIds.documents}-content`}>
              {documents}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SummaryTabs;
