const debounce = <T extends (...args: any[]) => void>(fn: T, time: number) => {
  let timer: ReturnType<typeof setTimeout>;
  const debounced = (...args: Parameters<T>) => {
    clearTimeout(timer);
    return new Promise<ReturnType<T>>(resolve => {
      timer = setTimeout(() => resolve(fn(...args) as ReturnType<T>), time);
    });
  };

  debounced.cancel = () => {
    clearTimeout(timer);
  };

  debounced.immediate = fn;

  return debounced;
};

export default debounce;
