import link from '@danfoss/mosaic/css/components/link.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { getAppConfig } from 'configs/global';

import styles from './NoDocuments.module.scss';

const NoDocuments = () => {
  const { DC_URL } = getAppConfig();
  const technicalLiteratureUrl = `${DC_URL}/s/technical-literature`;

  const url = (
    <a
      href={technicalLiteratureUrl}
      target="_blank"
      rel="noreferrer"
      className={link.linkSecondary}
    >
      <FormattedMessage id="documents_no_documents_link" />
    </a>
  );

  return (
    <div className={cn(core.flex, core.alignCenter, utils.my20)} data-testid="no-documents">
      <div className={styles.wrapper}>
        <h1 className={utils.mb2}>
          <FormattedMessage id="documents_no_documents_title" />
        </h1>
        <p>
          <FormattedMessage id="documents_no_documents_text" values={{ link: url }} />
        </p>
      </div>
    </div>
  );
};

export default NoDocuments;
