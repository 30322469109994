import button from '@danfoss/mosaic/css/components/button.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import React, { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { SystemArchitecture } from 'views/shared/types';
import RollingRadiusExplanationModal from 'views/wizard/product-selection/sections/Motors/components/MotorWidget/components/EditableAttributes/RollingRadiusExplanationModal';
import { TractiveForceValidation } from 'views/wizard/requirements/helpers/types';

import { IRequirementsForm } from '../../../../types';
import { MultipleMotorsRollingRadiuses } from './MultipleMotorsRollingRadiuses';
import { RollingRadiusField } from './RollingRadiusField';

export interface RollingRadiusProps {
  form: UseFormReturn<IRequirementsForm>;
  initialArchitecture: SystemArchitecture;
  validation?: TractiveForceValidation;
}

export function RollingRadius({ form, validation, initialArchitecture }: RollingRadiusProps) {
  const [isOpened, setIsOpened] = useState(false);

  const open = () => setIsOpened(true);
  const close = () => setIsOpened(false);

  const isMultipleMotors = initialArchitecture === SystemArchitecture.OnePumpTwoMotors;
  const isDualPath = initialArchitecture === SystemArchitecture.DualPath;
  const singleMotorArchitectures = [
    SystemArchitecture.OnePumpOneMotor,
    SystemArchitecture.OnePumpOneMotorGearBox,
  ];
  const isSingleMotor = singleMotorArchitectures.includes(initialArchitecture);

  return (
    <>
      <h5 className={utils.mt5} data-testid="rolling-radius-header">
        <FormattedMessage id="wizard_requirements_rolling_radius" />
        <button
          className={cn(utils.ml3, button.textBtn, button.textBtnPrimary)}
          type="button"
          onClick={open}
          data-testid="rolling-radius-details-button"
        >
          <FormattedMessage id="wizard_requirements_view_details" />
        </button>
      </h5>

      {isMultipleMotors && <MultipleMotorsRollingRadiuses form={form} validation={validation} />}
      {isDualPath && (
        <div className={utils.row}>
          <RollingRadiusField
            className={utils.col6}
            form={form}
            validation={validation}
            number={1}
          />
          <RollingRadiusField
            className={utils.col6}
            form={form}
            validation={validation}
            number={2}
          />
        </div>
      )}
      {isSingleMotor && <RollingRadiusField form={form} validation={validation} number={1} />}

      {isOpened && <RollingRadiusExplanationModal onClose={close} />}
    </>
  );
}
