import core from '@danfoss/mosaic/css/core.module.css';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { roundPrice } from 'views/shared/helpers/formatters';
import { IPrice } from 'views/shared/types';

interface IProductItemPriceProps {
  price?: IPrice;
}

const ProductItemPrice = ({ price }: IProductItemPriceProps) => {
  const { formatNumber } = useIntl();
  const isPriceAvailable = price && price.value !== 0;

  return (
    <div className={cn(core.flex, core.flexColumn, core.alignEnd)}>
      <p>
        <FormattedMessage id="products_table_price_label" />
      </p>
      <h3>
        {!isPriceAvailable && (
          <span className={core.textRegular} data-testid="price-unavailable">
            <FormattedMessage id="data_unavailable" />
          </span>
        )}
        {isPriceAvailable && (
          <>
            <span className={core.textRegular}>
              <FormattedMessage id="products_table_price_starts_at" />
            </span>{' '}
            <FormattedMessage id="currency_euro_symbol" /> {formatNumber(roundPrice(price.value))}
          </>
        )}
      </h3>
    </div>
  );
};

export default ProductItemPrice;
