import breadcrumbs from '@danfoss/mosaic/css/components/breadcrumbs.module.css';
import { FunctionComponent } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

interface IBreadcrumbsProps {
  className?: string;
}

const Breadcrumbs: FunctionComponent<IBreadcrumbsProps> = ({ children, className }) => {
  return (
    <nav aria-label="Breadcrumb navigation" className={className} data-testid="breadcrumbs">
      <ul className={breadcrumbs.breadcrumbs}>
        <li className={breadcrumbs.breadcrumb}>
          <Link to="/">
            <FormattedMessage id="wizard_breadcrumbs_home" />
          </Link>
        </li>
        {children}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;
