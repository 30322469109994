import axios from 'axios';

import { getAppConfig } from '../configs/global';

export interface IGlobalWarning {
  message: string;
}

export const getGlobalWarnings = async (locale: string) => {
  const functionsUrl = getAppConfig().AZURE_FUNCTIONS_URL;
  const { data } = await axios.get<IGlobalWarning[]>(`${functionsUrl}/api/WarningsFunction`, {
    params: { locale },
  });

  return data;
};
