import core from '@danfoss/mosaic/css/core.module.css';
import { memo } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';

import { roundPrice } from 'views/shared/helpers/formatters';
import { ISelectedProducts } from 'views/shared/types';

interface IPriceProps {
  data: ISelectedProducts;
}

const Price = ({ data }: IPriceProps) => {
  const { motors, pumps, steering_products } = data;
  const productsList = [...motors, ...pumps, ...steering_products];
  const totalPrice = productsList.reduce((total, { price }) => total + (price?.value ?? 0), 0);

  return (
    <div data-testid="price">
      <div>
        <FormattedMessage id="compare_page_list_price_label" />
      </div>
      <h3 className={core.textBold} data-testid="total-price">
        <FormattedMessage id="currency_euro_symbol" />
        <FormattedNumber value={roundPrice(totalPrice)} />
      </h3>
    </div>
  );
};

export default memo(Price);
