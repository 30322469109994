import { ReactComponent as SvgGears } from '@danfoss/mosaic-icons/dist/svg/settings.svg';
import components from '@danfoss/mosaic/css/components.module.css';
import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfContextMenu } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { ChangeEvent, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Fieldset from 'views/shared/components/form/Fieldset';
import Label from 'views/shared/components/form/Label/Label';
import {
  MeasuringSystemContext,
  MeasuringUnits,
  storage,
} from 'views/shared/helpers/measuringSystem/MeasuringSystemContext';

import styles from './ConfigMenu.module.scss';

const ConfigMenu = () => {
  const { units, setUnits } = useContext(MeasuringSystemContext);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);

  const onSettingsOpen = () => setIsSettingsOpen(true);
  const onSettingsClose = () => setIsSettingsOpen(false);
  const onUnitsChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    setUnits(value as MeasuringUnits);
    storage.setUnits(value as MeasuringUnits);
    setIsSettingsOpen(false);
  };

  return (
    <div className={styles['config-wrapper']}>
      <button
        className={cn(button.btnSecondary, styles['config-button'])}
        onClick={onSettingsOpen}
        data-testid="settings-menu"
        type="button"
      >
        <SvgGears className={core.icon} />
      </button>
      <DfContextMenu
        posX={25}
        posY={25}
        onOutsideClick={onSettingsClose}
        data-testid="settings-context-menu"
        isOpen={isSettingsOpen}
        isRightAsXPosition
      >
        <div className={cn(utils.px6, utils.py4)}>
          <Fieldset className={components.radioGroup}>
            <Label className={cn(utils.mb3, core.textBold)}>
              <FormattedMessage id="settings_menu_unit_system_label" />
            </Label>
            <div className={components.radio}>
              <input
                type="radio"
                id="metric-system"
                name="switch-units-system"
                value={MeasuringUnits.Metric}
                data-testid="metric-system"
                checked={units === MeasuringUnits.Metric}
                onChange={onUnitsChange}
              />
              <label htmlFor="metric-system">
                <FormattedMessage id="settings_menu_unit_system_metric" />
              </label>
            </div>
            <div className={components.radio}>
              <input
                type="radio"
                id="imperial-system"
                name="switch-units-system"
                value={MeasuringUnits.Imperial}
                data-testid="imperial-system"
                checked={units === MeasuringUnits.Imperial}
                onChange={onUnitsChange}
              />
              <label htmlFor="imperial-system">
                <FormattedMessage id="settings_menu_unit_system_imperial" />
              </label>
            </div>
          </Fieldset>
        </div>
      </DfContextMenu>
    </div>
  );
};

export default ConfigMenu;
