import { ReactComponent as SvgSuccess } from '@danfoss/mosaic-icons/dist/svg/check-circle-outline.svg';
import alert from '@danfoss/mosaic/css/components/alert.module.css';
import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './Toast.module.scss';

interface IToastSuccessProps {
  heading: ReactNode;
  children: ReactNode;
  onClose: () => void;
}

const ToastSuccess = ({ children, heading, onClose }: IToastSuccessProps) => {
  return (
    <div className={cn(alert.alert, alert.alertSuccess, styles.align)} data-testid="success-toast">
      <SvgSuccess className={cn(core.icon, alert.alertIcon)} />
      <div className={alert.alertMessage}>
        <p className={alert.alertHeading}>{heading}</p>
        <p>{children}</p>
      </div>
      <button
        className={cn(button.textBtn, button.textBtnSecondary, utils.ml4)}
        onClick={onClose}
        data-testid="close-alert-button"
      >
        <FormattedMessage id="toast_close_button" />
      </button>
    </div>
  );
};

export default ToastSuccess;
