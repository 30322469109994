import { useCallback } from 'react';
import { useIntl } from 'react-intl';

import ProductItem from 'views/shared/components/ProductsTable/ProductItem/ProductItem';
import ProductItemPrice from 'views/shared/components/ProductsTable/ProductItem/ProductItemPrice';
import useMeasuringUnitsIntl from 'views/shared/helpers/measuringSystem/useMeasuringUnitsIntl';
import useMeasuringValueConvertor from 'views/shared/helpers/measuringSystem/useMeasuringValueConvertor';

import { IPrice, ISelectedMotor, ISelectedPump, ISelectedSteeringProduct } from '../../types';

export function useRenderProducts(prices?: Partial<Record<string, IPrice>>) {
  const { formatMessage } = useIntl();
  const { formatMeasuringUnit } = useMeasuringUnitsIntl();
  const { convertor } = useMeasuringValueConvertor();

  const renderMotor = useCallback(
    (motor: ISelectedMotor): JSX.Element => {
      const value = convertor('cm3', 'in3', motor.related_motor_info.max_displacement);
      const unit = formatMeasuringUnit({
        metric: 'measurements_unit_cm3_rev',
        imperial: 'measurements_unit_in3_rev',
      });

      return (
        <ProductItem
          key={motor.id}
          data={motor}
          price={prices ? <ProductItemPrice price={prices[motor.product_id]} /> : undefined}
          specs={`${value} ${unit}`}
          imageAlt={formatMessage({ id: 'products_image_motor_alt' })}
        />
      );
    },
    [prices, convertor, formatMeasuringUnit, formatMessage],
  );

  const renderPump = useCallback(
    (pump: ISelectedPump): JSX.Element => {
      const value = convertor('bar', 'psi', pump.rated_pressure);
      const units = formatMeasuringUnit({
        metric: 'measurements_unit_bar',
        imperial: 'measurements_unit_psi',
      });

      return (
        <ProductItem
          key={pump.id}
          data={pump}
          price={prices ? <ProductItemPrice price={prices[pump.product_id]} /> : undefined}
          specs={`${value} ${units}`}
          imageAlt={formatMessage({ id: 'products_image_pump_alt' })}
        />
      );
    },
    [prices, convertor, formatMeasuringUnit, formatMessage],
  );

  const renderSteeringProduct = useCallback(
    (product: ISelectedSteeringProduct): JSX.Element => {
      let specs = '';

      if (product.spool) {
        const value = convertor('l', 'gal', product.spool);
        const units = formatMeasuringUnit({
          metric: 'measurements_unit_l_min',
          imperial: 'measurements_unit_gal_min',
        });

        specs = `${value} ${units}`;
      }

      if (product.displacement) {
        const value = convertor('cm3', 'in3', product.displacement);
        const units = formatMeasuringUnit({
          metric: 'measurements_unit_cm3_rev',
          imperial: 'measurements_unit_in3_rev',
        });

        specs = `${value} ${units}`;
      }

      return (
        <ProductItem
          key={product.id}
          data={product}
          price={prices ? <ProductItemPrice price={prices[product.product_id]} /> : undefined}
          specs={specs}
          imageAlt={formatMessage({ id: 'products_image_steering_product_alt' })}
        />
      );
    },
    [prices, convertor, formatMeasuringUnit, formatMessage],
  );

  return {
    renderMotor,
    renderPump,
    renderSteeringProduct,
  };
}
