import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

interface IToastErrorProps {
  children: ReactNode;
  reload: boolean;
  onClose: () => void;
}

const ToastError = ({ reload, onClose, children }: IToastErrorProps) => {
  const onReload = () => window.location.reload();

  return (
    <div className={cn(core.flex, core.flexBetween)} data-testid="error-toast">
      <p>
        {children}
        {reload && (
          <button
            className={cn(button.textBtn, button.textBtnPrimary, utils.ml4)}
            onClick={onReload}
            data-testid="reload-page-button"
          >
            <FormattedMessage id="toast_reload_button" />
          </button>
        )}
      </p>
      <button
        className={cn(button.textBtn, button.textBtnPrimary, utils.ml4)}
        onClick={onClose}
        data-testid="close-alert-button"
      >
        <FormattedMessage id="toast_close_button" />
      </button>
    </div>
  );
};

export default ToastError;
