import core from '@danfoss/mosaic/css/core.module.css';
import cn from 'classnames';
import React, { ReactNode, useContext } from 'react';
import { FieldErrors, FieldError, get } from 'react-hook-form';
import { useIntl } from 'react-intl';

import FormatMeasureValue from 'views/shared/helpers/measuringSystem/FormatMeasureValue';
import {
  MeasuringSystemContext,
  MeasuringUnits,
} from 'views/shared/helpers/measuringSystem/MeasuringSystemContext';
import { Units } from 'views/shared/helpers/measuringSystem/convertors';

import { IValidationRules } from './types';

type RulesList = Partial<keyof IValidationRules>;

export type NestedRules = {
  [key: string]: IValidationRules | NestedRules;
};

export interface IFormErrorProps {
  errors: FieldErrors;
  rules: NestedRules;
  field: string;
  metric?: Units;
  imperial?: Units;
}

const defaultMetricMessages = {
  required: 'default_required_error',
  min: 'default_min_error',
  max: 'default_max_error',
};

const defaultImperialMessages = {
  ...defaultMetricMessages,
  min: 'default_imperial_min_error',
  max: 'default_imperial_max_error',
};

const getFieldName = (fieldName: string) => {
  const paths = fieldName.split('.');

  return paths[paths.length - 1];
};

const FormError = ({ rules, errors = {}, field, metric, imperial }: IFormErrorProps) => {
  const { formatMessage } = useIntl();
  const { units } = useContext(MeasuringSystemContext);
  const isImperialSystem = units === MeasuringUnits.Imperial;
  const error = get(errors, field) as FieldError;

  if (!error) {
    return null;
  }

  let message: ReactNode;

  if (error.message) {
    message = error.message;
  } else {
    const type = error.type as RulesList;
    const fieldRules = get(rules, getFieldName(field)) as IValidationRules;
    const ruleValue = fieldRules?.[type];
    const isConversionRequired = imperial && metric;
    const messagePayload = isConversionRequired ? (
      <FormatMeasureValue metric={metric} imperial={imperial} value={ruleValue as number} />
    ) : (
      ruleValue
    );
    const messageId = isImperialSystem
      ? defaultImperialMessages[type]
      : defaultMetricMessages[type];

    message = formatMessage(
      { id: messageId },
      {
        [type]: messagePayload,
      },
    );
  }

  return (
    <label className={cn(core.helperText, core.error)} data-testid={`error-${field}`}>
      {message}
    </label>
  );
};

export default FormError;
