import button from '@danfoss/mosaic/css/components/button.module.css';
import cn from 'classnames';
import { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'views/shared/components/Button/Button';
import { sendToDC } from 'views/shared/helpers/designCenterService';
import { IProject } from 'views/shared/types';

import styles from './SendToDC.module.scss';

interface ISendToDCProps {
  project: IProject;
}

const SendToDC = ({ project }: ISendToDCProps) => {
  const { id } = project;
  const [isSending, setIsSending] = useState(false);
  const onSendClick = async () => {
    try {
      setIsSending(true);
      await sendToDC(id);
    } finally {
      setIsSending(false);
    }
  };

  return (
    <Button
      className={cn(button.textBtn, button.textBtnPrimary, styles.button)}
      loading={isSending}
      onClick={onSendClick}
      data-testid="send-to-dc-button"
    >
      <FormattedMessage id="transfer_selection_button" />
    </Button>
  );
};

export default memo(SendToDC);
