import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import type { RootState } from 'configs/store';

export interface ProductFilterState {
  authorizedProducts: {
    motors: boolean;
    pumps: boolean;
    steering: boolean;
  };
  pressureLimit: {
    motors: boolean;
  };
}

export const initialState: ProductFilterState = {
  authorizedProducts: {
    motors: false,
    pumps: false,
    steering: false,
  },
  pressureLimit: {
    motors: true,
  },
};

export const getPumpsAuthorizedFilter = (state: RootState) =>
  state.productFilter.authorizedProducts.pumps;
export const getMotorsAuthorizedFilter = (state: RootState) =>
  state.productFilter.authorizedProducts.motors;
export const getSteeringAuthorizedFilter = (state: RootState) =>
  state.productFilter.authorizedProducts.steering;

export const getMotorsPressureLimitFilter = (state: RootState) =>
  state.productFilter.pressureLimit.motors;

const productFilterSlice = createSlice({
  name: 'productFilter',
  initialState,
  reducers: {
    setMotorsAuthorizedFilter(state, action: PayloadAction<boolean>) {
      state.authorizedProducts.motors = action.payload;
    },
    setPumpsAuthorizedFilter(state, action: PayloadAction<boolean>) {
      state.authorizedProducts.pumps = action.payload;
    },
    setSteeringAuthorizedFilter(state, action: PayloadAction<boolean>) {
      state.authorizedProducts.steering = action.payload;
    },
    setMotorsPressureLimitFilter(state, action: PayloadAction<boolean>) {
      state.pressureLimit.motors = action.payload;
    },
  },
});

export const {
  setMotorsAuthorizedFilter,
  setPumpsAuthorizedFilter,
  setSteeringAuthorizedFilter,
  setMotorsPressureLimitFilter,
} = productFilterSlice.actions;

export default productFilterSlice.reducer;
