import button from '@danfoss/mosaic/css/components/button.module.css';
import input from '@danfoss/mosaic/css/components/input.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfLoader, DfModal, DfTooltip } from '@danfoss/mosaic/react';
import cn from 'classnames';
import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as ChainSvg } from 'assets/icons/chain.svg';
import { ReactComponent as UpSvg } from 'assets/icons/up.svg';
import { getAppConfig } from 'configs/global';
import Checkbox from 'views/shared/components/form/Checkbox';
import Fieldset from 'views/shared/components/form/Fieldset';
import Label from 'views/shared/components/form/Label/Label';
import {
  createLink,
  deleteLink,
  getLink as getLinkAPI,
  updateLink,
} from 'views/sharing/api/sharing-api';

import { ISharedLink } from '../types';
import styles from './ShareModal.module.scss';

interface IShareModalProps {
  projectId: string;
  onClose: () => void;
}

const ShareModal = ({ onClose, projectId }: IShareModalProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [linkDetails, setLinkDetails] = useState<ISharedLink | null>();
  const { formatMessage } = useIntl();
  const modalTitle = formatMessage({ id: 'share_modal_title' });
  const [linkTitle, setLinkTitle] = useState<string>('');
  const [isWarning, setIsWarning] = useState(false);
  const { STATIC_SITE_DOMAIN } = getAppConfig();
  const link = linkDetails ? `https://${STATIC_SITE_DOMAIN}/sharing/${linkDetails.id}` : '';

  const handleLinkStatus = async () => {
    try {
      setIsLoading(true);

      if (linkDetails) {
        await deleteLink(linkDetails.id);

        setLinkDetails(null);
      } else {
        const linkData = await createLink(projectId);

        setLinkDetails(linkData);
      }
    } finally {
      setIsLoading(false);
    }
  };
  const onTitleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const maxTitleLength = 25;
    const { value } = event.target;

    if (value.length > maxTitleLength) {
      setIsWarning(true);
      return;
    }

    setIsWarning(false);
    setLinkTitle(value);
  };
  const onTitleBlur = async (event: FormEvent) => {
    const { value } = event.target as HTMLInputElement;

    if (value === linkDetails!.title) {
      return;
    }

    try {
      setIsLoading(true);
      const updatedLinkData = await updateLink(linkDetails!.id, value);

      setLinkDetails(updatedLinkData);
    } finally {
      setIsLoading(false);
    }
  };
  const onOpenClick = () => {
    window.open(link);
  };
  const onCopyClick = () => {
    navigator.clipboard.writeText(link);
  };

  useEffect(() => {
    async function getLink() {
      try {
        setIsLoading(true);

        const linkData = await getLinkAPI(projectId);

        if (linkData) {
          setLinkDetails(linkData);
        } else {
          const newLinkData = await createLink(projectId);
          setLinkDetails(newLinkData);
        }
      } finally {
        setIsLoading(false);
      }
    }

    getLink();
  }, []);

  useEffect(() => {
    if (linkDetails) {
      setLinkTitle(linkDetails.title || '');
    }
  }, [linkDetails]);

  return (
    <DfModal
      onModalClose={onClose}
      heading={modalTitle}
      size="medium"
      isOpen
      className={styles.wrapper}
      data-testid="share-modal"
    >
      <div slot="modal-body" className={styles.content} data-testid="share-dialog">
        <DfLoader isVisible={isLoading} data-testid="share-modal-loading">
          <FormattedMessage id="share_modal_link_description" />
          <Checkbox
            id="activate-link"
            className={cn(utils.mt4, utils.mb6)}
            checked={!!linkDetails}
            onChange={handleLinkStatus}
            data-testid="activate-link"
          >
            <FormattedMessage id="share_modal_activate_link" />
          </Checkbox>
          {linkDetails && (
            <>
              <Fieldset>
                <Label className={styles.label}>
                  <FormattedMessage id="share_modal_generated_link" />
                </Label>
                <input
                  className={cn(input.input, { [input.warning]: isWarning })}
                  data-testid="link-title"
                  value={linkTitle}
                  onChange={onTitleChange}
                  onBlur={onTitleBlur}
                  placeholder={formatMessage({ id: 'share_modal_title_field_placeholder' })}
                />
                <label
                  className={cn(core.helperText, utils.mb4, { [core.warning]: isWarning })}
                  data-testid="rename-modal-helper"
                >
                  <FormattedMessage id="share_modal_title_field_help_text" />
                </label>
                <input className={input.input} data-testid="shared-link" value={link} readOnly />
              </Fieldset>
              <div className={cn(utils.mt2, core.flex, core.alignCenter)}>
                <DfTooltip trigger="click" placement="top" arrowOffset={1}>
                  <div slot="tooltip-reference">
                    <button
                      className={cn(button.textBtn, button.textBtnPrimary, styles.button)}
                      onClick={onCopyClick}
                      data-testid="copy-link"
                    >
                      <ChainSvg className={utils.mr2} />
                      <FormattedMessage id="share_modal_copy_link" />
                    </button>
                  </div>
                  <span slot="tooltip-content">
                    <FormattedMessage id="share_modal_copy_button_tooltip" />
                  </span>
                </DfTooltip>
                <button
                  className={cn(button.textBtn, button.textBtnPrimary, utils.ml10, styles.button)}
                  onClick={onOpenClick}
                  data-testid="open-link"
                >
                  <UpSvg className={utils.mr2} />
                  <FormattedMessage id="share_modal_open_link" />
                </button>
              </div>
            </>
          )}
        </DfLoader>
      </div>
      <div slot="modal-footer">
        <div className={cn(core.flex, core.flexEnd)}>
          <button
            className={cn(button.textBtn, button.textBtnSecondary)}
            onClick={() => onClose()}
            data-testid="share-close-button"
          >
            <FormattedMessage id="share_modal_close_button" />
          </button>
        </div>
      </div>
    </DfModal>
  );
};

export default ShareModal;
