import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfLoader } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import styles from './ModalLoader.module.scss';

const ModalLoader = () => (
  <div className={cn(core.flex, core.alignCenter, core.flexCenter, styles['loader-wrapper'])}>
    <DfLoader isVisible></DfLoader>
    <span
      className={cn(utils.mt16, core.textSemibold, utils.colorNeutralBase, styles['loader-text'])}
    >
      <FormattedMessage id="wizard_selection_modal_loading_products" />
    </span>
  </div>
);

export default ModalLoader;
