import axios from 'axios';

export interface ISendToDCParams {
  project_id: string;
  design_center_project_id: string;
  design_center_contact_id: string;
}

interface ISendToDCResponse {
  design_center_uri: string;
  products_statuses: {
    product_id: string;
    is_configuration_creation_succeeded: boolean;
  }[];
}

export const sendToDC = async (params: ISendToDCParams) => {
  const { data } = await axios.post<ISendToDCResponse>('/design-center-configurations', params);

  return data;
};
