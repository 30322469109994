import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { useEffect, useState } from 'react';

import { getGlobalWarnings, IGlobalWarning } from 'api/warnings';

const GlobalWarnings = () => {
  const locale = navigator.language.toLocaleLowerCase();
  const [warnings, setWarnings] = useState<IGlobalWarning[]>();

  useEffect(() => {
    (async () => {
      const data = await getGlobalWarnings(locale);

      setWarnings(data);
    })();
  }, []);

  if (!warnings || !warnings.length) {
    return null;
  }

  return (
    <div
      className={cn(utils.bgColorSecondary, utils.colorSurfaceBase, utils.py4)}
      data-testid="global-warnings"
    >
      <div className={core.container}>
        {warnings.map(({ message }, index) => {
          return <p key={index}>{message}</p>;
        })}
      </div>
    </div>
  );
};

export default GlobalWarnings;
