import core from '@danfoss/mosaic/css/core.module.css';
import cn from 'classnames';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import ConfigMenu from 'views/base/ConfigMenu/ConfigMenu';

import styles from './SubHeader.module.scss';

interface ISubHeader {
  separator?: boolean;
  className?: string;
}

const SubHeader = ({ separator, className }: ISubHeader) => {
  const containerClasses = cn(
    core.flex,
    core.flexBetween,
    core.alignCenter,
    { [styles.separator]: separator },
    styles.container,
    className,
  );

  return (
    <div className={containerClasses} data-testid="subheader-container">
      <h1>
        <FormattedMessage id="home_title" />
      </h1>
      <ConfigMenu />
    </div>
  );
};

export default memo(SubHeader);
