import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { ProjectStatus } from 'views/shared/types';

const useRedirectByStatus = (
  projectId: string | undefined,
  projectStatus: ProjectStatus | undefined,
  allowedProjectStatuses: ProjectStatus[],
) => {
  const history = useHistory();

  const initialValues = useRef([projectId, projectStatus] as const);

  let [initialProjectId, initialProjectStatus] = initialValues.current;

  if (initialProjectId !== projectId || (!initialProjectStatus && projectStatus)) {
    initialValues.current = [projectId, projectStatus];

    [initialProjectId, initialProjectStatus] = initialValues.current;
  }

  useEffect(() => {
    if (
      !projectId ||
      !initialProjectStatus ||
      allowedProjectStatuses.includes(initialProjectStatus)
    ) {
      return;
    }

    if (initialProjectStatus === ProjectStatus.CalculationsCompleted) {
      history.push(`/product-selection/${projectId}`);
    }

    if (initialProjectStatus === ProjectStatus.Draft) {
      history.push(`/requirements/${projectId}`);
    }

    if (initialProjectStatus === ProjectStatus.Configured) {
      history.push(`/summary/${projectId}`);
    }
  }, [initialProjectId, initialProjectStatus]);
};

export default useRedirectByStatus;
