import checkbox from '@danfoss/mosaic/css/components/checkbox.module.css';
import cn from 'classnames';
import { forwardRef } from 'react';

interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {
  id: string;
  labelClassName?: string;
  'data-testid': string;
}

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  ({ id, children, className, labelClassName, ...rest }, ref) => {
    return (
      <div
        data-testid={`${rest['data-testid']}-container`}
        className={cn(checkbox.checkbox, className)}
      >
        <input id={id} type="checkbox" ref={ref} {...rest} />
        <label htmlFor={id} className={labelClassName}>
          {children}
        </label>
      </div>
    );
  },
);

export default Checkbox;
