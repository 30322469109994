import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfLoader } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { MouseEventHandler, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import helpers from 'styles/helpers.module.scss';

import { useAppDispatch, useAppSelector } from 'configs/store';
import { MotorType } from 'views/shared/types';
import { useMotorTypeContext } from 'views/wizard/product-selection/motor-type-context/motorTypeContext';
import { showOverSpeedingWarning } from 'views/wizard/shared/helpers/showOverSpeedingWarning';

import {
  autoUpdateProducts,
  getCalculations,
  getCurrent,
  getMotorDetailsAndCalculations,
  getMotorsPrices,
  getPropelSizingData,
  getPumpsPrices,
  getSelectedMotors,
  getSelectedPumps,
  setKeepPumpSelected,
} from '../../../../shared/store/projectSlice';
import MotorTypeSelection from '../../Motors/components/MotorTypeSelection';
import EditableAttributes from '../components/EditableAttributes/EditableAttributes';
import Motor from '../components/Motor/Motor';
import Pump from '../components/Pump/Pump';
import { Summary, ISummaryProps } from '../components/Summary/Summary';
import styles from './OneMotorPropelSystem.module.scss';

interface IOneMotorPropelSystemProps {
  goToRequirements: () => void;
}

export function OneMotorPropelSystem({ goToRequirements }: IOneMotorPropelSystemProps) {
  const dispatch = useAppDispatch();

  const project = useAppSelector(getCurrent);
  const propelSizing = useAppSelector(getPropelSizingData)!;
  const selectedMotors = useAppSelector(getSelectedMotors)!;
  const selectedPumps = useAppSelector(getSelectedPumps);
  const motorsPrices = useAppSelector(getMotorsPrices);
  const pumpsPrices = useAppSelector(getPumpsPrices);
  const calculations = useAppSelector(getCalculations);

  const { motorType, setMotorType } = useMotorTypeContext();

  const [isSectionUpdating, setIsSectionUpdating] = useState(false);
  const [hasWidgetsErrors, setHasWidgetsErrors] = useState(false);

  useEffect(() => {
    if (!motorType) {
      setMotorType(selectedMotors[0].type);
    }
  }, [selectedMotors, motorType, setMotorType]);

  const motor = selectedMotors[0];
  const pump = selectedPumps && selectedPumps[0];
  const { id: projectId } = project;
  const { recommended_motor_type } = propelSizing;
  const motorPrice = motorsPrices[motor.product_id];
  const pumpPrice = pump ? pumpsPrices[pump.product_id] : undefined;
  const hasGearbox = !!propelSizing.two_speed_gear_box;
  const tractiveForce: ISummaryProps['tractiveForce'] = {
    actual: motor.tractive_force_actual,
    required: motor.tractive_force_required,
  };
  const tractiveForceGear2: ISummaryProps['tractiveForce'] = {
    actual: motor.tractive_force_actual2!,
    required: motor.tractive_force_required2!,
  };

  const onAttributesUpdate = (isUpdating: boolean, isValid: boolean) => {
    setIsSectionUpdating(isUpdating);
    setHasWidgetsErrors(!isValid);
  };
  const clearMotors = async (selectedMotorType?: MotorType) => {
    try {
      setIsSectionUpdating(true);

      const { pumps, warning } = await dispatch(
        autoUpdateProducts({ projectId, displacementType: selectedMotorType }),
      ).unwrap();

      showOverSpeedingWarning(warning, projectId);
      const pumpsId = pumps.map(item => item.selected_pump.product_id);

      await dispatch(getMotorDetailsAndCalculations({ projectId, pumpsId })).unwrap();
    } finally {
      setIsSectionUpdating(false);
    }
  };

  const onKeepPumpSelectedChange = ({ target }: React.FormEvent<HTMLInputElement>) => {
    const { id, product_id } = pump!;
    const { id: motorId, min_displacement } = motor;

    dispatch(
      setKeepPumpSelected({
        pump: { id, product_id },
        keepPump: (target as HTMLInputElement).checked,
        displacements:
          motorType === MotorType.VariablePiston ? [{ id: motorId, min_displacement }] : [],
      }),
    );
  };
  const onChangeArchitecture: MouseEventHandler<HTMLButtonElement> = event => {
    event.preventDefault();
    goToRequirements();
  };

  return (
    <section
      className={cn(utils.mt10, helpers.loaderWrapper)}
      data-testid="predefined-propel-system"
    >
      <DfLoader isVisible={isSectionUpdating}>
        <h3>
          <FormattedMessage id="wizard_product_selected_propel_system" />
        </h3>
        <div className={utils.mb5}>
          <MotorTypeSelection
            motorTypeWarning={hasGearbox ? undefined : recommended_motor_type?.warning}
            isCustomFlow={false}
            onMotorTypeForcedChange={clearMotors}
            isMotorSelected
          />
        </div>
        {hasGearbox && (
          <div className={utils.mb5} data-testid="change-architecture">
            <div className={cn(utils.mb3, core.flex, core.alignCenter)}>
              <div className={core.textBold}>
                <FormattedMessage id="wizard_product_system_architecture" />
              </div>
              <button
                className={cn(button.textBtn, button.textBtnPrimary, utils.ml3)}
                onClick={onChangeArchitecture}
                data-testid="change-architecture-button"
              >
                <FormattedMessage id="wizard_product_gearbox_change_requirements_button" />
              </button>
            </div>
            <div>
              <FormattedMessage id="wizard_product_gearbox_title" />
            </div>
          </div>
        )}
        <div className={utils.mb5}>
          <EditableAttributes
            motor={motor}
            projectId={projectId}
            hasGearbox={hasGearbox}
            onUpdate={onAttributesUpdate}
          />
        </div>
        <div className={cn(utils.row, utils.mb6)}>
          <div
            className={cn(
              utils.col3,
              utils.colMd4,
              utils.colSm8,
              utils.mb3,
              utils.pb4,
              styles['motor-widget'],
            )}
          >
            <Motor
              projectId={projectId}
              motor={motor}
              price={motorPrice}
              isReadOnly={hasWidgetsErrors}
            />
          </div>
          <div className={cn(utils.col3, utils.colMd4, utils.colSm8)}>
            {pump && (
              <Pump
                pump={pump}
                price={pumpPrice}
                isReadOnly={hasWidgetsErrors}
                onKeepPumpSelectedChange={onKeepPumpSelectedChange}
              />
            )}
          </div>
        </div>
        <Summary
          className={utils.mt6}
          calculations={calculations}
          tractiveForce={tractiveForce}
          tractiveForceGear2={tractiveForceGear2}
          hasGearbox={hasGearbox}
        />
      </DfLoader>
    </section>
  );
}
