import { createContext } from 'react';

import { getAppConfig } from 'configs/global';

export enum MeasuringUnits {
  Imperial = 'imperial',
  Metric = 'metric',
}

interface IMeasuringSystemContext {
  units: MeasuringUnits;
  setUnits: (units: MeasuringUnits) => void;
}

export const MeasuringSystemContext = createContext<IMeasuringSystemContext>({
  units: MeasuringUnits.Metric,
  setUnits: () => {},
});

const storageKey = `${getAppConfig().APPLICATION_PREFIX}-measuring-system`;
export const storage = {
  setUnits: (units: MeasuringUnits) => localStorage.setItem(storageKey, units),
  getUnits: () => localStorage.getItem(storageKey) as MeasuringUnits | null,
};
