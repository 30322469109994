import link from '@danfoss/mosaic/css/components/link.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { getAppConfig } from 'configs/global';
import { getDCContactId } from 'utils/DesignCenterContactIdService';
import { getDCProjectId } from 'utils/DesignCenterProjectIdService';
import { showSuccess } from 'utils/ToastService';
import { sendToDC as sendToDesignCenter } from 'views/shared/api/designCenter';

export const sendToDC = async (projectId: string) => {
  const { DC_URL } = getAppConfig();
  const myConfigurationsUrl = `${DC_URL}/s/my-configurations`;

  await sendToDesignCenter({
    project_id: projectId,
    design_center_project_id: getDCProjectId()!,
    design_center_contact_id: getDCContactId()!,
  });

  showSuccess(
    <FormattedMessage id="sent_to_dc_toast_heading" />,
    <span data-testid="sent-to-dc-success-alert">
      <FormattedMessage id="sent_to_dc_toast_text" />{' '}
      <a
        className={cn(link.linkSecondary, core.textBold)}
        href={myConfigurationsUrl}
        target="_blank"
        rel="noreferrer"
      >
        <FormattedMessage id="sent_to_dc_toast_link" />
      </a>
    </span>,
  );
};
