import { TableColumnType, TableRowSelectedEventType } from '@danfoss/mosaic';
import core from '@danfoss/mosaic/css/core.module.css';
import cn from 'classnames';
import { memo } from 'react';
import { useIntl } from 'react-intl';

import useMeasuringUnitsIntl from 'views/shared/helpers/measuringSystem/useMeasuringUnitsIntl';
import { IAvailableSteeringUnit } from 'views/wizard/product-selection/api/products';

import { nameCell } from '../../../components/tables/helpers/formatters';
import SteeringTable from './SteeringTable';
import {
  getHybridLockToLockCellFormatter,
  hybridSpoolCell,
  hybridDisplacementCell,
  useSteeringPriceCellFormatter,
} from './helpers/formatters';

interface HybridTableProps {
  list: IAvailableSteeringUnit[];
  onRowSelected: (e: CustomEvent<TableRowSelectedEventType>) => void;
}

const HybridTable = ({ list, onRowSelected }: HybridTableProps) => {
  const { formatMessage } = useIntl();
  const { formatMeasuringUnit } = useMeasuringUnitsIntl();
  const priceCellFormatter = useSteeringPriceCellFormatter(useIntl());

  const columns: TableColumnType[] = [
    {
      accessor: 'steering_unit.name',
      id: 'name',
      Header: formatMessage({ id: 'wizard_selection_modal_header_name' }),
      Cell: nameCell(formatMessage),
      getCellProps: () => ({ className: cn(core.flex, core.flexColumn) }),
    },
    {
      accessor: 'steering_unit',
      id: 'actual_l2l',
      Header: formatMessage({
        id: 'wizard_steering_hybrid_modal_header_l2l',
      }),
      Cell: getHybridLockToLockCellFormatter(formatMessage),
    },
    {
      accessor: 'steering_unit.products',
      id: 'spool',
      Header: formatMessage(
        { id: 'wizard_steering_steer_by_wire_modal_header_spool' },
        {
          units: formatMeasuringUnit({
            metric: 'measurements_unit_l_min',
            imperial: 'measurements_unit_gal_min',
          }),
        },
      ),
      Cell: hybridSpoolCell,
    },
    {
      accessor: 'steering_unit.products',
      id: 'displacement',
      Header: formatMessage(
        { id: 'wizard_steering_conventional_steering_modal_header_displacement' },
        {
          units: formatMeasuringUnit({
            metric: 'measurements_unit_cm3_rev',
            imperial: 'measurements_unit_in3_rev',
          }),
        },
      ),
      Cell: hybridDisplacementCell,
    },
    {
      accessor: 'steering_unit.products',
      id: 'price',
      Header: formatMessage({ id: 'wizard_selection_modal_header_price' }),
      Cell: priceCellFormatter,
      width: 150,
    },
  ];

  return <SteeringTable list={list} onRowSelected={onRowSelected} columns={columns} />;
};

export default memo(HybridTable);
