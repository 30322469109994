import { useProductListItems } from 'views/shared/components/ProductsTable/useProductListItems';
import { ISelectedMotor, ISelectedPump, ISelectedSteeringProduct } from 'views/shared/types';

interface IProductListProps {
  products: {
    motors: ISelectedMotor[];
    pumps: ISelectedPump[];
    steeringProducts: ISelectedSteeringProduct[];
  };
}

const PublicProductList = ({ products }: IProductListProps) => {
  const { configurableItems, nonConfigurableItems } = useProductListItems({ products });

  return (
    <div data-testid="public-products-list">
      {configurableItems}
      {nonConfigurableItems}
    </div>
  );
};

export default PublicProductList;
