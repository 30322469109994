import { forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';

export const Footer = forwardRef<HTMLDivElement>((_, forwardedRef) => {
  return (
    <footer ref={forwardedRef} style={{ fontFamily: 'arial' }} data-testid="footer-wrapper">
      <div
        className="footer_disclaimer"
        style={{
          marginBottom: '16px',
          paddingBottom: '16px',
          borderBottom: '2px solid black',
          fontSize: '20px',
        }}
      >
        <FormattedMessage id="pdf_footer_text" />
      </div>
      <div
        className="footer_info"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '30x',
          lineHeight: '36px',
        }}
      >
        <div className="footer_copyright">
          <FormattedMessage
            id="pdf_footer_small_text"
            values={{ time: new Date().toUTCString() }}
          />
        </div>
      </div>
    </footer>
  );
});
