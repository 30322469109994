import { ReactComponent as InfoSvg } from '@danfoss/mosaic-icons/dist/svg/info-outline.svg';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as SvgSteeringDimensions } from 'assets/icons/steering_dimensions_light.svg';
import TooltipInfo from 'views/shared/components/TooltipInfo/TooltipInfo';

import styles from './SteeringDimensionsTooltip.module.scss';

interface ISteeringDimensionsTooltipProps {
  className?: string;
}

const SteeringDimensionsTooltip = ({ className }: ISteeringDimensionsTooltipProps) => {
  return (
    <div className={cn(core.flex, core.alignCenter, className)}>
      <TooltipInfo
        placement="bottom"
        className={styles['tooltip-wrapper']}
        trigger={
          <div className={cn(core.flex, core.alignCenter)}>
            <span className={core.textSemibold}>
              <FormattedMessage id="wizard_steering_modal_dimensions_label" />
            </span>
            <InfoSvg className={cn(core.icon, utils.ml1)} />
          </div>
        }
      >
        <div className={styles.height}>
          <FormattedMessage id="wizard_steering_dimensions_height" />
        </div>
        <div className={styles.width}>
          <FormattedMessage id="wizard_steering_dimensions_width" />
        </div>
        <div className={styles.length}>
          <FormattedMessage id="wizard_steering_dimensions_length" />
        </div>
        <SvgSteeringDimensions />
      </TooltipInfo>
    </div>
  );
};

export default SteeringDimensionsTooltip;
