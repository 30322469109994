import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfModal } from '@danfoss/mosaic/react';
import { FormattedMessage } from 'react-intl';

const SharingInDraftModal = () => {
  return (
    <DfModal data-testid="sharing-in-draft-modal" isHeaderHidden isOpen>
      <div slot="modal-body">
        <h1 className={utils.mb4}>
          <FormattedMessage id="shared_page_selection_in_draft_title" />
        </h1>
        <FormattedMessage id="shared_page_selection_in_draft_text" />
      </div>
    </DfModal>
  );
};

export default SharingInDraftModal;
