import { useContext } from 'react';

import { round } from '../formatters';
import { MeasuringSystemContext, MeasuringUnits } from './MeasuringSystemContext';
import { convert, Units } from './convertors';

interface IFormatMeasureValueProps {
  metric: Units;
  imperial: Units;
  value: string | number | null;
  precision?: {
    metric: number;
    imperial: number;
  };
}

const FormatMeasureValue = ({ metric, imperial, value, precision }: IFormatMeasureValueProps) => {
  const { units } = useContext(MeasuringSystemContext);
  const isImperialSystem = units === MeasuringUnits.Imperial;

  const imperialPrecision = precision?.imperial ?? 3;
  const metricPrecision = precision?.metric ?? 3;

  if (value === null) {
    return null;
  }

  if (isImperialSystem) {
    return (
      <>
        {round(
          convert(+value)
            .from(metric)
            .to(imperial),
          imperialPrecision,
        )}
      </>
    );
  }

  return <>{round(+value, metricPrecision)}</>;
};

export default FormatMeasureValue;
