import { AxiosError } from 'axios';
// eslint-disable-next-line import/no-extraneous-dependencies
import { History } from 'history';

import { AxiosConfigWithFlags, NOT_FOUND_STATUS } from 'configs/axios';
import { showError, showUnknownError } from 'utils/ToastService';

import { CommonError } from '../types';

export const createNotFoundInterceptor = (history: History) => (error: AxiosError) => {
  if (error.response?.status === NOT_FOUND_STATUS) {
    history.push('/');
  }
};

export const errorInterceptor = (error: AxiosError<CommonError>) => {
  const { disableErrorInterceptor } = error.config as AxiosConfigWithFlags;

  if (disableErrorInterceptor) {
    return;
  }

  const errorData = error.response?.data;

  if (!errorData || (error.response?.status ?? 0) >= 500) {
    showUnknownError();

    return;
  }

  const { errors } = errorData;
  const message = errors && Object.values(errors)[0]?.[0];

  if (!message) {
    showUnknownError();

    return;
  }

  showError(message);
};
