import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as SvgInfoFilled } from 'assets/icons/info-filled.svg';
import Fieldset from 'views/shared/components/form/Fieldset';
import Input from 'views/shared/components/form/Input';
import Addon from 'views/shared/components/form/InputAddon';
import InputGroup from 'views/shared/components/form/InputGroup';
import Label from 'views/shared/components/form/Label/Label';
import { round } from 'views/shared/helpers/formatters';
import ConverterInput from 'views/shared/helpers/measuringSystem/ConverterInput';
import FormatMeasureUnit from 'views/shared/helpers/measuringSystem/FormatMeasureUnit';
import {
  MeasuringSystemContext,
  MeasuringUnits,
} from 'views/shared/helpers/measuringSystem/MeasuringSystemContext';

import { motorWidgetValidation } from '../../../../../../constants';
import styles from './EditableAttributes.module.scss';
import FinalDriveRatioExplanationModal from './FinalDriveRatioExplanationModal';
import RollingRadiusExplanationModal from './RollingRadiusExplanationModal';

interface EditableAttributesProps {
  rollingRadius?: number;
  driveRatio?: number;
  onRollingRadiusChange: (value: number, isValid: boolean) => void;
  onRollingRadiusValidation: (hasError: boolean) => void;
  onDriveRatioChange: (value: number, isValid: boolean) => void;
  onDriveRatioValidation: (hasError: boolean) => void;
  isReadOnly: boolean;
  isFinalDriveRatioHidden: boolean;
}

const EditableAttributes = ({
  isReadOnly,
  rollingRadius,
  driveRatio,
  onDriveRatioChange,
  onDriveRatioValidation,
  onRollingRadiusChange,
  onRollingRadiusValidation,
  isFinalDriveRatioHidden,
}: EditableAttributesProps) => {
  const { units } = useContext(MeasuringSystemContext);
  const [currentRollingRadius, setCurrentRollingRadius] = useState(rollingRadius || '');
  const [currentDriveRatio, setCurrentDriveRatio] = useState(driveRatio || '');

  const [rollingRadiusError, setRollingRadiusError] = useState(false);
  const [driveRatioError, setDriveRatioError] = useState(false);
  const [isFinalDriveRatioModalShown, setIsFinalDriveRatioModalShown] = useState(false);
  const [isRollingRadiusModalShown, setIsRollingRadiusModalShown] = useState(false);

  const isRollingRadiusValid = (value: number) => {
    const actualValue = +value.toFixed(3);

    return (
      actualValue >= motorWidgetValidation.rolling_radius.min &&
      actualValue <= motorWidgetValidation.rolling_radius.max
    );
  };

  const isDriveRatioValid = (value: number) =>
    isFinalDriveRatioHidden ||
    (value >= motorWidgetValidation.final_drive_ratio.min &&
      value <= motorWidgetValidation.final_drive_ratio.max);

  const onDriveRatioChangeCallback = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    const isValid = isDriveRatioValid(+value);

    setCurrentDriveRatio(value);
    setDriveRatioError(!isValid);
    onDriveRatioValidation(!isValid);
    onDriveRatioChange(+value, isValid);
  };

  const onRollingRadiusChangeCallback = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;
    const isValid = isRollingRadiusValid(+value);

    setCurrentRollingRadius(value);
    setRollingRadiusError(!isValid);
    onRollingRadiusValidation(!isValid);
    onRollingRadiusChange(+value, isValid);
  };

  const onFinalDriveRatioIconClick = () => {
    setIsFinalDriveRatioModalShown(true);
  };
  const onFinalDriveRatioModalClose = () => {
    setIsFinalDriveRatioModalShown(false);
  };
  const onRollingRadiusIconClick = () => {
    setIsRollingRadiusModalShown(true);
  };
  const onRollingRadiusModalClose = () => {
    setIsRollingRadiusModalShown(false);
  };

  useEffect(() => {
    if (isFinalDriveRatioHidden) {
      setDriveRatioError(false);
      onDriveRatioValidation(false);
    }
  }, [isFinalDriveRatioHidden]);

  useEffect(() => {
    setCurrentRollingRadius(rollingRadius || '');
    setCurrentDriveRatio(driveRatio ? round(driveRatio) : '');

    if (driveRatio) {
      const hasError = !isDriveRatioValid(driveRatio);

      setDriveRatioError(hasError);
      onDriveRatioValidation(hasError);
    }
  }, [rollingRadius, driveRatio]);

  return (
    <>
      <div className={cn(core.flex, core.flexBetween, utils.mt5)}>
        <Fieldset className={styles['input-item']}>
          <Label className={cn(styles['input-label'], core.flex)}>
            <span>
              <FormattedMessage id="wizard_product_motor_widget_rolling_radius" />
            </span>
            <span
              className={cn(utils.ml1, styles['icon-button'])}
              data-testid="rolling-radius-icon-button"
              onClick={onRollingRadiusIconClick}
            >
              <SvgInfoFilled className={core.icon} />
            </span>
          </Label>
          <InputGroup>
            <ConverterInput
              type="number"
              value={currentRollingRadius}
              onChange={onRollingRadiusChangeCallback}
              readOnly={isReadOnly}
              data-testid="rolling-radius"
              {...motorWidgetValidation.rolling_radius}
              step={units === MeasuringUnits.Metric ? 1 : 0.001}
              metric="mm"
              imperial="in"
              precision={{
                metric: 0,
                imperial: 3,
              }}
            />
            <Addon>
              <FormatMeasureUnit metric="measurements_unit_mm" imperial="measurements_unit_inch" />
            </Addon>
          </InputGroup>
          <label
            className={cn(core.helperText, core.error, utils.mt0, styles.error)}
            data-testid="rolling-radius-error"
          >
            {rollingRadiusError && (
              <FormattedMessage id="wizard_product_motor_widget_rolling_radius_error" />
            )}
          </label>
        </Fieldset>
        {!isFinalDriveRatioHidden && (
          <Fieldset className={styles['input-item']} data-testid="final-drive-ratio-fieldset">
            <Label className={cn(core.flex, styles['input-label'])}>
              <span>
                <FormattedMessage id="wizard_product_motor_widget_final_drive_ratio" />
              </span>
              <span
                className={cn(utils.ml1, styles['icon-button'])}
                data-testid="final-drive-ratio-icon-button"
                onClick={onFinalDriveRatioIconClick}
              >
                <SvgInfoFilled className={core.icon} />
              </span>
            </Label>
            <InputGroup>
              <Input
                type="number"
                value={currentDriveRatio}
                onChange={onDriveRatioChangeCallback}
                readOnly={isReadOnly}
                step="0.001"
                data-testid="final-drive-ratio"
                {...motorWidgetValidation.final_drive_ratio}
              />
              <Addon>
                <FormattedMessage id="measurements_unit_ratio_one" />
              </Addon>
            </InputGroup>
            <label
              className={cn(core.helperText, core.error, utils.mt0, styles.error)}
              data-testid="final-drive-ratio-error"
            >
              {driveRatioError && (
                <FormattedMessage id="wizard_product_motor_widget_drive_ratio_error" />
              )}
            </label>
          </Fieldset>
        )}
      </div>
      {isFinalDriveRatioModalShown && (
        <FinalDriveRatioExplanationModal onClose={onFinalDriveRatioModalClose} />
      )}
      {isRollingRadiusModalShown && (
        <RollingRadiusExplanationModal onClose={onRollingRadiusModalClose} />
      )}
    </>
  );
};

export default EditableAttributes;
