import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import Fieldset from 'views/shared/components/form/Fieldset';
import Addon from 'views/shared/components/form/InputAddon';
import InputGroup from 'views/shared/components/form/InputGroup';
import ConverterInput from 'views/shared/helpers/measuringSystem/ConverterInput';
import FormatMeasureUnit from 'views/shared/helpers/measuringSystem/FormatMeasureUnit';
import { convert } from 'views/shared/helpers/measuringSystem/convertors';
import { ICalculations } from 'views/shared/types';

import styles from './VehicleSpeed.module.scss';

interface IVehicleSpeedProps {
  calculations: ICalculations;
}

const VehicleSpeed = ({ calculations }: IVehicleSpeedProps) => {
  const { vehicle_work_speed, vehicle_travel_speed } = calculations;

  const travelSpeedPerMinute = convert(vehicle_travel_speed).from('km/h').to('m/min');
  const workSpeedPerMinute = convert(vehicle_work_speed).from('km/h').to('m/min');

  return (
    <div className={styles.wrapper}>
      <div className={cn(utils.mb4, core.flex, core.flexBetween, core.alignCenter)}>
        <p className={utils.mr6}>
          <FormattedMessage id="wizard_product_vehicle_speed_widget_work_speed" />
        </p>
        <Fieldset>
          <InputGroup>
            <ConverterInput
              className={styles.input}
              value={vehicle_work_speed || ''}
              data-testid="work-speed"
              readOnly
              metric="km/h"
              imperial="mph"
            />
            <Addon className={utils.mr4}>
              <FormatMeasureUnit metric="measurements_unit_kmh" imperial="measurements_unit_mph" />
            </Addon>
            <ConverterInput
              className={styles.input}
              value={workSpeedPerMinute}
              data-testid="work-speed-per-minute"
              readOnly
              metric="m/min"
              imperial="ft/min"
            />
            <Addon>
              <FormatMeasureUnit
                metric="measurements_unit_mmin"
                imperial="measurements_unit_ftmin"
              />
            </Addon>
          </InputGroup>
        </Fieldset>
      </div>
      <div className={cn(core.flex, core.flexBetween, core.alignCenter)}>
        <p className={utils.mr6}>
          <FormattedMessage id="wizard_product_vehicle_speed_widget_travel_speed" />
        </p>
        <Fieldset>
          <InputGroup>
            <ConverterInput
              className={styles.input}
              value={vehicle_travel_speed || ''}
              data-testid="travel-speed"
              readOnly
              metric="km/h"
              imperial="mph"
            />
            <Addon className={utils.mr4}>
              <FormatMeasureUnit metric="measurements_unit_kmh" imperial="measurements_unit_mph" />
            </Addon>
            <ConverterInput
              className={styles.input}
              value={travelSpeedPerMinute}
              data-testid="travel-speed-per-minute"
              readOnly
              metric="m/min"
              imperial="ft/min"
            />
            <Addon>
              <FormatMeasureUnit
                metric="measurements_unit_mmin"
                imperial="measurements_unit_ftmin"
              />
            </Addon>
          </InputGroup>
        </Fieldset>
      </div>
    </div>
  );
};

export default VehicleSpeed;
