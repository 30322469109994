import axios from 'axios';

import { AvailableProductsSearchMode, IPrice, MotorType } from 'views/shared/types';

export interface IAvailableProduct {
  price?: IPrice;
  recommended?: boolean;
}

interface IPump {
  id: string;
  name: string;
  series: string;
  min_pressure: number;
  max_pressure: number;
  rated_pressure: number;
  continuous_pressure: number;
  speed_limit_max: number;
  speed_limit_rated: number;
}

export interface IAvailablePump extends IAvailableProduct {
  capacity: number;
  pump: IPump;
}

interface IMotor {
  id: string;
  name: string;
  series: string;
  min_pressure: number;
  max_pressure: number;
  rated_pressure: number;
  continuous_pressure: number;
  speed_limit_max: number;
  speed_limit_rated: number;
  corner_power: number;
  type: MotorType;
}

export interface IAvailableMotor extends IAvailableProduct {
  capacity: number;
  motor: IMotor;
}

export interface IAvailableSteeringProduct {
  id: string;
  name: string;
  series?: string;
  photo_url?: string;
  displacement?: number;
  spool?: number;
  price?: IPrice;
}

interface ISteeringUnit {
  id: string;
  name: string;
  length?: number;
  width?: number;
  height?: number;
  actual_l2l_sec?: number;
  actual_l2l_turns?: number;
  products: IAvailableSteeringProduct[];
}

export interface IAvailableSteeringUnit extends Omit<IAvailableProduct, 'price'> {
  steering_unit: ISteeringUnit;
}

export interface IAvailableProducts<T> {
  total: number;
  most_suitable: T;
  items: T[];
}

interface IAvailableMotorsParams {
  number_of_motors: number;
  item_to_replace?: string;
  filter_search_mode?: AvailableProductsSearchMode;
  filter_displacement_type?: MotorType;
  filter_min_capacity?: number;
  filter_max_capacity?: number;
  filter_by_max_pressure: boolean;
}

export const getAvailableMotors = async (id: string, params: IAvailableMotorsParams) => {
  const { data } = await axios.get<IAvailableProducts<IAvailableMotor>>(
    `/propel-sizings/${id}/available-motors`,
    {
      params,
    },
  );

  return data.items.map(motor => ({
    ...motor,
    recommended: motor.motor.id === data.most_suitable.motor.id,
  }));
};

interface IAvailablePumpsParams {
  number_of_pumps: number;
  filter_search_mode?: AvailableProductsSearchMode;
  filter_min_capacity?: number;
  filter_max_capacity?: number;
  filter_by_max_pressure?: boolean;
}

export const getAvailablePumps = async (id: string, params: IAvailablePumpsParams) => {
  const { data } = await axios.get<IAvailableProducts<IAvailablePump>>(
    `/propel-sizings/${id}/available-pumps`,
    { params },
  );

  return data.items.map(pump => ({
    ...pump,
    recommended: pump.pump.id === data.most_suitable.pump.id,
  }));
};

interface IAvailableSteeringUnitsParams {
  filter_required_l2l_turns?: number;
  filter_required_l2l_sec?: number;
  filter_search_mode?: AvailableProductsSearchMode;
}

export const getAvailableSteeringUnits = async (
  id: string,
  params: IAvailableSteeringUnitsParams,
) => {
  const { data } = await axios.get<IAvailableProducts<IAvailableSteeringUnit>>(
    `/steering-systems/${id}/available-units`,
    { params },
  );

  return data.items.map(steeringUnit => ({
    ...steeringUnit,
    recommended: steeringUnit.steering_unit.id === data.most_suitable.steering_unit.id,
  }));
};
