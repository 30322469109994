import { DfLoader } from '@danfoss/mosaic/react';
import { ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { Controller, ControllerRenderProps, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import helpers from 'styles/helpers.module.scss';

import { useAppDispatch } from 'configs/store';
import debounce from 'utils/debounce';
import Fieldset from 'views/shared/components/form/Fieldset';
import FormError from 'views/shared/components/form/FormError';
import Addon from 'views/shared/components/form/InputAddon';
import InputGroup from 'views/shared/components/form/InputGroup';
import Label from 'views/shared/components/form/Label/Label';
import ConverterInput from 'views/shared/helpers/measuringSystem/ConverterInput';
import FormatMeasureUnit from 'views/shared/helpers/measuringSystem/FormatMeasureUnit';
import {
  MeasuringSystemContext,
  MeasuringUnits,
} from 'views/shared/helpers/measuringSystem/MeasuringSystemContext';
import { ISelectedMotor } from 'views/shared/types';

import { updateMotorDetailsAndCalculations } from '../../../../../shared/store/projectSlice';
import styles from './MinDisplacementField.module.scss';

interface IMinDisplacementFieldProps {
  motor: ISelectedMotor;
}

interface IMinDispForm {
  min_displacement: number;
}

export default function MinDisplacementField({ motor }: IMinDisplacementFieldProps) {
  const dispatch = useAppDispatch();
  const { control, formState, handleSubmit, setValue } = useForm<IMinDispForm>({
    mode: 'onChange',
  });
  const { units } = useContext(MeasuringSystemContext);

  const [isUpdating, setIsUpdating] = useState(false);

  const { errors } = formState;
  const { id, related_motor_info } = motor;
  const validation = {
    min_displacement: {
      required: true,
      min: Math.ceil(related_motor_info.min_displacement),
      max: Math.floor(related_motor_info.max_min_displacement!),
    },
  };

  const onSubmit = async (values: IMinDispForm) => {
    try {
      setIsUpdating(true);

      const updatedMotor = {
        id,
        min_displacement: values.min_displacement,
      };
      await dispatch(updateMotorDetailsAndCalculations({ motors: [updatedMotor] })).unwrap();
    } finally {
      setIsUpdating(false);
    }
  };
  const onSubmitDebounced = useMemo(() => debounce(handleSubmit(onSubmit), 1000), [id]);
  const onChange =
    (field: ControllerRenderProps<IMinDispForm, 'min_displacement'>) =>
    (event: ChangeEvent<HTMLInputElement>) => {
      field.onChange(event);

      onSubmitDebounced();
    };

  useEffect(() => {
    const { min, max } = validation.min_displacement;

    const normalizedValue = Math.max(Math.min(motor.min_displacement!, max), min);

    setValue('min_displacement', normalizedValue);
  }, [motor.min_displacement]);

  return (
    <Fieldset className={helpers.loaderWrapper} data-testid="min-displacement-fieldset">
      <DfLoader isVisible={isUpdating}>
        <Label>
          <FormattedMessage id="wizard_product_motor_widget_min_displacement" />
        </Label>
        <InputGroup>
          <Controller
            control={control}
            name="min_displacement"
            rules={validation.min_displacement}
            render={({ field }) => (
              <ConverterInput
                className={styles.input}
                type="number"
                value={Math.floor(field.value)}
                onChange={onChange(field)}
                error={!!errors.min_displacement}
                step={units === MeasuringUnits.Metric ? 1 : 0.01}
                metric="cm3"
                imperial="in3"
                precision={{
                  metric: 0,
                  imperial: 2,
                }}
                {...validation.min_displacement}
                data-testid="min-displacement-field"
              />
            )}
          />
          <Addon>
            <FormatMeasureUnit
              metric="measurements_unit_cm3_rev"
              imperial="measurements_unit_in3_rev"
            />
          </Addon>
        </InputGroup>
        <FormError errors={errors} rules={validation} field="min_displacement" />
      </DfLoader>
    </Fieldset>
  );
}
