import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'configs/store';
import { ISelectedMotor, ISelectedPump, ISelectedSteeringProduct } from 'views/shared/types';
import {
  getSelectedMotors,
  getSelectedProducts,
  getSelectedPumps,
  getSelectedSteeringProducts,
} from 'views/wizard/shared/store/projectSlice';

export interface IProducts {
  motors: ISelectedMotor[] | null;
  pumps: ISelectedPump[] | null;
  steeringProducts: ISelectedSteeringProduct[] | null;
}

export const useSelectedProducts = (requestedProjectId: string) => {
  const dispatch = useAppDispatch();
  const selectedPumps = useAppSelector(getSelectedPumps);
  const selectedMotors = useAppSelector(getSelectedMotors);
  const selectedSteeringProducts = useAppSelector(getSelectedSteeringProducts);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const products: IProducts = {
    motors: selectedMotors,
    pumps: selectedPumps,
    steeringProducts: selectedSteeringProducts,
  };

  useEffect(() => {
    async function getData() {
      setIsLoading(true);

      try {
        await dispatch(getSelectedProducts(requestedProjectId)).unwrap();
      } catch (e) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    }

    if (!selectedMotors || !selectedPumps || !selectedSteeringProducts) {
      getData();
    }
  }, [dispatch, selectedMotors, selectedPumps]);

  return { isLoading, error, products };
};
