import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfLoader } from '@danfoss/mosaic/react';
import cn from 'classnames';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, UseFormReturn, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import debounce from 'utils/debounce';
import Fieldset from 'views/shared/components/form/Fieldset';
import Input from 'views/shared/components/form/Input';
import Addon from 'views/shared/components/form/InputAddon';
import InputGroup from 'views/shared/components/form/InputGroup';
import Label from 'views/shared/components/form/Label/Label';
import { IValidationRules } from 'views/shared/components/form/types';
import { round } from 'views/shared/helpers/formatters';
import ConverterInput from 'views/shared/helpers/measuringSystem/ConverterInput';
import FormatMeasureUnit from 'views/shared/helpers/measuringSystem/FormatMeasureUnit';
import { IProjectPropelSizingData, IPullRatioCalculation } from 'views/shared/types';

import { getError } from '../../../../helpers/getError';
import { IRequirementsForm } from '../../../../types';
import FormError from '../../../FormError';
import PullRatioCalculationModal from './PullRatioCalculationModal/PullRatioCalculationModal';
import PullRatioModal from './PullRatioExampleModal/PullRatioExampleModal';
import styles from './TractiveForceWithPullRatio.module.scss';
import { getTractiveForce } from './api/tractiveForce';
import { defaultValues as defaultPullRatioCalculationsValues } from './pullRatioCalculationValues';

interface ITractiveForceSectionProps {
  form: UseFormReturn<IRequirementsForm>;
  formPart: 'propelSizingData' | 'propelSizingData.two_speed_gear_box';
  onUpdate: (isUpdating: boolean) => void;
  dependsOn: {
    loadedWeight: number;
    pullRatioCalculation: IPullRatioCalculation | null;
  };
  validation?: {
    [field in keyof IProjectPropelSizingData]?: IValidationRules;
  };
}

const TractiveForceWithPullRatio = ({
  form,
  formPart,
  onUpdate,
  dependsOn,
  validation = {},
}: ITractiveForceSectionProps) => {
  const { loadedWeight, pullRatioCalculation } = dependsOn;
  const { setValue, control, resetField, getValues, formState } = form;
  const { errors } = formState;

  const [isPullRatioExampleModalOpen, setIsPullRatioExampleModalOpen] = useState(false);
  const [isPullRatioCalculationsModalOpen, setIsPullRatioCalculationsModalOpen] = useState(false);
  const [tractiveForceRequestError, setTractiveForceRequestError] = useState(false);
  const [isTractiveForceUpdating, setIsTractiveForceUpdating] = useState(false);

  const fetchTractiveForce = useMemo(() => debounce(getTractiveForce, 1000), []);

  const pullRatioCalculationsForm = useForm<IPullRatioCalculation>({
    defaultValues: defaultPullRatioCalculationsValues,
  });

  const pullRatioValue = useWatch({
    name: `${formPart}.pull_ratio`,
    control,
  });

  const isTractiveForceKnown = useWatch({
    name: `${formPart}.is_tractive_force_known`,
    control,
  });

  const isTractiveForceRequestErrorShown = !isTractiveForceKnown && tractiveForceRequestError;

  const openPullRatioExampleModal = () => setIsPullRatioExampleModalOpen(true);
  const closePullRatioExampleModal = () => setIsPullRatioExampleModalOpen(false);

  const openPullRatioCalculationsModal = () => setIsPullRatioCalculationsModalOpen(true);
  const closePullRatioCalculationsModal = () => setIsPullRatioCalculationsModalOpen(false);

  const resetPullRatioCalculations = () => {
    pullRatioCalculationsForm.reset(defaultPullRatioCalculationsValues);
    setValue(`${formPart}.pull_ratio_calculation`, null);
  };
  const setPullRatio = (value: number) => {
    resetPullRatioCalculations();
    setValue(`${formPart}.pull_ratio`, value, { shouldValidate: true });
  };
  const setPullRatioCalculations = (calculation: IPullRatioCalculation) => {
    setValue(`${formPart}.pull_ratio_calculation`, calculation);
    setValue(`${formPart}.pull_ratio`, round(calculation.pull_ratio));
  };

  useEffect(() => {
    const predefinedPullRatio = getValues(`${formPart}.pull_ratio`);
    const pullRatioFromInput = pullRatioValue;
    const actualPullRatio = pullRatioFromInput ?? predefinedPullRatio;

    if (!actualPullRatio || isTractiveForceKnown) {
      return;
    }

    (async () => {
      const isDependentFieldValid = await form.trigger([`${formPart}.pull_ratio`]);

      if (!isDependentFieldValid) {
        return;
      }

      try {
        setIsTractiveForceUpdating(true);
        setTractiveForceRequestError(false);
        onUpdate(true);

        const tractiveForce = await fetchTractiveForce({
          pull_ratio: actualPullRatio,
          weight: loadedWeight,
        });

        setValue(`${formPart}.tractive_force`, tractiveForce, { shouldValidate: true });
      } catch {
        resetField(`${formPart}.tractive_force`);
        setTractiveForceRequestError(true);
      } finally {
        setIsTractiveForceUpdating(false);
        onUpdate(false);
      }
    })();
  }, [pullRatioValue, loadedWeight, isTractiveForceKnown]);

  useEffect(() => {
    if (pullRatioCalculation) {
      pullRatioCalculationsForm.reset(pullRatioCalculation);
    }
  }, []);

  return (
    <>
      {!isTractiveForceKnown && (
        <>
          <Fieldset className={utils.mt5}>
            <div className={cn(core.flex, core.flexBetween)}>
              <Label>
                <FormattedMessage id="wizard_requirements_pull_ratio" />
              </Label>
              <button
                onClick={openPullRatioExampleModal}
                className={cn(button.textBtn, button.textBtnPrimary, utils.mb1)}
                data-testid="pull-ratio-example-button"
                type="button"
              >
                <FormattedMessage id="wizard_requirements_pull_ratio_pick_example" />
              </button>
            </div>
            <Input
              type="number"
              step="0.01"
              data-testid="pull_ratio"
              error={!!getError(errors, `${formPart}.pull_ratio`)}
              {...form.register(`${formPart}.pull_ratio`, {
                ...validation.pull_ratio,
                valueAsNumber: true,
                onChange: resetPullRatioCalculations,
                required: true,
              })}
              {...validation.pull_ratio}
            />
            <button
              onClick={openPullRatioCalculationsModal}
              className={cn(button.textBtn, button.textBtnPrimary, utils.mb1, utils.mr4)}
              data-testid="pull-ratio-calculation-button"
              type="button"
            >
              <FormattedMessage id="wizard_requirements_pull_ratio_calculate_manually" />
            </button>
            <FormError errors={errors} rules={validation} field={`${formPart}.pull_ratio`} />
          </Fieldset>
          <Label className={utils.mt6}>
            <FormattedMessage id="wizard_requirements_tractive_force" />
          </Label>
        </>
      )}
      <div className={styles['tractive-force-wrapper']}>
        <DfLoader isVisible={isTractiveForceUpdating} circles={1}>
          <InputGroup>
            <Controller
              control={control}
              name={`${formPart}.tractive_force`}
              rules={validation.tractive_force}
              render={({ field }) => (
                <ConverterInput
                  type="number"
                  metric="N"
                  imperial="lbf"
                  data-testid="tractive_force"
                  value={field.value ?? ''}
                  onChange={field.onChange}
                  onBlur={field.onBlur}
                  error={
                    isTractiveForceRequestErrorShown ||
                    !!getError(errors, `${formPart}.tractive_force`)
                  }
                  readOnly={!isTractiveForceKnown}
                  {...validation.tractive_force}
                />
              )}
            />
            <Addon data-testid="tractive-force-unit">
              <FormatMeasureUnit metric="measurements_unit_n" imperial="measurements_unit_lbf" />
            </Addon>
          </InputGroup>
          <FormError
            errors={errors}
            rules={validation}
            metric="N"
            imperial="lbf"
            field={`${formPart}.tractive_force`}
          />
          {isTractiveForceRequestErrorShown && (
            <label
              className={cn(core.helperText, core.error)}
              data-testid="tractive-force-request-error"
            >
              <FormattedMessage id="wizard_requirements_tractive_force_request_failed" />
            </label>
          )}
        </DfLoader>
      </div>
      {isPullRatioExampleModalOpen && (
        <PullRatioModal onSelect={setPullRatio} onClose={closePullRatioExampleModal} />
      )}
      <input hidden {...form.register(`${formPart}.pull_ratio_calculation`, { value: null })} />
      {isPullRatioCalculationsModalOpen && (
        <PullRatioCalculationModal
          form={pullRatioCalculationsForm}
          onSuccess={setPullRatioCalculations}
          onClose={closePullRatioCalculationsModal}
        />
      )}
    </>
  );
};

export default TractiveForceWithPullRatio;
