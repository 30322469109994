import utils from '@danfoss/mosaic/css/utils.module.css';
import { FormattedMessage } from 'react-intl';

import styles from './NoProducts.module.scss';

const NoProducts = () => {
  return (
    <div className={styles.wrapper} data-testid="no-products">
      <h1 className={utils.mb2}>
        <FormattedMessage id="wizard_selection_modal_no_products_title" />
      </h1>
      <p>
        <FormattedMessage id="wizard_selection_modal_no_products_text" />
      </p>
    </div>
  );
};

export default NoProducts;
