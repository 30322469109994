import globals from '@danfoss/mosaic/css/globals.module.css';
import { ReactNode } from 'react';
import hotToast from 'react-hot-toast';
import { FormattedMessage } from 'react-intl';

import ToastError from 'views/shared/components/Toasts/ToastError';
import ToastSuccess from 'views/shared/components/Toasts/ToastSuccess';
import ToastWarning from 'views/shared/components/Toasts/ToastWarning';

const style = { maxWidth: '1000px', borderRadius: 0 };

export function showError(text: string | ReactNode, reload = false) {
  hotToast.error(
    instance => {
      const onClose = () => hotToast.dismiss(instance.id);

      return (
        <div className={globals.mosaic}>
          <ToastError onClose={onClose} reload={reload}>
            {text}
          </ToastError>
        </div>
      );
    },
    { duration: Infinity, style },
  );
}

export function showUnknownError() {
  showError(<FormattedMessage id="server_error_message" />, true);
}

export function showSuccess(heading: string | ReactNode, text: string | ReactNode) {
  hotToast(
    instance => {
      const onClose = () => hotToast.dismiss(instance.id);

      return (
        <div className={globals.mosaic}>
          <ToastSuccess onClose={onClose} heading={heading}>
            {text}
          </ToastSuccess>
        </div>
      );
    },
    {
      duration: Infinity,
      style: { ...style, background: 'none', padding: 0, boxShadow: 'none' },
    },
  );
}

export function showWarning(
  heading: string | ReactNode,
  text: string | ReactNode,
  actions?: string | ReactNode,
) {
  hotToast(
    instance => {
      const onClose = () => hotToast.dismiss(instance.id);

      return (
        <div className={globals.mosaic}>
          <ToastWarning onClose={onClose} heading={heading} actions={actions}>
            {text}
          </ToastWarning>
        </div>
      );
    },
    {
      duration: Infinity,
      style: { ...style, background: 'none', padding: 0, boxShadow: 'none' },
    },
  );
}
