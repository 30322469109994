import button from '@danfoss/mosaic/css/components/button.module.css';
import tooltip from '@danfoss/mosaic/css/components/tooltip.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfLoader } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useAppDispatch } from 'configs/store';
import { trackEvent } from 'utils/analytics';
import ContactUs from 'views/base/ContactUs/ContactUs';
import SubHeader from 'views/base/SubHeader/SubHeader';
import {
  deleteSelection as deleteSelectionAPI,
  duplicateSelection as duplicateSelectionAPI,
  getAllSelections as getAllSelectionsAPI,
  IProjectSelection,
} from 'views/shared/api/selections';
import ShareModal from 'views/sharing/ShareModal/ShareModal';
import { updateProject } from 'views/wizard/shared/api/project';
import ConfirmationModal from 'views/wizard/shared/components/ConfirmationModal';
import useConfirmationModal from 'views/wizard/shared/helpers/useConfirmationModal';
import { clearCurrentProject } from 'views/wizard/shared/store/projectSlice';
import { setSelectedFunctions } from 'views/wizard/shared/store/sizingFunctionsSlice';
import RenameSelectionModal from 'views/wizard/summary/components/RenameSelectionModal';

import styles from './Selections.module.scss';
import HeroBanner from './components/HeroBanner/HeroBanner';
import NoSelections from './components/NoSelections/NoSelections';
import Selection from './components/Selection/Selection';

const Selections = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const history = useHistory();

  const [allSelections, setAllSelections] = useState<IProjectSelection[]>([]);
  const [isSelectionsUpdating, setIsSelectionsUpdating] = useState(false);
  const [isSelectionsLoading, setIsSelectionsLoading] = useState(false);
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [selectedIDsToCompare, setSelectedIDsToCompare] = useState<string[]>([]);

  const selectionToShare = useRef<string | null>(null);
  const isAllSelectionsEmpty = allSelections.length === 0;

  const isCompareAvailable = !!selectedIDsToCompare.length;
  const maxSelectedProjects = 4;
  const compareButtonSuffix = isCompareAvailable
    ? `( ${selectedIDsToCompare.length}/${maxSelectedProjects} )`
    : '';
  const isCompareLimitExceeded = selectedIDsToCompare.length > maxSelectedProjects;

  const intersectionObserver = useRef<IntersectionObserver>();
  const stickyElementClassName = 'mfd-sticky-section';
  const elementToTrack = useRef<HTMLDivElement>(null);
  const selectionHeader = useRef<HTMLDivElement>(null);

  const getAllSelections = async () => {
    const { projects } = await getAllSelectionsAPI();
    setAllSelections(projects);
  };

  const duplicateSelection = useCallback(async (id: string) => {
    try {
      setIsSelectionsUpdating(true);
      await duplicateSelectionAPI(id);
      await getAllSelections();
    } finally {
      setIsSelectionsUpdating(false);
    }
  }, []);

  const deleteSelection = useCallback(async (id: string) => {
    await deleteSelectionAPI(id);
    setAllSelections(selections => selections.filter(item => item.id !== id));
    setSelectedIDsToCompare(ids => ids.filter(selectionId => selectionId !== id));
  }, []);

  const {
    isOpen: isDeleteConfirmationModalOpen,
    open: openDeleteConfirmationModal,
    props: deleteConfirmationModalProps,
  } = useConfirmationModal<string>(deleteSelection);

  const selectionToDelete = useRef<IProjectSelection | null>(null);
  const handleDeleteSelection = useCallback(async (selection: IProjectSelection) => {
    selectionToDelete.current = selection;
    openDeleteConfirmationModal(selection.id);
  }, []);

  const onCompareButtonClick = () => {
    trackEvent({ event: 'add_to_compare' });
    history.push(`/compare/${selectedIDsToCompare.join(',')}`);
  };
  const onCompareCheckboxClick = useCallback((newId: string) => {
    setSelectedIDsToCompare(selectedIds => {
      if (selectedIds.includes(newId)) {
        return selectedIds.filter(id => id !== newId);
      }

      return [...selectedIds, newId];
    });
  }, []);

  const onShare = useCallback((id: string) => {
    selectionToShare.current = id;
    setIsShareModalOpen(true);
  }, []);
  const onShareClose = () => {
    setIsShareModalOpen(false);
    selectionToShare.current = null;
  };

  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const selectionToRename = useRef<IProjectSelection | null>(null);

  const openRenameModal = useCallback((selection: IProjectSelection) => {
    selectionToRename.current = selection;

    setIsRenameModalOpen(true);
  }, []);
  const closeRenameModal = () => {
    selectionToRename.current = null;

    setIsRenameModalOpen(false);
  };
  const renameSelection = async (newName: string) => {
    if (!selectionToRename.current) {
      return;
    }

    const { id } = selectionToRename.current;

    await updateProject(id, { project_name: newName });
    setAllSelections(selections =>
      selections.map(item => (item.id === id ? { ...item, project_name: newName } : item)),
    );

    closeRenameModal();
  };

  useEffect(() => {
    dispatch(clearCurrentProject());

    (async () => {
      try {
        setIsSelectionsLoading(true);
        await getAllSelections();
      } finally {
        setIsSelectionsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    intersectionObserver.current = new IntersectionObserver(([e]) => {
      selectionHeader.current!.classList.toggle(stickyElementClassName, e.intersectionRatio < 1);
    });

    return () => intersectionObserver.current!.disconnect();
  }, []);

  useEffect(() => {
    if (!intersectionObserver.current || !selectionHeader.current) {
      return;
    }

    if (isCompareAvailable) {
      intersectionObserver.current.observe(elementToTrack.current!);
    } else {
      selectionHeader.current.classList.remove(stickyElementClassName);
      intersectionObserver.current.unobserve(elementToTrack.current!);
    }
  }, [isCompareAvailable]);

  useEffect(() => {
    dispatch(setSelectedFunctions(null));
  }, []);

  return (
    <>
      <SubHeader />
      <HeroBanner />
      <div ref={elementToTrack} style={{ height: '1px' }} />
      <div className={cn(utils.mt16, utils.mb12)} data-testid="selections">
        <div
          className={cn(core.flex, core.flexBetween, utils.py5)}
          ref={selectionHeader}
          data-testid="sticky-header"
        >
          <h3>
            <FormattedMessage id="home_selections_title" />
          </h3>
          {!isAllSelectionsEmpty && (
            <div className={cn(core.flex, core.flexEnd)}>
              <div data-tooltip data-placement="left" className={tooltip.tooltip}>
                <button
                  className={button.btnPrimary}
                  onClick={onCompareButtonClick}
                  disabled={!isCompareAvailable || isCompareLimitExceeded}
                  data-testid="compare-button"
                >
                  <FormattedMessage id="home_selections_compare_button" /> {compareButtonSuffix}
                </button>
                {isCompareLimitExceeded && (
                  <span
                    className={cn(tooltip.tooltipContent, styles['compare-button-tooltip'])}
                    data-testid="compare-limit-exceeded-tooltip"
                  >
                    <FormattedMessage id="home_selections_compare_button_tooltip" />
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
        <div className={cn(utils.row, styles.container)}>
          <DfLoader isVisible={isSelectionsUpdating} />
          {isSelectionsLoading && (
            <div className={styles['loader-wrapper']}>
              <DfLoader />
            </div>
          )}
          {isAllSelectionsEmpty && !isSelectionsLoading && (
            <div className={utils.my20}>
              <NoSelections />
            </div>
          )}
          {allSelections.map(selection => (
            <div className={cn(utils.col4, utils.colMd4, utils.colSm8)} key={selection.id}>
              <Selection
                data={selection}
                onDuplicate={duplicateSelection}
                onDelete={handleDeleteSelection}
                onShare={onShare}
                onRename={openRenameModal}
                onCompare={onCompareCheckboxClick}
              />
            </div>
          ))}
          {isShareModalOpen && selectionToShare.current && (
            <ShareModal onClose={onShareClose} projectId={selectionToShare.current} />
          )}
        </div>
      </div>
      <ContactUs />
      {isDeleteConfirmationModalOpen && (
        <ConfirmationModal
          headingLabel={formatMessage({
            id: 'home_selection_delete_confirmation_modal_heading',
          })}
          confirmLabel={formatMessage({
            id: 'home_selection_delete_confirmation_modal_change_button',
          })}
          {...deleteConfirmationModalProps}
        >
          <FormattedMessage
            id="home_selection_delete_confirmation_modal_text"
            values={{
              selectionName: selectionToDelete.current?.project_name,
            }}
          />
        </ConfirmationModal>
      )}
      {isRenameModalOpen && (
        <RenameSelectionModal
          projectName={selectionToRename.current!.project_name}
          onSubmit={renameSelection}
          onCancel={closeRenameModal}
        />
      )}
    </>
  );
};

export default Selections;
