import { TableRowSelectedEventType } from '@danfoss/mosaic';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfAccordion } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { useEffect, useState, useCallback, MutableRefObject, ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch, useAppSelector } from 'configs/store';
import { trackEvent } from 'utils/analytics';
import Checkbox from 'views/shared/components/form/Checkbox';
import { AvailableProductsSearchMode, MachineExampleCode } from 'views/shared/types';
import { useMotorTypeContext } from 'views/wizard/product-selection/motor-type-context/motorTypeContext';
import {
  getSelectedMotors,
  addMotor,
  updateMotor,
  getCurrent,
  addMotorsPrices,
  getSelectedProducts,
  getMotorDetailsAndCalculations,
  getProject,
} from 'views/wizard/shared/store/projectSlice';

import { getAvailableMotors, IAvailableMotor } from '../../../../api/products';
import {
  getMotorsAuthorizedFilter,
  getMotorsPressureLimitFilter,
  setMotorsAuthorizedFilter,
  setMotorsPressureLimitFilter,
} from '../../../../store/productFilterSlice';
import ModalLoader from '../../../components/modals/ModalLoader/ModalLoader';
import NoProducts from '../../../components/modals/NoProducts/NoProducts';
import SelectionModal, {
  SelectionModalAction,
} from '../../../components/modals/SelectionModal/SelectionModal';
import selectionModalStyles from '../../../components/modals/SelectionModal/SelectionModal.module.scss';
import MotorTable from '../MotorTable/MotorTable';

interface MotorModalProps {
  isOpen: boolean;
  focusedItem: MutableRefObject<string | null>;
  action: SelectionModalAction;
  numberOfMotors: number;
  onModalClose: () => void;
}

const MotorModal = ({
  isOpen,
  onModalClose,
  action,
  focusedItem,
  numberOfMotors,
}: MotorModalProps) => {
  const dispatch = useAppDispatch();
  const project = useAppSelector(getCurrent);
  const selectedMotors = useAppSelector(getSelectedMotors)!;
  const { motorType } = useMotorTypeContext();

  const isCustomFlow = project.machine_example_code === MachineExampleCode.Custom;

  const [isModalUpdating, setIsModalUpdating] = useState(false);
  const [selectedMotorId, setSelectedMotorId] = useState<string>();

  const [isMotorListLoading, setIsMotorListLoading] = useState(false);
  const [motorList, setMotorList] = useState<IAvailableMotor[]>([]);

  const authOnly = useAppSelector(getMotorsAuthorizedFilter);
  const searchMode = authOnly
    ? AvailableProductsSearchMode.AuthedOnly
    : AvailableProductsSearchMode.All;

  const handleAuthCheckboxChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { checked } = target;

    dispatch(setMotorsAuthorizedFilter(checked));
  };

  const pressureLimitFilter = useAppSelector(getMotorsPressureLimitFilter);
  const handlePressureLimitFilterChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { checked } = target;

    dispatch(setMotorsPressureLimitFilter(checked));
  };

  const fetchMotorList = async () => {
    try {
      setIsMotorListLoading(true);

      const list = await getAvailableMotors(project.id, {
        number_of_motors: numberOfMotors,
        filter_displacement_type: motorType!,
        filter_by_max_pressure: !pressureLimitFilter,
        filter_search_mode: searchMode,
        item_to_replace: focusedItem.current || undefined,
      });

      setMotorList(list);
      setSelectedMotorId(list.find(({ recommended }) => recommended)?.motor.id);
    } finally {
      setIsMotorListLoading(false);
    }
  };

  useEffect(() => {
    fetchMotorList();
  }, [authOnly, pressureLimitFilter]);

  const onRowSelected = useCallback((event: CustomEvent<TableRowSelectedEventType>) => {
    const { detail } = event;
    setSelectedMotorId(detail.rowId);
  }, []);

  const onAddMotor = async () => {
    const motorData = motorList.find(({ motor }) => motor.id === selectedMotorId);

    await dispatch(
      addMotor({ motorId: selectedMotorId!, numberOfSelectedMotors: numberOfMotors }),
    ).unwrap();

    if (motorData?.price) {
      dispatch(
        addMotorsPrices({
          [motorData.motor.id]: motorData.price,
        }),
      );
    }
  };

  const onReplaceMotor = async () => {
    const oldMotor = selectedMotors.find(({ id }) => id === focusedItem.current)!;
    const { id, rolling_radius } = oldMotor;
    const motorData = motorList.find(({ motor }) => motor.id === selectedMotorId);
    const recommendedMotorId = motorList.find(motor => !!motor.recommended)?.motor.id;

    trackEvent({
      event: 'product_change',
      productType: 'motor',
      selectionId: project.id,
      recommendedProduct: recommendedMotorId === selectedMotorId,
    });
    await dispatch(
      updateMotor({
        motor: {
          id,
          rolling_radius,
          product_id: selectedMotorId!,
        },
      }),
    ).unwrap();

    if (motorData?.price) {
      dispatch(
        addMotorsPrices({
          [motorData.motor.id]: motorData.price,
        }),
      );
    }

    if (!isCustomFlow) {
      const products = await dispatch(getSelectedProducts(project.id)).unwrap();
      const pumpsId = products.pumps.map(pump => pump.selected_pump.product_id);

      await dispatch(getMotorDetailsAndCalculations({ projectId: project.id, pumpsId })).unwrap();
      await dispatch(getProject(project.id)).unwrap();
    }
  };

  const actions = {
    add: onAddMotor,
    replace: onReplaceMotor,
  };

  const handleSubmit = async () => {
    try {
      setIsModalUpdating(true);
      await actions[action!]();
      onModalClose();
    } catch {
      setIsModalUpdating(false);
    }
  };

  const Products = motorList.length ? (
    <MotorTable list={motorList} onRowSelected={onRowSelected} data-testid="motor-modal-table" />
  ) : (
    <NoProducts />
  );

  const Sidebar = (
    <>
      <h2 className={utils.mb6}>
        <FormattedMessage id="wizard_product_add_motor" />
      </h2>
      <div className={cn(selectionModalStyles.filters, utils.py2)}>
        <DfAccordion isExpanded>
          <span slot="header">
            <FormattedMessage id="wizard_selection_modal_product_families" />
          </span>
          <div slot="content" className={utils.pt4}>
            <Checkbox
              id="authorized-products"
              onChange={handleAuthCheckboxChange}
              checked={authOnly}
              data-testid="motor-modal-authorized-products-checkbox"
            >
              <FormattedMessage id="wizard_selection_modal_product_families_only_auth" />
            </Checkbox>
          </div>
        </DfAccordion>
      </div>
      <div className={cn(selectionModalStyles.filters, utils.py2)}>
        <DfAccordion isExpanded>
          <span slot="header">
            <FormattedMessage id="wizard_selection_modal_attributes_filters" />
          </span>
          <div slot="content" className={utils.pt4}>
            <Checkbox
              id="pressure-limit-filter"
              onChange={handlePressureLimitFilterChange}
              checked={pressureLimitFilter}
              data-testid="motor-modal-pressure-limit-filter"
            >
              <FormattedMessage id="wizard_selection_modal_pressure_limit_filter" />
            </Checkbox>
          </div>
        </DfAccordion>
      </div>
    </>
  );

  return (
    <SelectionModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      submitLabel={<FormattedMessage id="wizard_motor_modal_select_motor" />}
      handleSubmit={handleSubmit}
      sidebar={Sidebar}
      isLoading={isMotorListLoading}
      isUpdating={isModalUpdating}
      isSubmitButtonDisabled={!selectedMotorId}
      data-testid="motor-modal"
    >
      <div className={cn(core.flex, core.alignCenter, utils.mt2, utils.mb6)}>
        <h4>
          <FormattedMessage id="wizard_motor_modal_hydraulic_solution" />
        </h4>
        <a
          href="https://powersource.danfoss.com/application-catalog"
          target="_blank"
          rel="noreferrer"
          className={cn(core.textBold, core.external, utils.ml6)}
        >
          <FormattedMessage id="wizard_motor_modal_electric_solutions" />
        </a>
      </div>
      <div
        className={cn(
          core.flex,
          core.alignCenter,
          core.flexCenter,
          selectionModalStyles['content-wrapper'],
        )}
      >
        {isMotorListLoading ? <ModalLoader /> : Products}
      </div>
    </SelectionModal>
  );
};

export default MotorModal;
