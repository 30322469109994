import configureMeasurements, {
  MassSystems,
  MassUnits,
  mass,
  VolumeSystems,
  VolumeUnits,
  volume,
  SpeedSystems,
  SpeedUnits,
  speed,
  PressureSystems,
  PressureUnits,
  pressure,
  LengthSystems,
  LengthUnits,
  length,
  Measure,
  PowerUnits,
  PowerSystems,
  power,
  ForceSystems,
  ForceUnits,
  force,
} from 'convert-units';

import {
  IAvailableMotor,
  IAvailablePump,
  IAvailableSteeringProduct,
  IAvailableSteeringUnit,
} from 'views/wizard/product-selection/api/products';

import { MeasuringUnits } from './MeasuringSystemContext';

type ExtendedSpeedUnits = SpeedUnits | 'm/min' | 'ft/min';
const extendedSpeed: Measure<SpeedSystems, ExtendedSpeedUnits> = {
  systems: {
    metric: {
      ...speed.systems.metric,
      'm/min': {
        name: {
          singular: 'm/min',
          plural: 'm/min',
        },
        to_anchor: 1 / 16.66667,
      },
    },
    imperial: {
      ...speed.systems.imperial,
      'ft/min': {
        name: {
          singular: 'ft/min',
          plural: 'ft/min',
        },
        to_anchor: 1 / 88,
      },
    },
  },
  anchors: {
    ...speed.anchors,
  },
};

type Measures = 'mass' | 'volume' | 'speed' | 'pressure' | 'length' | 'power' | 'force';
type Systems =
  | MassSystems
  | VolumeSystems
  | SpeedSystems
  | PressureSystems
  | LengthSystems
  | PowerSystems
  | ForceSystems;
export type Units =
  | MassUnits
  | VolumeUnits
  | ExtendedSpeedUnits
  | PressureUnits
  | LengthUnits
  | PowerUnits
  | ForceUnits;
type MetricAndImperialUnits = {
  metric: Units;
  imperial: Units;
};

export const convert = configureMeasurements<Measures, Systems, Units>({
  mass,
  volume,
  speed: extendedSpeed,
  pressure,
  length,
  power,
  force,
});

export type ObjectConverterRules = {
  [fieldName: string]: MetricAndImperialUnits;
};

export const objectConverter = <
  TRules extends ObjectConverterRules,
  TShape extends Record<string, any>,
>(
  object: TShape,
  rulesForConversion: TRules,
  toSystem: MeasuringUnits,
) => {
  return Object.keys(object).reduce((acc, attribute) => {
    const units = rulesForConversion[attribute as keyof TRules];
    const originalValue = object[attribute];

    if (!units || !Number.isFinite(originalValue)) {
      return { ...acc, [attribute]: originalValue };
    }

    const toUnit = toSystem === MeasuringUnits.Metric ? units.metric : units.imperial;
    const fromUnit = toSystem === MeasuringUnits.Metric ? units.imperial : units.metric;
    const value = convert(originalValue).from(fromUnit).to(toUnit);

    return { ...acc, [attribute]: value };
  }, {});
};

type IAvailablePumpUnits = {
  [key in keyof IAvailablePump['pump']]?: MetricAndImperialUnits;
};

const availablePumpUnits: IAvailablePumpUnits = {
  max_pressure: {
    metric: 'bar',
    imperial: 'psi',
  },
  rated_pressure: {
    metric: 'bar',
    imperial: 'psi',
  },
  continuous_pressure: {
    metric: 'bar',
    imperial: 'psi',
  },
};

export const convertAvailablePump = (pump: IAvailablePump['pump'], toSystem: MeasuringUnits) => {
  return objectConverter(pump, availablePumpUnits, toSystem) as IAvailablePump['pump'];
};

type IAvailableMotorUnits = {
  [key in keyof IAvailableMotor['motor']]?: MetricAndImperialUnits;
};

const availableMotorUnits: IAvailableMotorUnits = {
  max_pressure: {
    metric: 'bar',
    imperial: 'psi',
  },
  rated_pressure: {
    metric: 'bar',
    imperial: 'psi',
  },
};

export const convertAvailableMotor = (
  motor: IAvailableMotor['motor'],
  toSystem: MeasuringUnits,
) => {
  return objectConverter(motor, availableMotorUnits, toSystem) as IAvailableMotor['motor'];
};

type IAvailableSteeringUnitUnits = {
  [key in keyof IAvailableSteeringUnit['steering_unit']]?: MetricAndImperialUnits;
};

const availableSteeringUnitUnits: IAvailableSteeringUnitUnits = {
  length: {
    metric: 'mm',
    imperial: 'in',
  },
  width: {
    metric: 'mm',
    imperial: 'in',
  },
  height: {
    metric: 'mm',
    imperial: 'in',
  },
};

export const convertAvailableSteeringUnit = (
  steeringUnit: IAvailableSteeringUnit['steering_unit'],
  toSystem: MeasuringUnits,
) => {
  return objectConverter(
    steeringUnit,
    availableSteeringUnitUnits,
    toSystem,
  ) as IAvailableSteeringUnit['steering_unit'];
};

type IAvailableSteeringProductUnits = {
  [key in keyof IAvailableSteeringProduct]?: MetricAndImperialUnits;
};

const availableSteeringProductUnits: IAvailableSteeringProductUnits = {
  displacement: {
    metric: 'cm3',
    imperial: 'in3',
  },
  spool: {
    metric: 'l',
    imperial: 'gal',
  },
};

export const convertAvailableSteeringProduct = (
  steeringProduct: IAvailableSteeringProduct,
  toSystem: MeasuringUnits,
) => {
  return objectConverter(
    steeringProduct,
    availableSteeringProductUnits,
    toSystem,
  ) as IAvailableSteeringProduct;
};
