import { ReactComponent as ArrowSvg } from '@danfoss/mosaic-icons/dist/svg/expand-less.svg';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { ReactNode, useState, FunctionComponent } from 'react';

import styles from './CollapsibleRow.module.scss';

interface ICollapsibleRowProps {
  title: ReactNode;
  titleClassName?: string;
  columnsCount: number;
  cellClassName?: string;
}

const CollapsibleRow: FunctionComponent<ICollapsibleRowProps> = ({
  title,
  titleClassName,
  cellClassName,
  columnsCount,
  children,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const onCollapse = () => {
    setIsCollapsed(true);
  };
  const onExpand = () => {
    setIsCollapsed(false);
  };

  return (
    <>
      <tbody className={cn(!isCollapsed && core.hidden)} data-testid="collapsed-part">
        <tr>
          <th className={titleClassName}>
            <div
              onClick={onExpand}
              className={cn(core.flex, styles.toggle)}
              data-testid="expand-trigger"
            >
              <span className={utils.mr1}>{title}</span> <ArrowSvg className={core.icon} />
            </div>
          </th>
          {new Array(columnsCount).fill(null).map((_, index) => (
            <td className={cellClassName} key={index} data-testid="empty-cell" />
          ))}
        </tr>
      </tbody>
      <tbody className={cn(isCollapsed && core.hidden, styles.table)} data-testid="content-part">
        <tr>
          <th className={cn(titleClassName, styles['row-name'])}>
            <div
              onClick={onCollapse}
              className={cn(core.flex, styles.toggle)}
              data-testid="collapse-trigger"
            >
              <span className={utils.mr1}>{title}</span>{' '}
              <ArrowSvg className={cn(core.icon, styles['collapsed-icon'])} />
            </div>
          </th>
          {children}
        </tr>
      </tbody>
    </>
  );
};

export default CollapsibleRow;
