import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { PropsWithChildren, ReactNode } from 'react';

import styles from './ExamplesSection.module.scss';

type ExamplesSectionProps = PropsWithChildren<{
  testId?: string;
  title?: ReactNode;
  description?: ReactNode;
}>;

export function ExamplesSection({ title, description, children, testId }: ExamplesSectionProps) {
  return (
    <>
      <h4 className={cn(utils.mt8, core.textCenter)} data-testid={testId}>
        {title}
      </h4>
      <p className={cn(utils.mt1, core.textCenter)}>{description}</p>
      <div className={cn(core.flex, core.flexCenter, utils.mt8, styles.options)}>{children}</div>
    </>
  );
}
