import core from '@danfoss/mosaic/css/core.module.css';
import cn from 'classnames';
import { ReactNode } from 'react';

import styles from './StaticAttribute.module.scss';

export interface StaticAttributeProps {
  name: ReactNode;
  value: ReactNode;
  boldValue?: boolean;
  boldTitle?: boolean;
  testId?: string;
}

const StaticAttribute = ({ name, value, boldValue, boldTitle, testId }: StaticAttributeProps) => {
  return (
    <div className={cn(styles['attribute-item'], core.flex, core.flexBetween)}>
      <div
        className={cn({ [core.textBold]: boldTitle })}
        data-testid={testId ? `${testId}-title` : null}
      >
        {name}
      </div>
      <div className={cn({ [core.textBold]: boldValue })} data-testid={testId}>
        {value}
      </div>
    </div>
  );
};

export default StaticAttribute;
