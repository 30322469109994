import button from '@danfoss/mosaic/css/components/button.module.css';
import hero from '@danfoss/mosaic/css/components/hero-card.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import styles from './HeroBanner.module.scss';

interface UmbracoImage {
  contentId: number;
  crops: {
    original: string;
  };
  name: string;
  originalHeight: number;
  originalWidth: number;
  url: string;
}

const HeroBanner = () => {
  const { messages } = useIntl();
  const image = Array.isArray(messages.home_banner_image)
    ? (messages.home_banner_image[0] as unknown as UmbracoImage)
    : null;
  const imageUrl = image && image.url;
  const history = useHistory();

  const redirectToFunctionSelection = () => {
    history.push('/function-selection');
  };

  return (
    <div>
      {imageUrl && <img src={imageUrl} alt="" className={styles['main-img']} />}
      <div className={cn(core.container, utils.pt12)}>
        <div className={cn(hero.heroCard, hero.contentBox)}>
          <div className={core.container}>
            <div className={cn(hero.content, styles.content)}>
              <div className={hero.mainContent}>
                <h1 className={styles.title}>
                  <FormattedMessage id="home_sub_title" />
                </h1>
                <p className={styles.description}>
                  <FormattedMessage id="home_banner_text" />
                </p>
              </div>
              <div className={hero.sideContent}>
                <button
                  className={button.btnPrimary}
                  onClick={redirectToFunctionSelection}
                  data-testid="hero-banner-button"
                >
                  <FormattedMessage id="home_new_selection_button" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(HeroBanner);
