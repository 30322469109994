import FormatMeasureUnit from 'views/shared/helpers/measuringSystem/FormatMeasureUnit';
import FormatMeasureValue from 'views/shared/helpers/measuringSystem/FormatMeasureValue';
import { ISelectedSteeringProduct } from 'views/shared/types';

export const getMainSpec = (product: ISelectedSteeringProduct) => {
  const { spool, displacement } = product;
  let mainSpec;

  if (spool) {
    mainSpec = (
      <span data-testid="steering-unit-spec">
        <FormatMeasureValue metric="l" imperial="gal" value={spool} />{' '}
        <FormatMeasureUnit metric="measurements_unit_l_min" imperial="measurements_unit_gal_min" />
      </span>
    );
  }

  if (displacement) {
    mainSpec = (
      <span data-testid="steering-unit-spec">
        <FormatMeasureValue metric="cm3" imperial="in3" value={displacement} />{' '}
        <FormatMeasureUnit
          metric="measurements_unit_cm3_rev"
          imperial="measurements_unit_in3_rev"
        />
      </span>
    );
  }

  return mainSpec as JSX.Element;
};
