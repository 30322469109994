import { ReactNode } from 'react';

import { ReactComponent as SvgCustom } from 'assets/icons/MachineExamples/custom.svg';
import { ReactComponent as SvgDualPath } from 'assets/icons/MachineExamples/dualpath.svg';
import { ReactComponent as SvgDumper } from 'assets/icons/MachineExamples/dumper.svg';
import { ReactComponent as SvgMining } from 'assets/icons/MachineExamples/mining.svg';
import { ReactComponent as SvgOnePumpMultipleMotors } from 'assets/icons/MachineExamples/one_pump_multiple_motors.svg';
import { ReactComponent as SvgOnePumpOneMotor } from 'assets/icons/MachineExamples/one_pump_one_motor.svg';
import { ReactComponent as SvgTelehandler } from 'assets/icons/MachineExamples/telehandler.svg';
import { ReactComponent as SvgWheelLoader } from 'assets/icons/MachineExamples/wheel_loader.svg';
import { MachineExampleCode } from 'views/shared/types';

interface IMappedExamplesData {
  icon?: ReactNode;
  title?: string;
  description?: string;
  tooltip?: string;
}

export const examplesData: Record<MachineExampleCode, IMappedExamplesData> = {
  [MachineExampleCode.Custom]: {
    icon: <SvgCustom height={48} width={48} />,
    title: 'wizard_example_selection_example_custom_title',
    description: 'wizard_example_selection_example_custom_description',
  },
  [MachineExampleCode.WheelLoader]: {
    icon: <SvgWheelLoader />,
    title: 'wizard_example_selection_example_wheel_loader_title',
    description: 'wizard_example_selection_example_wheel_loader_description',
  },
  [MachineExampleCode.MiningVehicle]: {
    icon: <SvgMining />,
    title: 'wizard_example_selection_example_mining_vehicle_title',
    description: 'wizard_example_selection_example_mining_vehicle_description',
  },
  [MachineExampleCode.Telehandler]: {
    icon: <SvgTelehandler />,
    title: 'wizard_example_selection_example_telehandler_title',
    description: 'wizard_example_selection_example_telehandler_description',
  },
  [MachineExampleCode.Dumper]: {
    icon: <SvgDumper />,
    title: 'wizard_example_selection_example_dumper_title',
    description: 'wizard_example_selection_example_dumper_description',
  },
  [MachineExampleCode.OnePumpOneMotor]: {
    icon: <SvgOnePumpOneMotor />,
    title: 'wizard_example_selection_example_one_pump_one_motor_title',
    tooltip: 'wizard_example_selection_example_one_pump_one_motor_tooltip',
  },
  [MachineExampleCode.OnePumpMultipleMotors]: {
    icon: <SvgOnePumpMultipleMotors />,
    title: 'wizard_example_selection_example_one_pump_multiple_motors_title',
    tooltip: 'wizard_example_selection_example_one_pump_multiple_motors_tooltip',
  },
  [MachineExampleCode.DualPath]: {
    icon: <SvgDualPath />,
    title: 'wizard_example_selection_example_dual_path_title',
    tooltip: 'wizard_example_selection_example_dual_path_tooltip',
  },
};

export const architectureExamples = [
  MachineExampleCode.OnePumpOneMotor,
  MachineExampleCode.OnePumpMultipleMotors,
  MachineExampleCode.DualPath,
];

export const machineExamples = [
  MachineExampleCode.WheelLoader,
  MachineExampleCode.MiningVehicle,
  MachineExampleCode.Telehandler,
  MachineExampleCode.Dumper,
];
