import { AxiosError } from 'axios';

import { NOT_AUTHORIZED_STATUS, NOT_FOUND_STATUS } from 'configs/axios';
import { errorInterceptor as commonErrorInterceptor } from 'views/shared/helpers/interceptors';
import { CommonError } from 'views/shared/types';

export const errorInterceptor = (error: AxiosError<CommonError>) => {
  const { status } = error.response || {};
  if (status === NOT_FOUND_STATUS || status === NOT_AUTHORIZED_STATUS) {
    return;
  }

  commonErrorInterceptor(error);
};
