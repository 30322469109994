import { useCallback, useEffect } from 'react';
import { useIntl } from 'react-intl';

const useNativeUnloadWarning = (flagToWatch: boolean) => {
  const { formatMessage } = useIntl();

  const handleUnload = useCallback((event: BeforeUnloadEvent) => {
    event.preventDefault();
    event.returnValue = formatMessage({ id: 'wizard_requirements_unload_message' });

    return formatMessage({ id: 'wizard_requirements_unload_message' });
  }, []);

  useEffect(() => {
    if (flagToWatch) {
      window.addEventListener('beforeunload', handleUnload);
    } else {
      window.removeEventListener('beforeunload', handleUnload);
    }
  }, [flagToWatch]);

  useEffect(
    () => () => {
      window.removeEventListener('beforeunload', handleUnload);
    },
    [],
  );
};

export default useNativeUnloadWarning;
