import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import { DfModal } from '@danfoss/mosaic/react';
import cn from 'classnames';
import React, { useState, ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from 'views/shared/components/Button/Button';
import Fieldset from 'views/shared/components/form/Fieldset';
import Input from 'views/shared/components/form/Input';
import Label from 'views/shared/components/form/Label/Label';

import styles from './RenameSelectionModal.module.scss';

interface RenameSelectionProps {
  projectName: string;
  onSubmit: (value: string) => void | Promise<void>;
  onCancel: () => void;
}

const MAX_CHARACTERS = 25;

const RenameSelectionModal = ({ onSubmit, onCancel, projectName }: RenameSelectionProps) => {
  const { formatMessage } = useIntl();
  const headingLabel = formatMessage({ id: 'wizard_summary_rename_modal_heading' });

  const [isUpdating, setIsUpdating] = useState(false);
  const [newName, setNewName] = useState(projectName);
  const isNameValid = !!newName.trim().length;
  const hasNameWarning = newName.length === MAX_CHARACTERS;

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    if (value.length > MAX_CHARACTERS) {
      return;
    }

    setNewName(value);
  };

  const handleSubmit = async () => {
    try {
      setIsUpdating(true);
      await onSubmit(newName.trim());
    } catch {
      setIsUpdating(false);
    }
  };

  return (
    <DfModal
      isOpen
      heading={headingLabel}
      size="small"
      onModalClose={onCancel}
      data-testid="rename-modal"
    >
      <div slot="modal-body">
        <Fieldset>
          <Label>
            <FormattedMessage id="wizard_summary_rename_modal_label" />
          </Label>
          <Input
            className={styles.input}
            onChange={handleChange}
            value={newName}
            warning={hasNameWarning}
            error={!isNameValid}
            data-testid="rename-modal-new-name-input"
          />
          {!isNameValid && (
            <label
              className={cn(core.helperText, core.error, styles.error)}
              data-testid="empty-name-error"
            >
              <FormattedMessage id="default_required_error" />
            </label>
          )}
          <label
            className={cn(core.helperText, { [core.warning]: hasNameWarning })}
            data-testid="rename-modal-helper"
          >
            <FormattedMessage id="wizard_summary_rename_modal_helper" />
          </label>
        </Fieldset>
      </div>
      <div slot="modal-footer">
        <Button
          className={cn(button.textBtn, button.textBtnSecondary)}
          onClick={onCancel}
          data-testid="rename-modal-cancel"
        >
          <FormattedMessage id="wizard_summary_rename_modal_cancel" />
        </Button>
        <Button
          className={button.btnPrimary}
          type="submit"
          disabled={!isNameValid}
          loading={isUpdating}
          onClick={handleSubmit}
          data-testid="rename-modal-confirm"
        >
          <FormattedMessage id="wizard_summary_rename_modal_confirm" />
        </Button>
      </div>
    </DfModal>
  );
};

export default RenameSelectionModal;
