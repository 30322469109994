export const finalDriveRatioValidation = {
  required: false,
  min: 1,
  max: 250,
};

export const motorWidgetValidation = {
  rolling_radius: {
    required: true,
    min: 50,
    max: 2500,
  },
  final_drive_ratio: finalDriveRatioValidation,
  final_drive_ratio2: finalDriveRatioValidation,
};
