import { DfLoader } from '@danfoss/mosaic/react';
import { useEffect, FunctionComponent, ReactElement, useState } from 'react';

import { getTranslations, ITranslations } from 'api/translations';
import commonEnTranslations from 'i18n/common/en.json';
import sharingTranslations from 'i18n/sharing/en.json';
import wizardTranslations from 'i18n/wizard/en.json';
import { showWarning } from 'utils/ToastService';

interface ITranslationsProps {
  onLoad: (data: ITranslations) => void;
  locale: string;
}

const Translations: FunctionComponent<ITranslationsProps> = ({ onLoad, locale, children }) => {
  const [isLoading, setIsLoading] = useState(true);
  const fallbackMessages = {
    ...commonEnTranslations,
    ...wizardTranslations,
    ...sharingTranslations,
  };

  const filterMessages = (messages: ITranslations) => {
    return Object.fromEntries(Object.entries(messages).filter(([, value]) => !!value));
  };

  useEffect(() => {
    (async () => {
      try {
        const data = await getTranslations(locale);
        const filledMessages = filterMessages(data);

        onLoad({ ...fallbackMessages, ...filledMessages });
      } catch {
        onLoad(fallbackMessages);
        showWarning(
          'Problems loading language',
          'Currently the tool is only available in English, please try again later.',
        );
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  if (isLoading) {
    return <DfLoader isGlobal isVisible data-testid="translations-loader" />;
  }

  return children as ReactElement;
};

export default Translations;
