import { ReactComponent as SvgClose } from '@danfoss/mosaic-icons/dist/svg/close.svg';
import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { Components } from '@danfoss/mosaic/dist/types/components';
import { DfModal } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'views/shared/components/Button/Button';

import styles from './SelectionModal.module.scss';

export type SelectionModalAction = 'add' | 'replace' | null;
export interface SelectionModalProps extends Partial<Components.DfModal> {
  isLoading?: boolean;
  isUpdating?: boolean;
  isSubmitButtonDisabled?: boolean;
  children?: ReactNode;
  sidebar?: ReactNode;
  submitLabel: ReactNode;
  onModalClose: () => void;
  handleSubmit: () => void;
}

const SelectionModal = ({
  children,
  sidebar,
  isLoading,
  submitLabel,
  onModalClose,
  handleSubmit,
  isUpdating,
  isSubmitButtonDisabled,
  ...props
}: SelectionModalProps) => {
  return (
    <DfModal
      {...props}
      className={styles['selection-modal']}
      onModalClose={onModalClose}
      isHeaderHidden
    >
      <div
        slot="modal-body"
        className={cn(core.flex, core.fullHeight)}
        data-testid="selection-modal-body"
      >
        <SvgClose
          className={cn(core.icon, styles['close-button'])}
          onClick={onModalClose}
          data-testid="modal-close-button"
        />
        <div className={styles.sidebar}>{sidebar}</div>
        <div className={utils.ml6}>{children}</div>
      </div>
      <div slot="modal-footer">
        <Button className={cn(button.textBtn, button.textBtnSecondary)} onClick={onModalClose}>
          <FormattedMessage id="wizard_selection_modal_cancel" />
        </Button>
        <Button
          className={button.btnPrimary}
          loading={isUpdating}
          disabled={isSubmitButtonDisabled || isLoading || isUpdating}
          onClick={handleSubmit}
          data-testid="selection-modal-select-button"
        >
          {submitLabel}
        </Button>
      </div>
    </DfModal>
  );
};

export default SelectionModal;
