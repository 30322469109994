import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { ReactNode, ChangeEvent, useContext, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as SvgInfoWarning } from 'assets/icons/info-warning.svg';
import { useAppSelector } from 'configs/store';
import debounce from 'utils/debounce';
import TooltipInfo from 'views/shared/components/TooltipInfo/TooltipInfo';
import Fieldset from 'views/shared/components/form/Fieldset';
import Addon from 'views/shared/components/form/InputAddon';
import InputGroup from 'views/shared/components/form/InputGroup';
import ConverterInput from 'views/shared/helpers/measuringSystem/ConverterInput';
import FormatMeasureUnit from 'views/shared/helpers/measuringSystem/FormatMeasureUnit';
import FormatMeasureValue from 'views/shared/helpers/measuringSystem/FormatMeasureValue';
import {
  MeasuringSystemContext,
  MeasuringUnits,
} from 'views/shared/helpers/measuringSystem/MeasuringSystemContext';
import { ICalculations, IMotorDetails } from 'views/shared/types';
import { getMotorsDetailsAndSpeed } from 'views/wizard/shared/api/project';
import {
  getCurrent,
  getSelectedMotors,
  getSelectedPumps,
} from 'views/wizard/shared/store/projectSlice';

import styles from './MotorsDisplacementSpeed.module.scss';

type IMotorDisplacementSpeedRecord = Record<string, IMotorDetails>;

interface MotorsDisplacementSpeedProps {
  isMinDisplacementVisible: boolean;
  selectedPumpId: string;
  ignorePump: string | null;
  onMotorsDataChange: (data: IMotorDisplacementSpeedRecord) => void;
  onCalculationsDataChange: (data: ICalculations) => void;
  onLoadingStateChange: (data: boolean) => void;
  onMinDisplacementError: (data: boolean) => void;
}

const MotorsDisplacementSpeed = ({
  isMinDisplacementVisible,
  selectedPumpId,
  ignorePump,
  onMotorsDataChange,
  onCalculationsDataChange,
  onLoadingStateChange,
  onMinDisplacementError,
}: MotorsDisplacementSpeedProps) => {
  const { formatMessage } = useIntl();
  const { units } = useContext(MeasuringSystemContext);
  const selectedMotors = useAppSelector(getSelectedMotors);
  const selectedPumps = useAppSelector(getSelectedPumps);
  const selectedPumpsIds = (selectedPumps || [])
    .filter(pump => pump.id !== ignorePump)
    .map(pump => pump.product_id);
  const { id: projectId } = useAppSelector(getCurrent);
  const [isLoading, setIsLoading] = useState(true);
  const [motorsData, setMotorsData] = useState<IMotorDisplacementSpeedRecord>({});
  const [displacements, setDisplacements] = useState<Record<string, number>>({});
  const [displacementErrors, setDisplacementErrors] = useState<Record<string, ReactNode>>({});
  const [calculationsData, setCalculationsData] = useState<ICalculations>({});

  const validateDisplacements = () => {
    const errors: Record<string, ReactNode> = {};

    Object.entries(displacements).forEach(([id, value]) => {
      const relatedMotor = (selectedMotors || []).find(motor => motor.id === id)!;
      const { min_displacement, max_min_displacement } = relatedMotor.related_motor_info;
      const max = Math.floor(max_min_displacement!);
      const min = Math.ceil(min_displacement);

      if (value < min) {
        errors[id] = formatMessage({ id: 'wizard_pump_modal_displacement_low' });
        return;
      }

      if (value > max) {
        errors[id] = formatMessage(
          { id: 'wizard_pump_modal_displacement_high' },
          {
            value: (
              <FormatMeasureValue
                metric="cm3"
                imperial="in3"
                value={max}
                precision={{
                  metric: 0,
                  imperial: 2,
                }}
              />
            ),
          },
        );
      }
    });

    return errors;
  };

  const getValueForMotor = (id: string, key: keyof IMotorDetails) => {
    let value = motorsData?.[id]?.[key];

    if (typeof value === 'number') {
      value = value.toFixed();
    }

    return value || '';
  };

  const fetchMotorSpecs = useMemo(
    () =>
      debounce(
        async (
          project: string,
          pumpIds: string[],
          displacementsForMotors?: Record<string, number>,
        ) => {
          try {
            setIsLoading(true);
            let motors;

            if (displacementsForMotors) {
              motors = Object.entries(displacementsForMotors).map(([id, min_displacement]) => ({
                id,
                min_displacement,
              }));
            }

            const response = await getMotorsDetailsAndSpeed(project, pumpIds, motors);

            const motorsDataAsObject = Object.fromEntries(
              response.data.motors
                .map(({ min_displacement, ...rest }) => {
                  const { related_motor_info } = (selectedMotors || []).find(
                    motor => motor.id === rest.id,
                  )!;
                  const min = Math.ceil(related_motor_info.min_displacement);
                  const max = Math.floor(related_motor_info.max_min_displacement!);
                  const normalizedValue = Math.max(Math.min(min_displacement!, max), min);

                  return {
                    ...rest,
                    min_displacement: normalizedValue,
                  };
                })
                .map(details => [details.id, details]),
            );

            setMotorsData(motorsDataAsObject);
            setCalculationsData(response.data.calculations);
            setDisplacementErrors({});
          } finally {
            setIsLoading(false);
          }
        },
        1000,
      ),
    [],
  );

  useEffect(() => {
    const pumpList = [...selectedPumpsIds, selectedPumpId];

    fetchMotorSpecs.immediate(projectId, pumpList);
  }, [selectedPumpId]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const errors = validateDisplacements();
    setDisplacementErrors(errors);

    if (!Object.keys(errors).length && isMinDisplacementVisible) {
      const pumpList = [...selectedPumpsIds, selectedPumpId];
      fetchMotorSpecs(projectId, pumpList, displacements);
    } else {
      fetchMotorSpecs.cancel();
    }
  }, [displacements]);

  useEffect(() => {
    onMotorsDataChange(motorsData);

    const dataForDisplacements = Object.entries(motorsData).reduce(
      (total, [key, data]) => ({
        ...total,
        [key]: data.min_displacement,
      }),
      {},
    );

    setDisplacements(dataForDisplacements);
  }, [motorsData]);

  useEffect(() => {
    onCalculationsDataChange(calculationsData);
  }, [calculationsData]);

  useEffect(() => {
    onLoadingStateChange(isLoading);
  }, [isLoading]);

  useEffect(() => {
    onMinDisplacementError(!!Object.keys(displacementErrors).length);
  }, [displacementErrors]);

  useEffect(
    () => () => {
      fetchMotorSpecs.cancel();
    },
    [],
  );

  const handleDisplacementChange = (id: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const value = +event.target.value;

    setDisplacements(state => ({
      ...state,
      [id]: value,
    }));
  };

  return (
    <div>
      <h4 className={cn(utils.mb3, core.textBold)}>
        <FormattedMessage id="wizard_pump_modal_selected_motors" />
      </h4>

      <table className={styles.table}>
        <thead>
          <tr>
            <th></th>
            {selectedMotors?.map(({ name, id }) => (
              <th key={id} className={cn(core.textBold, styles.header, styles.column)}>
                {name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isMinDisplacementVisible && (
            <tr>
              <th className={cn(styles.label, core.textBold)}>
                <FormattedMessage id="wizard_pump_modal_min_displacement" />
              </th>
              {selectedMotors?.map(motor => (
                <td className={styles.column} key={motor.id}>
                  <Fieldset>
                    <InputGroup>
                      <ConverterInput
                        type="number"
                        className={styles['input-displacement']}
                        value={Math.floor(displacements[motor.id]) || ''}
                        min={Math.ceil(motor.related_motor_info.min_displacement)}
                        max={Math.floor(motor.related_motor_info.max_min_displacement!)}
                        step={units === MeasuringUnits.Metric ? 1 : 0.01}
                        onChange={handleDisplacementChange(motor.id)}
                        data-testid={`motor-min-displacement-value-${motor.id}`}
                        metric="cm3"
                        imperial="in3"
                        precision={{
                          metric: 0,
                          imperial: 2,
                        }}
                      />
                      <Addon>
                        <FormatMeasureUnit
                          metric="measurements_unit_cm3_rev"
                          imperial="measurements_unit_in3_rev"
                        />
                      </Addon>
                    </InputGroup>
                    <div
                      className={cn(utils.colorAccentBase, styles['error-container'])}
                      data-testid={`motor-min-displacement-error-${motor.id}`}
                    >
                      {displacementErrors[motor.id]}
                    </div>
                  </Fieldset>
                </td>
              ))}
            </tr>
          )}
          <tr>
            <th className={cn(core.textRegular, styles.label)}>
              <FormattedMessage id="wizard_pump_modal_speed" />
            </th>
            {selectedMotors?.map(motor => (
              <td key={motor.id} className={styles.column}>
                <div>
                  <span data-testid={`motor-shaft-speed-${motor.id}`}>
                    {getValueForMotor(motor.id, 'speed')}{' '}
                    <FormattedMessage id="measurements_unit_rpm" />
                  </span>
                  <div className={styles['error-container']}>
                    {getValueForMotor(motor.id, 'is_overspeeding') && (
                      <div className={cn(core.flex, core.alignCenter)}>
                        <span
                          className={utils.colorAccentBase}
                          data-testid={`shaft-speed-overspeeding-${motor.id}`}
                        >
                          <FormattedMessage id="wizard_pump_modal_rated" />{' '}
                          {getValueForMotor(motor.id, 'speed_limit_rated_for_displacement')}{' '}
                          <FormattedMessage id="measurements_unit_rpm" />
                        </span>
                        <TooltipInfo
                          trigger={
                            <SvgInfoWarning
                              className={cn(
                                core.icon,
                                core.iconM,
                                utils.colorAccentBase,
                                utils.ml1,
                              )}
                            />
                          }
                          className={styles['tooltip-container']}
                        >
                          <FormattedMessage id="wizard_overspeeding_warning" />
                        </TooltipInfo>
                      </div>
                    )}
                  </div>
                </div>
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default MotorsDisplacementSpeed;
