import { ReactComponent as SvgPlus } from '@danfoss/mosaic-icons/dist/svg/plus.svg';
import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from 'configs/store';
import Button from 'views/shared/components/Button/Button';
import { MachineExampleCode, SteeringFunction } from 'views/shared/types';
import { getCurrent, getSelectedSteeringProducts } from 'views/wizard/shared/store/projectSlice';

import styles from '../../ProductSelection.module.scss';
import HybridSteeringWidget from './components/HybridSteeringWidget/HybridSteeringWidget';
import ConventionalSteeringModal from './components/SteeringModal/ConventionalSteeringModal';
import HybridModal from './components/SteeringModal/HybridSteeringModal';
import SteerByWireModal from './components/SteeringModal/SteerByWireModal';
import SteeringWidget from './components/SteeringWidget';

interface SteeringProps {
  clearSteeringUnit: () => Promise<void>;
}

const Steering = memo(({ clearSteeringUnit }: SteeringProps) => {
  const project = useAppSelector(getCurrent);
  const steeringFunction = project.steering_system!.function;
  const selectedSteeringProducts = useAppSelector(getSelectedSteeringProducts);
  const isSteeringUnitSelected = selectedSteeringProducts && selectedSteeringProducts.length > 0;
  const isCustomFlow = project.machine_example_code === MachineExampleCode.Custom;

  const [isSteeringModalOpen, setIsSteeringModalOpen] = useState(false);

  const onUpdateSteeringModalOpen = () => setIsSteeringModalOpen(true);
  const onUpdateSteeringModalClose = () => setIsSteeringModalOpen(false);

  return (
    <section className={utils.mt6}>
      <h3>
        <FormattedMessage id="wizard_product_selected_steering_unit" />
      </h3>
      <div className={cn(utils.row, utils.mt8)}>
        {isSteeringUnitSelected && steeringFunction !== SteeringFunction.Hybrid && (
          <div
            className={cn(
              utils.col3,
              utils.colMd4,
              utils.colSm8,
              utils.pt2,
              styles['product-item'],
            )}
          >
            <SteeringWidget
              data={selectedSteeringProducts[0]}
              type={project.steering_system!.function}
              unitAttributes={project.steering_system!.steering_unit_attributes}
              onChange={onUpdateSteeringModalOpen}
              onRemove={isCustomFlow ? clearSteeringUnit : undefined}
            />
          </div>
        )}
        {isSteeringUnitSelected && steeringFunction === SteeringFunction.Hybrid && (
          <div
            className={cn(
              utils.col3,
              utils.colMd4,
              utils.colSm8,
              utils.pt2,
              styles['product-item'],
            )}
          >
            <HybridSteeringWidget
              products={selectedSteeringProducts}
              unitAttributes={project.steering_system!.steering_unit_attributes}
              onChange={onUpdateSteeringModalOpen}
              onRemove={isCustomFlow ? clearSteeringUnit : undefined}
            />
          </div>
        )}
        {!isSteeringUnitSelected && (
          <div className={cn(utils.col3, utils.colMd4, utils.colSm8, styles['add-item'])}>
            <Button
              className={cn(button.textBtn, button.textBtnPrimary)}
              onClick={onUpdateSteeringModalOpen}
              data-testid="product-selection-add-steering-unit-button"
            >
              <SvgPlus className={core.icon} />
              <FormattedMessage id="wizard_product_add_steering_unit" />
            </Button>
          </div>
        )}
      </div>
      {isSteeringModalOpen && steeringFunction === SteeringFunction.Conventional && (
        <ConventionalSteeringModal onModalClose={onUpdateSteeringModalClose} />
      )}
      {isSteeringModalOpen && steeringFunction === SteeringFunction.SteerByWire && (
        <SteerByWireModal onModalClose={onUpdateSteeringModalClose} />
      )}
      {isSteeringModalOpen && steeringFunction === SteeringFunction.Hybrid && (
        <HybridModal onModalClose={onUpdateSteeringModalClose} />
      )}
    </section>
  );
});

export default Steering;
