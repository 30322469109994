import core from '@danfoss/mosaic/css/core.module.css';
import cn from 'classnames';

interface IFieldset extends React.HTMLProps<HTMLFieldSetElement> {}

const Fieldset = ({ className, children, ...props }: IFieldset) => {
  return (
    <fieldset className={cn(className, core.formField)} {...props}>
      {children}
    </fieldset>
  );
};

export default Fieldset;
