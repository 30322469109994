import button from '@danfoss/mosaic/css/components/button.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfModal } from '@danfoss/mosaic/react';
import { FormattedMessage, useIntl } from 'react-intl';

interface IRollingRadiusExplanationModalProps {
  onClose: () => void;
}

const RollingRadiusExplanationModal = ({ onClose }: IRollingRadiusExplanationModalProps) => {
  const { formatMessage } = useIntl();
  return (
    <DfModal
      heading={formatMessage({ id: 'wizard_product_motor_widget_rolling_radius_modal_title' })}
      onModalClose={onClose}
      data-testid="rolling-radius-explanation"
      isOpen
    >
      <div slot="modal-body">
        <p className={utils.mb3}>
          <FormattedMessage id="wizard_product_motor_widget_rolling_radius_modal_text_1" />
        </p>
        <p className={utils.mb3}>
          <FormattedMessage id="wizard_product_motor_widget_rolling_radius_modal_text_2" />
        </p>
        <p className={utils.mb3}>
          <FormattedMessage id="wizard_product_motor_widget_rolling_radius_modal_text_3" />
        </p>
        <p className={utils.mb3}>
          <FormattedMessage id="wizard_product_motor_widget_rolling_radius_modal_text_4" />
        </p>
      </div>
      <div slot="modal-footer">
        <button
          className={button.btnPrimary}
          onClick={() => onClose()}
          data-testid="rolling-radius-explanation-button"
        >
          <FormattedMessage id="wizard_product_motor_widget_rolling_radius_modal_ok_button" />
        </button>
      </div>
    </DfModal>
  );
};

export default RollingRadiusExplanationModal;
