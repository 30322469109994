import { CustomSelectOptionBaseType, SelectChangeEventArgsType } from '@danfoss/mosaic';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfSelect } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { IPerformanceCalculations } from 'views/shared/api/performance';
import { getAllSelections, IProjectSelection } from 'views/shared/api/selections';
import { AuthContext, AuthType } from 'views/shared/helpers/auth/AuthContext';
import {
  FunctionCode,
  IProject,
  IProjectPropelSizingData,
  IProjectSteeringData,
  ProjectStatus,
} from 'views/shared/types';

import { IComparison } from '../../api';
import styles from './ComparisonTable.module.scss';
import CollapsibleRow from './components/CollapsibleRow/CollapsibleRow';
import Name from './components/cells/Name/Name';
import Performance from './components/cells/Performance/Performance';
import Price from './components/cells/Price';
import Products from './components/cells/Products/Products';
import Propel from './components/cells/Propel';
import SendToDC from './components/cells/SendToDC/SendToDC';
import Steering from './components/cells/Steering';
import System from './components/cells/System';
import Type from './components/cells/Type';
import { getUniqueFields } from './helpers/calculateDifference';

interface IComparisonTableProps {
  selections: IComparison[];
  onRemoveSelection: (project: IProject) => void;
  onAddSelection: (id: string) => void;
  showOnlyDifference: boolean;
  isCompareLimitExceeded: boolean;
}

const useSelectOptions = (finishedSelections: IProjectSelection[]) => {
  const { formatDate } = useIntl();

  const truncateSelectionName = (selection: IProjectSelection) => {
    const { project_name, modified_at } = selection;
    const MAX_CHARACTERS = 12;

    if (project_name.length > MAX_CHARACTERS) {
      return `${project_name.substring(0, MAX_CHARACTERS)}... | ${formatDate(modified_at)}`;
    }

    return `${project_name} | ${formatDate(modified_at)}`;
  };

  const selectOptions: CustomSelectOptionBaseType[] = finishedSelections.map(selection => ({
    id: selection.id,
    value: selection.project_name,
    label: truncateSelectionName(selection),
  }));

  return {
    selectOptions,
  };
};

const ComparisonTable = ({
  selections,
  onRemoveSelection,
  onAddSelection,
  showOnlyDifference,
  isCompareLimitExceeded,
}: IComparisonTableProps) => {
  const history = useHistory();
  const { authType } = useContext(AuthContext);
  const [allSelectionsPerformance, setAllSelectionsPerformance] = useState<
    IPerformanceCalculations[]
  >([]);

  const { formatMessage } = useIntl();
  const [isDropdownUpdating, setIsDropdownUpdating] = useState(false);
  const isSendToDCAvailable = authType === AuthType.SF;
  const { ids } = useParams<{ ids: string }>();
  const parsedIds = ids.split(',');
  const [finishedSelections, setFinishedSelections] = useState<IProjectSelection[]>([]);
  const { selectOptions } = useSelectOptions(finishedSelections);

  const systemFieldsToShow =
    showOnlyDifference && selections.length > 1
      ? getUniqueFields(selections.map(selection => selection.project.system_data))
      : null;

  const propelSelectionsToCompare: IProjectPropelSizingData[] = [];
  const steeringSelectionsToCompare: IProjectSteeringData[] = [];

  selections.forEach(selection => {
    if (selection.project.propel_sizing) {
      propelSelectionsToCompare.push(selection.project.propel_sizing);
    }

    if (selection.project.steering_system) {
      steeringSelectionsToCompare.push(selection.project.steering_system);
    }
  });

  const propelFieldsToShow =
    showOnlyDifference && propelSelectionsToCompare.length > 1
      ? getUniqueFields(propelSelectionsToCompare)
      : null;
  const steeringFieldsToShow =
    showOnlyDifference && steeringSelectionsToCompare.length > 1
      ? getUniqueFields(steeringSelectionsToCompare)
      : null;

  const onView = (project: IProject) => {
    const { project_status, id } = project;

    if (project_status === ProjectStatus.Draft) {
      history.push(`/requirements/${id}`);
    }

    if (project_status === ProjectStatus.CalculationsCompleted) {
      history.push(`/product-selection/${id}`);
    }

    if (project_status === ProjectStatus.Configured) {
      history.push(`/summary/${id}`);
    }
  };
  const onRemove = (project: IProject) => {
    setAllSelectionsPerformance(items => items.filter(item => item.project_id !== project.id));
    setFinishedSelections(items => [...items, project]);
    onRemoveSelection(project);
  };
  const onAdd = (event: CustomEvent<SelectChangeEventArgsType<false>>) => {
    const selectedId: string = event.detail.value.id;
    const isInList = finishedSelections.find(item => item.id === selectedId);

    if (isInList) {
      setFinishedSelections(items => items.filter(item => item.id !== selectedId));
    }

    onAddSelection(selectedId);
  };
  const onPerformanceLoad = (performance: IPerformanceCalculations) => {
    setAllSelectionsPerformance(items => [...items, performance]);
  };

  useEffect(() => {
    const getFinishedSelections = async () => {
      try {
        setIsDropdownUpdating(true);
        const { projects } = await getAllSelections();

        const configuredSelections = projects.filter(
          project =>
            project.project_status === ProjectStatus.Configured && !parsedIds.includes(project.id),
        );

        setFinishedSelections(configuredSelections);
      } finally {
        setIsDropdownUpdating(false);
      }
    };

    getFinishedSelections();
  }, []);

  return (
    <div className={styles['table-wrapper']}>
      <table className={styles.table}>
        <thead>
          <tr>
            <th className={cn(styles['name-cell'], styles.cell, styles.sticky, styles.light)}>
              <FormattedMessage id="compare_page_name_cell" />
            </th>
            {selections.map(selection => (
              <Name
                key={selection.project.id}
                project={selection.project}
                onRemove={onRemove}
                onView={onView}
                className={styles.cell}
              />
            ))}
            {!isCompareLimitExceeded && (
              <td className={cn(utils.px3, styles.select_wrapper)} data-testid="compare-select">
                <h5 className={utils.mb2}>
                  <FormattedMessage id="compare_page_selection_cell_title" />
                </h5>
                <DfSelect
                  options={selectOptions}
                  isLoading={isDropdownUpdating}
                  value={formatMessage({
                    id: 'compare_page_selection_cell_choose_selection',
                  })}
                  onSelectChange={onAdd}
                />
              </td>
            )}
          </tr>
        </thead>
        <tbody className={styles.greyed}>
          <tr>
            <th className={cn(styles.cell, styles.sticky, styles.greyed)}>
              <FormattedMessage id="compare_page_type_cell" />
            </th>
            {selections.map(selection => (
              <Type
                key={selection.project.id}
                project={selection.project}
                className={styles.cell}
              />
            ))}
          </tr>
        </tbody>
        <CollapsibleRow
          title={<FormattedMessage id="compare_page_products_cell" />}
          titleClassName={cn(styles.cell, styles.sticky, styles.light)}
          cellClassName={styles.cell}
          columnsCount={selections.length}
        >
          {selections.map(selection => (
            <td className={styles.cell} key={selection.project.id}>
              <Products data={selection.products} />
            </td>
          ))}
        </CollapsibleRow>
        <CollapsibleRow
          title={<FormattedMessage id="compare_page_performance_cell" />}
          titleClassName={cn(styles.cell, styles.sticky, styles.light)}
          cellClassName={styles.cell}
          columnsCount={selections.length}
        >
          {selections.map(selection => (
            <td className={styles.cell} key={selection.project.id}>
              {selection.project.function_codes.includes(FunctionCode.PropelSizing) && (
                <Performance
                  projectId={selection.project.id}
                  motorType={selection.products.motors[0].selected_motor.type}
                  allSelectionsPerformance={allSelectionsPerformance}
                  onLoad={onPerformanceLoad}
                />
              )}
            </td>
          ))}
        </CollapsibleRow>
        <CollapsibleRow
          title={<FormattedMessage id="compare_page_system_cell" />}
          titleClassName={cn(styles.cell, styles.sticky, styles.greyed)}
          cellClassName={cn(styles.cell, styles.greyed)}
          columnsCount={selections.length}
        >
          {selections.map(selection => (
            <td className={cn(styles.cell, styles.greyed)} key={selection.project.id}>
              <System data={selection.project.system_data} fieldsToShow={systemFieldsToShow} />
            </td>
          ))}
        </CollapsibleRow>
        <CollapsibleRow
          title={<FormattedMessage id="compare_page_propel_cell" />}
          titleClassName={cn(styles.cell, styles.sticky, styles.light)}
          cellClassName={styles.cell}
          columnsCount={selections.length}
        >
          {selections.map(selection => (
            <td className={styles.cell} key={selection.project.id}>
              {selection.project.propel_sizing && (
                <Propel
                  data={selection.project.propel_sizing}
                  fieldsToShow={propelFieldsToShow}
                  motorType={selection.products.motors[0]?.selected_motor.type}
                />
              )}
            </td>
          ))}
        </CollapsibleRow>
        <CollapsibleRow
          title={<FormattedMessage id="compare_page_steering_cell" />}
          titleClassName={cn(styles.cell, styles.sticky, styles.greyed)}
          cellClassName={cn(styles.cell, styles.greyed)}
          columnsCount={selections.length}
        >
          {selections.map(selection => (
            <td className={cn(styles.cell, styles.greyed)} key={selection.project.id}>
              {selection.project.steering_system && (
                <Steering
                  data={selection.project.steering_system}
                  fieldsToShow={steeringFieldsToShow}
                />
              )}
            </td>
          ))}
        </CollapsibleRow>
        <tbody>
          <tr>
            <th className={cn(styles['price-cell'], styles.cell, styles.sticky, styles.light)}>
              <FormattedMessage id="compare_page_price_cell" />
            </th>
            {selections.map(selection => (
              <td className={styles.cell} key={selection.project.id}>
                <Price data={selection.products} />
                {isSendToDCAvailable && (
                  <div className={utils.mt3}>
                    <SendToDC project={selection.project} />
                  </div>
                )}
              </td>
            ))}
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default ComparisonTable;
