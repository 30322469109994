import { useMemo } from 'react';

import { IProducts } from 'views/wizard/shared/helpers/useSelectedProducts';

import { IPrice, ISelectedProduct } from '../../types';
import { useRenderProducts } from './useRenderProducts';

export function useProductListItems({
  products: { motors, pumps, steeringProducts },
  prices,
}: {
  products: IProducts;
  prices?: Partial<Record<string, IPrice>>;
}) {
  const { renderMotor, renderPump, renderSteeringProduct } = useRenderProducts(prices);

  const renderedMotors = useMemo(
    () =>
      motors?.map(motor => [motor, renderMotor(motor)] as [ISelectedProduct, JSX.Element]) ?? [],
    [motors, renderMotor],
  );

  const renderedPumps = useMemo(
    () => pumps?.map(pump => [pump, renderPump(pump)] as [ISelectedProduct, JSX.Element]) ?? [],
    [pumps, renderPump],
  );

  const renderedSteeringProducts = useMemo(
    () =>
      steeringProducts?.map(
        steeringProduct =>
          [steeringProduct, renderSteeringProduct(steeringProduct)] as [
            ISelectedProduct,
            JSX.Element,
          ],
      ) ?? [],
    [steeringProducts, renderSteeringProduct],
  );

  return useMemo(() => {
    const configurableItems: JSX.Element[] = [];
    const nonConfigurableItems: JSX.Element[] = [];
    let totalPrice = 0;

    renderedMotors.concat(renderedPumps, renderedSteeringProducts).forEach(([product, element]) => {
      totalPrice += prices?.[product.product_id]?.value ?? 0;

      if (product.is_configurable) {
        configurableItems.push(element);
      } else {
        nonConfigurableItems.push(element);
      }
    });

    return {
      configurableItems,
      nonConfigurableItems,
      totalPrice,
    };
  }, [renderedMotors, renderedPumps, renderedSteeringProducts]);
}
