import { ReactComponent as SvgPdfIcon } from '@danfoss/mosaic-icons/dist/svg/pdf-outline.svg';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { renderToString } from 'react-dom/server';
import { IntlShape } from 'react-intl';

import { IDocument } from '../../../../api/documents';

export const actionsCell = (value: string) =>
  renderToString(
    <a href={value} target="_blank" rel="noreferrer">
      <SvgPdfIcon className={core.icon} />
    </a>,
  );

export const nameCell = (value: string, { url }: IDocument) =>
  renderToString(
    <a className={utils.colorSecondary} href={url} target="_blank" rel="noreferrer">
      <div className={core.textBold}>{value}</div>
    </a>,
  );

export const languageCell = (formatMessage: IntlShape['formatMessage']) => (value: string) =>
  formatMessage({ id: `language_${value}` });
