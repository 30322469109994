import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { IPrice } from 'views/shared/types';

import { MeasuringSystemContext, MeasuringUnits } from './measuringSystem/MeasuringSystemContext';
import { convert } from './measuringSystem/convertors';

export const round = (value: number, roundTo?: number) =>
  Number.isInteger(value) ? value : +value.toFixed(roundTo ?? 3);

export const roundPrice = (value: number) => Math.round(value / 100) * 100;

export const usePriceFormatter = () => {
  const { formatMessage, formatNumber } = useIntl();

  return (price?: IPrice) =>
    price?.value
      ? `${formatMessage({ id: 'price_starts_at' })} ${formatMessage({
          id: 'currency_euro_symbol',
        })}${formatNumber(roundPrice(price.value))}`
      : formatMessage({ id: 'data_unavailable' });
};

export const truncToTwoFractionDigits = (value: number) => Math.trunc(value * 100) / 100;

export const useTractiveForceFormatter = () => {
  const { units } = useContext(MeasuringSystemContext);
  const isImperialSystem = units === MeasuringUnits.Imperial;
  const convertToLbf = (value: number) => convert(value).from('N').to('lbf');
  const { formatNumber, formatMessage } = useIntl();

  return (value?: number) => {
    if (isImperialSystem) {
      return value
        ? `${truncToTwoFractionDigits(convertToLbf(value))} ${formatMessage({
            id: 'measurements_unit_lbf',
          })}`
        : '-';
    }

    const formattedValue = value
      ? formatNumber(truncToTwoFractionDigits(value / 1000), { minimumFractionDigits: 2 })
      : '-';

    return `${formattedValue} ${formatMessage({ id: 'measurements_unit_kn' })}`;
  };
};
