import React from 'react';

import { MachineExampleCode, SystemArchitecture } from 'views/shared/types';

import CustomPropelSystem from '../CustomPropelSystem';
import { DualPathPropelSystem } from './DualPathPropelSystem/DualPathPropelSystem';
import { MultipleMotorsPropelSystem } from './MultipleMotorsPropelSystem/MultipleMotorsPropelSystem';
import { OneMotorPropelSystem } from './OneMotorPropelSystem/OneMotorPropelSystem';

export interface PropelSystemProps {
  architecture?: SystemArchitecture;
  machineExampleCode: MachineExampleCode;
  hasPropelProducts?: boolean;
  goToRequirements: () => void;
}

export function PropelSystem({
  architecture,
  machineExampleCode,
  hasPropelProducts,
  goToRequirements,
}: PropelSystemProps) {
  const isMultiMotor = architecture === SystemArchitecture.OnePumpTwoMotors;
  const isDualPath = architecture === SystemArchitecture.DualPath;
  const isCustomFlow = machineExampleCode === MachineExampleCode.Custom;

  if (isCustomFlow) {
    return <CustomPropelSystem />;
  }

  if (!hasPropelProducts) {
    return null;
  }

  if (isMultiMotor) {
    return <MultipleMotorsPropelSystem />;
  }

  if (isDualPath) {
    return <DualPathPropelSystem />;
  }

  return <OneMotorPropelSystem goToRequirements={goToRequirements} />;
}
