import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import React, { useContext, useState } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as SvgInfoFilled } from 'assets/icons/info-filled.svg';
import Fieldset from 'views/shared/components/form/Fieldset';
import FormError from 'views/shared/components/form/FormError';
import InputAddon from 'views/shared/components/form/InputAddon';
import InputGroup from 'views/shared/components/form/InputGroup';
import Label from 'views/shared/components/form/Label/Label';
import ConverterInput from 'views/shared/helpers/measuringSystem/ConverterInput';
import FormatMeasureUnit from 'views/shared/helpers/measuringSystem/FormatMeasureUnit';
import {
  MeasuringSystemContext,
  MeasuringUnits,
} from 'views/shared/helpers/measuringSystem/MeasuringSystemContext';
import { motorWidgetValidation } from 'views/wizard/product-selection/constants';

import RollingRadiusExplanationModal from '../../../Motors/components/MotorWidget/components/EditableAttributes/RollingRadiusExplanationModal';
import styles from './EditableAttributes.module.scss';
import { IEditableAttributesForm } from './types';

export interface RollingRadiusFieldProps {
  form: UseFormReturn<IEditableAttributesForm>;
  onChange: (field: ControllerRenderProps) => React.ChangeEventHandler<HTMLInputElement>;
}

export function RollingRadiusField({ form, onChange }: RollingRadiusFieldProps) {
  const { units } = useContext(MeasuringSystemContext);
  const [isRollingRadiusModalShown, setIsRollingRadiusModalShown] = useState(false);

  const {
    control,
    formState: { errors },
  } = form;

  const onRollingRadiusIconClick = () => setIsRollingRadiusModalShown(true);
  const onRollingRadiusModalClose = () => setIsRollingRadiusModalShown(false);

  return (
    <>
      <Fieldset data-testid="rolling-radius-fieldset">
        <Label className={cn(core.flex, core.alignCenter)}>
          <FormattedMessage id="wizard_product_motor_widget_rolling_radius" />
          <button
            type="button"
            className={cn(button.textBtn, button.textBtnSecondary, utils.ml1)}
            data-testid="rolling-radius-icon-button"
            onClick={onRollingRadiusIconClick}
          >
            <SvgInfoFilled className={core.icon} />
          </button>
        </Label>
        <InputGroup>
          <Controller
            control={control}
            name="rolling_radius"
            rules={motorWidgetValidation.rolling_radius}
            render={({ field }) => (
              <ConverterInput
                type="number"
                value={field.value}
                onChange={onChange(field as unknown as ControllerRenderProps)}
                error={!!errors.rolling_radius}
                className={styles.input}
                data-testid="rolling-radius"
                {...motorWidgetValidation.rolling_radius}
                step={units === MeasuringUnits.Metric ? 1 : 0.001}
                metric="mm"
                imperial="in"
                precision={{
                  metric: 0,
                  imperial: 3,
                }}
              />
            )}
          />
          <InputAddon>
            <FormatMeasureUnit metric="measurements_unit_mm" imperial="measurements_unit_inch" />
          </InputAddon>
        </InputGroup>
        <FormError
          errors={errors}
          rules={motorWidgetValidation}
          field="rolling_radius"
          metric="mm"
          imperial="in"
        />
      </Fieldset>
      {isRollingRadiusModalShown && (
        <RollingRadiusExplanationModal onClose={onRollingRadiusModalClose} />
      )}
    </>
  );
}
