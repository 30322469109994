import axios from 'axios';

export interface IDocument {
  name: string;
  type: string;
  url: string;
  language: string;
}

export interface IDocumentsData {
  documents: IDocument[];
}

export const getDocumentsByProducts = async (productIds: string[]) => {
  const params = new URLSearchParams();

  productIds.forEach(id => params.append('product_ids', id));

  const { data } = await axios.get<IDocumentsData>(`/documents`, {
    params,
  });

  return data.documents;
};
