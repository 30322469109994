import components from '@danfoss/mosaic/css/components.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Fieldset from 'views/shared/components/form/Fieldset';
import Label from 'views/shared/components/form/Label/Label';
import RadioInput from 'views/shared/components/form/RadioInput';
import { MotorTypeWarning, MotorType } from 'views/shared/types';
import { useMotorTypeContext } from 'views/wizard/product-selection/motor-type-context/motorTypeContext';
import ConfirmationModal from 'views/wizard/shared/components/ConfirmationModal';
import useConfirmationModal from 'views/wizard/shared/helpers/useConfirmationModal';

export interface MotorTypeSelectionProps {
  isMotorSelected: boolean;
  isCustomFlow: boolean;
  motorTypeWarning?: MotorTypeWarning;
  onMotorTypeForcedChange: (motorType: MotorType) => Promise<void>;
}

const MotorTypeSelection = ({
  isMotorSelected,
  isCustomFlow,
  motorTypeWarning,
  onMotorTypeForcedChange,
}: MotorTypeSelectionProps) => {
  const { formatMessage } = useIntl();
  const { motorType, setMotorType } = useMotorTypeContext();

  const isMotorTypeWarningShown =
    motorTypeWarning && motorTypeWarning === MotorTypeWarning.MultiRatioGearBoxRequired;

  const {
    props: motorTypeConfirmationModalProps,
    open: openMotorTypeConfirmationModal,
    isOpen: isMotorTypeConfirmationModalOpen,
  } = useConfirmationModal<MotorType>(async lastSelectedType => {
    await onMotorTypeForcedChange(lastSelectedType);
    setMotorType(lastSelectedType);
  });

  const onMotorTypeChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const isConfirmationNeeded = isCustomFlow && isMotorSelected;
    const motorTypeValue = event.target.value as MotorType;

    if (isConfirmationNeeded) {
      openMotorTypeConfirmationModal(motorTypeValue);
      return;
    }

    if (!isCustomFlow) {
      await onMotorTypeForcedChange(motorTypeValue);
    }

    setMotorType(motorTypeValue);
  };

  return (
    <div className={utils.mt4}>
      <Label>
        <FormattedMessage id="wizard_product_motor_type_label" />
      </Label>
      <Fieldset className={cn(components.radioGroup, components.inline, utils.mt3)}>
        <RadioInput
          id="radio-motor-type-variable-piston"
          data-testid="radio-motor-type-variable-piston"
          name="motor-type"
          value={MotorType.VariablePiston}
          onChange={onMotorTypeChange}
          checked={motorType === MotorType.VariablePiston}
        >
          <FormattedMessage id="wizard_product_motor_variable_piston" />
        </RadioInput>
        <RadioInput
          id="radio-motor-type-fixed-orbital"
          data-testid="radio-motor-type-fixed-orbital"
          name="motor-type"
          value={MotorType.FixedOrbital}
          onChange={onMotorTypeChange}
          checked={motorType === MotorType.FixedOrbital}
        >
          <FormattedMessage id="wizard_product_motor_fixed_orbital" />
        </RadioInput>
        <RadioInput
          id="radio-motor-type-fixed-piston"
          data-testid="radio-motor-type-fixed-piston"
          name="motor-type"
          value={MotorType.FixedPiston}
          onChange={onMotorTypeChange}
          checked={motorType === MotorType.FixedPiston}
        >
          <FormattedMessage id="wizard_product_motor_fixed_piston" />
        </RadioInput>
      </Fieldset>
      {isMotorTypeWarningShown && (
        <div
          className={cn(core.helperText, utils.mb3)}
          data-testid="motor-type-with-multi-ratio-gear-warning"
        >
          <FormattedMessage id="wizard_product_motor_with_multi_ratio_gear_warning" />
        </div>
      )}
      {isMotorTypeConfirmationModalOpen && (
        <ConfirmationModal
          headingLabel={formatMessage({ id: 'wizard_confirmation_modal_heading' })}
          confirmLabel={formatMessage({
            id: 'wizard_product_motor_type_confirmation_modal_change_button',
          })}
          {...motorTypeConfirmationModalProps}
        >
          <FormattedMessage id="wizard_product_motor_type_confirmation_modal_text" />
        </ConfirmationModal>
      )}
    </div>
  );
};

export default MotorTypeSelection;
