import axios from 'axios';

import { AxiosConfigWithFlags } from 'configs/axios';
import {
  IProject,
  ISelectedProducts,
  IUpdatedMotorsData,
  IMotorToUpdate,
  ISelectedMotor,
  IUpdatedPumpsData,
  ISelectedPump,
  FunctionCode,
  IProjectSystemData,
  IProjectPropelSizingData,
  IProjectSteeringData,
  ISteeringUnitAttributes,
  ISelectedSteeringProduct,
  IMotorDetails,
  IMotorsDisplacementAndSpeed,
  AutoUpdateProducts,
  MotorType,
  SystemArchitecture,
  MachineExampleCode,
  AdditionalMotors,
} from 'views/shared/types';

export interface ISteeringUnitRequestData {
  attributes: ISteeringUnitAttributes;
  product_ids: string[];
}

export interface ISteeringUnitResponseData {
  attributes: ISteeringUnitAttributes;
  products: ISelectedSteeringProduct[];
}

export const createNewProject = (
  function_codes: FunctionCode[],
  machine_example_code: MachineExampleCode,
  system_architecture?: SystemArchitecture,
) => {
  return axios.post<IProject>('/projects', {
    function_codes,
    machine_example_code,
    system_architecture,
  });
};

export interface IProjectToUpdate
  extends Pick<IProject, 'project_status' | 'project_name' | 'function_codes'> {}

export const updateProject = (id: string, project: Partial<IProjectToUpdate>) => {
  return axios.patch<IProject>(`/projects/${id}`, project);
};

export const getProject = (id: string) => {
  return axios.get<IProject>(`/projects/${id}`);
};

export const updateSystemData = (id: string, data: Partial<IProjectSystemData>) => {
  return axios.patch<IProjectSystemData>(`/projects/${id}/system-data`, data);
};

export type IProjectSteeringDataToUpdate = Omit<IProjectSteeringData, 'steering_unit_attributes'>;

export const updateSteeringSystem = (id: string, data: IProjectSteeringDataToUpdate) => {
  return axios.patch<IProjectSteeringData>(`/steering-systems/${id}`, data);
};

export const updatePropelSizing = (id: string, data: Partial<IProjectPropelSizingData>) => {
  return axios.patch<IProjectPropelSizingData>(`/propel-sizings/${id}`, data);
};

export const getProducts = (id: string) => {
  return axios.get<ISelectedProducts>(`/projects/${id}/products`);
};

export const autoUpdateProducts = (
  projectId: string,
  currency?: string,
  displacementType?: MotorType,
  disableErrorInterceptor = false,
  additionalMotors?: AdditionalMotors,
) => {
  const config: AxiosConfigWithFlags = {
    params: {
      currency,
      displacement_type: displacementType,
      additional_motors: additionalMotors,
    },
    disableErrorInterceptor,
  };

  return axios.post<AutoUpdateProducts>(
    `/projects/${projectId}/autoupdate-products`,
    undefined,
    config,
  );
};

export const addMotor = (projectId: string, motorId: string, numberOfSelectedMotors: number) => {
  return axios.patch<IUpdatedMotorsData>(`/propel-sizings/${projectId}/motors`, {
    new_products: [motorId],
    number_of_motors: numberOfSelectedMotors,
  });
};

export const removeMotors = (projectId: string, motorIds: string[]) => {
  return axios.patch<IUpdatedMotorsData>(`/propel-sizings/${projectId}/motors`, {
    deleted_items: motorIds,
  });
};

export const updateMotor = (projectId: string, motor: IMotorToUpdate) => {
  return axios.patch<IUpdatedMotorsData>(`/propel-sizings/${projectId}/motors`, {
    changed_items: [motor],
  });
};

export const getMotorsDetailsAndSpeed = (
  projectId: string,
  pumpIds: string[],
  motors?: Pick<IMotorDetails, 'id' | 'min_displacement'>[],
) => {
  const params = new URLSearchParams();

  pumpIds.forEach(pump => params.append('pump_ids', pump));
  motors?.forEach(motor => params.append('motors_min_displacements', JSON.stringify(motor)));

  return axios.get<IMotorsDisplacementAndSpeed>(
    `/propel-sizings/${projectId}/motors-displacements-and-speeds`,
    {
      params,
    },
  );
};

export const updateMotorMinDisplacement = (
  projectId: string,
  motors: Pick<IMotorDetails, 'id' | 'min_displacement'>[],
) => {
  return axios.put<IMotorsDisplacementAndSpeed>(
    `/propel-sizings/${projectId}/motors-displacements-and-speeds`,
    { motors_min_displacements: motors },
  );
};

export const addPump = (
  projectId: string,
  pumpId: string,
  displacements?: Pick<ISelectedMotor, 'id' | 'min_displacement'>[],
) => {
  return axios.patch<IUpdatedPumpsData>(`/propel-sizings/${projectId}/pumps-and-motors`, {
    new_products: [pumpId],
    motors_min_displacements: displacements,
  });
};

export const removePumps = (projectId: string, pumpIds: string[]) => {
  return axios.patch<IUpdatedPumpsData>(`/propel-sizings/${projectId}/pumps-and-motors`, {
    deleted_items: pumpIds,
  });
};

export const replacePump = (
  projectId: string,
  newPumpId: string,
  oldPump: Pick<ISelectedPump, 'id'>,
  displacements?: Pick<ISelectedMotor, 'id' | 'min_displacement'>[],
) => {
  const { id } = oldPump;
  const changedItem = {
    product_id: newPumpId,
    id,
  };

  return axios.patch<IUpdatedPumpsData>(`/propel-sizings/${projectId}/pumps-and-motors`, {
    changed_items: [changedItem],
    motors_min_displacements: displacements,
  });
};

export const setKeepPumpSelected = (
  projectId: string,
  pump: Pick<ISelectedPump, 'id' | 'product_id'>,
  keepPump: boolean,
  displacements: Pick<ISelectedMotor, 'id' | 'min_displacement'>[],
) => {
  const { id, product_id } = pump;

  return axios.patch<IUpdatedPumpsData>(`/propel-sizings/${projectId}/pumps-and-motors`, {
    changed_items: [
      {
        id,
        product_id,
        keep_pump: keepPump,
      },
    ],
    motors_min_displacements: displacements,
  });
};

export const updateSteeringUnit = (
  projectId: string,
  steeringUnitData: ISteeringUnitRequestData,
) => {
  return axios.put<ISteeringUnitResponseData>(
    `/steering-systems/${projectId}/units`,
    steeringUnitData,
  );
};

export const removeSteeringUnit = (projectId: string) => {
  return axios.delete(`/steering-systems/${projectId}/units`);
};
