import { FieldErrors, FieldPath } from 'react-hook-form';

import FormErrorBasic, {
  IFormErrorProps as IFormErrorBasicProps,
} from 'views/shared/components/form/FormError';
import { IValidationRules } from 'views/shared/components/form/types';

import { IRequirementsForm } from '../types';

type IAllFormFields =
  | keyof IRequirementsForm['systemData']
  | keyof IRequirementsForm['steeringData']
  | keyof IRequirementsForm['propelSizingData'];

interface IFormErrorProps extends IFormErrorBasicProps {
  errors: FieldErrors<IRequirementsForm>;
  rules: {
    [field in IAllFormFields]?: IValidationRules;
  };
  field: FieldPath<IRequirementsForm>;
}

const FormError = ({ rules, errors, field, metric, imperial }: IFormErrorProps) => {
  return (
    <FormErrorBasic
      rules={rules}
      errors={errors}
      field={field}
      metric={metric}
      imperial={imperial}
    />
  );
};

export default FormError;
