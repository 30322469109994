import utils from '@danfoss/mosaic/css/utils.module.css';
import { UseFormReturn, useWatch } from 'react-hook-form';

import { TractiveForceValidation } from 'views/wizard/requirements/helpers/types';
import { IRequirementsForm } from 'views/wizard/requirements/types';

import { OptionalRollingRadiusField } from './OptionalRollingRadiusField/OptionalRollingRadiusField';
import { RollingRadiusField } from './RollingRadiusField';

export interface MultipleMotorsRollingRadiusesProps {
  form: UseFormReturn<IRequirementsForm>;
  validation?: TractiveForceValidation;
}

const motorKeys = ['motor_1', 'motor_2', 'motor_3', 'motor_4'] as const;

function useMotors(form: UseFormReturn<IRequirementsForm>) {
  const { control, setValue } = form;

  const defaultRRs = useWatch({
    name: 'propelSizingData.default_rolling_radiuses',
    control,
  });

  const numberOfMotors = Object.values(defaultRRs ?? {}).filter(
    rr => typeof rr === 'number',
  ).length;

  const addMotor = () => {
    const previous = defaultRRs?.[motorKeys[numberOfMotors - 1]];

    setValue(`propelSizingData.default_rolling_radiuses.${motorKeys[numberOfMotors]}`, previous);
  };

  const removeMotor = (motorNumber: 3 | 4) => {
    if (numberOfMotors === motorNumber) {
      setValue(
        `propelSizingData.default_rolling_radiuses.${motorKeys[motorNumber - 1]}`,
        undefined,
      );
    } else {
      setValue('propelSizingData.default_rolling_radiuses.motor_3', defaultRRs?.motor_4);
      setValue('propelSizingData.default_rolling_radiuses.motor_4', undefined);
    }
  };

  return {
    addMotor,
    removeMotor,
  };
}

export function MultipleMotorsRollingRadiuses({
  form,
  validation,
}: MultipleMotorsRollingRadiusesProps) {
  const { addMotor, removeMotor } = useMotors(form);

  return (
    <>
      <div className={utils.row}>
        <RollingRadiusField className={utils.col6} form={form} validation={validation} number={1} />
        <RollingRadiusField className={utils.col6} form={form} validation={validation} number={2} />
      </div>

      <div className={utils.row}>
        <OptionalRollingRadiusField
          form={form}
          number={3}
          onAddMotor={addMotor}
          onRemoveMotor={removeMotor}
          validation={validation}
        />

        <OptionalRollingRadiusField
          form={form}
          number={4}
          onAddMotor={addMotor}
          onRemoveMotor={removeMotor}
          validation={validation}
        />
      </div>
    </>
  );
}
