import cn from 'classnames';
import { memo } from 'react';
import { useIntl } from 'react-intl';

import { functionDescription } from 'views/shared/constants';
import { IProject } from 'views/shared/types';

interface ITypeProps {
  project: IProject;
  className?: string;
}

const Type = ({ project, className }: ITypeProps) => {
  const { formatMessage } = useIntl();
  const functionCodes = project.function_codes.map(code =>
    formatMessage({ id: functionDescription[code] }),
  );

  return (
    <td className={cn(className)} data-testid="function-codes">
      {functionCodes.join(', ')}
    </td>
  );
};

export default memo(Type);
