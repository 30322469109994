import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

import { RootState } from 'configs/store';

import {
  IRequirementsValidation,
  getRequirementsValidation as getRequirementsValidationAPI,
} from '../api/validation';

interface IValidationState {
  requirements: IRequirementsValidation | null;
}

const initialState: IValidationState = {
  requirements: null,
};

export const getRequirementsValidationRules = (state: RootState) => state.validation.requirements;

export const getRequirementsValidation = createAsyncThunk<IRequirementsValidation>(
  'validation/requirements/get',
  () => {
    return getRequirementsValidationAPI();
  },
);

const validationSlice = createSlice({
  name: 'validation',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getRequirementsValidation.fulfilled, (state, { payload }) => {
      state.requirements = payload;
    });
  },
});

export default validationSlice.reducer;
