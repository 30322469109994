import { TableRowSelectedEventType } from '@danfoss/mosaic';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfAccordion, DfLoader } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { useEffect, useState, useCallback, MutableRefObject, ChangeEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch, useAppSelector } from 'configs/store';
import { trackEvent } from 'utils/analytics';
import Checkbox from 'views/shared/components/form/Checkbox';
import {
  AvailableProductsSearchMode,
  ICalculations,
  IMotorDetails,
  ISelectedMotor,
} from 'views/shared/types';
import {
  addPump,
  addPumpsPrices,
  getCurrent,
  getProject,
  getSelectedPumps,
  getSystemData,
  replacePump,
} from 'views/wizard/shared/store/projectSlice';

import { getAvailablePumps, IAvailablePump } from '../../../../api/products';
import {
  getPumpsAuthorizedFilter,
  setPumpsAuthorizedFilter,
} from '../../../../store/productFilterSlice';
import ModalLoader from '../../../components/modals/ModalLoader/ModalLoader';
import NoProducts from '../../../components/modals/NoProducts/NoProducts';
import SelectionModal, {
  SelectionModalAction,
} from '../../../components/modals/SelectionModal/SelectionModal';
import selectionModalStyles from '../../../components/modals/SelectionModal/SelectionModal.module.scss';
import PumpTable from '../PumpTable/PumpTable';
import styles from './PumpModal.module.scss';
import MotorsDisplacementSpeed from './components/MotorsDisplacementSpeed/MotorsDisplacementSpeed';
import SpeedSummary from './components/SpeedSummary/SpeedSummary';

interface PumpModalProps {
  isOpen: boolean;
  focusedItem: MutableRefObject<string | null>;
  action: SelectionModalAction;
  numberOfPumps: number;
  isMotorTypeVariable: boolean;
  onModalClose: () => void;
}

const PumpModal = ({
  isOpen,
  onModalClose,
  action,
  focusedItem,
  numberOfPumps,
  isMotorTypeVariable,
}: PumpModalProps) => {
  const dispatch = useAppDispatch();
  const project = useAppSelector(getCurrent);
  const systemData = useAppSelector(getSystemData);

  const [isModalUpdating, setIsModalUpdating] = useState(false);
  const [selectedPumpId, setSelectedPumpId] = useState<string>();
  const selectedPumps = useAppSelector(getSelectedPumps)!;

  const [isPumpListLoading, setIsPumpListLoading] = useState(false);
  const [pumpList, setPumpList] = useState<IAvailablePump[]>([]);

  const authOnly = useAppSelector(getPumpsAuthorizedFilter);
  const searchMode = authOnly
    ? AvailableProductsSearchMode.AuthedOnly
    : AvailableProductsSearchMode.All;

  const handleAuthCheckboxChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { checked } = target;

    dispatch(setPumpsAuthorizedFilter(checked));
  };

  const [motorsData, setMotorsData] = useState<Record<string, IMotorDetails>>();
  const [calculationsData, setCalculationsData] = useState<ICalculations>({});
  const [isMinDisplacementLoading, setIsMinDisplacementLoading] = useState(false);
  const [isMinDisplacementInvalid, setIsMinDisplacementInvalid] = useState(false);

  const getMotorsDataAsList = (): Pick<ISelectedMotor, 'id' | 'min_displacement'>[] => {
    return Object.values(motorsData ?? {}).map(({ id, min_displacement }) => ({
      id,
      min_displacement,
    }));
  };

  const fetchPumpList = async () => {
    try {
      setIsPumpListLoading(true);

      const list = await getAvailablePumps(project.id, {
        number_of_pumps: numberOfPumps,
        filter_search_mode: searchMode,
      });

      setPumpList(list);
      setSelectedPumpId(list.find(({ recommended }) => recommended)?.pump.id);
    } finally {
      setIsPumpListLoading(false);
    }
  };

  useEffect(() => {
    fetchPumpList();
  }, [authOnly]);

  const onRowSelected = useCallback((event: CustomEvent<TableRowSelectedEventType>) => {
    const { detail } = event;
    setSelectedPumpId(detail.rowId);
  }, []);

  const onAddPump = async () => {
    const pumpData = pumpList.find(({ pump }) => pump.id === selectedPumpId);

    await dispatch(
      addPump({
        pumpId: selectedPumpId!,
        displacements: isMotorTypeVariable ? getMotorsDataAsList() : [],
      }),
    ).unwrap();

    if (pumpData?.price) {
      dispatch(
        addPumpsPrices({
          [pumpData.pump.id]: pumpData.price,
        }),
      );
    }
  };

  const onReplacePump = async () => {
    const oldPump = selectedPumps.find(({ id }) => id === focusedItem.current)!;
    const { id } = oldPump;
    const pumpData = pumpList.find(({ pump }) => pump.id === selectedPumpId);
    const recommendedPumpId = pumpList.find(pump => !!pump.recommended)?.pump.id;

    trackEvent({
      event: 'product_change',
      productType: 'pump',
      selectionId: project.id,
      recommendedProduct: recommendedPumpId === selectedPumpId,
    });
    await dispatch(
      replacePump({
        oldPump: {
          id,
        },
        newPumpId: selectedPumpId!,
        displacements: isMotorTypeVariable ? getMotorsDataAsList() : [],
      }),
    ).unwrap();

    if (pumpData?.price) {
      dispatch(
        addPumpsPrices({
          [pumpData.pump.id]: pumpData.price,
        }),
      );
    }

    await dispatch(getProject(project.id)).unwrap();
  };

  const actions = {
    add: onAddPump,
    replace: onReplacePump,
  };

  const handleSubmit = async () => {
    try {
      setIsModalUpdating(true);
      await actions[action!]();
      onModalClose();
    } catch {
      setIsModalUpdating(false);
    }
  };

  const Products = pumpList.length ? (
    <div className={cn(utils.mb4, styles.content)}>
      <DfLoader isVisible={isMinDisplacementLoading} />
      <div className={styles['main-section']}>
        {selectedPumpId && (
          <div className={utils.my2}>
            <MotorsDisplacementSpeed
              isMinDisplacementVisible={isMotorTypeVariable}
              selectedPumpId={selectedPumpId}
              ignorePump={focusedItem.current}
              onMotorsDataChange={setMotorsData}
              onCalculationsDataChange={setCalculationsData}
              onLoadingStateChange={setIsMinDisplacementLoading}
              onMinDisplacementError={setIsMinDisplacementInvalid}
            />
          </div>
        )}
        <PumpTable
          list={pumpList}
          onRowSelected={onRowSelected}
          tableClassName={cn(utils.mt2, styles.table)}
          requiredPumpPressure={systemData.pressure_limit!}
          data-testid="pump-modal-table"
        />
      </div>
      {selectedPumpId && (
        <div className={cn(utils.pt4, styles.footer)}>
          <SpeedSummary calculations={calculationsData} propelSizing={project.propel_sizing!} />
        </div>
      )}
    </div>
  ) : (
    <NoProducts />
  );

  const Sidebar = (
    <>
      <h2 className={utils.mb6}>
        <FormattedMessage id="wizard_product_add_pump" />
      </h2>
      <div className={cn(selectionModalStyles.filters, utils.py2)}>
        <DfAccordion isExpanded>
          <span slot="header">
            <FormattedMessage id="wizard_selection_modal_product_families" />
          </span>
          <div slot="content" className={utils.pt4}>
            <Checkbox
              id="authorized-products"
              onChange={handleAuthCheckboxChange}
              checked={authOnly}
              data-testid="pump-modal-authorized-products-checkbox"
            >
              <FormattedMessage id="wizard_selection_modal_product_families_only_auth" />
            </Checkbox>
          </div>
        </DfAccordion>
      </div>
    </>
  );

  return (
    <SelectionModal
      isOpen={isOpen}
      onModalClose={onModalClose}
      submitLabel={<FormattedMessage id="wizard_pump_modal_select_pump" />}
      handleSubmit={handleSubmit}
      sidebar={Sidebar}
      isLoading={isPumpListLoading || isMinDisplacementLoading}
      isUpdating={isModalUpdating}
      isSubmitButtonDisabled={!selectedPumpId || isMinDisplacementInvalid}
      data-testid="pump-modal"
    >
      <div
        className={cn(
          core.flex,
          core.alignCenter,
          core.flexCenter,
          selectionModalStyles['content-wrapper'],
        )}
      >
        {isPumpListLoading ? <ModalLoader /> : Products}
      </div>
    </SelectionModal>
  );
};

export default PumpModal;
