import utils from '@danfoss/mosaic/css/utils.module.css';
import React, { useContext } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import Fieldset from 'views/shared/components/form/Fieldset';
import FormError, { NestedRules } from 'views/shared/components/form/FormError';
import InputAddon from 'views/shared/components/form/InputAddon';
import InputGroup from 'views/shared/components/form/InputGroup';
import Label from 'views/shared/components/form/Label/Label';
import ConverterInput from 'views/shared/helpers/measuringSystem/ConverterInput';
import FormatMeasureUnit from 'views/shared/helpers/measuringSystem/FormatMeasureUnit';
import {
  MeasuringSystemContext,
  MeasuringUnits,
} from 'views/shared/helpers/measuringSystem/MeasuringSystemContext';
import { TractiveForceValidation } from 'views/wizard/requirements/helpers/types';

import { IRequirementsForm } from '../../../../types';

export interface RollingRadiusProps {
  className?: string;
  form: UseFormReturn<IRequirementsForm>;
  validation?: TractiveForceValidation;
  number: 1 | 2 | 3 | 4;
}

export function RollingRadiusField({ className, form, validation, number }: RollingRadiusProps) {
  const { units } = useContext(MeasuringSystemContext);
  const {
    control,
    formState: { errors },
  } = form;
  const motorKey = `motor_${number}` as const;

  return (
    <Fieldset className={className} data-testid={`rolling-radius-fieldset-${number}`}>
      <Label className={utils.mt6}>
        <FormattedMessage id="wizard_requirements_rolling_radius_motor" values={{ number }} />
      </Label>

      <InputGroup>
        <Controller
          name={`propelSizingData.default_rolling_radiuses.${motorKey}`}
          control={control}
          rules={validation?.default_rolling_radiuses?.[motorKey]}
          render={({ field: { value, ...field }, fieldState: { invalid } }) => (
            <ConverterInput
              type="number"
              data-testid={`rolling-radius-${number}`}
              metric="mm"
              imperial="in"
              step={units === MeasuringUnits.Metric ? 1 : 0.001}
              precision={{ metric: 0, imperial: 3 }}
              value={value!}
              error={invalid}
              {...field}
              {...validation?.default_rolling_radiuses?.[motorKey]}
            />
          )}
        />
        <InputAddon>
          <FormatMeasureUnit metric="measurements_unit_mm" imperial="measurements_unit_inch" />
        </InputAddon>
      </InputGroup>
      <FormError
        field={`propelSizingData.default_rolling_radiuses.${motorKey}`}
        metric="mm"
        imperial="in"
        errors={errors}
        rules={validation?.default_rolling_radiuses as NestedRules}
      />
    </Fieldset>
  );
}
