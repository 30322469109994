import { Component } from 'react';

import ErrorPage from './base/ErrorPage';

interface IErrorBoundaryState {
  hasError: boolean;
}

export default class ErrorBoundary extends Component<Record<string, unknown>, IErrorBoundaryState> {
  state = { hasError: false };

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}
