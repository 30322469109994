import { IPrice } from 'views/shared/types';

import { IAvailableSteeringUnit } from '../../../../../api/products';

export const convertToIdsWithPrices = (data: IAvailableSteeringUnit) => {
  const { products } = data.steering_unit;
  const productIDs = products.map(({ id }) => id);

  const prices = products.reduce((acc, product) => {
    if (!product.price) {
      return acc;
    }

    return {
      ...acc,
      [product.id]: product.price,
    };
  }, {} as { [id: string]: IPrice });

  return { prices, productIDs };
};
