import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { ReactNode } from 'react';

import GlobalWarnings from 'views/shared/components/GlobalWarnings/GlobalWarnings';

import styles from './PageLayout.module.scss';

interface IPageLayoutProps {
  children: ReactNode;
}

const PageLayout = ({ children }: IPageLayoutProps) => {
  return (
    <main
      className={cn(core.mainContent, core.flex, core.flexColumn, 'main-container')}
      role="main"
    >
      <GlobalWarnings />
      <div
        className={cn(
          core.container,
          core.flex,
          core.flexColumn,
          core.fullHeight,
          styles.container,
        )}
      >
        <div className={cn(utils.row, utils.mt10, core.fullHeight, styles.row)}>
          <div className={cn(utils.col12, core.flex, core.flexColumn)}>{children}</div>
        </div>
      </div>
    </main>
  );
};

export default PageLayout;
