import { Auth0Provider, Auth0ProviderOptions, useAuth0 } from '@auth0/auth0-react';
import { DfLoader } from '@danfoss/mosaic/react';
import { AxiosError } from 'axios';
import { useEffect, useState, FunctionComponent } from 'react';
import { useHistory } from 'react-router-dom';

import { configAxios, NOT_AUTHORIZED_STATUS, setupBaseURL } from 'configs/axios';
import { getAppConfig } from 'configs/global';
import {
  clearDCProjectID,
  getDCProjectId,
  setDCProjectId,
} from 'utils/DesignCenterProjectIdService';
import {
  clearToken,
  getToken as getSFToken,
  setToken as setSFTokenInStorage,
} from 'utils/SalesforceTokenService';

import ErrorInterceptorProvider from '../ErrorInterceptorProvider';
import { AuthContext, AuthType } from './AuthContext';
import { IAuthComponentProps } from './types';

const StandaloneAuth: FunctionComponent<IAuthComponentProps> = ({
  children,
  unauthorizedCallback,
}) => {
  const [isAuthenticationFinished, setIsAuthenticationFinished] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authType, setAuthType] = useState<AuthType>();

  const history = useHistory();

  const removeParametersFromURL = () => {
    window.location.hash = '';
  };
  const nonAuthorizedInterceptor = (error: AxiosError) => {
    if (error.response?.status === NOT_AUTHORIZED_STATUS) {
      clearToken();
      clearDCProjectID();
      setIsAuthenticated(false);
      unauthorizedCallback(history);
    }
  };

  const {
    isAuthenticated: isAuthenticatedDIP,
    getAccessTokenSilently: getDIPAccessTokenSilently,
    isLoading: isDIPAuthLoading,
  } = useAuth0();

  const trySFAuth = async () => {
    const SFtoken = getSFToken();
    const DCProjectId = getDCProjectId();

    if (DCProjectId) {
      setDCProjectId(DCProjectId);
    }

    if (!SFtoken) {
      throw new Error('Salesforce token not found');
    }

    await configAxios(SFtoken);

    setSFTokenInStorage(SFtoken);
    removeParametersFromURL();
    setIsAuthenticated(true);
    setAuthType(AuthType.SF);
  };

  const tryDIPAuth = async () => {
    if (isAuthenticatedDIP) {
      const token = await getDIPAccessTokenSilently();
      await configAxios(token);

      setIsAuthenticated(true);
      setAuthType(AuthType.DIP);
    }
  };

  useEffect(() => {
    if (isDIPAuthLoading) {
      return;
    }

    (async () => {
      try {
        await trySFAuth();
      } catch {
        await tryDIPAuth();
      }

      setupBaseURL();
      setIsAuthenticationFinished(true);
    })();
  }, [isDIPAuthLoading]);

  if (!isAuthenticationFinished) {
    return <DfLoader isVisible isGlobal data-testid="app-loader" />;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        authType,
      }}
    >
      <ErrorInterceptorProvider interceptors={[nonAuthorizedInterceptor]}>
        {children}
      </ErrorInterceptorProvider>
    </AuthContext.Provider>
  );
};

const StandaloneAuthWrapper: FunctionComponent<IAuthComponentProps> = ({ children, ...rest }) => {
  const appConfig = getAppConfig();
  const auth0Config: Auth0ProviderOptions = {
    scope: appConfig.AUTH0_SCOPE,
    audience: appConfig.AUTH0_AUDIENCE,
    domain: appConfig.AUTH0_DOMAIN,
    clientId: appConfig.AUTH0_CLIENT_ID,
    redirectUri: appConfig.AUTH0_REDIRECT_URL,
  };

  return (
    <Auth0Provider {...auth0Config}>
      <StandaloneAuth {...rest}>{children}</StandaloneAuth>
    </Auth0Provider>
  );
};

export default StandaloneAuthWrapper;
