import { useCallback, useState } from 'react';

export function useToggle(
  defaultValue = false,
): [value: boolean, toggleOn: () => void, toggleOf: () => void] {
  const [value, setValue] = useState(defaultValue);

  const toggleOn = useCallback(() => setValue(true), []);
  const toggleOff = useCallback(() => setValue(false), []);

  return [value, toggleOn, toggleOff];
}
