import footer from '@danfoss/mosaic/css/components/footer.module.css';
import link from '@danfoss/mosaic/css/components/link.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import classNames from 'classnames';
import { MouseEvent } from 'react';

const Footer = () => {
  const onCookiesClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    window.CookieConsent.show();
  };

  return (
    <footer className={footer.footer} data-testid="main-footer">
      <div className={footer.footerDisclaimers}>
        <nav className={core.container} aria-label="Danfoss footer links">
          <ul>
            <li>
              <a
                href="https://terms.danfoss.com/privacypolicy?culture=en&appId=machinefunctiondesign"
                target="_blank"
                rel="noreferrer"
                className={classNames(link.linkTertiary, link.external)}
              >
                Privacy Policy
              </a>
            </li>
            <li>
              <a
                href="https://terms.danfoss.com/TermsAndConditions?culture=en&appId=machinefunctiondesign"
                target="_blank"
                rel="noreferrer"
                className={classNames(link.linkTertiary, link.external)}
              >
                Terms of Use
              </a>
            </li>
            <li>
              <a
                href="https://www.danfoss.com/en/terms/generalinformation"
                target="_blank"
                rel="noreferrer"
                className={classNames(link.linkTertiary, link.external)}
              >
                General Information
              </a>
            </li>
            <li>
              <a href="#" onClick={onCookiesClick} className={link.linkTertiary}>
                Cookies
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </footer>
  );
};

export default Footer;
