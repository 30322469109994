import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { ISelectedSteeringProduct, ISteeringUnitAttributes } from 'views/shared/types';

import Header from '../../../components/widgets/components/Header/Header';
import ProductInfo from '../../../components/widgets/components/Header/ProductInfo';
import StaticAttribute from '../../../components/widgets/components/StaticAttributes/StaticAttribute';
import { getMainSpec } from '../../helpers';
import styles from './HybridSteeringWidget.module.scss';

interface SteeringWidgetProps {
  products: ISelectedSteeringProduct[];
  unitAttributes: ISteeringUnitAttributes;
  onChange: () => void;
  onRemove?: () => void;
}

const HybridSteeringWidget = ({
  products,
  unitAttributes,
  onChange,
  onRemove,
}: SteeringWidgetProps) => {
  const { formatMessage, formatNumber } = useIntl();
  const { required_l2l_turns, actual_l2l_turns, required_l2l_sec, actual_l2l_sec } = unitAttributes;
  const [mainProduct, ...otherProducts] = products;
  const mainProductSpec = getMainSpec(mainProduct);

  const requiredL2LSeconds = `${formatNumber(required_l2l_sec!)} ${formatMessage({
    id: 'measurements_unit_seconds',
  })}`;
  const requiredL2LTurns = `${formatNumber(required_l2l_turns!)} ${formatMessage({
    id: 'measurements_unit_turns',
  })}`;
  const requiredL2L = `${requiredL2LTurns} | ${requiredL2LSeconds}`;
  const actualL2LSeconds = `${formatNumber(actual_l2l_sec!)} ${formatMessage({
    id: 'measurements_unit_seconds',
  })}`;
  const actualL2LTurns = `${formatNumber(actual_l2l_turns!)} ${formatMessage({
    id: 'measurements_unit_turns',
  })}`;
  const actualL2L = `${actualL2LTurns} | ${actualL2LSeconds}`;

  return (
    <div data-testid="hybrid-steering-widget">
      <Header
        id={mainProduct.product_id}
        photoUrl={mainProduct.photo_url}
        photoAlt={formatMessage({ id: 'wizard_product_steering_unit_widget_image_alt' })}
        name={mainProduct.name}
        series={mainProduct.series}
        spec={mainProductSpec}
        onChange={onChange}
        onRemove={onRemove}
        isReadOnly={false}
        hideBorder
      />
      {otherProducts.map(product => {
        const { id, photo_url, series, name, product_id } = product;
        const spec = getMainSpec(product);

        return (
          <div data-testid={`widget-header-${product_id}`} key={id}>
            <ProductInfo
              name={name}
              series={series}
              spec={spec}
              photoAlt={formatMessage({ id: 'wizard_product_steering_unit_widget_image_alt' })}
              photoUrl={photo_url}
            />
          </div>
        );
      })}
      <div className={cn(utils.pt3, utils.mt3, styles.characteristics)}>
        <p className={core.textBold}>
          <FormattedMessage id="specs_l2l_hybrid" />
        </p>
        <div>
          <StaticAttribute
            name={<FormattedMessage id="wizard_product_steering_unit_widget_required" />}
            value={requiredL2L}
            testId="steering-widget-required-l2l"
          />
          <StaticAttribute
            name={<FormattedMessage id="wizard_product_steering_unit_widget_actual" />}
            value={actualL2L}
            testId="steering-widget-actual-l2l"
          />
        </div>
      </div>
    </div>
  );
};

export default HybridSteeringWidget;
