import input from '@danfoss/mosaic/css/components/input.module.css';
import cn from 'classnames';
import { HTMLProps } from 'react';

interface IInputAddon extends HTMLProps<HTMLDivElement> {
  'data-testid'?: string;
  children: React.ReactNode;
}

const InputAddon = ({ className, children, ...props }: IInputAddon) => {
  const dataTestId = props['data-testid'] ?? 'input-addon';

  return (
    <div className={cn(className, input.inputGroupAddonEnd)} {...props} data-testid={dataTestId}>
      <span className={input.inputGroupAddonContent}>{children}</span>
    </div>
  );
};

export default InputAddon;
