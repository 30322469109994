import { TableColumnType, TableRowSelectedEventType } from '@danfoss/mosaic';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { memo } from 'react';
import { renderToString } from 'react-dom/server';
import { useIntl } from 'react-intl';

import { round } from 'views/shared/helpers/formatters';
import useMeasuringUnitsIntl from 'views/shared/helpers/measuringSystem/useMeasuringUnitsIntl';
import { IAvailableSteeringUnit } from 'views/wizard/product-selection/api/products';

import { dimensionsCell, nameCell } from '../../../components/tables/helpers/formatters';
import SteeringTable from './SteeringTable';
import SteeringDimensionsTooltip from './components/SteeringDimensionsTooltip/SteeringDimensionsTooltip';
import { useSteeringPriceCellFormatter } from './helpers/formatters';

interface SteerByWireTableProps {
  list: IAvailableSteeringUnit[];
  onRowSelected: (e: CustomEvent<TableRowSelectedEventType>) => void;
}

const SteerByWireTable = ({ list, onRowSelected }: SteerByWireTableProps) => {
  const { formatMessage } = useIntl();
  const { formatMeasuringUnit } = useMeasuringUnitsIntl();
  const priceCellFormatter = useSteeringPriceCellFormatter(useIntl());

  const columns: TableColumnType[] = [
    {
      accessor: 'steering_unit.name',
      id: 'name',
      Header: formatMessage({ id: 'wizard_selection_modal_header_name' }),
      Cell: nameCell(formatMessage),
      getCellProps: () => ({ className: cn(core.flex, core.flexColumn) }),
    },
    {
      accessor: 'steering_unit.actual_l2l_sec',
      id: 'actual_l2l',
      Header: formatMessage({
        id: 'wizard_steering_steer_by_wire_modal_header_l2l_seconds',
      }),
    },
    {
      accessor: 'steering_unit.products[0].spool',
      id: 'spool',
      Header: formatMessage(
        { id: 'wizard_steering_steer_by_wire_modal_header_spool' },
        {
          units: formatMeasuringUnit({
            metric: 'measurements_unit_l_min',
            imperial: 'measurements_unit_gal_min',
          }),
        },
      ),
      Cell: value => (value ? renderToString(<span data-testid="spool">{round(value)}</span>) : ''),
    },
    {
      accessor: 'steering_unit',
      id: 'dimensions',
      Header: formatMessage(
        { id: 'wizard_steering_modal_header_dimensions' },
        {
          units: formatMeasuringUnit({
            metric: 'measurements_unit_mm',
            imperial: 'measurements_unit_inch',
          }),
        },
      ),
      Cell: dimensionsCell(formatMessage),
    },
    {
      accessor: 'steering_unit.products',
      id: 'price',
      Header: formatMessage({ id: 'wizard_selection_modal_header_price' }),
      Cell: priceCellFormatter,
      width: 150,
    },
  ];

  return (
    <div className={core.fullWidth}>
      <SteeringDimensionsTooltip className={utils.mb1} />
      <SteeringTable list={list} onRowSelected={onRowSelected} columns={columns} />
    </div>
  );
};

export default memo(SteerByWireTable);
