import progressSteps from '@danfoss/mosaic/css/components/progress-steps.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import cn from 'classnames';
import React from 'react';

import styles from './Stepper.module.scss';

interface IStep {
  title: string;
  handler?: () => void;
}

interface IStepperProps {
  steps: IStep[];
  progress: number;
  className?: string;
}

const Stepper = ({ steps, progress, className }: IStepperProps) => {
  return (
    <ul
      className={cn(className, progressSteps.progressStepsHorizontal, core.flexCenter)}
      role="tablist"
      data-testid="stepper"
    >
      {steps.map(({ title, handler }, i) => {
        const isCompleted = i < progress;
        const isDisabled = i > progress;
        const isNotClickable = !handler;
        const handleClick = isCompleted ? handler : undefined;

        return (
          <li
            key={i}
            className={cn(progressSteps.progressStepsItem, {
              [progressSteps.completed]: isCompleted,
              [progressSteps.disabled]: isDisabled,
              [styles['hover-disabled']]: !isCompleted || isNotClickable,
            })}
            onClick={handleClick}
            data-testid={`stepper-item-${title}`}
          >
            <span className={progressSteps.progressStepsTitle} role="tab" tabIndex={i}>
              {title}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export default Stepper;
