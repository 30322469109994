import utils from '@danfoss/mosaic/css/utils.module.css';
import { useContext } from 'react';
import { useIntl } from 'react-intl';

import { round } from 'views/shared/helpers/formatters';
import {
  MeasuringSystemContext,
  MeasuringUnits,
} from 'views/shared/helpers/measuringSystem/MeasuringSystemContext';
import { convert } from 'views/shared/helpers/measuringSystem/convertors';

const getPositiveLabel = (value: string, tractiveForceUnitMeasure: string) => (
  <span className={utils.colorSuccess}>
    +{value} {tractiveForceUnitMeasure}
  </span>
);
const getNegativeLabel = (value: string, tractiveForceUnitMeasure: string) => (
  <span className={utils.colorActiveBase}>
    {value} {tractiveForceUnitMeasure}
  </span>
);

export const useTractiveForceDifferenceFormatter = () => {
  const { units } = useContext(MeasuringSystemContext);
  const isImperialSystem = units === MeasuringUnits.Imperial;
  const convertToLbf = (value: number) => convert(value).from('N').to('lbf');

  const { formatNumber, formatMessage } = useIntl();
  const getRoundedNumber = (value: number) =>
    formatNumber(round(value, 2), { minimumFractionDigits: 2 });
  const knUnits = formatMessage({ id: 'measurements_unit_kn' });
  const percetageUnits = formatMessage({ id: 'measurements_unit_percentage' });

  return (actual: number, required: number, withPercentage?: boolean) => {
    let difference = (actual - required) / 1000;
    const differenceInPercentage = (actual / required - 1) * 100;
    const differencePercentageSuffix = Number.isFinite(differenceInPercentage)
      ? `(${differenceInPercentage.toFixed(1)} ${percetageUnits})`
      : '';
    let suffix = withPercentage ? `${knUnits} ${differencePercentageSuffix}` : knUnits;

    if (isImperialSystem) {
      difference = convertToLbf(actual - required);
      suffix = formatMessage({
        id: 'measurements_unit_lbf',
      });
    }

    return difference > 0
      ? getPositiveLabel(getRoundedNumber(difference), suffix)
      : getNegativeLabel(getRoundedNumber(difference), suffix);
  };
};
