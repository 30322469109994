import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { useIntl } from 'react-intl';

import { useAppSelector } from 'configs/store';
import { useTractiveForceFormatter } from 'views/shared/helpers/formatters';
import { getSelectedMotors } from 'views/wizard/shared/store/projectSlice';

import StaticAttribute from '../../../components/widgets/components/StaticAttributes/StaticAttribute';
import { useTractiveForceDifferenceFormatter } from '../../../components/widgets/helpers/formatters';
import styles from './TotalTractiveForce.module.scss';

interface TotalTractiveForceProps {
  selectedNumberOfMotors: number;
}

const TotalTractiveForce = ({ selectedNumberOfMotors }: TotalTractiveForceProps) => {
  const { formatMessage } = useIntl();
  const selectedMotors = useAppSelector(getSelectedMotors);
  const formatTractiveForce = useTractiveForceFormatter();
  const formatTractiveForceDifference = useTractiveForceDifferenceFormatter();

  const [tractiveForceActual, tractiveForceRequired] = (selectedMotors ?? []).reduce<
    [number, number]
  >(
    ([actual, required], { tractive_force_required, tractive_force_actual = 0 }) => {
      return [actual + tractive_force_actual, required + tractive_force_required];
    },
    [0, 0],
  );

  const actualTractiveForce = formatTractiveForce(tractiveForceActual);
  const requiredTractiveForce = formatTractiveForce(tractiveForceRequired);
  const differenceTractiveForce = formatTractiveForceDifference(
    tractiveForceActual,
    tractiveForceRequired,
    true,
  );

  const desktopGridClasses = {
    3: utils.col3,
    4: utils.col4,
    6: utils.col6,
    8: utils.col8,
    9: utils.col9,
    12: utils.col12,
  };
  const devicesGridClasses = {
    3: utils.colMd3,
    4: utils.colMd4,
    6: utils.colMd6,
    8: utils.colMd8,
  };
  const widthClass =
    desktopGridClasses[Math.min(selectedNumberOfMotors * 3, 12) as keyof typeof desktopGridClasses];
  const widthClassMd =
    devicesGridClasses[Math.min(selectedNumberOfMotors * 4, 8) as keyof typeof devicesGridClasses];
  const innerWidthClass =
    desktopGridClasses[(12 * (1 / selectedNumberOfMotors)) as keyof typeof desktopGridClasses];
  const innerWidthMdClass = devicesGridClasses[selectedNumberOfMotors > 1 ? 4 : 8];

  return (
    <div className={utils.row}>
      <div
        className={cn(
          utils.py4,
          core.flex,
          core.flexAround,
          utils.colSm8,
          styles.container,
          widthClass,
          widthClassMd,
        )}
        data-testid="total-tractive-force-container"
      >
        <div
          className={cn(utils.colSm6, innerWidthClass, innerWidthMdClass)}
          data-testid="total-tractive-force-col"
        >
          <StaticAttribute
            name={formatMessage({ id: 'wizard_product_motor_widget_actual' })}
            value={actualTractiveForce}
            testId="total-actual-tractive-force"
          />
          <StaticAttribute
            name={formatMessage({ id: 'wizard_product_motor_widget_required' })}
            value={requiredTractiveForce}
            testId="total-required-tractive-force"
          />
          <StaticAttribute
            name={formatMessage({ id: 'wizard_product_motor_widget_difference' })}
            value={differenceTractiveForce}
            testId="total-difference-required-tractive-force"
            boldTitle
            boldValue
          />
        </div>
      </div>
    </div>
  );
};

export default TotalTractiveForce;
