import button from '@danfoss/mosaic/css/components/button.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfModal } from '@danfoss/mosaic/react';
import { FormattedMessage, useIntl } from 'react-intl';

interface IContactModalProps {
  projectId?: string;
  onClose: () => void;
}

const ContactModal = ({ onClose, projectId }: IContactModalProps) => {
  const { formatMessage } = useIntl();
  const email = formatMessage({ id: 'contact_modal_email_address' });
  const body = formatMessage({ id: 'contact_modal_email_body' }, { projectId });
  const subject = formatMessage({ id: 'contact_modal_email_subject' });
  let link = `mailto:${email}?subject=${subject}`;

  if (projectId) {
    link += `&body=${body}`;
  }

  return (
    <DfModal
      onModalClose={onClose}
      heading={formatMessage({ id: 'contact_modal_title' })}
      data-testid="contact-modal"
      isOpen
    >
      <div slot="modal-body">
        <div>
          <FormattedMessage id="contact_modal_text_line_1" />
        </div>
        <div className={utils.my4}>
          <FormattedMessage id="contact_modal_text_line_2" />{' '}
          <a href={link} target="_blank" rel="noreferrer" data-testid="contact-link">
            <FormattedMessage id="contact_modal_email_link" />
          </a>
        </div>
      </div>
      <div slot="modal-footer">
        <button className={button.btnPrimary} onClick={onClose} data-testid="contact-close-button">
          <FormattedMessage id="contact_modal_close_button" />
        </button>
      </div>
    </DfModal>
  );
};

export default ContactModal;
