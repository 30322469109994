import { useContext, useState } from 'react';
import { useIntl } from 'react-intl';

import {
  AutofillStrategy,
  createPerformanceCalculations,
  IPerformanceCalculations,
  PerformanceCalculationsWarning,
  IMotorPerformanceCase,
} from '../api/performance';
import { round } from './formatters';
import { MeasuringSystemContext, MeasuringUnits } from './measuringSystem/MeasuringSystemContext';
import { convert } from './measuringSystem/convertors';
import useMeasuringValueConvertor from './measuringSystem/useMeasuringValueConvertor';

export const usePerformancePointsHelper = () => {
  const { units } = useContext(MeasuringSystemContext);
  const isImperialSystem = units === MeasuringUnits.Imperial;
  const { convertor } = useMeasuringValueConvertor();
  const convertToLbf = (value: number) => convert(value).from('N').to('lbf');
  const convertToKn = (value: number) => value / 1000;

  return (totalPerformance: IMotorPerformanceCase['total_performance']) => {
    const { vehicle_speeds, tractive_forces } = totalPerformance;

    return vehicle_speeds
      .map((speed, index) => {
        const force = tractive_forces[index];

        const forceConverted = isImperialSystem ? convertToLbf(force) : convertToKn(force);
        const speedConverted = round(convertor('km/h', 'mph', speed), 1);

        return { x: speedConverted, y: forceConverted };
      })
      .filter(points => {
        return points.x >= 0 && points.y >= 0;
      });
  };
};

export function usePerformance({
  projectId,
  performanceStrategy,
}: {
  projectId: string;
  performanceStrategy: AutofillStrategy;
}) {
  const { formatMessage } = useIntl();

  const [performances, setPerformances] = useState<
    Partial<Record<AutofillStrategy, IPerformanceCalculations>>
  >({});
  const [errors, setErrors] = useState<Record<AutofillStrategy, string | null>>({
    [AutofillStrategy.Fixed]: null,
    [AutofillStrategy.Variable]: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const loadPerformance = async (strategy: AutofillStrategy) => {
    if (performances[strategy]) {
      return;
    }

    setErrors(previous => ({ ...previous, [strategy]: null }));
    setIsLoading(true);

    try {
      const newPerformance = await createPerformanceCalculations(projectId, {
        strategy,
      });

      if (newPerformance.warning === PerformanceCalculationsWarning.GlobalLimitReached) {
        const errorMessage = formatMessage({
          id: 'performance_prediction_warning_global',
        });

        setErrors(previous => ({ ...previous, [strategy]: errorMessage }));

        return;
      }

      if (newPerformance.warning === PerformanceCalculationsWarning.ProjectLimitReached) {
        const errorMessage = formatMessage({
          id: 'performance_prediction_warning_project',
        });

        setErrors(previous => ({ ...previous, [strategy]: errorMessage }));

        return;
      }

      if (newPerformance.performances) {
        setPerformances(previous => ({ ...previous, [strategy]: newPerformance.performances }));
      }
    } catch {
      const errorMessage = formatMessage({
        id: 'performance_prediction_error',
      });

      setErrors(previous => ({ ...previous, [strategy]: errorMessage }));
    } finally {
      setIsLoading(false);
    }
  };

  return {
    performance: performances[performanceStrategy],
    error: errors[performanceStrategy],
    isLoading,
    loadPerformance,
  };
}
