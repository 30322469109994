import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';

import { useTractiveForceFormatter } from 'views/shared/helpers/formatters';
import FormatMeasureUnit from 'views/shared/helpers/measuringSystem/FormatMeasureUnit';
import FormatMeasureValue from 'views/shared/helpers/measuringSystem/FormatMeasureValue';
import { FunctionCode, ICalculations, IProject, MotorType } from 'views/shared/types';

import styles from './EnteredData.module.scss';
import SpecsEntry from './SpecsEntry';

interface IEnteredDataProps {
  project: IProject | Record<string, never>;
  linkLabel?: string;
  onLinkClick?: () => void;
  calculations?: ICalculations;
  className?: string;
  motorType?: MotorType | null;
}

const formatSpeed = (speed: number) => {
  return (
    <>
      <FormatMeasureValue metric="km/h" imperial="mph" value={speed} />{' '}
      <FormatMeasureUnit metric="measurements_unit_kmh" imperial="measurements_unit_mph" />
    </>
  );
};

export default function EnteredData({
  project,
  calculations,
  className,
  linkLabel,
  onLinkClick,
  motorType,
}: IEnteredDataProps) {
  const formatTractiveForce = useTractiveForceFormatter();
  const isLinkAvailable = linkLabel && onLinkClick;
  const { vehicle_travel_speed, vehicle_work_speed, vehicle_travel_speed2, vehicle_work_speed2 } =
    calculations || {};
  const { system_data, propel_sizing } = project;
  const { hydraulic_dedicated_power, engine_speed, pressure_limit } = system_data;
  const isPropelSizingAvailable = project.function_codes.includes(FunctionCode.PropelSizing);
  const hasGearbox = isPropelSizingAvailable && !!propel_sizing?.two_speed_gear_box;

  let workSpeedLabel = 'specs_maximum_work_speed';
  let travelSpeedLabel = 'specs_maximum_travel_speed';
  let traciveForceLabel = 'specs_tractive_force';

  let actualTravelSpeed = vehicle_travel_speed && formatSpeed(vehicle_travel_speed);
  let actualWorkSpeed = vehicle_work_speed && formatSpeed(vehicle_work_speed);
  let requiredTravelSpeed =
    propel_sizing?.max_travel_speed && formatSpeed(propel_sizing.max_travel_speed);
  const requiredWorkSpeedRaw =
    motorType === MotorType.VariablePiston
      ? propel_sizing?.max_work_speed
      : propel_sizing?.max_travel_speed;
  let requiredWorkSpeed = requiredWorkSpeedRaw && formatSpeed(requiredWorkSpeedRaw);
  let tractiveForce = formatTractiveForce(propel_sizing?.tractive_force);

  if (hasGearbox) {
    actualTravelSpeed = vehicle_travel_speed2 && (
      <>
        {actualTravelSpeed} | {formatSpeed(vehicle_travel_speed2)}
      </>
    );

    actualWorkSpeed = vehicle_work_speed2 && (
      <>
        {actualWorkSpeed} | {formatSpeed(vehicle_work_speed2)}
      </>
    );

    tractiveForce = `${tractiveForce} | ${formatTractiveForce(
      propel_sizing?.two_speed_gear_box!.tractive_force,
    )}`;

    const requiredWorkSpeed2Raw =
      motorType === MotorType.VariablePiston
        ? propel_sizing?.two_speed_gear_box?.max_work_speed
        : propel_sizing?.two_speed_gear_box?.max_travel_speed;
    const requiredWorkSpeed2 = requiredWorkSpeed2Raw && formatSpeed(requiredWorkSpeed2Raw);

    requiredWorkSpeed = (
      <>
        {requiredWorkSpeed} | {requiredWorkSpeed2}
      </>
    );

    const requiredTravelSpeed2 =
      propel_sizing?.two_speed_gear_box?.max_travel_speed &&
      formatSpeed(propel_sizing.two_speed_gear_box.max_travel_speed);

    requiredTravelSpeed = (
      <>
        {requiredTravelSpeed} | {requiredTravelSpeed2}
      </>
    );

    workSpeedLabel = 'specs_maximum_work_speed_with_gearbox';
    travelSpeedLabel = 'specs_maximum_travel_speed_with_gearbox';
    traciveForceLabel = 'specs_tractive_force_with_gearbox';
  }

  const handleLinkClick: MouseEventHandler<HTMLButtonElement> = () => {
    if (onLinkClick) {
      onLinkClick();
    }
  };

  return (
    <div
      className={cn(utils.row, styles['product-specs-container'], className)}
      data-testid="product-selection-specs-container"
    >
      <div className={cn(utils.col4, utils.colMd4, utils.colSm8)}>
        <SpecsEntry
          value={
            hydraulic_dedicated_power && (
              <FormatMeasureValue metric="kW" imperial="hp" value={hydraulic_dedicated_power} />
            )
          }
          suffix={
            <FormatMeasureUnit
              metric="measurements_unit_kw"
              imperial="measurements_unit_horsepower"
            />
          }
          data-testid="hydraulic-dedicated-power-spec-item"
        >
          <FormattedMessage id="specs_power_for_propel_system" />
        </SpecsEntry>
        <SpecsEntry
          value={engine_speed}
          suffix={<FormattedMessage id="measurements_unit_rpm" />}
          data-testid="engine-speed-spec-item"
        >
          <FormattedMessage id="specs_engine_speed" />
        </SpecsEntry>
        {isPropelSizingAvailable && (
          <>
            <SpecsEntry
              value={requiredWorkSpeed}
              actual={actualWorkSpeed}
              data-testid="max-work-speed-spec-item"
            >
              <FormattedMessage id={workSpeedLabel} />
            </SpecsEntry>
          </>
        )}
      </div>
      <div className={cn(utils.col4, utils.colMd4, utils.colSm8)}>
        {isPropelSizingAvailable && (
          <SpecsEntry
            value={requiredTravelSpeed}
            actual={actualTravelSpeed}
            data-testid="max-travel-speed-spec-item"
          >
            <FormattedMessage id={travelSpeedLabel} />
          </SpecsEntry>
        )}
        <SpecsEntry
          value={<FormatMeasureValue metric="bar" imperial="psi" value={pressure_limit!} />}
          data-testid="pressure-limit-spec-item"
          suffix={
            <FormatMeasureUnit metric="measurements_unit_bar" imperial="measurements_unit_psi" />
          }
        >
          <FormattedMessage id="specs_system_pressure_limit" />
        </SpecsEntry>
        {isPropelSizingAvailable && (
          <SpecsEntry value={tractiveForce} data-testid="tractive-force-spec-item">
            <FormattedMessage id={traciveForceLabel} />
          </SpecsEntry>
        )}
      </div>
      <div className={cn(utils.col4, utils.colMd8, core.flex, core.flexColumn)}>
        {isLinkAvailable && (
          <button
            className={cn(button.textBtn, button.textBtnPrimary, core.end, core.textBold)}
            onClick={handleLinkClick}
            data-testid="entered-data-link"
          >
            {linkLabel}
          </button>
        )}
      </div>
    </div>
  );
}
