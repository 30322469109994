import { useAuth0 } from '@auth0/auth0-react';
import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import ConfigMenu from 'views/base/ConfigMenu/ConfigMenu';
import DocumentList from 'views/shared/components/DocumentList/DocumentList';
import EnteredData from 'views/shared/components/EnteredData/EnteredData';
import SummaryTabs from 'views/shared/components/SummaryTabs/SummaryTabs';

import { ISharedPublicProject } from '../types';
import PublicProductList from './PublicProductList';

interface IPublicSharedSummaryProps {
  data: ISharedPublicProject;
}

const PublicSharedSummary = ({ data }: IPublicSharedSummaryProps) => {
  const { loginWithRedirect } = useAuth0();
  const { project, motors, pumps, steering_products, title } = data;
  const products = { motors, pumps, steeringProducts: steering_products };
  const isProductsAvailable = Boolean(motors.length || pumps.length || steering_products.length);

  const productIds = [...motors, ...pumps, ...steering_products].map(
    ({ product_id }) => product_id,
  );

  const onLoginClick = () => {
    loginWithRedirect({ redirectUri: window.location.origin });
  };

  const motorType = motors[0]?.type;

  return (
    <div className={utils.mb10} data-testid="public-shared-summary-page">
      <div className={cn(core.flex, core.flexBetween, core.alignCenter)}>
        <h1 className={utils.mb4} data-testid="public-shared-summary-page-title">
          {title}
        </h1>
        <ConfigMenu />
      </div>
      <EnteredData
        className={utils.mb4}
        project={project}
        calculations={project.results}
        motorType={motorType}
      />
      <div
        className={cn(core.flex, core.flexEnd, core.alignCenter)}
        data-testid="public-shared-summary-page-login-block"
      >
        <button
          onClick={onLoginClick}
          className={cn(button.textBtn, button.textBtnPrimary, utils.mr1)}
          data-testid="public-shared-summary-page-login-button"
        >
          <FormattedMessage id="shared_page_login_button" />
        </button>
        <p>
          <FormattedMessage id="shared_page_login_text" />
        </p>
      </div>
      {isProductsAvailable && (
        <SummaryTabs
          products={<PublicProductList products={products} />}
          documents={<DocumentList productIds={productIds} />}
        />
      )}
    </div>
  );
};

export default PublicSharedSummary;
