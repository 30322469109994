import button from '@danfoss/mosaic/css/components/button.module.css';
import { DfModal } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from 'views/shared/components/Button/Button';

export interface IConfirmationModalLogic {
  onClose: () => void;
  onChange: () => void;
  isUpdating?: boolean;
}

export interface ConfirmationModalProps extends IConfirmationModalLogic {
  confirmLabel: string;
  headingLabel: string;
  cancelLabel?: string;
  isCancelHidden?: boolean;
  children: ReactNode;
}

const ConfirmationModal = ({
  onClose,
  onChange,
  isUpdating,
  isCancelHidden,
  confirmLabel,
  headingLabel,
  cancelLabel,
  children,
}: ConfirmationModalProps) => {
  return (
    <DfModal onModalClose={onClose} heading={headingLabel} isOpen data-testid="confirmation-modal">
      <div slot="modal-body">{children}</div>
      <div slot="modal-footer">
        {!isCancelHidden && (
          <Button
            className={cn(button.textBtn, button.textBtnSecondary)}
            onClick={onClose}
            data-testid="confirmation-modal-close"
          >
            {cancelLabel || <FormattedMessage id="wizard_confirmation_modal_cancel" />}
          </Button>
        )}
        <Button
          className={button.btnPrimary}
          onClick={onChange}
          loading={isUpdating}
          data-testid="confirmation-modal-confirm"
        >
          {confirmLabel}
        </Button>
      </div>
    </DfModal>
  );
};

export default ConfirmationModal;
