import { useAuth0 } from '@auth0/auth0-react';
import { SplitFactory } from '@splitsoftware/splitio-react';
import { FunctionComponent, useContext } from 'react';

import { getAppConfig } from 'configs/global';
import { getDCProjectId } from 'utils/DesignCenterProjectIdService';

import { AuthContext, AuthType } from '../auth/AuthContext';

const StandaloneFeatureFlags: FunctionComponent = ({ children }) => {
  const { SPLIT_IO_KEY } = getAppConfig();
  const { user } = useAuth0();
  const { isAuthenticated, authType } = useContext(AuthContext);
  const DCProjectId = getDCProjectId();

  if (!isAuthenticated) {
    return children as JSX.Element;
  }

  const userId = authType === AuthType.DIP ? user?.email : DCProjectId;
  const splitIOConfig: SplitIO.IBrowserSettings = {
    core: {
      authorizationKey: SPLIT_IO_KEY,
      key: userId!,
    },
  };

  return (
    <SplitFactory config={splitIOConfig} data-testid="split-factory">
      {children as JSX.Element}
    </SplitFactory>
  );
};

export default StandaloneFeatureFlags;
