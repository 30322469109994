export type ProductType = 'motor' | 'pump' | 'steering';

interface IProductProps {
  image?: string;
  series?: string;
  name: string;
  specs: string;
  type: ProductType;
}

export const Product = ({ series, name, specs, type }: IProductProps) => {
  const productTypeValues = {
    motor: 'Motor',
    pump: 'Pump',
    steering: 'Steering Unit',
  };
  const productType = productTypeValues[type];

  return (
    <div
      data-testid={`product-${type}-item`}
      style={{
        borderBottom: '1px solid #d8d8d9',
        padding: '20px 16px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        data-testid="product-image-placeholder"
        style={{
          width: '90px',
          height: '90px',
          border: '1px solid #acacad',
          color: '#acacad',
          textAlign: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div>{productType}</div>
      </div>
      <div
        data-testid="product-item-desc"
        style={{ marginLeft: '16px', display: 'flex', flexDirection: 'column', lineHeight: 1 }}
      >
        <p data-testid="product-item-series" style={{ margin: 0, fontSize: '20px' }}>
          {series}
        </p>
        <h2 data-testid="product-item-name" style={{ fontSize: '30px', margin: '8px 0' }}>
          {name}
        </h2>
        <p
          data-testid="product-item-specs"
          style={{ margin: 0, fontWeight: 700, fontSize: '20px' }}
        >
          {specs}
        </p>
      </div>
    </div>
  );
};
