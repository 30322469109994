import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { ReactElement, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

interface SpecsEntryProps {
  children: ReactNode;
  value?: number | string | ReactElement;
  actual?: number | string | ReactElement;
  suffix?: ReactNode;
  joined?: boolean;
  'data-testid'?: string;
}

const SpecsEntry = ({ children, value, suffix, joined, actual, ...rest }: SpecsEntryProps) => {
  return (
    <div className={utils.row}>
      <div
        className={cn(utils.col6, utils.mb5, core.textBold)}
        data-testid={rest['data-testid'] ? `${rest['data-testid']}-title` : null}
      >
        {children}
      </div>
      <div className={cn(utils.col6, utils.mb5)} {...rest}>
        <p>
          {value || '-'}
          {!joined && ' '}
          {suffix}
        </p>
        {actual && (
          <p className={core.textItalic} data-testid="actual">
            <FormattedMessage id="specs_data_actual" /> {actual}
            {!joined && ' '}
            {suffix}
          </p>
        )}
      </div>
    </div>
  );
};

export default SpecsEntry;
