import axios from 'axios';

export enum AutofillStrategy {
  Fixed = 'FixedMotorDisplacement',
  Variable = 'VariableMotorDisplacement',
}

export interface IMotorPerformanceCase {
  case_number: number;
  motors: {
    selected_motor_id: string;
    displacements: number[];
  }[];
  total_performance: {
    tractive_forces: number[];
    vehicle_speeds: number[];
  };
}

export enum PerformanceCalculationsWarning {
  GlobalLimitReached = 'GlobalLimitReached',
  ProjectLimitReached = 'ProjectLimitReached',
}

export interface IPerformanceCalculationsResponse {
  performances: IPerformanceCalculations;
  warning?: PerformanceCalculationsWarning;
}

export interface IPerformanceCalculations {
  id: string;
  project_id: string;
  displacement_conditions_autofill_strategy: AutofillStrategy;
  pump_calculations: {
    selected_pump_id: string;
    displacements: number[];
  }[];
  motors_cases: IMotorPerformanceCase[];
}

export const createPerformanceCalculations = async (
  project_id: string,
  autofill_options: { strategy: AutofillStrategy; conditions_count?: number },
) => {
  const { data } = await axios.post<IPerformanceCalculationsResponse>('/performance-calculations', {
    project_id,
    autofill_options,
  });

  return data;
};
