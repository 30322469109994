import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { ReactNode, useState } from 'react';

import TooltipInfo from 'views/shared/components/TooltipInfo/TooltipInfo';
import { MachineExampleCode, SystemArchitecture } from 'views/shared/types';

import { ArchitectureSelectTooltip } from './ArchitectureSelect/ArchitectureSelectTooltip';
import styles from './ExampleOptions.module.scss';

interface IExampleOptionProps {
  code: MachineExampleCode;
  title: ReactNode;
  description: ReactNode;
  tooltip?: ReactNode;
  icon: ReactNode;
  selected: boolean;
  onSelect: (code: MachineExampleCode) => void;
  onSelectArchitecture?: (architecture: SystemArchitecture) => void;
  onCancelSelection?: () => void;
}

export default function ExampleOption({
  code,
  title,
  description,
  icon,
  selected,
  onSelect,
  onSelectArchitecture,
  onCancelSelection,
  tooltip,
}: IExampleOptionProps) {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleClick = () => {
    if (onSelectArchitecture) {
      setShowTooltip(true);
    }

    onSelect(code);
  };

  const closeTooltip = () => {
    setShowTooltip(false);
    onCancelSelection?.();
  };

  const onTooltipSelect = (architecture: SystemArchitecture) => {
    closeTooltip();
    onSelectArchitecture?.(architecture);
  };

  return (
    <div
      className={cn(core.flex, core.flexColumn, core.alignCenter, styles.container)}
      onClick={handleClick}
      data-testid={`example-selection-option-${code}`}
    >
      <div
        className={cn(core.flex, core.flexCenter, core.alignCenter, styles['icon-container'], {
          [styles.selected]: selected,
        })}
      >
        {icon}

        {showTooltip && (
          <ArchitectureSelectTooltip onSelect={onTooltipSelect} onClose={closeTooltip} />
        )}
      </div>
      <h5 className={cn(core.flex, core.alignCenter, core.textCenter, utils.mt2)}>
        {title} {tooltip && <TooltipInfo className={styles.tooltip}>{tooltip}</TooltipInfo>}
      </h5>
      <p className={cn(core.textCenter, utils.mt1)}>{description}</p>
    </div>
  );
}
