import { useIntl } from 'react-intl';

import { useTractiveForceFormatter } from 'views/shared/helpers/formatters';

import StaticAttribute from '../../components/widgets/components/StaticAttributes/StaticAttribute';
import { useTractiveForceDifferenceFormatter } from '../../components/widgets/helpers/formatters';

interface ITractiveForceProps {
  actual: number;
  required: number;
  actualLabel?: string;
  requiredLabel?: string;
}

const TractiveForce = ({ actual, required, actualLabel, requiredLabel }: ITractiveForceProps) => {
  const { formatMessage } = useIntl();
  const formatTractiveForce = useTractiveForceFormatter();
  const formatTractiveForceDifference = useTractiveForceDifferenceFormatter();

  const actualTractiveForce = formatTractiveForce(actual);
  const requiredTractiveForce = formatTractiveForce(required);
  const differenceTractiveForce = formatTractiveForceDifference(actual, required);

  return (
    <div data-testid="tractive-force-summary">
      <StaticAttribute
        name={formatMessage({ id: actualLabel ?? 'wizard_product_motor_widget_actual' })}
        value={actualTractiveForce}
        testId="actual-tractive-force"
      />
      <StaticAttribute
        name={formatMessage({ id: requiredLabel ?? 'wizard_product_motor_widget_required' })}
        value={requiredTractiveForce}
        testId="required-tractive-force"
      />
      <StaticAttribute
        name={formatMessage({ id: 'wizard_product_motor_widget_difference' })}
        value={differenceTractiveForce}
        testId="difference-tractive-force"
        boldTitle
        boldValue
      />
    </div>
  );
};

export default TractiveForce;
