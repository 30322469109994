import button from '@danfoss/mosaic/css/components/button.module.css';
import staticPages from '@danfoss/mosaic/css/components/static-pages.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as SvgBigGears } from 'assets/icons/big-gears.svg';

import styles from './Login.module.scss';

interface ILoginProps {
  onLogin: () => void;
}

const Login = ({ onLogin }: ILoginProps) => {
  return (
    <div
      className={cn(core.container, utils.my24, staticPages.maintenancePage, styles.wrapper)}
      data-testid="no-login"
    >
      <div className={staticPages.maintenancePageMain}>
        <div>
          <SvgBigGears />
        </div>
        <div className={staticPages.maintenanceInfo}>
          <h3 className={utils.mb3}>
            <FormattedMessage id="login_page_headline" />
          </h3>
          <p className={utils.mb0}>
            <FormattedMessage id="login_page_description" />
            <br />
            <FormattedMessage id="login_page_login_before" />{' '}
            <button
              className={cn(button.textBtn, button.textBtnPrimary)}
              onClick={() => onLogin()}
              data-testid="login-button"
            >
              <FormattedMessage id="login_page_login" />
            </button>{' '}
            <FormattedMessage id="login_page_login_after" />
          </p>
        </div>
      </div>
    </div>
  );
};

export default Login;
