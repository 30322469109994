import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

import { MotorType } from 'views/shared/types';

export interface MotorTypeContextValue {
  motorType: MotorType | null;
  setMotorType: (motorType: MotorType) => void;
}

export const MotorTypeContext = createContext<MotorTypeContextValue>({
  motorType: null,
  setMotorType: () => {},
});

export function useMotorTypeContext() {
  return useContext(MotorTypeContext);
}

export function MotorTypeProvider({ children }: PropsWithChildren<unknown>) {
  const [motorType, setMotorType] = useState<MotorType | null>(null);

  const context = useMemo<MotorTypeContextValue>(
    () => ({ motorType, setMotorType }),
    [motorType, setMotorType],
  );

  return <MotorTypeContext.Provider value={context}>{children}</MotorTypeContext.Provider>;
}
