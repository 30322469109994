import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { ReactNode } from 'react';

interface IParameterProps {
  field: ReactNode;
  value: ReactNode;
  'data-testid'?: string;
}

const Parameter = ({ field, value, 'data-testid': testId }: IParameterProps) => {
  return (
    <div className={utils.mb4} data-testid={testId}>
      <div className={core.textBold}>{field}</div>
      <div>{value}</div>
    </div>
  );
};

export default Parameter;
