import components from '@danfoss/mosaic/css/components.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import Fieldset from 'views/shared/components/form/Fieldset';
import Label from 'views/shared/components/form/Label/Label';
import RadioInput from 'views/shared/components/form/RadioInput';
import { IValidationRules } from 'views/shared/components/form/types';
import {
  IProjectPropelSizingData,
  IPullRatioCalculation,
  SystemArchitecture,
} from 'views/shared/types';

import { IRequirementsForm } from '../../../../types';
import TractiveForceWithPullRatio from '../TractiveForceWithPullRatio/TractiveForceWithPullRatio';
import TransmissionRatio from '../TransmissionRatio/TransmissionRatio';

interface ITractiveForceGroupProps {
  form: UseFormReturn<IRequirementsForm>;
  formPart: 'propelSizingData' | 'propelSizingData.two_speed_gear_box';
  onUpdate: (isUpdating: boolean, isError?: boolean) => void;
  validation?: {
    [field in keyof IProjectPropelSizingData]?: IValidationRules;
  };
  dependsOn: {
    loadedWeight: number;
    hydraulicDedicatedPower: number;
    pullRatioCalculation: IPullRatioCalculation | null;
    initialArchitecture: SystemArchitecture;
  };
}

export const TractiveForceGroup = ({
  form,
  formPart,
  onUpdate,
  validation,
  dependsOn,
}: ITractiveForceGroupProps) => {
  const { loadedWeight, hydraulicDedicatedPower, pullRatioCalculation, initialArchitecture } =
    dependsOn;
  const { control } = form;
  const tractiveForceValue = useWatch({
    name: `${formPart}.tractive_force`,
    control,
  });
  const maxTravelSpeedValue = useWatch({
    name: `${formPart}.max_travel_speed`,
    control,
  });
  const maxWorkSpeedValue = useWatch({
    name: `${formPart}.max_work_speed`,
    control,
  });

  return (
    <div data-testid={`${formPart}.tractive-force`}>
      <div>
        <Label>
          <FormattedMessage id="wizard_requirements_tractive_force" />
        </Label>
        <Controller
          control={form.control}
          name={`${formPart}.is_tractive_force_known`}
          defaultValue={false}
          render={({ field: { onChange, name, value } }) => (
            <Fieldset
              className={cn(components.radioGroup, components.inline, utils.mt4, utils.mb3)}
              data-testid="tractive-force-fieldset"
            >
              <RadioInput
                id={`${formPart}-radio-tf-known`}
                name={name}
                data-testid="tractive-force-known-option"
                onChange={() => onChange(true)}
                checked={value}
              >
                <FormattedMessage id="wizard_requirements_known" />
              </RadioInput>
              <RadioInput
                id={`${formPart}-radio-tf-unknown`}
                name={name}
                data-testid="tractive-force-unknown-option"
                onChange={() => onChange(false)}
                checked={!value}
              >
                <FormattedMessage id="wizard_requirements_unknown" />
              </RadioInput>
            </Fieldset>
          )}
        />
        <TractiveForceWithPullRatio
          form={form}
          formPart={formPart}
          onUpdate={onUpdate}
          dependsOn={{ loadedWeight, pullRatioCalculation }}
          validation={validation}
        />
      </div>
      <TransmissionRatio
        form={form}
        formPart={formPart}
        onUpdate={onUpdate}
        dependsOn={{
          tractiveForce: tractiveForceValue,
          maxTravelSpeed: maxTravelSpeedValue,
          maxWorkSpeed: maxWorkSpeedValue,
          architecture: initialArchitecture,
          hydraulicDedicatedPower,
        }}
      />
    </div>
  );
};
