import { RowType } from '@danfoss/mosaic';
import { useCallback, useRef } from 'react';

export function useScrollIntoRow() {
  const observerRef = useRef<MutationObserver>();

  const ref = useCallback((element: HTMLDfTableElement | null) => {
    observerRef.current?.disconnect();

    if (!element) {
      return;
    }

    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.addedNodes.length) {
          const rowElement = element.querySelector('.recommended');

          rowElement?.scrollIntoView({ behavior: 'smooth' });
        }
      });
    });

    observer.observe(element, {
      childList: true,
    });

    observerRef.current = observer;
  }, []);

  const getRowProps = (row: RowType) => {
    if (row.original.recommended) {
      return {
        className: 'recommended',
      };
    }

    return {};
  };

  return { ref, getRowProps };
}
