import core from '@danfoss/mosaic/css/core.module.css';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import styles from './NoSelections.module.scss';

const NoSelections = () => {
  return (
    <h2 className={cn(core.textCenter, styles['secondary-text'])} data-testid="no-selections">
      <FormattedMessage id="home_selections_empty" />
    </h2>
  );
};

export default NoSelections;
