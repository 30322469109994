import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Label from 'views/shared/components/form/Label/Label';

import styles from './FinalDriveEfficiency.module.scss';

export interface FinalDriveEfficiencyProps {
  className?: string;
  finalDriveEfficiency: number;
  compact?: boolean;
}

export function FinalDriveEfficiency({
  className,
  finalDriveEfficiency,
  compact,
}: FinalDriveEfficiencyProps) {
  const { formatNumber } = useIntl();

  return (
    <div className={cn(core.flex, styles.container, className, { [core.flexColumn]: !compact })}>
      <Label>
        <FormattedMessage id="wizard_product_motor_widget_drive_efficiency" />
      </Label>
      <div
        data-testid="final-drive-efficiency"
        className={cn(compact && utils.ml5, !compact && styles['final-drive-efficiency'])}
      >
        {formatNumber(finalDriveEfficiency / 100, { style: 'percent' })}
      </div>
    </div>
  );
}
