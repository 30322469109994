import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { roundPrice } from 'views/shared/helpers/formatters';
import { IProducts } from 'views/wizard/shared/helpers/useSelectedProducts';

import { IPrice } from '../types';
import { useProductListItems } from './ProductsTable/useProductListItems';

interface IProductListProps {
  products: IProducts;
  prices: Partial<Record<string, IPrice>>;
}

const ProductList = ({ products, prices }: IProductListProps) => {
  const { formatNumber } = useIntl();

  const { configurableItems, nonConfigurableItems, totalPrice } = useProductListItems({
    prices,
    products,
  });

  return (
    <div data-testid="products-list">
      {configurableItems.length > 0 && (
        <h3 className={utils.mt2}>
          <FormattedMessage id="products_configurable_section_title" />
        </h3>
      )}
      {configurableItems}

      {nonConfigurableItems.length > 0 && (
        <>
          <h3 className={utils.mt2} data-testid="products-non-configurable-section-title">
            <FormattedMessage id="products_non_configurable_section_title" />
          </h3>
          <div className={utils.mt2} data-testid="products-non-configurable-disclaimer">
            <FormattedMessage id="products_non_configurable_disclaimer" />
          </div>
        </>
      )}
      {nonConfigurableItems}

      <div className={cn(utils.px4, utils.mt2, core.textRight)}>
        <FormattedMessage id="products_price_disclaimer" />
      </div>
      <div className={cn(core.flex, utils.mt2, utils.px4)}>
        <div className={cn(core.flex, core.end)}>
          <h3 className={utils.mr10}>
            <FormattedMessage id="products_price_total" />
          </h3>
          <h3 data-testid="product-list-total-price">
            <FormattedMessage id="currency_euro_symbol" /> {formatNumber(roundPrice(totalPrice))}
          </h3>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
