import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import FormatMeasureUnit from 'views/shared/helpers/measuringSystem/FormatMeasureUnit';
import FormatMeasureValue from 'views/shared/helpers/measuringSystem/FormatMeasureValue';
import { ICalculations, IProjectPropelSizingData, MotorType } from 'views/shared/types';
import { useMotorTypeContext } from 'views/wizard/product-selection/motor-type-context/motorTypeContext';

import styles from './SpeedSummary.module.scss';

interface SpeedSummaryProps {
  calculations: ICalculations;
  propelSizing: IProjectPropelSizingData;
}

const SpeedSummary = ({ calculations, propelSizing }: SpeedSummaryProps) => {
  const { motorType } = useMotorTypeContext();
  const { vehicle_work_speed, vehicle_travel_speed } = calculations;
  const { max_work_speed, max_travel_speed } = propelSizing;

  const workSpeed = motorType === MotorType.VariablePiston ? max_work_speed : max_travel_speed;

  return (
    <div className={cn(core.flex, core.alignCenter)}>
      <div
        data-testid="speed-summary-work-speed"
        className={cn(core.flex, core.alignCenter, utils.mr4, styles.container)}
      >
        <p className={core.textSemibold}>
          <FormattedMessage id="wizard_pump_modal_summary_work_speed" />
        </p>
        <FormatMeasureValue imperial="mph" metric="km/h" value={vehicle_work_speed ?? ''} />{' '}
        <FormatMeasureUnit metric="measurements_unit_kmh" imperial="measurements_unit_mph" />
        {workSpeed && (
          <span className={core.textItalic}>
            <FormattedMessage
              id="wizard_pump_modal_summary_speed_required"
              values={{
                value: <FormatMeasureValue imperial="mph" metric="km/h" value={workSpeed} />,
                units: (
                  <FormatMeasureUnit
                    metric="measurements_unit_kmh"
                    imperial="measurements_unit_mph"
                  />
                ),
              }}
            />
          </span>
        )}
      </div>
      <div
        data-testid="speed-summary-travel-speed"
        className={cn(core.flex, core.alignCenter, styles.container)}
      >
        <p className={core.textSemibold}>
          <FormattedMessage id="wizard_pump_modal_summary_travel_speed" />
        </p>
        <FormatMeasureValue imperial="mph" metric="km/h" value={vehicle_travel_speed ?? ''} />{' '}
        <FormatMeasureUnit metric="measurements_unit_kmh" imperial="measurements_unit_mph" />
        {max_travel_speed && (
          <span className={core.textItalic}>
            <FormattedMessage
              id="wizard_pump_modal_summary_speed_required"
              values={{
                value: <FormatMeasureValue imperial="mph" metric="km/h" value={max_travel_speed} />,
                units: (
                  <FormatMeasureUnit
                    metric="measurements_unit_kmh"
                    imperial="measurements_unit_mph"
                  />
                ),
              }}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default SpeedSummary;
