import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';

import ProductImage from 'views/shared/components/ProductImage/ProductImage';

import styles from './ProductInfo.module.scss';
import { IProductDetails } from './types';

const ProductInfo = ({ photoUrl, photoAlt, spec, series, name }: IProductDetails) => {
  return (
    <div className={core.flex} data-testid="product-info">
      <div className={utils.mr2}>
        <ProductImage photoUrl={photoUrl} width={100} height={100} alt={photoAlt} />
      </div>
      <div className={cn(core.flex, core.flexColumn, core.flexCenter)}>
        <p>{series}</p>
        <h2 className={styles.title}>{name}</h2>
        <p className={core.textBold} data-testid="product-spec">
          {spec}
        </p>
      </div>
    </div>
  );
};

export default ProductInfo;
