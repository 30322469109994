import { renderToString } from 'react-dom/server';
import { IntlShape } from 'react-intl';

import { round } from 'views/shared/helpers/formatters';

import { IAvailableSteeringProduct, IAvailableSteeringUnit } from '../../../../../api/products';
import { usePriceCellFormatter } from '../../../../components/tables/helpers/formatters';

export const useSteeringPriceCellFormatter = (intl: IntlShape) => {
  const defaultPriceCellFormatter = usePriceCellFormatter(intl);

  return (steeringProducts: IAvailableSteeringProduct[]) => {
    const totalPrice = steeringProducts.reduce((acc, product) => {
      if (product.price) {
        return acc + product.price.value;
      }

      return acc;
    }, 0);
    const isPriceAvailable = totalPrice !== 0;

    return defaultPriceCellFormatter(totalPrice, isPriceAvailable);
  };
};

export const getHybridLockToLockCellFormatter =
  (formatMessage: IntlShape['formatMessage']) =>
  (steeringUnit: IAvailableSteeringUnit['steering_unit']) => {
    const { actual_l2l_sec, actual_l2l_turns } = steeringUnit;
    const notAvailable = formatMessage({ id: 'data_unavailable' });
    const actualTurns = actual_l2l_turns
      ? `${actual_l2l_turns} ${formatMessage({ id: 'measurements_unit_turns' })}`
      : notAvailable;
    const actualSeconds = actual_l2l_sec
      ? `${actual_l2l_sec} ${formatMessage({ id: 'measurements_unit_seconds' })}`
      : notAvailable;

    return renderToString(
      <span data-testid="lock-to-lock">{`${actualTurns} | ${actualSeconds}`}</span>,
    );
  };

export const hybridSpoolCell = (products: IAvailableSteeringProduct[]) => {
  const productWithSpool = products.find(product => product.spool && product.spool >= 0);

  if (productWithSpool) {
    return renderToString(<span data-testid="spool">{round(productWithSpool.spool!)}</span>);
  }

  return '';
};

export const hybridDisplacementCell = (products: IAvailableSteeringProduct[]) => {
  const productWithDisplacement = products.find(
    product => product.displacement && product.displacement >= 0,
  );

  if (productWithDisplacement) {
    return renderToString(
      <span data-testid="displacement">{round(productWithDisplacement.displacement!)}</span>,
    );
  }

  return '';
};
