import axios from 'axios';

import { MotorType, MotorTypeWarning, SystemArchitecture } from 'views/shared/types';

export interface ITransmissionRatio {
  value: number;
  recommended_motor_type: {
    motor_type: MotorType;
    warning?: MotorTypeWarning;
  };
  recommended_system_architecture: SystemArchitecture;
}

export interface ITransmissionRatioCalculationValues {
  tractive_force: number;
  speed_maximum: number;
  pump_power: number;
}

export const getTransmissionRatio = async (params: ITransmissionRatioCalculationValues) => {
  const { data } = await axios.get<ITransmissionRatio>('/physics-calculations/transmission-ratio', {
    params,
  });

  return data;
};
