import { ReactComponent as SvgAlertError } from '@danfoss/mosaic-icons/dist/svg/dangerous-outline.svg';
import alert from '@danfoss/mosaic/css/components/alert.module.css';
import checkbox from '@danfoss/mosaic/css/components/checkbox.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfAlert, DfLoader } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { ChangeEvent, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { trackEvent } from 'utils/analytics';
import { AutofillStrategy } from 'views/shared/api/performance';
import TooltipInfo from 'views/shared/components/TooltipInfo/TooltipInfo';
import { usePerformance } from 'views/shared/helpers/performance';
import { MotorType } from 'views/shared/types';
import { ContactUsButton } from 'views/wizard/requirements/components/ContactUsButton';

import styles from './Performance.module.scss';
import PerformanceChart from './components/PerformanceChart';

interface IPerformanceProps {
  projectId: string;
  motorType: MotorType;
  trigger?: (load: () => void, isLoading: boolean, isDisabled: boolean) => JSX.Element;
  onUpdate?: (isUpdating: boolean) => void;
}

export default function Performance({
  projectId,
  motorType,
  trigger,
  onUpdate,
}: IPerformanceProps) {
  const checkboxId = new Date().toISOString();
  const isMotorsVariable = motorType === MotorType.VariablePiston;
  const isStrategyChangeAvailable = isMotorsVariable;
  const [performanceStrategy, setPerformanceStrategy] = useState<AutofillStrategy>(
    isMotorsVariable ? AutofillStrategy.Variable : AutofillStrategy.Fixed,
  );
  const { error, isLoading, loadPerformance, performance } = usePerformance({
    projectId,
    performanceStrategy,
  });
  const hasError = Boolean(error);

  const handleStrategyCheckboxChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { checked } = target;

    const newStrategy = checked ? AutofillStrategy.Fixed : AutofillStrategy.Variable;

    setPerformanceStrategy(newStrategy);

    loadPerformance(newStrategy);
  };
  const handleTriggerClick = () => {
    trackEvent({ event: 'generate_performance_report', selectionId: projectId });
    loadPerformance(performanceStrategy);
  };

  useEffect(() => {
    if (!trigger) {
      loadPerformance(performanceStrategy);
    }
  }, []);

  useEffect(() => {
    if (onUpdate) {
      onUpdate(isLoading);
    }
  }, [isLoading]);

  return (
    <div className={styles.wrapper} data-testid="performance-chart">
      <div className={utils.mb8}>
        <h1>
          <FormattedMessage id="wizard_summary_performance_prediction_title" />
        </h1>
        <p className={utils.my4}>
          <FormattedMessage id="wizard_summary_performance_prediction_text" />
        </p>
        <DfAlert isVisible type="info">
          <p>
            <FormattedMessage id="wizard_summary_performance_prediction_notification" />
          </p>
        </DfAlert>

        {trigger && trigger(handleTriggerClick, isLoading, Boolean(performance) || hasError)}
        {!trigger && isLoading && <DfLoader data-testid="performance-loader" />}

        {!hasError && isStrategyChangeAvailable && (
          <fieldset className={utils.mt4}>
            <div className={checkbox.checkbox}>
              <input
                id={checkboxId}
                type="checkbox"
                onChange={handleStrategyCheckboxChange}
                checked={performanceStrategy === AutofillStrategy.Fixed}
                disabled={!performance?.motors_cases}
                data-testid="performance-strategy-switch"
              />
              <label
                htmlFor={checkboxId}
                className={cn(core.flex, core.alignCenter)}
                data-testid="label-for-checkbox"
              >
                <p>
                  <FormattedMessage id="wizard_summary_performance_prediction_strategy_switch_label" />
                </p>
                <TooltipInfo>
                  <FormattedMessage id="wizard_summary_performance_prediction_strategy_tooltip" />
                </TooltipInfo>
              </label>
            </div>
          </fieldset>
        )}
      </div>
      {hasError && (
        <div
          data-testid="performance-error"
          className={cn(alert.alert, alert.alertError, styles.error)}
          role="alert"
        >
          <SvgAlertError className={cn(core.icon, alert.alertIcon)} />
          <div
            className={cn(
              alert.alertMessage,
              core.flex,
              core.alignCenter,
              core.flexBetween,
              core.fullWidth,
            )}
          >
            <p data-testid="performance-error-text">{error}</p>
            <ContactUsButton projectId={projectId} />
          </div>
        </div>
      )}
      {!hasError && performance && (
        <div className={styles['chart-container']} data-testid="chart-wrapper">
          <PerformanceChart data={performance.motors_cases} />
        </div>
      )}
    </div>
  );
}
