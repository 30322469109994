import axios from 'axios';

import { IPullRatioCalculation } from 'views/shared/types';

export interface IPullRatioData {
  value: number;
}

export const getPullRatio = async (params: Omit<IPullRatioCalculation, 'pull_ratio'>) => {
  const { data } = await axios.get<IPullRatioData>('/physics-calculations/pull-ratio', {
    params,
  });

  return data.value;
};
