import { useCallback, useContext } from 'react';
import { useIntl } from 'react-intl';

import { MeasuringSystemContext, MeasuringUnits } from './MeasuringSystemContext';

type Systems = {
  metric: string;
  imperial: string;
};
const useMeasuringUnitsIntl = () => {
  const { formatMessage } = useIntl();
  const { units } = useContext(MeasuringSystemContext);

  const formatMeasuringUnit = useCallback(
    ({ metric, imperial }: Systems) => {
      const translationKey = units === MeasuringUnits.Metric ? metric : imperial;

      return formatMessage({ id: translationKey });
    },
    [formatMessage, units],
  );

  return { formatMeasuringUnit };
};

export default useMeasuringUnitsIntl;
