import { ReactComponent as SvgWarning } from '@danfoss/mosaic-icons/dist/svg/error-outline.svg';
import components from '@danfoss/mosaic/css/components.module.css';
import alert from '@danfoss/mosaic/css/components/alert.module.css';
import button from '@danfoss/mosaic/css/components/button.module.css';
import input from '@danfoss/mosaic/css/components/input.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfLoader } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch } from 'configs/store';
import debounce from 'utils/debounce';
import ContactModal from 'views/base/ContactUs/ContactModal';
import Button from 'views/shared/components/Button/Button';
import Fieldset from 'views/shared/components/form/Fieldset';
import Input from 'views/shared/components/form/Input';
import Addon from 'views/shared/components/form/InputAddon';
import InputGroup from 'views/shared/components/form/InputGroup';
import Label from 'views/shared/components/form/Label/Label';
import RadioInput from 'views/shared/components/form/RadioInput';
import { IValidationRules } from 'views/shared/components/form/types';
import ConverterInput from 'views/shared/helpers/measuringSystem/ConverterInput';
import FormatMeasureUnit from 'views/shared/helpers/measuringSystem/FormatMeasureUnit';
import { OperationalEnvironment, IProjectSystemData } from 'views/shared/types';
import { updateProject } from 'views/wizard/shared/store/projectSlice';

import { IRequirementsForm } from '../../../types';
import FormError from '../../FormError';
import PrimeMoverModal from './PrimeMoverModal/PrimeMoverModal';
import styles from './SystemData.module.scss';

interface ISystemDataProps {
  form: UseFormReturn<IRequirementsForm>;
  onClose: () => void;
  data?: IProjectSystemData;
  validation: {
    [field in keyof IProjectSystemData]?: IValidationRules;
  };
  projectId: string;
  projectName: string;
  setIsValidNewName: (value: boolean) => void;
}

const useNewSelectionName = (
  projectId: string,
  projectName: string,
  setIsValidNewName: (value: boolean) => void,
) => {
  const [newSelectionName, setNewSelectionName] = useState(projectName);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const MAX_CHARACTERS = 25;

  const isValidNewName = (value: string) => {
    return !!value.trim().length;
  };

  const hasNameWarning = newSelectionName.length === MAX_CHARACTERS;

  const updateSelectionNameDebounce = useMemo(
    () =>
      debounce(async (value: string) => {
        try {
          setIsLoading(true);
          await dispatch(
            updateProject({
              id: projectId,
              project: {
                project_name: value.trim(),
              },
            }),
          ).unwrap();
        } finally {
          setIsLoading(false);
        }
      }, 1000),
    [projectId],
  );

  const handleSelectionNameChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { value } = target;

    if (value.length > MAX_CHARACTERS) {
      return;
    }

    const isValidCurrentValue = isValidNewName(value);

    setNewSelectionName(value);
    setIsValidNewName(isValidCurrentValue);

    if (isValidCurrentValue) {
      updateSelectionNameDebounce(value);
    }
  };

  const isNameValid = isValidNewName(newSelectionName);

  return {
    newSelectionName,
    hasNameWarning,
    isNameValid,
    handleSelectionNameChange,
    isLoading,
  };
};

const SystemData = ({
  form,
  data,
  onClose,
  validation,
  projectId,
  projectName,
  setIsValidNewName,
}: ISystemDataProps) => {
  const { formatMessage } = useIntl();
  const { newSelectionName, hasNameWarning, isNameValid, handleSelectionNameChange, isLoading } =
    useNewSelectionName(projectId, projectName, setIsValidNewName);
  const [isPrimeMoverModalOpen, setIsPrimeMoverModalOpen] = useState(false);
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const { control, formState, setValue, trigger } = form;
  const { errors } = formState;
  const formErrors = errors.systemData || {};
  const loadedWeightValue = useWatch({
    name: 'systemData.loaded_weight',
    control,
  });
  const unloadedWeightValue = useWatch({
    name: 'systemData.unloaded_weight',
    control,
  });
  const operationEnvironment = useWatch({
    name: 'systemData.operation_environment',
    control,
  });
  const isHighwayModeOn = operationEnvironment === OperationalEnvironment.Highway;

  const onPrimeMoverDetailsClick = () => {
    setIsPrimeMoverModalOpen(true);
  };
  const onClosePrimeMoverModal = () => {
    setIsPrimeMoverModalOpen(false);
  };

  const validateUnloadedWeight = (value?: number) => {
    if (value && loadedWeightValue && value > loadedWeightValue) {
      return formatMessage({ id: 'wizard_requirements_unloaded_weight_error_exceeds_loaded' });
    }
  };
  const isMinLoadedWeightExceeded = () => {
    const minLoadedWeight = 200.1;

    return loadedWeightValue ? loadedWeightValue < minLoadedWeight : false;
  };
  const isMaxLoadedWeightExceeded = () => {
    const maxLoadedWeight = 30000;

    return loadedWeightValue ? loadedWeightValue > maxLoadedWeight : false;
  };

  const onContactModalOpen = () => {
    setIsContactModalOpen(true);
  };
  const onContactModalClose = () => {
    setIsContactModalOpen(false);
  };

  useEffect(() => {
    if (loadedWeightValue && unloadedWeightValue) {
      trigger('systemData.unloaded_weight');
    }
  }, [loadedWeightValue, unloadedWeightValue]);

  useEffect(() => {
    const isUnloadedWeightDirty = formState.dirtyFields.systemData?.unloaded_weight;
    const isFormDirty = formState.isDirty;

    if (isFormDirty && !isUnloadedWeightDirty) {
      setValue('systemData.unloaded_weight', loadedWeightValue);
    }
  }, [loadedWeightValue]);

  useEffect(() => {
    if (data && Object.keys(data).length) {
      form.reset({ systemData: data });
    }
  }, []);

  return (
    <>
      <div className={utils.row}>
        <div
          className={cn(
            utils.offset1,
            utils.col11,
            utils.colMd7,
            core.flex,
            core.flexBetween,
            styles.title,
          )}
        >
          <h3>
            <FormattedMessage id="wizard_requirements_system_data_tab" />
          </h3>
          <Button
            type="button"
            onClick={onClose}
            className={cn(button.textBtn, button.textBtnPrimary)}
            data-testid="system-data-close-draft"
          >
            <FormattedMessage id="wizard_requirements_close_draft" />
          </Button>
        </div>
      </div>
      <div className={cn(utils.row, utils.mt8)} data-testid="system-data-form">
        <div className={cn(utils.offset1, utils.col4, utils.colMd4, utils.colSm7)}>
          <h5>
            <FormattedMessage id="wizard_requirements_prime_mover" />
            <button
              className={cn(utils.ml3, button.textBtn, button.textBtnPrimary)}
              type="button"
              onClick={onPrimeMoverDetailsClick}
              data-testid="prime-mover-details"
            >
              <FormattedMessage id="wizard_requirements_view_details" />
            </button>
          </h5>
          <Fieldset>
            <Label className={utils.mt6}>
              <span>
                <FormattedMessage id="wizard_requirements_hydraulic_traction_label" />
              </span>
            </Label>
            <InputGroup>
              <Controller
                control={control}
                name="systemData.hydraulic_dedicated_power"
                rules={validation.hydraulic_dedicated_power}
                render={({ field }) => (
                  <ConverterInput
                    type="number"
                    metric="kW"
                    imperial="hp"
                    data-testid="hydraulic_dedicated_power"
                    value={field.value ?? ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!formErrors.hydraulic_dedicated_power}
                    {...validation.hydraulic_dedicated_power}
                  />
                )}
              />
              <Addon>
                <FormatMeasureUnit
                  metric="measurements_unit_kw"
                  imperial="measurements_unit_horsepower"
                />
              </Addon>
            </InputGroup>
            <FormError
              errors={errors}
              rules={validation}
              metric="kW"
              imperial="hp"
              field="systemData.hydraulic_dedicated_power"
            />
          </Fieldset>
          <Fieldset>
            <Label className={utils.mt6}>
              <FormattedMessage id="wizard_requirements_at_engine_speed_label" />
            </Label>
            <InputGroup>
              <Input
                type="number"
                data-testid="engine_speed"
                error={!!formErrors.engine_speed}
                {...validation.engine_speed}
                {...form.register('systemData.engine_speed', {
                  ...validation.engine_speed,
                  valueAsNumber: true,
                })}
              />
              <Addon>
                <FormattedMessage id="measurements_unit_rpm" />
              </Addon>
            </InputGroup>
            <FormError errors={errors} rules={validation} field="systemData.engine_speed" />
          </Fieldset>
          <Fieldset>
            <Label className={utils.mt6}>
              <FormattedMessage id="wizard_requirements_system_pressure_limit_label" />
            </Label>
            <InputGroup>
              <Controller
                control={control}
                name="systemData.pressure_limit"
                rules={validation.pressure_limit}
                render={({ field }) => (
                  <ConverterInput
                    type="number"
                    metric="bar"
                    imperial="psi"
                    data-testid="pressure_limit"
                    value={field.value ?? ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!formErrors.pressure_limit}
                    {...validation.pressure_limit}
                  />
                )}
              />
              <Addon>
                <FormatMeasureUnit
                  metric="measurements_unit_bar"
                  imperial="measurements_unit_psi"
                />
              </Addon>
            </InputGroup>
            <FormError
              errors={errors}
              rules={validation}
              metric="bar"
              imperial="psi"
              field="systemData.pressure_limit"
            />
            <label className={core.helperText}>
              <FormattedMessage id="wizard_requirements_system_pressure_limit_help_text" />
            </label>
          </Fieldset>
          <div className={cn(utils.mt4, styles.input_wrapper)}>
            <Fieldset>
              <DfLoader isVisible={isLoading} data-testid="new-name-input-loader" />
              <Label>
                <FormattedMessage id="wizard_requirements_name_of_selection_label" />
              </Label>
              <input
                type="text"
                className={cn(input.input, {
                  [core.warning]: hasNameWarning,
                  [core.error]: !isNameValid,
                })}
                onChange={handleSelectionNameChange}
                value={newSelectionName}
                data-testid="rename-selection-input"
              />
              {!isNameValid && (
                <label
                  className={cn(core.helperText, core.error, styles.error)}
                  data-testid="empty-name-error"
                >
                  <FormattedMessage id="default_required_error" />
                </label>
              )}
              <label
                className={cn(core.helperText, { warning: hasNameWarning })}
                data-testid="rename-selection-helper"
              >
                <FormattedMessage id="wizard_requirements_name_of_selection_helper" />
              </label>
            </Fieldset>
          </div>
        </div>
        <div className={cn(utils.offset1, utils.col4, utils.colMd4, utils.colSm7)}>
          <h5 className={styles['machine-operation-title']}>
            <FormattedMessage id="wizard_requirements_machine_operation" />
          </h5>
          <Fieldset>
            <Label className={utils.mt6}>
              <FormattedMessage id="specs_loaded_machine_weight_label" />
            </Label>
            <InputGroup>
              <Controller
                control={control}
                name="systemData.loaded_weight"
                rules={validation.loaded_weight}
                render={({ field }) => (
                  <ConverterInput
                    type="number"
                    metric="kg"
                    imperial="lb"
                    data-testid="loaded_weight"
                    value={field.value ?? ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!formErrors.loaded_weight}
                    warning={isMinLoadedWeightExceeded() || isMaxLoadedWeightExceeded()}
                    {...validation.loaded_weight}
                  />
                )}
              />
              <Addon>
                <FormatMeasureUnit metric="measurements_unit_kg" imperial="measurements_unit_lbs" />
              </Addon>
            </InputGroup>
            <FormError
              errors={errors}
              rules={validation}
              metric="kg"
              imperial="lb"
              field="systemData.loaded_weight"
            />
            {isMinLoadedWeightExceeded() && (
              <label className={core.helperText} data-testid="loaded_weight-min-warning">
                <FormattedMessage id="tractive_force_validation_total_machine_wight_min_warning" />
              </label>
            )}
            {isMaxLoadedWeightExceeded() && (
              <label className={core.helperText} data-testid="loaded_weight-max-warning">
                <FormattedMessage id="tractive_force_validation_total_machine_wight_max_warning" />
              </label>
            )}
          </Fieldset>
          <Fieldset>
            <Label className={utils.mt6}>
              <FormattedMessage id="specs_unloaded_machine_weight_label" />
            </Label>
            <InputGroup>
              <Controller
                control={control}
                name="systemData.unloaded_weight"
                rules={{
                  ...validation.unloaded_weight,
                  validate: validateUnloadedWeight,
                }}
                render={({ field }) => (
                  <ConverterInput
                    type="number"
                    metric="kg"
                    imperial="lb"
                    data-testid="unloaded_weight"
                    value={field.value ?? ''}
                    onChange={field.onChange}
                    onBlur={field.onBlur}
                    error={!!formErrors.unloaded_weight}
                    {...validation.unloaded_weight}
                  />
                )}
              />
              <Addon>
                <FormatMeasureUnit metric="measurements_unit_kg" imperial="measurements_unit_lbs" />
              </Addon>
            </InputGroup>
            <FormError
              errors={errors}
              rules={validation}
              metric="kg"
              imperial="lb"
              field="systemData.unloaded_weight"
            />
          </Fieldset>
          <div>
            <Label className={utils.mt6}>
              <FormattedMessage id="specs_operation_environment_label" />
            </Label>
            <Fieldset
              className={cn(
                components.radioGroup,
                components.inline,
                utils.mt3,
                styles['operation-environment-options'],
              )}
            >
              <RadioInput
                id="operational-environment-highway"
                value={OperationalEnvironment.Highway}
                data-testid="operational-environment-highway"
                {...form.register('systemData.operation_environment')}
              >
                <FormattedMessage id="specs_operation_environment_highway_option" />
              </RadioInput>
              <RadioInput
                id="operational-environment-off-highway"
                value={OperationalEnvironment.OffHighway}
                data-testid="operational-environment-off-highway"
                {...form.register('systemData.operation_environment')}
              >
                <FormattedMessage id="specs_operation_environment_off_highway_option" />
              </RadioInput>
            </Fieldset>
          </div>
        </div>
        {isHighwayModeOn && (
          <div className={cn(utils.offset1, utils.col9, utils.colMd7, utils.colSm7)}>
            <div
              className={cn(alert.alert, alert.alertWarning, utils.mt8)}
              data-testid="operation-environment-warning"
            >
              <SvgWarning className={cn(core.icon, alert.alertIcon)} />
              <div className={cn(alert.alertMessage, core.flexColumn)}>
                <p className={alert.alertHeading}>
                  <FormattedMessage id="wizard_requirements_operation_environment_warning_title" />
                </p>
                <p>
                  <FormattedMessage
                    id="wizard_requirements_operation_environment_warning_text"
                    values={{
                      linebreak: <br />,
                    }}
                  />
                  <button
                    className={cn(button.textBtn, button.textBtnPrimary, utils.ml2)}
                    data-testid="contact-modal-button"
                    type="button"
                    onClick={onContactModalOpen}
                  >
                    <FormattedMessage id="wizard_requirements_operation_environment_warning_button" />
                  </button>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      {isPrimeMoverModalOpen && <PrimeMoverModal onClose={onClosePrimeMoverModal} />}
      {isContactModalOpen && <ContactModal onClose={onContactModalClose} projectId={projectId} />}
    </>
  );
};

export default SystemData;
