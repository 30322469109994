import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfLoader } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import helpers from 'styles/helpers.module.scss';

import { useAppDispatch, useAppSelector } from 'configs/store';
import { AdditionalMotors, ISelectedMotor, MotorType } from 'views/shared/types';
import { useMotorTypeContext } from 'views/wizard/product-selection/motor-type-context/motorTypeContext';
import { showOverSpeedingWarning } from 'views/wizard/shared/helpers/showOverSpeedingWarning';

import {
  autoUpdateProducts,
  getCalculations,
  getCurrent,
  getMotorDetailsAndCalculations,
  getPropelSizingData,
  getSelectedMotors,
  getSelectedPumps,
  setKeepPumpSelected,
} from '../../../../shared/store/projectSlice';
import MotorTypeSelection from '../../Motors/components/MotorTypeSelection';
import { FinalDriveEfficiency } from '../components/EditableAttributes/FinalDriveEfficiency';
import { MultipleMotors } from '../components/MultipleMotors/MultipleMotors';
import { Summary, ISummaryProps } from '../components/Summary/Summary';

export function MultipleMotorsPropelSystem() {
  const dispatch = useAppDispatch();

  const project = useAppSelector(getCurrent);
  const propelSizing = useAppSelector(getPropelSizingData)!;
  const selectedMotors = useAppSelector(getSelectedMotors)!;
  const selectedPumps = useAppSelector(getSelectedPumps);
  const calculations = useAppSelector(getCalculations);

  const { motorType, setMotorType } = useMotorTypeContext();

  const [isSectionUpdating, setIsSectionUpdating] = useState(false);
  const [{ final_drive_efficiency: finalDriveEfficiency, type }] = selectedMotors;
  const [hasWidgetsErrors, setHasWidgetsErrors] = useState(false);

  useEffect(() => {
    if (!motorType) {
      setMotorType(type);
    }
  }, [type, motorType, setMotorType]);

  const pump = selectedPumps?.[0];
  const { id: projectId } = project;
  const { recommended_motor_type } = propelSizing;

  const onAttributesUpdate = (isUpdating: boolean, isValid: boolean) => {
    setIsSectionUpdating(isUpdating);
    setHasWidgetsErrors(!isValid);
  };

  const clearMotors = async (selectedMotorType?: MotorType) => {
    try {
      setIsSectionUpdating(true);

      const { pumps, warning } = await dispatch(
        autoUpdateProducts({
          projectId,
          displacementType: selectedMotorType,
          additionalMotors: (selectedMotors.length - 2) as AdditionalMotors,
        }),
      ).unwrap();

      showOverSpeedingWarning(warning, projectId);

      const pumpsId = pumps.map(item => item.selected_pump.product_id);

      await dispatch(getMotorDetailsAndCalculations({ projectId, pumpsId })).unwrap();
    } finally {
      setIsSectionUpdating(false);
    }
  };

  const [tractiveForceActual, tractiveForceRequired] = selectedMotors.reduce(
    ([actual, required], { tractive_force_actual, tractive_force_required }) => {
      return [actual + (tractive_force_actual ?? 0), required + tractive_force_required];
    },
    [0, 0],
  );
  const tractiveForce: ISummaryProps['tractiveForce'] = {
    actual: tractiveForceActual,
    required: tractiveForceRequired,
  };

  const onAdd = async () => {
    setIsSectionUpdating(true);

    try {
      const currentAdditionalMotors = selectedMotors.length - 2;

      const { pumps, warning } = await dispatch(
        autoUpdateProducts({
          projectId,
          displacementType: motorType!,
          additionalMotors: (currentAdditionalMotors + 1) as AdditionalMotors,
        }),
      ).unwrap();

      showOverSpeedingWarning(warning, projectId);

      const pumpsId = pumps.map(({ selected_pump: { product_id } }) => product_id);

      await dispatch(getMotorDetailsAndCalculations({ projectId, pumpsId })).unwrap();
    } finally {
      setIsSectionUpdating(false);
    }
  };

  const onRemove = async () => {
    setIsSectionUpdating(true);

    try {
      const currentAdditionalMotors = selectedMotors.length - 2;

      const { pumps, warning } = await dispatch(
        autoUpdateProducts({
          projectId,
          displacementType: motorType!,
          additionalMotors: (currentAdditionalMotors - 1) as AdditionalMotors,
        }),
      ).unwrap();

      showOverSpeedingWarning(warning, projectId);

      const pumpsId = pumps.map(({ selected_pump: { product_id } }) => product_id);

      await dispatch(getMotorDetailsAndCalculations({ projectId, pumpsId })).unwrap();
    } finally {
      setIsSectionUpdating(false);
    }
  };

  const onKeepPumpSelectedChange = ({ target }: React.FormEvent<HTMLInputElement>) => {
    const { id: pumpId, product_id } = pump!;

    const getMotorDisplacement = ({ id, min_displacement }: ISelectedMotor) => ({
      id,
      min_displacement,
    });

    const displacements =
      motorType === MotorType.VariablePiston ? selectedMotors.map(getMotorDisplacement) : [];

    dispatch(
      setKeepPumpSelected({
        pump: { id: pumpId, product_id },
        keepPump: (target as HTMLInputElement).checked,
        displacements,
      }),
    );
  };

  const areFDEsEqual =
    new Set(selectedMotors.map(({ final_drive_efficiency }) => final_drive_efficiency)).size === 1;

  return (
    <section
      className={cn(helpers.loaderWrapper, utils.mt10)}
      data-testid="multiple-motors-propel-system"
    >
      <DfLoader isVisible={isSectionUpdating}>
        <h3>
          <FormattedMessage id="wizard_product_selected_propel_system" />
        </h3>
        <MotorTypeSelection
          motorTypeWarning={recommended_motor_type?.warning}
          isCustomFlow={false}
          onMotorTypeForcedChange={clearMotors}
          isMotorSelected
        />

        {areFDEsEqual && (
          <FinalDriveEfficiency
            className={utils.mt5}
            compact
            finalDriveEfficiency={finalDriveEfficiency}
          />
        )}

        <MultipleMotors
          className={utils.mt5}
          pump={pump}
          motors={selectedMotors}
          projectId={projectId}
          hasWidgetsErrors={hasWidgetsErrors}
          onAttributesUpdate={onAttributesUpdate}
          areFDEsEqual={areFDEsEqual}
          onAdd={onAdd}
          onRemove={onRemove}
          onKeepPumpSelectedChange={onKeepPumpSelectedChange}
        />

        <Summary className={utils.mt6} calculations={calculations} tractiveForce={tractiveForce} />
      </DfLoader>
    </section>
  );
}
