import checkbox from '@danfoss/mosaic/css/components/checkbox.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { HTMLProps } from 'react';

import styles from './WizardOption.module.scss';

const WizardOption = ({ children, checked, id, ...props }: HTMLProps<HTMLInputElement>) => {
  const containerClasses = cn(
    styles.container,
    utils.px6,
    utils.py6,
    core.flex,
    core.alignCenter,
    checkbox.checkbox,
    {
      [styles.selected]: checked,
    },
  );

  return (
    <label htmlFor={id} className={styles['wrapper-label']} data-testid="wizardoption-label">
      <div className={containerClasses} data-testid="wizardoption-container">
        <input
          type="checkbox"
          data-testid="wizardoption-input"
          checked={checked}
          id={id}
          {...props}
        />
        <div className={cn(styles.label, core.flex, core.alignCenter, utils.ml4)}>{children}</div>
      </div>
    </label>
  );
};

export default WizardOption;
