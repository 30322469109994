import { ReactComponent as SvgPersonSolid } from '@danfoss/mosaic-icons/dist/svg/person.svg';
import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';

import { getAppConfig } from 'configs/global';

import styles from '../Header.module.scss';
import { ProfileContextMenuProps } from '../types';

const ProfileContextMenuSF = ({ handleContextToggle }: ProfileContextMenuProps) => {
  const appConfig = getAppConfig();

  const SFProfileURL = `${appConfig.DC_URL}/s/`;
  const SFLogoutURL = `${appConfig.DC_URL}/secur/logout.jsp`;

  return (
    <>
      <span
        className={cn(utils.colorSurfaceBase, styles['context-button'])}
        data-testid="user-profile-icon"
        onClick={handleContextToggle}
      >
        <SvgPersonSolid className={core.icon} />
      </span>
      {/* "df-context-menu-overlay align-right" needed here because DfHeader has nested structure which relies on this classes */}
      <ul className="df-context-menu-overlay align-right">
        <li>
          <a
            className={cn(button.linkSecondary, core.textBold)}
            href={SFProfileURL}
            target="_blank"
            rel="noreferrer"
            data-testid="context-menu-sf-profile-link"
          >
            Back to Design Center
          </a>
        </li>
        <li className={utils.mt2}>
          <a
            className={cn(button.linkSecondary, core.textBold)}
            href={SFLogoutURL}
            data-testid="context-menu-sf-logout-button"
          >
            Log out
          </a>
        </li>
      </ul>
    </>
  );
};

export default ProfileContextMenuSF;
