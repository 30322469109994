import { useEffect } from 'react';

const useAppVersion = () => {
  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('%c%s', 'font-size: 18px', `MFD-WEB VERSION: ${process.env.REACT_APP_VERSION!}`);
  }, []);
};

export default useAppVersion;
