import { FormattedMessage } from 'react-intl';

import Label from 'views/shared/components/form/Label/Label';
import FormatMeasureUnit from 'views/shared/helpers/measuringSystem/FormatMeasureUnit';
import FormatMeasureValue from 'views/shared/helpers/measuringSystem/FormatMeasureValue';
import { ICalculations } from 'views/shared/types';

import StaticAttribute from '../../components/widgets/components/StaticAttributes/StaticAttribute';

interface IVehicleSpeedProps {
  data: ICalculations;
  hasGearbox?: boolean;
}

const VehicleSpeed = ({ data, hasGearbox }: IVehicleSpeedProps) => {
  const { vehicle_work_speed, vehicle_work_speed2, vehicle_travel_speed, vehicle_travel_speed2 } =
    data;
  const workSpeedLabel = hasGearbox
    ? 'wizard_product_vehicle_speed_widget_work_speed_gear1'
    : 'wizard_product_vehicle_speed_widget_work_speed';
  const travelSpeedLabel = hasGearbox
    ? 'wizard_product_vehicle_speed_widget_travel_speed_gear1'
    : 'wizard_product_vehicle_speed_widget_travel_speed';

  return (
    <>
      <Label>
        <FormattedMessage id="wizard_product_vehicle_speed_widget_title" />
      </Label>
      <div>
        <StaticAttribute
          name={<FormattedMessage id={workSpeedLabel} />}
          value={
            <>
              <FormatMeasureValue value={vehicle_work_speed!} metric="km/h" imperial="mph" />{' '}
              <FormatMeasureUnit metric="measurements_unit_kmh" imperial="measurements_unit_mph" />
            </>
          }
          testId="work-speed"
        />
        <StaticAttribute
          name={<FormattedMessage id={travelSpeedLabel} />}
          value={
            <>
              <FormatMeasureValue value={vehicle_travel_speed!} metric="km/h" imperial="mph" />{' '}
              <FormatMeasureUnit metric="measurements_unit_kmh" imperial="measurements_unit_mph" />
            </>
          }
          testId="travel-speed"
        />
        {hasGearbox && (
          <>
            <StaticAttribute
              name={<FormattedMessage id="wizard_product_vehicle_speed_widget_work_speed_gear2" />}
              value={
                <>
                  <FormatMeasureValue value={vehicle_work_speed2!} metric="km/h" imperial="mph" />{' '}
                  <FormatMeasureUnit
                    metric="measurements_unit_kmh"
                    imperial="measurements_unit_mph"
                  />
                </>
              }
              testId="work-speed2"
            />
            <StaticAttribute
              name={
                <FormattedMessage id="wizard_product_vehicle_speed_widget_travel_speed_gear2" />
              }
              value={
                <>
                  <FormatMeasureValue value={vehicle_travel_speed2!} metric="km/h" imperial="mph" />{' '}
                  <FormatMeasureUnit
                    metric="measurements_unit_kmh"
                    imperial="measurements_unit_mph"
                  />
                </>
              }
              testId="travel-speed2"
            />
          </>
        )}
      </div>
    </>
  );
};

export default VehicleSpeed;
