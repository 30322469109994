import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfLoader } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { memo, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppDispatch } from 'configs/store';
import debounce from 'utils/debounce';
import { useTractiveForceFormatter } from 'views/shared/helpers/formatters';
import FormatMeasureUnit from 'views/shared/helpers/measuringSystem/FormatMeasureUnit';
import FormatMeasureValue from 'views/shared/helpers/measuringSystem/FormatMeasureValue';
import { IGearBoxValues, IMotorToUpdate, ISelectedMotor, MotorType } from 'views/shared/types';
import { useMotorTypeContext } from 'views/wizard/product-selection/motor-type-context/motorTypeContext';
import ConfirmationModal from 'views/wizard/shared/components/ConfirmationModal';
import useConfirmationModal from 'views/wizard/shared/helpers/useConfirmationModal';
import {
  getSelectedProducts,
  updateMotor,
  getMotorDetailsAndCalculations,
} from 'views/wizard/shared/store/projectSlice';

import widgetStyles from '../../../components/widgets/Widget.module.scss';
import Header from '../../../components/widgets/components/Header/Header';
import StaticAttribute from '../../../components/widgets/components/StaticAttributes/StaticAttribute';
import { useTractiveForceDifferenceFormatter } from '../../../components/widgets/helpers/formatters';
import AddGearBox from '../GearBox/AddGearBox';
import GearBox from '../GearBox/GearBox';
import motorWidgetStyles from './MotorWidget.module.scss';
import EditableAttributes from './components/EditableAttributes/EditableAttributes';

interface MotorWidgetProps {
  data: ISelectedMotor;
  onChange: (id: string) => void;
  onRemove?: (id: string) => void;
  onValidation: (id: string, hasError: boolean) => void;
  isCustomFlow: boolean;
  isPumpAdded: boolean;
  isSpeedGearboxAvailable: boolean;
  projectId: string;
}

const defaultValues = {
  gear_ratio_travel: 1,
  gear_ratio_work: 4,
  mechanical_reduction: 15.426,
};

const MotorWidget = ({
  data,
  onRemove,
  onChange,
  onValidation,
  isCustomFlow,
  isPumpAdded,
  isSpeedGearboxAvailable,
  projectId,
}: MotorWidgetProps) => {
  const dispatch = useAppDispatch();
  const [isUpdating, setIsUpdating] = useState(false);
  const { formatMessage } = useIntl();
  const formatTractiveForce = useTractiveForceFormatter();
  const formatTractiveForceDifference = useTractiveForceDifferenceFormatter();
  const {
    id,
    name,
    series,
    product_id,
    rolling_radius,
    final_drive_ratio,
    final_drive_efficiency,
    tractive_force_actual,
    tractive_force_required,
    weight_distribution,
    min_displacement,
    motor_speed,
    is_motor_overspeeding,
    speed_limit_rated_for_displacement,
    photo_url,
    related_motor_info,
  } = data;
  const { motorType } = useMotorTypeContext();
  const isReadOnly = isCustomFlow && isPumpAdded;

  const [isGearBoxEnabled, setGearBoxMode] = useState(data.is2_gear_box_mode);
  const [hasGearBoxErrors, setHasGearBoxErrors] = useState(false);

  const [rollingRadius, setRollingRadius] = useState(rolling_radius);
  const [hasRollingRadiusError, setHasRollingRadiusError] = useState(false);

  const [finalDriveRatio, setFinalDriveRatio] = useState(final_drive_ratio);
  const [hasDriveRatioError, setHasDriveRatioError] = useState(false);

  const hasAnyErrors = hasRollingRadiusError || hasDriveRatioError || hasGearBoxErrors;

  const [gearBoxValues, setGearBoxValues] = useState<IGearBoxValues | undefined>();

  const getMotorSpeed = (value: number, hasError: boolean) => (
    <div className={cn(hasError && 'error')} data-testid="motor-speed">
      {`${value.toFixed()} ${formatMessage({ id: 'measurements_unit_rpm' })}`}
    </div>
  );
  const getMinDisplacement = (value: number) => (
    <div data-testid="motor-details-min-displacement">
      <FormatMeasureValue metric="cm3" imperial="in3" value={value} />{' '}
      <FormatMeasureUnit metric="measurements_unit_cm3_rev" imperial="measurements_unit_in3_rev" />
    </div>
  );

  const motorSpeed = motor_speed ? getMotorSpeed(motor_speed, is_motor_overspeeding) : null;
  const isOverspeeding = is_motor_overspeeding;
  const minDisplacement = min_displacement ? getMinDisplacement(min_displacement) : null;
  const ratedSpeed = speed_limit_rated_for_displacement?.toFixed();

  const overspeedingHelpText = (
    <div className={core.error}>
      {formatMessage({ id: 'wizard_product_motor_widget_motor_speed_overspeeding' })}
    </div>
  );

  const mainSpec = (
    <span data-testid="motor-spec">
      <FormatMeasureValue metric="cm3" imperial="in3" value={related_motor_info.max_displacement} />{' '}
      <FormatMeasureUnit metric="measurements_unit_cm3_rev" imperial="measurements_unit_in3_rev" />
    </span>
  );
  const finalDriveEfficiency = `${final_drive_efficiency}%`;
  const weightDistribution = `${weight_distribution}%`;

  const actualTractiveForce = formatTractiveForce(tractive_force_actual);
  const requiredTractiveForce = formatTractiveForce(tractive_force_required);
  const differenceTractiveForce = formatTractiveForceDifference(
    tractive_force_actual,
    tractive_force_required,
  );

  const updateMotorData = async (updatedData: IMotorToUpdate) => {
    try {
      setIsUpdating(true);
      await dispatch(
        updateMotor({
          motor: updatedData,
        }),
      ).unwrap();

      if (!isCustomFlow) {
        const products = await dispatch(getSelectedProducts(projectId)).unwrap();
        const pumpsId = products.pumps.map(pump => pump.selected_pump.product_id);

        await dispatch(getMotorDetailsAndCalculations({ projectId, pumpsId })).unwrap();
      }
    } finally {
      setIsUpdating(false);
    }
  };
  const updateSpeedGearbox = async (values?: IGearBoxValues) => {
    const motorToUpdate: IMotorToUpdate = {
      id,
      product_id,
      rolling_radius: rollingRadius,
      gear_box_stats: values,
    };

    await updateMotorData(motorToUpdate);
    setGearBoxValues(values);
  };
  const onGearBoxValuesChange = (values: IGearBoxValues, isValid: boolean) => {
    setGearBoxValues(values);

    if (hasRollingRadiusError || hasDriveRatioError || !isValid) {
      return;
    }

    updateSpeedGearbox(values);
  };
  const onGearBoxValuesChangeDebounced = useMemo(
    () => debounce(onGearBoxValuesChange, 1000),
    [hasRollingRadiusError, hasDriveRatioError, rollingRadius],
  );

  const {
    props: removeGearBoxConfirmationModalProps,
    open: openRemoveGearBoxConfirmationModal,
    isOpen: isRemoveGearBoxConfirmationModalOpen,
  } = useConfirmationModal(async () => {
    await updateSpeedGearbox(undefined);
    setGearBoxMode(false);
  });
  const {
    props: addGearBoxConfirmationModalProps,
    open: openAddGearBoxConfirmationModal,
    isOpen: isAddGearBoxConfirmationModalOpen,
  } = useConfirmationModal(async () => {
    await updateSpeedGearbox(defaultValues);
    setGearBoxMode(true);
  });
  const onAddGearboxClick = async () => {
    if (isPumpAdded) {
      openAddGearBoxConfirmationModal();
    } else {
      await updateSpeedGearbox(defaultValues);
      setGearBoxMode(true);
      setHasDriveRatioError(false);
    }
  };
  const onRemoveGearboxClick = async () => {
    if (isPumpAdded) {
      openRemoveGearBoxConfirmationModal();
    } else {
      await updateSpeedGearbox(undefined);
      setGearBoxMode(false);
      setHasGearBoxErrors(false);
    }
  };

  const onRollingRadiusChange = (value: number, isValid: boolean) => {
    const motorToUpdate: IMotorToUpdate = {
      id,
      product_id,
      rolling_radius: value,
    };

    setRollingRadius(value);

    if (hasGearBoxErrors || !isValid) {
      return;
    }

    if (gearBoxValues) {
      motorToUpdate.gear_box_stats = gearBoxValues;
    } else {
      motorToUpdate.final_drive_ratio = hasDriveRatioError ? undefined : finalDriveRatio;
    }

    updateMotorData(motorToUpdate);
  };
  const onRollingRadiusChangeDebounced = useMemo(
    () => debounce(onRollingRadiusChange, 1000),
    [hasGearBoxErrors, hasDriveRatioError, gearBoxValues, finalDriveRatio],
  );
  const onDriveRatioChange = async (value: number, isValid: boolean) => {
    setFinalDriveRatio(value);

    if (hasGearBoxErrors || hasRollingRadiusError || !isValid) {
      return;
    }

    updateMotorData({
      id,
      product_id,
      rolling_radius: rollingRadius,
      final_drive_ratio: value,
    });
  };
  const onDriveRatioChangeDebounced = useMemo(
    () => debounce(onDriveRatioChange, 1000),
    [hasGearBoxErrors, hasRollingRadiusError, rollingRadius],
  );

  useEffect(() => {
    onValidation(data.id, hasAnyErrors);
  }, [hasAnyErrors]);

  useEffect(() => {
    return () => {
      onValidation(data.id, false);
    };
  }, []);

  return (
    <div className={utils.row}>
      <div
        className={cn(
          isSpeedGearboxAvailable && cn(utils.col3, utils.colMd4, utils.colSm8),
          isSpeedGearboxAvailable && motorWidgetStyles.widget,
        )}
        data-testid="motor-widget"
      >
        <DfLoader isVisible={isUpdating} data-testid="motor-widget-loader">
          <Header
            id={id}
            name={name}
            series={series}
            spec={mainSpec}
            photoUrl={photo_url}
            photoAlt={formatMessage({ id: 'wizard_product_motor_widget_image_alt' })}
            onChange={onChange}
            onRemove={onRemove}
            isReadOnly={false}
          />
          <EditableAttributes
            isReadOnly={isReadOnly}
            rollingRadius={rolling_radius}
            onRollingRadiusChange={onRollingRadiusChangeDebounced}
            onRollingRadiusValidation={setHasRollingRadiusError}
            driveRatio={final_drive_ratio}
            onDriveRatioChange={onDriveRatioChangeDebounced}
            onDriveRatioValidation={setHasDriveRatioError}
            isFinalDriveRatioHidden={isGearBoxEnabled}
          />
          <div className={utils.mb5}>
            <StaticAttribute
              name={formatMessage({ id: 'wizard_product_motor_widget_drive_efficiency' })}
              testId="final-drive-efficiency"
              value={finalDriveEfficiency}
              boldValue
            />
            {!isSpeedGearboxAvailable && (
              <StaticAttribute
                name={formatMessage({ id: 'wizard_product_motor_widget_weight_distribution' })}
                testId="weight-distribution"
                value={weightDistribution}
                boldValue
              />
            )}
          </div>
          <div>
            <p className={cn(core.textBold, utils.mb2)}>
              <FormattedMessage id="wizard_product_motor_widget_tractive_force" />
            </p>
            <div>
              <StaticAttribute
                name={formatMessage({ id: 'wizard_product_motor_widget_actual' })}
                value={actualTractiveForce}
                testId="actual-tractive-force"
              />
              <StaticAttribute
                name={formatMessage({ id: 'wizard_product_motor_widget_required' })}
                value={requiredTractiveForce}
                testId="required-tractive-force"
              />
              <StaticAttribute
                name={formatMessage({ id: 'wizard_product_motor_widget_difference' })}
                value={differenceTractiveForce}
                testId="difference-tractive-force"
                boldTitle
                boldValue
              />
            </div>
          </div>
          {isPumpAdded && (
            <div className={utils.my5}>
              <p className={utils.mb2}>
                <span className={cn(core.textBold, utils.mr2)}>
                  <FormattedMessage id="wizard_product_motor_widget_motor_speed_title" />
                </span>
                {!!ratedSpeed && (
                  <span data-testid="motor-rated-speed">
                    <FormattedMessage id="wizard_product_motor_widget_rated_speed" /> {ratedSpeed}{' '}
                    <FormattedMessage id="measurements_unit_rpm" />
                  </span>
                )}
              </p>
              <div>
                {motorType === MotorType.VariablePiston && (
                  <StaticAttribute
                    name={formatMessage({ id: 'wizard_product_motor_widget_min_displacement' })}
                    value={minDisplacement}
                  />
                )}
                <StaticAttribute
                  name={formatMessage({ id: 'wizard_product_motor_widget_motor_speed' })}
                  value={motorSpeed}
                />
                {isOverspeeding && (
                  <div className={widgetStyles['widget-section']} data-testid="motor-overspeeding">
                    {overspeedingHelpText}
                  </div>
                )}
              </div>
            </div>
          )}
        </DfLoader>
      </div>
      {isSpeedGearboxAvailable && !isGearBoxEnabled && (
        <div
          className={cn(utils.col2, utils.colMd4, utils.colSm8, motorWidgetStyles['gear-box-item'])}
        >
          <AddGearBox onClick={onAddGearboxClick} disabled={isUpdating} />
        </div>
      )}
      {isSpeedGearboxAvailable && isGearBoxEnabled && (
        <div
          className={cn(utils.col2, utils.colMd4, utils.colSm8, motorWidgetStyles['gear-box-item'])}
        >
          <GearBox
            data={data}
            onRemove={onRemoveGearboxClick}
            onValuesChange={onGearBoxValuesChangeDebounced}
            onValidation={setHasGearBoxErrors}
            isUpdating={isUpdating}
            isReadonly={isPumpAdded}
          />
        </div>
      )}
      {isRemoveGearBoxConfirmationModalOpen && (
        <ConfirmationModal
          headingLabel={formatMessage({
            id: 'wizard_product_remove_gearbox_confirmation_modal_heading',
          })}
          confirmLabel={formatMessage({
            id: 'wizard_product_remove_gearbox_confirmation_modal_change_button',
          })}
          {...removeGearBoxConfirmationModalProps}
        >
          <FormattedMessage id="wizard_product_remove_gearbox_confirmation_modal_text" />
        </ConfirmationModal>
      )}
      {isAddGearBoxConfirmationModalOpen && (
        <ConfirmationModal
          headingLabel={formatMessage({
            id: 'wizard_product_add_gearbox_confirmation_modal_heading',
          })}
          confirmLabel={formatMessage({
            id: 'wizard_product_add_gearbox_confirmation_modal_change_button',
          })}
          {...addGearBoxConfirmationModalProps}
        >
          <FormattedMessage id="wizard_product_add_gearbox_confirmation_modal_text" />
        </ConfirmationModal>
      )}
    </div>
  );
};

export default memo(MotorWidget);
