import { useCallback, useRef, useState } from 'react';

import { IConfirmationModalLogic } from '../components/ConfirmationModal';

type IConfirmationFunction<T> = (lastSelectedValue: T) => void | Promise<void>;

const useConfirmationModal = <T>(confirmationFunction: IConfirmationFunction<T>) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);

  const ref = useRef<T | null>(null);

  const open = useCallback((valueToRemember?: T) => {
    if (valueToRemember !== undefined) {
      ref.current = valueToRemember;
    }

    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    ref.current = null;

    setIsOpen(false);
  }, []);

  const onChange = useCallback(async () => {
    try {
      setIsUpdating(true);

      await confirmationFunction(ref.current as T);
    } finally {
      setIsUpdating(false);

      onClose();
    }
  }, [confirmationFunction, onClose]);

  return {
    props: {
      isUpdating,
      onClose,
      onChange,
    } as IConfirmationModalLogic,
    open,
    isOpen,
  };
};

export default useConfirmationModal;
