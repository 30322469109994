import { ReactComponent as SvgMore } from '@danfoss/mosaic-icons/dist/svg/more-vert.svg';
import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfContextMenu, DfLoader, DfMenuItem } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { memo, useContext, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { trackEvent } from 'utils/analytics';
import { IProjectSelection } from 'views/shared/api/selections';
import Checkbox from 'views/shared/components/form/Checkbox';
import { functionDescription } from 'views/shared/constants';
import { AuthContext, AuthType } from 'views/shared/helpers/auth/AuthContext';
import { sendToDC } from 'views/shared/helpers/designCenterService';
import { ProjectStatus } from 'views/shared/types';

import styles from './Selection.module.scss';

interface SelectionProps {
  data: IProjectSelection;
  onDuplicate: (id: string) => Promise<void>;
  onDelete: (id: IProjectSelection) => Promise<void>;
  onShare: (id: string) => void;
  onRename: (selection: IProjectSelection) => void;
  onCompare: (id: string) => void;
}

const projectStatusDescriptions = {
  [ProjectStatus.Draft]: 'home_selection_status_draft',
  [ProjectStatus.Configured]: 'home_selection_status_configured',
  [ProjectStatus.CalculationsCompleted]: 'home_selection_status_draft',
  [ProjectStatus.Deprecated]: 'home_selection_status_deprecated',
};

const Selection = ({
  data,
  onDuplicate,
  onDelete,
  onShare,
  onRename,
  onCompare,
}: SelectionProps) => {
  const history = useHistory();
  const { authType } = useContext(AuthContext);
  const { formatDate, formatMessage } = useIntl();
  const [isContextMenuOpen, setIsContextMenuOpen] = useState(false);
  const [isSendingToDC, setIsSendingToDC] = useState(false);

  const handleContextMenuIconClick = () => setIsContextMenuOpen(true);
  const handleContextMenuClose = () => setIsContextMenuOpen(false);

  const formattedModifiedDate = formatDate(data.modified_at);
  const functionsLabel = data.function_codes
    .map(func => {
      return formatMessage({ id: functionDescription[func] });
    })
    .join(', ');
  const projectStatusLabel = formatMessage({ id: projectStatusDescriptions[data.project_status] });

  const isSendToDCAvailable =
    data.project_status === ProjectStatus.Configured && authType === AuthType.SF;
  const isCompareAvailable = data.project_status === ProjectStatus.Configured;

  const handleDuplicateSelection = () => {
    handleContextMenuClose();
    onDuplicate(data.id);
  };
  const handleDeleteSelection = () => {
    handleContextMenuClose();
    onDelete(data);
  };
  const handleOpenSelection = () => {
    const { project_status, id } = data;

    if (project_status === ProjectStatus.Draft) {
      history.push(`/requirements/${id}`);
    }

    if (project_status === ProjectStatus.CalculationsCompleted) {
      history.push(`/product-selection/${id}`);
    }

    if (project_status === ProjectStatus.Configured) {
      history.push(`/summary/${data.id}`);
    }
  };
  const handleSendToDC = async () => {
    handleContextMenuClose();

    try {
      setIsSendingToDC(true);
      await sendToDC(data.id);
    } finally {
      setIsSendingToDC(false);
    }
  };
  const handleShare = () => {
    trackEvent({ event: 'share_selection', selectionId: data.id, pageType: 'home' });
    handleContextMenuClose();
    onShare(data.id);
  };
  const handleRenameSelection = () => {
    handleContextMenuClose();
    onRename(data);
  };

  return (
    <div
      className={cn(utils.mb6, utils.py5, utils.px4, core.flex, core.flexColumn, styles.container)}
      data-testid={`selection-${data.id}`}
    >
      <div className={cn(core.flex, core.flexBetween, styles['top-section'])}>
        {!isCompareAvailable && <span data-testid="selection-status">{projectStatusLabel}</span>}
        {isCompareAvailable && (
          <Checkbox id={data.id} onChange={() => onCompare(data.id)} data-testid="compare-checkbox">
            <FormattedMessage id="home_selections_compare_checkbox" />
          </Checkbox>
        )}
        <DfContextMenu
          posX={18}
          posY={18}
          onOutsideClick={handleContextMenuClose}
          className={styles['context-menu']}
          data-testid="selection-context-menu"
          isRightAsXPosition
          isOpen={isContextMenuOpen}
        >
          <DfMenuItem
            label={formatMessage({ id: 'home_selection_menu_open' })}
            onClick={handleOpenSelection}
            data-testid="selection-context-menu-open-option"
          />
          <DfMenuItem
            label={formatMessage({ id: 'home_selection_menu_rename' })}
            onClick={handleRenameSelection}
            data-testid="selection-context-menu-rename-option"
          />
          <DfMenuItem
            label={formatMessage({ id: 'home_selection_menu_share' })}
            onClick={handleShare}
            data-testid="selection-context-menu-share-option"
          />
          <DfMenuItem
            label={formatMessage({ id: 'home_selection_menu_duplicate' })}
            onClick={handleDuplicateSelection}
            data-testid="selection-context-menu-duplicate-option"
          />
          <div
            style={{ display: isSendToDCAvailable ? 'block' : 'none' }}
            data-testid="selection-context-menu-send-wrapper"
          >
            <DfMenuItem
              label={formatMessage({ id: 'transfer_selection_button' })}
              data-testid="selection-context-menu-send-option"
              onClick={handleSendToDC}
            />
          </div>
          <DfMenuItem
            itemClassName={utils.colorAccentBase}
            label={formatMessage({ id: 'home_selection_menu_delete' })}
            onClick={handleDeleteSelection}
            data-testid="selection-context-menu-delete-option"
          />
        </DfContextMenu>
        <div className={cn(core.flex, core.flexEnd)}>
          <div
            className={cn(utils.pl2, styles['context-menu-icon'], {
              [styles['context-menu-icon-disabled']]: isSendingToDC,
            })}
            onClick={handleContextMenuIconClick}
            data-testid="selection-context-menu-icon"
          >
            <SvgMore className={core.icon} />
          </div>
        </div>
      </div>
      <button
        className={cn(
          utils.my6,
          utils.mx6,
          core.flex,
          core.alignCenter,
          core.flexCenter,
          core.textCenter,
          button.textBtn,
          button.textBtnSecondary,
          styles['title-container'],
        )}
        onClick={handleOpenSelection}
        disabled={isSendingToDC}
        data-testid="selection-title"
      >
        {isSendingToDC ? (
          <div className={styles.loader}>
            <DfLoader data-testid="sending-loader" />
            <p className={styles['sending-loader-text']}>
              <FormattedMessage id="home_selection_sending" />
            </p>
          </div>
        ) : (
          <h4 className={styles.title}>{data.project_name}</h4>
        )}
      </button>
      <div className={cn(core.flex, core.alignCenter)}>
        <div data-testid="modified-date">{formattedModifiedDate}</div>
        <div className={cn(utils.mx2, styles.divider)} />
        <div data-testid="function-list">{functionsLabel}</div>
      </div>
    </div>
  );
};

export default memo(Selection);
