// eslint-disable-next-line import/no-extraneous-dependencies
import { Location, UnregisterCallback } from 'history';
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import useConfirmationModal from 'views/wizard/shared/helpers/useConfirmationModal';

const useCustomUnloadWarning = (flagToWatch: boolean) => {
  const history = useHistory();
  const unblockNavigationRef = useRef<UnregisterCallback | null>(null);

  const unblockNavigation = () => {
    if (unblockNavigationRef.current) {
      unblockNavigationRef.current();
      unblockNavigationRef.current = null;
    }
  };
  const { open, props, isOpen } = useConfirmationModal<Location>(location => {
    unblockNavigation();
    history.push(location);
  });

  useEffect(() => {
    if (flagToWatch) {
      unblockNavigationRef.current = history.block(location => {
        open(location);
        return false;
      });
    }

    return () => {
      unblockNavigation();
    };
  }, [flagToWatch]);

  return { isOpen, props, unblock: unblockNavigation };
};

export default useCustomUnloadWarning;
