import { useState } from 'react';

import { ReactComponent as SvgPlaceholder } from 'assets/icons/product-placeholder.svg';
import { addSizeToPhotoUrl } from 'views/wizard/shared/helpers/imageUtils';

interface ProductImageProps {
  photoUrl?: string;
  height: number;
  width: number;
  alt: string;
}

const ProductImage = ({ photoUrl, ...rest }: ProductImageProps) => {
  const [hasError, setHasError] = useState(false);

  const onError = () => setHasError(true);

  if (hasError || !photoUrl) {
    return <SvgPlaceholder data-testid="product-image-placeholder" {...rest} />;
  }

  return (
    <img
      data-testid="product-image"
      src={addSizeToPhotoUrl(photoUrl, rest.width, rest.height)}
      onError={onError}
      {...rest}
    />
  );
};

export default ProductImage;
