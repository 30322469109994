import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfModal } from '@danfoss/mosaic/react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as SvgFinalDriveRatio } from 'assets/icons/final_drive_ratio.svg';

interface IFinalDriveRatioExplanationModalProps {
  onClose: () => void;
}

const FinalDriveRatioExplanationModal = ({ onClose }: IFinalDriveRatioExplanationModalProps) => {
  const { formatMessage } = useIntl();

  return (
    <DfModal
      heading={formatMessage({ id: 'wizard_product_motor_widget_final_drive_ratio_modal_title' })}
      onModalClose={onClose}
      data-testid="final-drive-ratio-explanation"
      isOpen
    >
      <div slot="modal-body">
        <p className={utils.mb3}>
          <FormattedMessage id="wizard_product_motor_widget_final_drive_ratio_modal_text_1" />
        </p>
        <p className={utils.mb3}>
          <FormattedMessage id="wizard_product_motor_widget_final_drive_ratio_modal_text_2" />
        </p>
        <p className={utils.mb3}>
          <FormattedMessage id="wizard_product_motor_widget_final_drive_ratio_modal_text_3" />
        </p>
        <div className={core.flex}>
          <p className={utils.mr20}>
            <FormattedMessage id="wizard_product_motor_widget_final_drive_ratio_modal_text_4" />
          </p>
          <div>
            <SvgFinalDriveRatio />
          </div>
        </div>
      </div>
      <div slot="modal-footer">
        <button className={button.btnPrimary} onClick={() => onClose()}>
          <FormattedMessage id="wizard_product_motor_widget_final_drive_ratio_modal_ok_button" />
        </button>
      </div>
    </DfModal>
  );
};

export default FinalDriveRatioExplanationModal;
