import { ReactComponent as SvgPlus } from '@danfoss/mosaic-icons/dist/svg/plus.svg';
import { ReactComponent as SvgMinus } from '@danfoss/mosaic-icons/dist/svg/remove.svg';
import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import React from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import Button from 'views/shared/components/Button/Button';
import { TractiveForceValidation } from 'views/wizard/requirements/helpers/types';
import { IRequirementsForm } from 'views/wizard/requirements/types';

import { RollingRadiusField } from '../RollingRadiusField';
import styles from './OptionalRollingRadiusField.module.scss';

export interface OptionalRollingRadiusFieldProps {
  form: UseFormReturn<IRequirementsForm>;
  validation?: TractiveForceValidation;
  number: 3 | 4;
  onAddMotor: () => void;
  onRemoveMotor: (motorNumber: 3 | 4) => void;
}

export function OptionalRollingRadiusField({
  form,
  validation,
  number,
  onAddMotor,
  onRemoveMotor,
}: OptionalRollingRadiusFieldProps) {
  const rollingRadius = useWatch({
    name: `propelSizingData.default_rolling_radiuses.motor_${number}`,
    control: form.control,
  });

  if (typeof rollingRadius !== 'number') {
    return (
      <Button
        type="button"
        className={cn(
          button.textBtn,
          button.textBtnPrimary,
          utils.col6,
          utils.mt14,
          styles['add-button'],
        )}
        data-testid={`add-motor-button-${number}`}
        onClick={onAddMotor}
      >
        <SvgPlus className={core.icon} />
        <FormattedMessage id="wizard_requirements_rolling_radius_add_motor" values={{ number }} />
      </Button>
    );
  }

  return (
    <div className={cn(core.flex, core.flexColumn, core.alignStart, utils.col6)}>
      <RollingRadiusField
        className={styles.fieldset}
        form={form}
        validation={validation}
        number={number}
      />
      <Button
        type="button"
        className={cn(button.textBtn, button.textBtnPrimary, utils.mt2)}
        data-testid={`remove-motor-button-${number}`}
        onClick={() => onRemoveMotor(number)}
      >
        <SvgMinus className={core.icon} />
        <FormattedMessage id="wizard_requirements_rolling_radius_remove_motor" />
      </Button>
    </div>
  );
}
