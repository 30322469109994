import React, { createContext, PropsWithChildren, useContext, useMemo, useState } from 'react';

import { MotorType } from 'views/shared/types';

export interface OverrideMotorTypeContextValue {
  motorType: MotorType | null;
  setMotorType: (motorType: MotorType | null) => void;
}

export const OverrideMotorTypeContext = createContext<OverrideMotorTypeContextValue>({
  motorType: null,
  setMotorType: () => {},
});

export function useOverrideMotorTypeContext() {
  return useContext(OverrideMotorTypeContext);
}

export function OverrideMotorTypeProvider({ children }: PropsWithChildren<unknown>) {
  const [motorType, setMotorType] = useState<OverrideMotorTypeContextValue['motorType']>(null);

  const context = useMemo<OverrideMotorTypeContextValue>(
    () => ({ motorType, setMotorType }),
    [motorType, setMotorType],
  );

  return (
    <OverrideMotorTypeContext.Provider value={context}>
      {children}
    </OverrideMotorTypeContext.Provider>
  );
}
