import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfLoader } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'configs/store';
import { trackEvent } from 'utils/analytics';
import ContactUs from 'views/base/ContactUs/ContactUs';
import Button from 'views/shared/components/Button/Button';
import { FunctionCode, MachineExampleCode, SystemArchitecture } from 'views/shared/types';

import WizardStep from '../shared/components/WizardStep';
import { createNewProject } from '../shared/store/projectSlice';
import { getSelectedFunctions, setSelectedFunctions } from '../shared/store/sizingFunctionsSlice';
import styles from './ExampleSelection.module.scss';
import { ArchitectureExamples } from './components/ArchitectureExamples';
import { MachineExamples } from './components/MachineExamples';
import { useExampleOptions } from './useExampleOptions';

export default function ExampleSelection() {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [isProjectSaving, setProjectSaving] = useState(false);
  const [selectedExample, setSelectedExample] = useState<MachineExampleCode>();
  const selectedFunctions = useAppSelector(getSelectedFunctions);

  const isArchitectureSelectionEnabled = selectedFunctions?.includes(FunctionCode.PropelSizing);

  useEffect(() => {
    if (!selectedFunctions?.length) {
      history.push('/function-selection');
    }
  }, []);

  const { architectureExamples, isPageLoading, machineExamples } =
    useExampleOptions(selectedFunctions);

  const previousStep = () => {
    history.push('/function-selection');
  };

  async function createProject(exampleCode: MachineExampleCode, architecture?: SystemArchitecture) {
    setProjectSaving(true);

    try {
      const project = await dispatch(
        createNewProject({
          functionCodes: Array.from(selectedFunctions!),
          exampleCode,
          architecture,
        }),
      ).unwrap();

      dispatch(setSelectedFunctions(null));

      setProjectSaving(false);
      history.push(`/requirements/${project.id}`);
    } catch (error) {
      setProjectSaving(false);
    }
  }

  const onSelectMachineExample = async (exampleCode: MachineExampleCode) => {
    if (isProjectSaving) {
      return;
    }

    if (isArchitectureSelectionEnabled) {
      setSelectedExample(exampleCode);
    } else {
      trackEvent({ event: 'start_selection', architecture: exampleCode });
      await createProject(exampleCode);
    }
  };

  const onSelectMachineArchitecture = async (architecture: SystemArchitecture) => {
    if (isProjectSaving) {
      return;
    }

    trackEvent({ event: 'start_selection', architecture: selectedExample });
    await createProject(selectedExample!, architecture);
  };

  const onSelectArchitectureExample = async (exampleCode: MachineExampleCode) => {
    if (isProjectSaving) {
      return;
    }

    trackEvent({ event: 'start_selection', architecture: exampleCode });
    setSelectedExample(exampleCode);
    createProject(exampleCode);
  };

  const onCancelSelection = () => {
    setSelectedExample(undefined);
  };

  if (isPageLoading) {
    return <DfLoader isGlobal isVisible data-testid="example-selection-page-loader" />;
  }

  return (
    <>
      <WizardStep
        progress={0}
        breadcrumb={<FormattedMessage id="wizard_breadcrumbs_composing" />}
      />
      {isProjectSaving && (
        <DfLoader
          isGlobal
          isVisible
          className={styles['loader-wrapper']}
          data-testid="example-selection-page-option-loader"
        />
      )}
      {architectureExamples && (
        <ArchitectureExamples
          selectedExample={selectedExample}
          onSelect={onSelectArchitectureExample}
          examples={architectureExamples}
        />
      )}
      <MachineExamples
        selectedExample={selectedExample}
        examples={machineExamples}
        onSelectExample={onSelectMachineExample}
        onSelectArchitecture={
          isArchitectureSelectionEnabled ? onSelectMachineArchitecture : undefined
        }
        onCancelSelection={onCancelSelection}
      />
      <div className={cn(utils.mt8, core.flex)}>
        <Button
          className={button.btnSecondary}
          data-testid="example-selection-button-prev"
          onClick={previousStep}
        >
          <FormattedMessage id="wizard_step_previous" />
        </Button>
      </div>
      <ContactUs />
    </>
  );
}
