import button from '@danfoss/mosaic/css/components/button.module.css';
import input from '@danfoss/mosaic/css/components/input.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import React, { ChangeEvent, ReactNode, useState } from 'react';
import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as SvgInfoFilled } from 'assets/icons/info-filled.svg';
import Fieldset from 'views/shared/components/form/Fieldset';
import FormError from 'views/shared/components/form/FormError';
import Input from 'views/shared/components/form/Input';
import InputAddon from 'views/shared/components/form/InputAddon';
import InputGroup from 'views/shared/components/form/InputGroup';
import Label from 'views/shared/components/form/Label/Label';
import {
  motorWidgetValidation,
  finalDriveRatioValidation,
} from 'views/wizard/product-selection/constants';

import FinalDriveRatioExplanationModal from '../../../Motors/components/MotorWidget/components/EditableAttributes/FinalDriveRatioExplanationModal';
import styles from './EditableAttributes.module.scss';
import { IEditableAttributesForm } from './types';

export interface FinalDriveRatioFieldProps {
  form: UseFormReturn<IEditableAttributesForm>;
  field: 'final_drive_ratio' | 'final_drive_ratio2';
  onChange: (field: ControllerRenderProps) => (event: { target: { value: string | null } }) => void;
  recommendedFinalDriveRatio?: number;
  title?: ReactNode;
}

const validationRules = {
  validate: {
    min: (value: number | null) => value === null || value >= finalDriveRatioValidation.min,
    max: (value: number | null) => value === null || value <= finalDriveRatioValidation.max,
  },
};

export function FinalDriveRatioField({
  form,
  field,
  onChange,
  recommendedFinalDriveRatio,
  title,
}: FinalDriveRatioFieldProps) {
  const [isFinalDriveRatioModalShown, setIsFinalDriveRatioModalShown] = useState(false);

  const {
    control,
    formState: { errors },
  } = form;

  const onFinalDriveRatioIconClick = () => setIsFinalDriveRatioModalShown(true);
  const onFinalDriveRatioModalClose = () => setIsFinalDriveRatioModalShown(false);

  return (
    <>
      <Fieldset className={utils.ml5} data-testid={`${field}-fieldset`}>
        <Label className={cn(core.flex, core.alignCenter)}>
          <span data-testid="title">
            {title ?? <FormattedMessage id="wizard_product_motor_widget_final_drive_ratio" />}
          </span>
          <button
            type="button"
            className={cn(utils.ml1, button.textBtn, button.textBtnSecondary)}
            data-testid="final-drive-ratio-icon-button"
            onClick={onFinalDriveRatioIconClick}
          >
            <SvgInfoFilled className={core.icon} />
          </button>
        </Label>
        <InputGroup>
          <Controller
            control={control}
            name={field}
            rules={validationRules}
            render={({ field: fieldControl, fieldState }) => (
              <Input
                type="number"
                value={fieldControl.value ?? ''}
                onChange={({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
                  onChange(fieldControl as unknown as ControllerRenderProps)({
                    target: {
                      value: value || null,
                    },
                  });
                }}
                error={fieldState.invalid}
                step="0.001"
                data-testid="final-drive-ratio"
                className={cn(styles.input, !fieldControl.value && input.warning)}
                placeholder="--"
                {...finalDriveRatioValidation}
              />
            )}
          />
          <InputAddon>
            <FormattedMessage id="measurements_unit_ratio_one" />
          </InputAddon>
        </InputGroup>
        <div className={cn(core.flex, core.flexColumn)}>
          <Label className={core.helperText}>
            <FormattedMessage
              id="wizard_product_motor_widget_final_drive_ratio_recommended"
              values={{ recommendation: recommendedFinalDriveRatio }}
            />
          </Label>
          <FormError errors={errors} rules={motorWidgetValidation} field={field} />
        </div>
      </Fieldset>
      {isFinalDriveRatioModalShown && (
        <FinalDriveRatioExplanationModal onClose={onFinalDriveRatioModalClose} />
      )}
    </>
  );
}
