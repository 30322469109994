import core from '@danfoss/mosaic/css/core.module.css';
import { jsPDF as JSPDF } from 'jspdf';
import { forwardRef, useImperativeHandle, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { useTractiveForceFormatter } from 'views/shared/helpers/formatters';
import FormatMeasureUnit from 'views/shared/helpers/measuringSystem/FormatMeasureUnit';
import FormatMeasureValue from 'views/shared/helpers/measuringSystem/FormatMeasureValue';
import useMeasuringUnitsIntl from 'views/shared/helpers/measuringSystem/useMeasuringUnitsIntl';
import useMeasuringValueConvertor from 'views/shared/helpers/measuringSystem/useMeasuringValueConvertor';
import { FunctionCode, ICalculations, IProject, MotorType } from 'views/shared/types';
import { IProducts } from 'views/wizard/shared/helpers/useSelectedProducts';

import { Footer } from './components/Footer';
import { Header } from './components/Header';
import { Product, ProductType } from './components/Product';
import { ProjectSpecItem } from './components/ProjectSpecItem';

interface IPDFTemplateProps {
  project: IProject;
  products: IProducts;
  calculations: ICalculations;
}

export interface PDFTemplateForwardedRef {
  render: (base64Image?: string, imageWidth?: number) => Promise<JSPDF>;
}

const formatSpeed = (speed: number) => {
  return (
    <>
      <FormatMeasureValue metric="km/h" imperial="mph" value={speed} />{' '}
      <FormatMeasureUnit metric="measurements_unit_kmh" imperial="measurements_unit_mph" />
    </>
  );
};

export const PDFTemplate = forwardRef<PDFTemplateForwardedRef, IPDFTemplateProps>(
  ({ project, products, calculations }, forwardedRef) => {
    const pdfDocumentRef = useRef(null);
    const pdfFooterRef = useRef(null);
    const { convertor } = useMeasuringValueConvertor();
    const { formatMeasuringUnit } = useMeasuringUnitsIntl();
    const formatTractiveForce = useTractiveForceFormatter();

    const { system_data, propel_sizing } = project;
    const { hydraulic_dedicated_power, engine_speed, pressure_limit } = system_data;
    const { motors, pumps, steeringProducts } = products;
    const isPropelSizingAvailable = project.function_codes.includes(FunctionCode.PropelSizing);
    const isSteeringAvailable = project.function_codes.includes(FunctionCode.SteeringSystem);
    const { vehicle_travel_speed, vehicle_work_speed, vehicle_travel_speed2, vehicle_work_speed2 } =
      calculations || {};
    const motorType = isPropelSizingAvailable && motors![0]?.type;
    const hasGearbox = isPropelSizingAvailable && !!propel_sizing?.two_speed_gear_box;

    const actualTravelSpeed = vehicle_travel_speed && formatSpeed(vehicle_travel_speed);
    const actualWorkSpeed = vehicle_work_speed && formatSpeed(vehicle_work_speed);
    const requiredTravelSpeed =
      propel_sizing?.max_travel_speed && formatSpeed(propel_sizing.max_travel_speed);
    const requiredWorkSpeedRaw =
      motorType === MotorType.VariablePiston
        ? propel_sizing?.max_work_speed
        : propel_sizing?.max_travel_speed;
    const requiredWorkSpeed = requiredWorkSpeedRaw && formatSpeed(requiredWorkSpeedRaw);

    const tractiveForce = formatTractiveForce(propel_sizing?.tractive_force);

    let actualTravelSpeed2;
    let actualWorkSpeed2;
    let requiredTravelSpeed2;
    let requiredWorkSpeed2;
    let tractiveForce2;

    const productsList: {
      image?: string;
      series?: string;
      name: string;
      specs: string;
      type: ProductType;
    }[] = [];

    if (isPropelSizingAvailable) {
      motors!.forEach(motor => {
        const value = convertor('cm3', 'in3', motor.related_motor_info.max_displacement);
        const unit = formatMeasuringUnit({
          metric: 'measurements_unit_cm3_rev',
          imperial: 'measurements_unit_in3_rev',
        });

        productsList.push({
          image: motor.photo_url,
          series: motor.series,
          name: motor.name,
          specs: `${value} ${unit}`,
          type: 'motor',
        });
      });

      pumps!.forEach(pump => {
        const value = convertor('bar', 'psi', pump.rated_pressure);
        const unit = formatMeasuringUnit({
          metric: 'measurements_unit_bar',
          imperial: 'measurements_unit_psi',
        });

        productsList.push({
          image: pump.photo_url,
          series: pump.series,
          name: pump.name,
          specs: `${value} ${unit}`,
          type: 'pump',
        });
      });
    }

    if (hasGearbox) {
      actualTravelSpeed2 = vehicle_travel_speed2 && formatSpeed(vehicle_travel_speed2);
      actualWorkSpeed2 = vehicle_work_speed2 && formatSpeed(vehicle_work_speed2);

      tractiveForce2 = formatTractiveForce(propel_sizing.two_speed_gear_box!.tractive_force);

      const requiredWorkSpeed2Raw =
        motorType === MotorType.VariablePiston
          ? propel_sizing?.two_speed_gear_box?.max_work_speed
          : propel_sizing?.two_speed_gear_box?.max_travel_speed;

      requiredWorkSpeed2 = requiredWorkSpeed2Raw && formatSpeed(requiredWorkSpeed2Raw);
      requiredTravelSpeed2 =
        propel_sizing.two_speed_gear_box?.max_travel_speed &&
        formatSpeed(propel_sizing.two_speed_gear_box.max_travel_speed);
    }

    if (isSteeringAvailable) {
      steeringProducts!.forEach(steeringItem => {
        let specs = '';

        if (steeringItem.spool) {
          const value = convertor('l', 'gal', steeringItem.spool);
          const units = formatMeasuringUnit({
            metric: 'measurements_unit_l_min',
            imperial: 'measurements_unit_gal_min',
          });

          specs = `${value} ${units}`;
        }

        if (steeringItem.displacement) {
          const value = convertor('cm3', 'in3', steeringItem.displacement);
          const units = formatMeasuringUnit({
            metric: 'measurements_unit_cm3_rev',
            imperial: 'measurements_unit_in3_rev',
          });

          specs = `${value} ${units}`;
        }

        productsList.push({
          image: steeringItem.photo_url,
          series: steeringItem.series,
          name: steeringItem.name,
          type: 'steering',
          specs,
        });
      });
    }

    const render = async (base64Image?: string, imageWidth?: number) => {
      return new Promise<JSPDF>((resolve, reject) => {
        try {
          const pdf = new JSPDF({
            hotfixes: ['px_scaling'],
            orientation: 'portrait',
            unit: 'px',
            compress: true,
          });
          const isOnlySteering = !isPropelSizingAvailable && isSteeringAvailable;
          const margins = 30;
          const footerHeight = isOnlySteering ? 180 : 130;
          const originalImageWidth = 956;
          const maxPdfImageWidth = 730;
          const htmlPageSettings = {
            autoPaging: true,
            windowWidth: 1100,
            width: 720,
            margin: margins,
          };

          pdf.html(pdfDocumentRef.current!, {
            callback: () => {
              if (base64Image) {
                let calculatedImageWidth = maxPdfImageWidth;
                let xMargin = margins;

                if (imageWidth && imageWidth !== originalImageWidth) {
                  const imageWidthPercentage = imageWidth / originalImageWidth;

                  calculatedImageWidth = maxPdfImageWidth * imageWidthPercentage;
                  xMargin = (maxPdfImageWidth - calculatedImageWidth) / 2 + margins;
                }

                pdf.addPage();
                pdf.addImage({
                  imageData: base64Image,
                  width: calculatedImageWidth,
                  height: 500,
                  x: xMargin,
                  y: margins,
                });
              }

              pdf.html(pdfFooterRef.current!, {
                callback: () => {
                  pdf.save(`${project.project_name}.pdf`);
                  resolve(pdf);
                },
                y: (pdf.internal.pageSize.getHeight() - footerHeight) * pdf.getNumberOfPages(),
                ...htmlPageSettings,
              });
            },
            ...htmlPageSettings,
          });
        } catch (error) {
          reject(error);
        }
      });
    };

    useImperativeHandle(forwardedRef, (): PDFTemplateForwardedRef => ({ render }), [project]);

    return (
      <div className={core.hidden} data-testid="pdf-template">
        <Footer ref={pdfFooterRef} />
        <div ref={pdfDocumentRef} style={{ fontFamily: 'arial' }}>
          <Header projectName={project.project_name} />
          <main style={{ margin: '50px 0 80px' }}>
            <div data-testid="main_info" style={{ borderBottom: '3px solid black' }}>
              <h1 data-testid="selection-name" style={{ fontSize: '58px' }}>
                {project.project_name}
              </h1>
              <div
                data-testid="specs"
                style={{ display: 'flex', padding: '40px 0', columnGap: '20px' }}
              >
                <div data-testid="specs-left-column" style={{ width: '50%' }}>
                  <ProjectSpecItem
                    value={
                      hydraulic_dedicated_power && (
                        <FormatMeasureValue
                          metric="kW"
                          imperial="hp"
                          value={hydraulic_dedicated_power}
                        />
                      )
                    }
                    suffix={
                      <FormatMeasureUnit
                        metric="measurements_unit_kw"
                        imperial="measurements_unit_horsepower"
                      />
                    }
                    data-testid="hydraulic-dedicated-power-spec"
                  >
                    <FormattedMessage id="specs_power_for_propel_system" />
                  </ProjectSpecItem>
                  <ProjectSpecItem
                    value={engine_speed}
                    suffix={<FormattedMessage id="measurements_unit_rpm" />}
                    data-testid="engine-speed-spec"
                  >
                    <FormattedMessage id="specs_engine_speed" />
                  </ProjectSpecItem>
                  {isPropelSizingAvailable && (
                    <>
                      <ProjectSpecItem
                        value={requiredWorkSpeed}
                        actual={actualWorkSpeed}
                        data-testid="work-speed-spec"
                      >
                        <FormattedMessage id="specs_maximum_work_speed" /> {hasGearbox && 1}
                      </ProjectSpecItem>
                      {hasGearbox && (
                        <ProjectSpecItem
                          value={requiredWorkSpeed2}
                          actual={actualWorkSpeed2}
                          data-testid="gearbox-work-speed-spec"
                        >
                          <FormattedMessage id="specs_maximum_work_speed" /> 2
                        </ProjectSpecItem>
                      )}
                    </>
                  )}
                </div>
                <div data-testid="specs-right-column" style={{ width: '50%' }}>
                  {isPropelSizingAvailable && (
                    <>
                      <ProjectSpecItem
                        value={requiredTravelSpeed}
                        actual={actualTravelSpeed}
                        data-testid="max-travel-speed-spec"
                      >
                        <FormattedMessage id="specs_maximum_travel_speed" /> {hasGearbox && 1}
                      </ProjectSpecItem>
                      {hasGearbox && (
                        <ProjectSpecItem
                          value={requiredTravelSpeed2}
                          actual={actualTravelSpeed2}
                          data-testid="gearbox-max-travel-speed-spec"
                        >
                          <FormattedMessage id="specs_maximum_travel_speed" /> 2
                        </ProjectSpecItem>
                      )}
                    </>
                  )}
                  <ProjectSpecItem
                    value={
                      <FormatMeasureValue metric="bar" imperial="psi" value={pressure_limit!} />
                    }
                    data-testid="pressure-limit-spec"
                    suffix={
                      <FormatMeasureUnit
                        metric="measurements_unit_bar"
                        imperial="measurements_unit_psi"
                      />
                    }
                  >
                    <FormattedMessage id="specs_system_pressure_limit" />
                  </ProjectSpecItem>
                  {isPropelSizingAvailable && (
                    <>
                      <ProjectSpecItem value={tractiveForce} data-testid="tractive-force-spec">
                        <FormattedMessage id="specs_tractive_force" /> {hasGearbox && 1}
                      </ProjectSpecItem>
                      {hasGearbox && (
                        <ProjectSpecItem
                          value={tractiveForce2}
                          data-testid="gearbox-tractive-force-spec"
                        >
                          <FormattedMessage id="specs_tractive_force" /> 2
                        </ProjectSpecItem>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div data-testid="products-container" style={{ margin: '34px 0 43px' }}>
              {productsList.map((item, index) => (
                <Product {...item} key={index} />
              ))}
            </div>
          </main>
        </div>
      </div>
    );
  },
);
