import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import ContactModal from './ContactModal';
import styles from './ContactUs.module.scss';

interface ContactUsProps {
  projectId?: string;
}

const ContactUs = ({ projectId }: ContactUsProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const onContactClick = () => setIsModalOpen(true);
  const onModalClose = () => setIsModalOpen(false);

  return (
    <div className={styles.wrapper}>
      <div className={cn(styles.container, utils.my10, utils.p8)} data-testid="contact-us">
        <div className={cn(styles['description-section'], utils.pr6)}>
          <h5 className={cn(styles['description-title'], utils.pr6)}>
            <FormattedMessage id="contact_widget_title" />
          </h5>
          <p>
            <FormattedMessage id="contact_widget_text" />{' '}
            <span className={core.textBold}>
              <FormattedMessage id="contact_widget_text_bold" />
            </span>
          </p>
        </div>
        <button
          className={cn(button.btnSecondary, core.autoWidth)}
          onClick={onContactClick}
          data-testid="contact-button"
        >
          <FormattedMessage id="contact_widget_button" />
        </button>
      </div>
      {isModalOpen && <ContactModal onClose={onModalClose} projectId={projectId} />}
    </div>
  );
};

export default memo(ContactUs);
