import button from '@danfoss/mosaic/css/components/button.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import Button from 'views/shared/components/Button/Button';
import { MotorType } from 'views/shared/types';

import Performance from './Performance/Performance';

interface IPerformanceTabProps {
  projectId: string;
  motorType: MotorType;
}

export default function PerformanceTab({ projectId, motorType }: IPerformanceTabProps) {
  return (
    <Performance
      projectId={projectId}
      motorType={motorType}
      trigger={(load, isLoading, isDisabled) => (
        <Button
          className={cn(button.btnPrimary, utils.mt5)}
          data-testid="generate-performance-graph-button"
          loading={isLoading}
          disabled={isDisabled}
          onClick={load}
        >
          <FormattedMessage
            id={
              isLoading
                ? 'wizard_summary_performance_prediction_generating_report'
                : 'wizard_summary_performance_prediction_generate_report'
            }
          />
        </Button>
      )}
    />
  );
}
