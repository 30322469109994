import { FieldErrors, FieldPath, get, FieldError } from 'react-hook-form';

import { IRequirementsForm } from '../types';

export const getError = (
  errors: FieldErrors<IRequirementsForm>,
  field: FieldPath<IRequirementsForm>,
) => {
  return get(errors, field, null) as FieldError | null;
};
