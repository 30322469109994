import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Label from 'views/shared/components/form/Label/Label';
import { ICalculations } from 'views/shared/types';

import TractiveForce from '../TractiveForce';
import VehicleSpeed from '../VehicleSpeed';
import styles from './Summary.module.scss';

export interface ISummaryProps {
  className?: string;
  calculations?: ICalculations;
  hasGearbox?: boolean;
  tractiveForce: {
    actual: number;
    required: number;
  };
  tractiveForceGear2?: {
    actual: number;
    required: number;
  };
}

export function Summary({
  className,
  calculations,
  tractiveForce,
  tractiveForceGear2,
  hasGearbox,
}: ISummaryProps) {
  const tractiveForceActualGear1Label = 'wizard_product_motor_widget_actual_gear1';
  const tractiveForceRequiredGear1Label = 'wizard_product_motor_widget_required_gear1';
  const tractiveForceActualGear2Label = 'wizard_product_motor_widget_actual_gear2';
  const tractiveForceRequiredGear2Label = 'wizard_product_motor_widget_required_gear2';

  return (
    <div className={cn(utils.row, utils.pt5, className, styles.section)}>
      <div className={cn(utils.col3, utils.colMd4, utils.colSm8, utils.mb4)}>
        {calculations && <VehicleSpeed data={calculations} hasGearbox={hasGearbox} />}
      </div>
      <div className={cn(utils.col3, utils.colMd4, utils.colSm8)}>
        <Label>
          <FormattedMessage id="wizard_product_motor_widget_tractive_force" />
        </Label>
        <TractiveForce
          actual={tractiveForce.actual}
          required={tractiveForce.required}
          actualLabel={hasGearbox ? tractiveForceActualGear1Label : undefined}
          requiredLabel={hasGearbox ? tractiveForceRequiredGear1Label : undefined}
        />
        {hasGearbox && tractiveForceGear2 && (
          <TractiveForce
            actual={tractiveForceGear2.actual}
            required={tractiveForceGear2.required}
            actualLabel={tractiveForceActualGear2Label}
            requiredLabel={tractiveForceRequiredGear2Label}
          />
        )}
      </div>
    </div>
  );
}
