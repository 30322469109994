import { ReactNode } from 'react';
import { Route, useHistory, Switch, Redirect } from 'react-router-dom';

import Compare from 'views/compare/Compare';
import Selections from 'views/selections/Selections';
import {
  createNotFoundInterceptor,
  errorInterceptor as commonErrorInterceptor,
} from 'views/shared/helpers/interceptors';
import SharedSummary from 'views/sharing/SharedSummary';
import { errorInterceptor as sharingErrorInterceptor } from 'views/sharing/utils/interceptors';
import ExampleSelection from 'views/wizard/example-selection/ExampleSelection';
import FunctionSelection from 'views/wizard/function-selection/FunctionSelection';
import ProductSelection from 'views/wizard/product-selection/ProductSelection';
import Requirements from 'views/wizard/requirements/Requirements';
import Summary from 'views/wizard/summary/Summary';

import ErrorInterceptorProvider from './shared/helpers/ErrorInterceptorProvider';
import GuardedRoute from './shared/helpers/auth/GuardedRoute';

interface IRoutesProps {
  children?: ReactNode;
  unauthorizedRedirectLocation?: string;
}

const Routes = ({ children, unauthorizedRedirectLocation }: IRoutesProps) => {
  const history = useHistory();
  const commonInterceptors = [createNotFoundInterceptor(history), commonErrorInterceptor];
  const sharingInterceptors = [sharingErrorInterceptor];

  return (
    <Switch>
      {children}
      <Route path="/sharing/:linkId">
        <ErrorInterceptorProvider interceptors={sharingInterceptors}>
          <SharedSummary />
        </ErrorInterceptorProvider>
      </Route>
      <Route path="/compare/:ids">
        <GuardedRoute unauthorizedRedirectTo={unauthorizedRedirectLocation}>
          <ErrorInterceptorProvider interceptors={commonInterceptors}>
            <Compare />
          </ErrorInterceptorProvider>
        </GuardedRoute>
      </Route>
      <Route path="/function-selection">
        <GuardedRoute unauthorizedRedirectTo={unauthorizedRedirectLocation}>
          <ErrorInterceptorProvider interceptors={commonInterceptors}>
            <FunctionSelection />
          </ErrorInterceptorProvider>
        </GuardedRoute>
      </Route>
      <Route path="/example-selection">
        <GuardedRoute unauthorizedRedirectTo={unauthorizedRedirectLocation}>
          <ErrorInterceptorProvider interceptors={commonInterceptors}>
            <ExampleSelection />
          </ErrorInterceptorProvider>
        </GuardedRoute>
      </Route>
      <Route path="/requirements/:projectId">
        <GuardedRoute unauthorizedRedirectTo={unauthorizedRedirectLocation}>
          <ErrorInterceptorProvider interceptors={commonInterceptors}>
            <Requirements />
          </ErrorInterceptorProvider>
        </GuardedRoute>
      </Route>
      <Route path="/product-selection/:projectId">
        <GuardedRoute unauthorizedRedirectTo={unauthorizedRedirectLocation}>
          <ErrorInterceptorProvider interceptors={commonInterceptors}>
            <ProductSelection />
          </ErrorInterceptorProvider>
        </GuardedRoute>
      </Route>
      <Route path="/summary/:projectId">
        <GuardedRoute unauthorizedRedirectTo={unauthorizedRedirectLocation}>
          <ErrorInterceptorProvider interceptors={commonInterceptors}>
            <Summary />
          </ErrorInterceptorProvider>
        </GuardedRoute>
      </Route>
      <Route exact path="/">
        <GuardedRoute unauthorizedRedirectTo={unauthorizedRedirectLocation}>
          <ErrorInterceptorProvider interceptors={commonInterceptors}>
            <Selections />
          </ErrorInterceptorProvider>
        </GuardedRoute>
      </Route>
      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;
