import { getAppConfig } from 'configs/global';
import { parseParameter } from 'utils/parseParams';

const tokenKeyName = `${getAppConfig().APPLICATION_PREFIX}-sf`;

export const setToken = (token: string) => {
  localStorage.setItem(tokenKeyName, token);
};

const getTokenFromParameters = () => {
  return parseParameter('token');
};

export const getTokenFromLocalStorage = () => {
  return localStorage.getItem(tokenKeyName);
};

export const getToken = () => {
  return getTokenFromParameters() || getTokenFromLocalStorage();
};

export const clearToken = () => {
  localStorage.removeItem(tokenKeyName);
};
