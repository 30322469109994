import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { RootState } from 'configs/store';

import { getMachineExamples as getMachineExamplesAPI } from '../api/machine-examples';
import { IMachineExample } from '../types';

export interface IMachineExamplesState {
  list: IMachineExample[] | null;
}

export const initialState: IMachineExamplesState = {
  list: null,
};

export const fetchMachineExamples = createAsyncThunk('machineExamples/get', () => {
  return getMachineExamplesAPI();
});

const getSlice = (state: RootState) => state.machineExamples;
export const getMachineExamples = (state: RootState) => getSlice(state).list;

const machineExamplesSlice = createSlice({
  name: 'machineExamples',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(fetchMachineExamples.fulfilled, (state, { payload }) => {
      state.list = payload;
    });
  },
});

export default machineExamplesSlice.reducer;
