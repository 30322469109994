import core from '@danfoss/mosaic/css/core.module.css';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import TooltipInfo from 'views/shared/components/TooltipInfo/TooltipInfo';
import Checkbox from 'views/shared/components/form/Checkbox';
import { usePriceFormatter } from 'views/shared/helpers/formatters';
import { IPrice, ISelectedPump, MotorType } from 'views/shared/types';
import { useMotorTypeContext } from 'views/wizard/product-selection/motor-type-context/motorTypeContext';

import PumpModal from '../../../Pumps/components/PumpModal/PumpModal';
import Header from '../../../components/widgets/components/Header/Header';

export interface IPumpProps {
  pump: ISelectedPump;
  price?: IPrice;
  isReadOnly: boolean;
  onKeepPumpSelectedChange?: React.FormEventHandler<HTMLInputElement>;
  isReplaceable?: boolean;
  numberOfPumps?: number;
}

export default function Pump({
  pump,
  price,
  isReadOnly,
  onKeepPumpSelectedChange,
  numberOfPumps = 1,
  isReplaceable = true,
}: IPumpProps) {
  const { formatMessage } = useIntl();
  const priceFormatter = usePriceFormatter();
  const { motorType } = useMotorTypeContext();

  const [isReplaceModalVisible, setIsReplaceModalVisible] = useState(false);

  const { id, name, photo_url, series } = pump;
  const pumpToReplace = {
    current: id,
  };
  const isMotorTypeVariable = motorType === MotorType.VariablePiston;
  const priceLabel = priceFormatter(price);

  const handleModalOpen = () => {
    setIsReplaceModalVisible(true);
  };
  const handleModalClose = () => {
    setIsReplaceModalVisible(false);
  };

  return (
    <>
      <Header
        id={id}
        photoUrl={photo_url}
        photoAlt={formatMessage({ id: 'wizard_product_pump_widget_image_alt' })}
        name={name}
        series={series}
        spec={priceLabel}
        onChange={isReplaceable ? handleModalOpen : undefined}
        isReadOnly={isReadOnly}
        hideBorder
      />
      {onKeepPumpSelectedChange && (
        <Checkbox
          id="keep-pump-selection"
          onChange={onKeepPumpSelectedChange}
          checked={pump.keep_pump}
          className={core.alignCenter}
          labelClassName={core.flex}
          data-testid="keep-pump-selection"
        >
          <span>
            <FormattedMessage id="wizard_product_pump_widget_keep_selection" />
          </span>
          <TooltipInfo placement="left">
            <FormattedMessage id="wizard_product_pump_widget_keep_selection_info" />
          </TooltipInfo>
        </Checkbox>
      )}

      {isReplaceModalVisible && (
        <PumpModal
          isOpen={isReplaceModalVisible}
          action="replace"
          numberOfPumps={numberOfPumps}
          focusedItem={pumpToReplace}
          onModalClose={handleModalClose}
          isMotorTypeVariable={isMotorTypeVariable}
        />
      )}
    </>
  );
}
