import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfLoader } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'configs/store';
import { trackEvent } from 'utils/analytics';
import ContactUs from 'views/base/ContactUs/ContactUs';
import Button from 'views/shared/components/Button/Button';
import EnteredData from 'views/shared/components/EnteredData/EnteredData';
import { FunctionCode, IProject, ProjectStatus } from 'views/shared/types';

import ConfirmationModal from '../shared/components/ConfirmationModal';
import WizardStep from '../shared/components/WizardStep';
import useConfirmationModal from '../shared/helpers/useConfirmationModal';
import { useProject } from '../shared/helpers/useProject';
import useRedirectByStatus from '../shared/helpers/useRedirectByStatus';
import { useSelectedProducts } from '../shared/helpers/useSelectedProducts';
import {
  clearProducts,
  getCalculations,
  removeSteeringUnit as removeSteeringUnitAction,
  updateProject,
} from '../shared/store/projectSlice';
import { MotorTypeContext, MotorTypeProvider } from './motor-type-context/motorTypeContext';
import { PropelSystem } from './sections/PropelSystem/PropelSystem';
import Steering from './sections/Steering/Steering';

const ProductSelection = () => {
  const { formatMessage } = useIntl();
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const dispatch = useAppDispatch();
  const [isPageUpdating, setPageUpdating] = useState(false);
  const [isProjectUpdating, setProjectUpdating] = useState(false);
  const { isLoading: isProjectLoading, project } = useProject(projectId);
  const calculations = useAppSelector(getCalculations);
  const { isLoading: isProductsLoading, products } = useSelectedProducts(projectId);
  const isPageLoading = isProjectLoading || isProductsLoading;
  const selectedPumps = products.pumps;
  const isPumpSelected = selectedPumps && selectedPumps.length > 0;
  const selectedMotors = products.motors;
  const selectedSteeringProducts = products.steeringProducts;
  const isSteeringProductSelected = selectedSteeringProducts && selectedSteeringProducts.length > 0;
  const hasPropelProducts = Boolean(selectedMotors?.length && selectedPumps?.length);

  const isPropelAvailable = project?.function_codes?.includes(FunctionCode.PropelSizing);
  const isSteeringAvailable = project?.function_codes?.includes(FunctionCode.SteeringSystem);

  useRedirectByStatus(project.id, project.project_status, [ProjectStatus.CalculationsCompleted]);

  const clearSteeringUnit = async () => {
    try {
      setPageUpdating(true);
      await dispatch(removeSteeringUnitAction()).unwrap();
    } finally {
      setPageUpdating(false);
    }
  };

  const nextStep = async () => {
    const projectUpdates: Partial<IProject> = {
      project_status: ProjectStatus.Configured,
    };

    try {
      setProjectUpdating(true);

      trackEvent({
        event: 'finish_selection',
        selectionId: project.id,
        recommendedProduct: project.is_recommendation_used,
      });
      await dispatch(
        updateProject({
          id: project.id,
          project: projectUpdates,
        }),
      ).unwrap();
    } finally {
      setProjectUpdating(false);
    }

    history.push(`/summary/${project.id}`);
  };

  const setProjectStatusDraft = async () => {
    try {
      setPageUpdating(true);

      await dispatch(
        updateProject({
          id: project.id,
          project: {
            project_status: ProjectStatus.Draft,
          },
        }),
      ).unwrap();
    } finally {
      setPageUpdating(false);
    }
  };

  const redirectToRequirements = () => {
    history.push(`/requirements/${project.id}`);
  };

  const {
    props: changeRequirementsConfirmationModalProps,
    open: openChangeRequirementsConfirmationModal,
    isOpen: isChangeRequirementsConfirmationModalOpen,
  } = useConfirmationModal(async () => {
    await setProjectStatusDraft();
    dispatch(clearProducts());
    redirectToRequirements();
  });

  const changeRequirements = async () => {
    // @TODO [custom] not needed for all architectures because products always added
    const isSelectionHasPropelProducts =
      isPropelAvailable && selectedMotors && selectedMotors.length;
    const isSelectionHasSteeringProducts =
      isSteeringAvailable && selectedSteeringProducts && selectedSteeringProducts.length;

    if (!isSelectionHasPropelProducts && !isSelectionHasSteeringProducts) {
      await setProjectStatusDraft();

      redirectToRequirements();
    } else {
      openChangeRequirementsConfirmationModal();
    }
  };

  if (isPageLoading) {
    return <DfLoader isGlobal isVisible data-testid="products-selection-page-loader" />;
  }

  const isPropelComplete = isPropelAvailable ? isPumpSelected : true;
  const isSteeringComplete = isSteeringAvailable ? isSteeringProductSelected : true;
  const isFinishSelectionAvailable = isPropelComplete && isSteeringComplete;

  return (
    <MotorTypeProvider>
      <div
        data-testid="product-selection-page"
        className={cn(core.fullHeight, core.flex, core.flexColumn)}
      >
        <WizardStep
          progress={2}
          breadcrumb={<FormattedMessage id="wizard_breadcrumbs_new_selection" />}
          setLoading={setPageUpdating}
        />
        <DfLoader isGlobal isVisible={isPageUpdating}>
          <MotorTypeContext.Consumer>
            {({ motorType }) => (
              <EnteredData
                project={project}
                calculations={calculations}
                onLinkClick={changeRequirements}
                linkLabel={formatMessage({ id: 'specs_change_requirements' })}
                motorType={motorType}
              />
            )}
          </MotorTypeContext.Consumer>
          {isSteeringAvailable && <Steering clearSteeringUnit={clearSteeringUnit} />}
          {isPropelAvailable && (
            <PropelSystem
              architecture={project.propel_sizing?.initial_system_architecture}
              machineExampleCode={project.machine_example_code}
              hasPropelProducts={hasPropelProducts}
              goToRequirements={changeRequirements}
            />
          )}
          <div className={cn(utils.mt8, core.flex)}>
            <Button
              className={button.btnSecondary}
              data-testid="product-selection-button-prev"
              onClick={changeRequirements}
            >
              <FormattedMessage id="wizard_step_previous" />
            </Button>
            <Button
              className={cn(button.btnPrimary, core.end)}
              data-testid="product-selection-button-finish-selection"
              type="submit"
              disabled={!isFinishSelectionAvailable}
              loading={isProjectUpdating}
              onClick={nextStep}
            >
              <FormattedMessage id="wizard_step_finish_selection" />
            </Button>
          </div>
        </DfLoader>
        <ContactUs projectId={project.id} />
        {isChangeRequirementsConfirmationModalOpen && (
          <ConfirmationModal
            headingLabel={formatMessage({
              id: 'wizard_product_change_requirements_modal_heading',
            })}
            confirmLabel={formatMessage({
              id: 'wizard_product_change_requirements_change',
            })}
            cancelLabel={formatMessage({
              id: 'wizard_product_change_requirements_cancel',
            })}
            {...changeRequirementsConfirmationModalProps}
          >
            <FormattedMessage id="wizard_product_change_requirements_text" />
          </ConfirmationModal>
        )}
      </div>
    </MotorTypeProvider>
  );
};

export default ProductSelection;
