import { memo } from 'react';
import { useIntl } from 'react-intl';

import useMeasuringUnitsIntl from 'views/shared/helpers/measuringSystem/useMeasuringUnitsIntl';
import useMeasuringValueConvertor from 'views/shared/helpers/measuringSystem/useMeasuringValueConvertor';
import { ISelectedProducts } from 'views/shared/types';

import Product from './Product';

interface IProductsProps {
  data: ISelectedProducts;
}

const Products = ({ data }: IProductsProps) => {
  const { formatMessage } = useIntl();
  const { formatMeasuringUnit } = useMeasuringUnitsIntl();
  const { convertor } = useMeasuringValueConvertor();
  const { motors, pumps, steering_products } = data;

  return (
    <>
      {motors.map(({ selected_motor }) => {
        const spec = `${convertor(
          'cm3',
          'in3',
          selected_motor.related_motor_info.max_displacement,
        )} ${formatMeasuringUnit({
          metric: 'measurements_unit_cm3_rev',
          imperial: 'measurements_unit_in3_rev',
        })}`;
        const imageAlt = formatMessage({ id: 'products_image_motor_alt' });

        return (
          <Product
            key={selected_motor.id}
            title={selected_motor.name}
            spec={spec}
            image={selected_motor.photo_url}
            imageAlt={imageAlt}
          />
        );
      })}
      {pumps.map(({ selected_pump }) => {
        const spec = `${convertor(
          'bar',
          'psi',
          selected_pump.rated_pressure,
        )} ${formatMeasuringUnit({
          metric: 'measurements_unit_bar',
          imperial: 'measurements_unit_psi',
        })}`;
        const imageAlt = formatMessage({ id: 'products_image_pump_alt' });

        return (
          <Product
            key={selected_pump.id}
            title={selected_pump.name}
            spec={spec}
            image={selected_pump.photo_url}
            imageAlt={imageAlt}
          />
        );
      })}
      {steering_products.map(({ selected_steering_product }) => {
        const imageAlt = formatMessage({ id: 'products_image_steering_product_alt' });
        let spec = '';

        if (selected_steering_product.spool) {
          spec = `${convertor('l', 'gal', selected_steering_product.spool)} ${formatMeasuringUnit({
            metric: 'measurements_unit_l_min',
            imperial: 'measurements_unit_gal_min',
          })}`;
        }

        if (selected_steering_product.displacement) {
          spec = `${convertor(
            'cm3',
            'in3',
            selected_steering_product.displacement,
          )} ${formatMeasuringUnit({
            metric: 'measurements_unit_cm3_rev',
            imperial: 'measurements_unit_in3_rev',
          })}`;
        }

        return (
          <Product
            key={selected_steering_product.id}
            title={selected_steering_product.name}
            spec={spec}
            image={selected_steering_product.photo_url}
            imageAlt={imageAlt}
          />
        );
      })}
    </>
  );
};

export default memo(Products);
