import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfAccordion } from '@danfoss/mosaic/react';
import cn from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import Checkbox from 'views/shared/components/form/Checkbox';

import selectionModalStyles from '../../../../components/modals/SelectionModal/SelectionModal.module.scss';

interface SteeringModalSidebarProps {
  authOnly: boolean;
  onAuthOnlyChange: React.FormEventHandler<HTMLInputElement>;
}

export function SteeringModalSidebar({ authOnly, onAuthOnlyChange }: SteeringModalSidebarProps) {
  return (
    <>
      <h2 className={utils.mb6}>
        <FormattedMessage id="wizard_product_add_steering" />
      </h2>
      <div className={cn(selectionModalStyles.filters, utils.py2)}>
        <DfAccordion isExpanded>
          <span slot="header">
            <FormattedMessage id="wizard_selection_modal_product_families" />
          </span>
          <div slot="content" className={utils.pt4}>
            <Checkbox
              id="authorized-products"
              onChange={onAuthOnlyChange}
              checked={authOnly}
              data-testid="steering-modal-authorized-products-checkbox"
            >
              <FormattedMessage id="wizard_selection_modal_product_families_only_auth" />
            </Checkbox>
          </div>
        </DfAccordion>
      </div>
    </>
  );
}
