import core from '@danfoss/mosaic/css/core.module.css';
import { renderToString } from 'react-dom/server';
import { IntlShape } from 'react-intl';

import { round, roundPrice } from 'views/shared/helpers/formatters';

import { IAvailableProduct, IAvailableSteeringUnit } from '../../../../api/products';

export const usePriceCellFormatter = (intl: IntlShape) => {
  return (value: number, isPriceAvailable: boolean) => {
    if (!isPriceAvailable) {
      return renderToString(
        <span data-testid="price-unavailable">
          {intl.formatMessage({ id: 'data_unavailable' })}
        </span>,
      );
    }

    return renderToString(
      <span data-testid="table-price-cell">
        {intl.formatMessage({ id: 'price_starts_at' })}{' '}
        <strong>
          {intl.formatMessage({ id: 'currency_euro_symbol' })}
          {intl.formatNumber(roundPrice(value))}
        </strong>
      </span>,
    );
  };
};

export const nameCell =
  (formatMessage: IntlShape['formatMessage']) => (name: string, data?: Record<string, any>) => {
    const { recommended } = data as IAvailableProduct;
    let suffix;

    if (recommended) {
      suffix = (
        <span className={core.textItalic} data-testid="selection-table-recommended-label">
          {formatMessage({ id: 'wizard_selection_modal_recommended_label' })}
        </span>
      );
    }

    return renderToString(
      <>
        <strong data-testid="selection-table-name">{name}</strong> {suffix}
      </>,
    );
  };

export const capacityCell = (formatMessage: IntlShape['formatMessage']) => (value: number) => {
  return renderToString(
    <span data-testid="capacity">
      {Math.round(value)}
      {formatMessage({ id: 'measurements_unit_percentage' })}
    </span>,
  );
};

export const rpmCell = (cellName: string) => (value: number) =>
  renderToString(<span data-testid={cellName}>{Math.round(value)}</span>);

export const dimensionsCell =
  (formatMessage: IntlShape['formatMessage']) =>
  ({ length, width, height }: IAvailableSteeringUnit['steering_unit']) => {
    if (!length || !width || !height) {
      return renderToString(
        <span data-testid="selection-table-dimensions-unavailable">
          {formatMessage({ id: 'data_unavailable' })}
        </span>,
      );
    }

    return `${round(length)}x${round(width)}x${round(height)}`;
  };
