import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'configs/store';
import {
  getRequirementsValidation,
  getRequirementsValidationRules,
} from 'views/wizard/shared/store/validationSlice';

export const useValidation = () => {
  const dispatch = useAppDispatch();
  const validation = useAppSelector(getRequirementsValidationRules);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(!validation);

  useEffect(() => {
    async function getValidation() {
      setIsLoading(true);

      try {
        await dispatch(getRequirementsValidation()).unwrap();
      } catch (e) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    }

    if (!validation) {
      getValidation();
    }
  }, []);

  return { error, validation, isLoading };
};
