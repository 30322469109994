import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import Button from 'views/shared/components/Button/Button';

import styles from './GearBox.module.scss';

interface IAddGearBoxProps {
  onClick: () => void;
  disabled: boolean;
}

const AddGearBox = ({ onClick, disabled }: IAddGearBoxProps) => {
  return (
    <div
      className={cn(core.flex, utils.p4, styles.wrapper, styles['add-wrapper'])}
      data-testid="add-gearbox-option"
    >
      <Button
        className={cn(button.textBtn, button.textBtnPrimary)}
        onClick={onClick}
        disabled={disabled}
        data-testid="add-gearbox-button"
      >
        + <FormattedMessage id="wizard_product_gear_box_widget_add" />
      </Button>
    </div>
  );
};

export default AddGearBox;
