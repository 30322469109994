import { useAuth0 } from '@auth0/auth0-react';
import { ReactComponent as SvgPersonSolid } from '@danfoss/mosaic-icons/dist/svg/person.svg';
import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';

import { getAppConfig } from 'configs/global';

import styles from '../Header.module.scss';
import { getInitials } from '../helpers/user';
import { ProfileContextMenuProps } from '../types';

const ProfileContextMenuDIP = ({ handleContextToggle }: ProfileContextMenuProps) => {
  const { user, logout } = useAuth0();
  const { DIP_LOGIN_URL } = getAppConfig();

  const initials = getInitials(user || {});

  const handleLogoutClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    logout({ returnTo: `${window.location.origin}${DIP_LOGIN_URL}` });
  };

  return (
    <>
      <span
        className={cn(
          utils.colorSurfaceBase,
          core.flex,
          core.alignCenter,
          styles['context-button'],
        )}
        data-testid="user-profile-icon"
        onClick={handleContextToggle}
      >
        <SvgPersonSolid className={cn(core.icon, utils.mr2)} /> <span>{initials}</span>
      </span>
      {/* "df-context-menu-overlay align-right" needed here because DfHeader has nested structure which relies on this classes */}
      <ul className="df-context-menu-overlay align-right">
        <li>
          <a
            className={cn(button.linkSecondary, core.textBold)}
            href="https://my.danfoss.com"
            target="_blank"
            rel="noreferrer"
            data-testid="context-menu-dip-profile-link"
          >
            You profile
          </a>
        </li>
        <li className={utils.mt2}>{user?.name}</li>
        <li className={utils.mt2}>{user?.email}</li>
        <li className={utils.mt2}>
          <a
            className={cn(button.linkSecondary, core.textBold)}
            href="#"
            data-testid="context-menu-dip-logout-button"
            onClick={handleLogoutClick}
          >
            Log out
          </a>
        </li>
      </ul>
    </>
  );
};

export default ProfileContextMenuDIP;
