import { RowType, TableColumnType } from '@danfoss/mosaic';
import core from '@danfoss/mosaic/css/core.module.css';
import { DfLoader, DfTable } from '@danfoss/mosaic/react';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { getDocumentsByProducts, IDocument } from 'api/documents';
import { trackEvent } from 'utils/analytics';

import styles from './DocumentList.module.scss';
import NoDocuments from './components/NoDocuments/NoDocuments';
import { actionsCell, languageCell, nameCell } from './formatters';

interface IDocumentListProps {
  productIds: string[];
}

const DocumentList = ({ productIds }: IDocumentListProps) => {
  const { formatMessage } = useIntl();
  const [documents, setDocuments] = useState<IDocument[]>();
  const [hasError, setHasError] = useState(false);

  const handleDownloadClick = (row: RowType<IDocument>) => () => {
    const { original } = row;
    const urlParts = original.url.split('.');
    const fileExtension = urlParts[urlParts.length - 1];

    trackEvent({
      event: 'download',
      downloadType: fileExtension,
      downloadCategory: original.type,
      downloadName: original.name,
      downloadPath: original.url,
    });
  };

  const columns: TableColumnType<IDocument>[] = [
    {
      accessor: 'name',
      id: 'name',
      Header: formatMessage({ id: 'documents_header_name' }),
      width: 480,
      Cell: nameCell,
    },
    {
      accessor: 'type',
      id: 'type',
      Header: formatMessage({ id: 'documents_header_type' }),
    },
    {
      accessor: 'language',
      id: 'language',
      Header: formatMessage({ id: 'documents_header_language' }),
      Cell: languageCell(formatMessage),
    },
    {
      accessor: 'url',
      id: 'url',
      Header: formatMessage({ id: 'documents_header_actions' }),
      getCellProps: (_, row) => {
        return {
          className: core.flexAround,
          onClick: handleDownloadClick(row),
          'data-testid': 'download-link',
        };
      },
      getHeaderProps: () => ({ className: core.flexAround }),
      Cell: actionsCell,
    },
  ];

  useEffect(() => {
    const loadDocuments = async () => {
      try {
        const list = await getDocumentsByProducts(productIds);

        setDocuments(list);
      } catch {
        setHasError(true);
      }
    };

    loadDocuments();
  }, []);

  if (hasError) {
    return <NoDocuments />;
  }

  if (!documents) {
    return (
      <div className={styles.loader}>
        <DfLoader />
      </div>
    );
  }

  return (
    <div data-testid="documents-list">
      {documents.length ? (
        <DfTable
          columns={columns as unknown as TableColumnType[]}
          data={documents}
          isSortable={false}
        />
      ) : (
        <NoDocuments />
      )}
    </div>
  );
};

export default DocumentList;
