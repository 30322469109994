import { useAuth0 } from '@auth0/auth0-react';
import { Redirect } from 'react-router-dom';

import Login from 'views/Login/Login';

const DIPLogin = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  if (isAuthenticated) {
    return <Redirect to="/" />;
  }

  return <Login onLogin={() => loginWithRedirect()} />;
};

export default DIPLogin;
