import { ChangeEvent, FocusEvent, forwardRef, useContext, useEffect, useState } from 'react';

import Input, { IInput } from 'views/shared/components/form/Input';

import { round } from '../formatters';
import { MeasuringSystemContext, MeasuringUnits } from './MeasuringSystemContext';
import { convert, Units } from './convertors';

interface IConverterInputProps extends IInput {
  metric: Units;
  imperial: Units;
  value: string | number;
  precision?: {
    metric: number;
    imperial: number;
  };
}

export default forwardRef<HTMLInputElement, IConverterInputProps>(function ConverterInput(
  { value, metric, imperial, onChange, onBlur, precision, min, max, ...rest },
  ref,
) {
  const { units } = useContext(MeasuringSystemContext);
  const isImperialSystem = units === MeasuringUnits.Imperial;
  const convertToImperial = (x: number) => convert(x).from(metric).to(imperial);
  const convertFromImperial = (x: number) => convert(x).from(imperial).to(metric);

  const metricPrecision = precision?.metric ?? 3;
  const imperialPrecision = precision?.imperial ?? 3;

  const [metricValue, setMetricValue] = useState('');
  const [imperialValue, setImperialValue] = useState('');

  useEffect(() => {
    if (value === '') {
      setMetricValue('');
      setImperialValue('');
      return;
    }

    if (+value !== +metricValue) {
      setMetricValue(round(+value, metricPrecision).toString());
      setImperialValue(round(convertToImperial(+value), imperialPrecision).toString());
    }
  }, [value]);

  useEffect(() => {
    if (value === '') {
      setMetricValue('');
      setImperialValue('');
      return;
    }

    setMetricValue(x => round(+x, metricPrecision).toString());
    setImperialValue(x => round(+x, imperialPrecision).toString());
  }, [units]);

  const updateStoredValues = (newValue: string) => {
    if (newValue === '') {
      setMetricValue('');
      setImperialValue('');
      return '';
    }

    const currentImperialValue = isImperialSystem
      ? newValue
      : convertToImperial(+newValue).toString();
    const currentMetricValue = isImperialSystem
      ? convertFromImperial(+newValue).toString()
      : newValue;

    setMetricValue(currentMetricValue);
    setImperialValue(currentImperialValue);

    return +currentMetricValue;
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const currentValue = e.target.value;
    const outputValue = updateStoredValues(currentValue);

    if (onChange) {
      const customOnChangeEvent = new Event('change') as unknown as ChangeEvent<HTMLInputElement>;

      Object.defineProperty(customOnChangeEvent, 'target', {
        value: { value: outputValue },
      });

      onChange(customOnChangeEvent);
    }
  };

  const handleBlur = () => {
    if (onBlur) {
      const customOnBlurEvent = new Event('blur') as unknown as FocusEvent<HTMLInputElement>;
      const outputValue = metricValue === '' ? '' : +metricValue;

      Object.defineProperty(customOnBlurEvent, 'target', { value: { value: outputValue } });

      onBlur(customOnBlurEvent);
    }
  };

  const imperialMin = min && round(convertToImperial(+min), imperialPrecision);
  const imperialMax = max && round(convertToImperial(+max), imperialPrecision);

  return (
    <Input
      {...rest}
      ref={ref}
      value={String(isImperialSystem ? imperialValue : metricValue)}
      min={isImperialSystem ? imperialMin : min}
      max={isImperialSystem ? imperialMax : max}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
});
