import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfModal } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as SvgPrimeMover } from 'assets/images/wizard/requirements/prime_mover_modal.svg';

interface IPrimeMoverModalProps {
  onClose: () => void;
}

const PrimeMoverModal = ({ onClose }: IPrimeMoverModalProps) => {
  const { formatMessage } = useIntl();

  return (
    <DfModal
      heading={formatMessage({ id: 'wizard_requirements_prime_mover_modal_title' })}
      onModalClose={onClose}
      data-testid="prime-mover-modal"
      isOpen
    >
      <div slot="modal-body">
        <p className={utils.mb3}>
          <FormattedMessage id="wizard_requirements_prime_mover_modal_text_1" />
        </p>
        <p>
          <FormattedMessage id="wizard_requirements_prime_mover_modal_text_2" />
        </p>
        <div className={cn(core.flex, core.flexCenter, core.alignCenter, utils.my10)}>
          <SvgPrimeMover />
        </div>
      </div>
      <div slot="modal-footer">
        <button
          className={button.btnPrimary}
          type="button"
          onClick={() => onClose()}
          data-testid="prime-mover-modal-close"
        >
          <FormattedMessage id="wizard_requirements_prime_mover_modal_close_button" />
        </button>
      </div>
    </DfModal>
  );
};

export default PrimeMoverModal;
