import { getAppConfig } from 'configs/global';
import { parseParameter } from 'utils/parseParams';

const DCProjectIdKey = `${getAppConfig().APPLICATION_PREFIX}-dc-project-id`;

const parseDCProjectId = () => {
  return parseParameter('DesignCenterProjectId');
};

export const setDCProjectId = (projectId: string) => {
  localStorage.setItem(DCProjectIdKey, projectId);
};

export const getDCProjectId = () => {
  return parseDCProjectId() || localStorage.getItem(DCProjectIdKey);
};

export const clearDCProjectID = () => {
  localStorage.removeItem(DCProjectIdKey);
};
