import backdrop from '@danfoss/mosaic/css/components/backdrop.module.css';
import { DfHeader } from '@danfoss/mosaic/react';
import cn from 'classnames';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { AuthContext, AuthType } from 'views/shared/helpers/auth/AuthContext';

import ProfileContextMenuDIP from './components/ProfileContextMenuDIP';
import ProfileContextMenuSF from './components/ProfileContextMenuSF';

interface HeaderProps {
  onLogoClick?: () => void;
}

const Header = ({ onLogoClick }: HeaderProps) => {
  const { authType } = useContext(AuthContext);

  const history = useHistory();

  const [profileContextVisible, setProfileContextVisible] = useState(false);

  const handleLogoClick = () => {
    if (onLogoClick) {
      onLogoClick();
    } else {
      history.push('/');
    }
  };

  const handleContextToggle = () => {
    setProfileContextVisible(state => !state);
  };

  return (
    <>
      {profileContextVisible && (
        <div
          id="modal-backdrop"
          className={cn(backdrop.backdrop, backdrop.open)}
          onClick={handleContextToggle}
          data-testid="profile-menu-backdrop"
        />
      )}
      <DfHeader onLogoLinkClick={handleLogoClick} isMenuIconHidden data-testid="main-header">
        {/* "df-nav-item open" needed here because DfHeader has nested structure which relies on this classes */}
        <li slot="link" className={cn('df-nav-item', { open: profileContextVisible })}>
          {authType === AuthType.DIP && (
            <ProfileContextMenuDIP handleContextToggle={handleContextToggle} />
          )}
          {authType === AuthType.SF && (
            <ProfileContextMenuSF handleContextToggle={handleContextToggle} />
          )}
        </li>
      </DfHeader>
    </>
  );
};

export default Header;
