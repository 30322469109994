import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import TooltipInfo from 'views/shared/components/TooltipInfo/TooltipInfo';
import Label from 'views/shared/components/form/Label/Label';
import { usePriceFormatter } from 'views/shared/helpers/formatters';
import { IPrice, ISelectedMotor, MotorType } from 'views/shared/types';

import MotorModal from '../../../Motors/components/MotorModal/MotorModal';
import Header from '../../../components/widgets/components/Header/Header';
import EditableAttributes from '../EditableAttributes/EditableAttributes';
import MinDisplacementField from './MinDisplacementField';

export interface MotorProps {
  projectId: string;
  motor: ISelectedMotor;
  price?: IPrice;
  isReadOnly: boolean;
  onRemove?: (id: string) => void;
  onAttributesUpdate?: (isUpdating: boolean, isValid: boolean) => void;
  hideFinalDriveEfficiency?: boolean;
  numberOfMotors?: number;
  isReplaceable?: boolean;
}

export default function Motor({
  projectId,
  motor,
  price,
  isReadOnly,
  onRemove,
  onAttributesUpdate,
  hideFinalDriveEfficiency,
  numberOfMotors = 1,
  isReplaceable = true,
}: MotorProps) {
  const { formatMessage } = useIntl();
  const priceFormatter = usePriceFormatter();

  const [isReplaceModalVisible, setIsReplaceModalVisible] = useState(false);

  const {
    id,
    name,
    series,
    photo_url,
    type,
    speed_limit_rated_for_displacement,
    motor_speed,
    is_motor_overspeeding,
  } = motor;
  const formattedSpeed = motor_speed?.toFixed();
  const formatterRatedSpeed = speed_limit_rated_for_displacement?.toFixed();
  const motorToReplace = {
    current: id,
  };
  const priceLabel = priceFormatter(price);
  const isMinDisplacementVisible = type === MotorType.VariablePiston;

  const handleMotorModalOpen = () => {
    setIsReplaceModalVisible(true);
  };
  const handleMotorModalClose = () => {
    setIsReplaceModalVisible(false);
  };

  return (
    <>
      <Header
        id={id}
        name={name}
        series={series}
        spec={priceLabel}
        photoUrl={photo_url}
        photoAlt={formatMessage({ id: 'wizard_product_motor_widget_image_alt' })}
        onChange={isReplaceable ? handleMotorModalOpen : undefined}
        isReadOnly={isReadOnly}
        hideBorder
        onRemove={onRemove}
      />
      <div className={cn(core.flex, core.flexColumn)}>
        <div className={core.flex}>
          {isMinDisplacementVisible && (
            <div className={utils.mr8}>
              <MinDisplacementField motor={motor} />
            </div>
          )}
          <div className={cn(core.flex, core.flexColumn)}>
            <Label>
              <FormattedMessage id="wizard_product_motor_widget_motor_speed_title" />
            </Label>
            <div data-testid="motor-speed">
              {formattedSpeed} <FormattedMessage id="measurements_unit_rpm" />
            </div>
            <div
              className={cn(
                core.flex,
                core.alignCenter,
                is_motor_overspeeding && utils.colorAccentBase,
              )}
              data-testid="rated-motor-speed"
            >
              <span>
                <FormattedMessage id="wizard_product_motor_widget_rated_speed" />{' '}
                {formatterRatedSpeed} <FormattedMessage id="measurements_unit_rpm" />
              </span>
              {is_motor_overspeeding && (
                <TooltipInfo>
                  <FormattedMessage id="wizard_overspeeding_warning" />
                </TooltipInfo>
              )}
            </div>
          </div>
        </div>
        {onAttributesUpdate && (
          <div className={utils.mt5}>
            <EditableAttributes
              compact
              motor={motor}
              projectId={projectId}
              onUpdate={onAttributesUpdate}
              hideFinalDriveEfficiency={hideFinalDriveEfficiency}
            />
          </div>
        )}
      </div>
      {isReplaceModalVisible && (
        <MotorModal
          isOpen={isReplaceModalVisible}
          action="replace"
          numberOfMotors={numberOfMotors}
          focusedItem={motorToReplace}
          onModalClose={handleMotorModalClose}
        />
      )}
    </>
  );
}
