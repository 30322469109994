import { ReactComponent as SvgWarning } from '@danfoss/mosaic-icons/dist/svg/error-outline.svg';
import alert from '@danfoss/mosaic/css/components/alert.module.css';
import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import styles from './Toast.module.scss';

interface IToastWarningProps {
  heading: ReactNode;
  children: ReactNode;
  onClose: () => void;
  actions: ReactNode;
}

const ToastWarning = ({ children, heading, onClose, actions }: IToastWarningProps) => {
  return (
    <div className={cn(alert.alert, alert.alertWarning, styles.align)} data-testid="warning-toast">
      <SvgWarning className={cn(core.icon, alert.alertIcon)} />
      <div className={cn(alert.alertMessage, core.alignCenter)}>
        <p className={alert.alertHeading}>{heading}</p>
        <p>{children}</p>
      </div>
      {actions}
      <button
        className={cn(button.textBtn, button.textBtnSecondary, utils.ml4)}
        onClick={onClose}
        data-testid="close-alert-button"
      >
        <FormattedMessage id="toast_close_button" />
      </button>
    </div>
  );
};

export default ToastWarning;
