import { DfLoader } from '@danfoss/mosaic/react';
import { AxiosError } from 'axios';
import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { NOT_FOUND_STATUS } from 'configs/axios';
import { AuthContext } from 'views/shared/helpers/auth/AuthContext';
import { ProjectStatus } from 'views/shared/types';

import { getSharedPrivateProject, getSharedPublicProject } from './api/sharing-api';
import PrivateSharedSummary from './components/PrivateSharedSummary';
import PublicSharedSummary from './components/PublicSharedSummary';
import SharingInDraftModal from './components/SharingInDraftModal';
import SharingNotFoundModal from './components/SharingNotFoundModal';
import { ISharedPrivateProject, ISharedPublicProject } from './types';

const SharedSummary = () => {
  const { linkId } = useParams<{ linkId: string }>();
  const { isAuthenticated } = useContext(AuthContext);

  const [selectionData, setSelectionData] = useState<
    ISharedPrivateProject | ISharedPublicProject
  >();
  const [pageError, setPageError] = useState<AxiosError>();
  const isSharingUnavailable = pageError?.response?.status === NOT_FOUND_STATUS;

  const isSelectionLoading = !selectionData;
  const isSelectionInDraft = selectionData?.project.project_status === ProjectStatus.Draft;

  useEffect(() => {
    (async () => {
      try {
        const fetchFunction = isAuthenticated ? getSharedPrivateProject : getSharedPublicProject;
        const data = await fetchFunction(linkId);

        setSelectionData(data);
      } catch (error) {
        setPageError(error as AxiosError);
      }
    })();
  }, []);

  if (isSharingUnavailable) {
    return <SharingNotFoundModal />;
  }

  if (isSelectionInDraft) {
    return <SharingInDraftModal />;
  }

  if (isSelectionLoading) {
    return <DfLoader isGlobal isVisible data-testid="shared-summary-page-loader" />;
  }

  return isAuthenticated ? (
    <PrivateSharedSummary data={selectionData as ISharedPrivateProject} />
  ) : (
    <PublicSharedSummary data={selectionData as ISharedPublicProject} />
  );
};

export default SharedSummary;
