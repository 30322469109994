import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useTractiveForceFormatter } from 'views/shared/helpers/formatters';
import useMeasuringUnitsIntl from 'views/shared/helpers/measuringSystem/useMeasuringUnitsIntl';
import useMeasuringValueConvertor from 'views/shared/helpers/measuringSystem/useMeasuringValueConvertor';
import { IProjectPropelSizingData, MotorType } from 'views/shared/types';

import Parameter from './Parameter';

interface IPropelProps {
  data: IProjectPropelSizingData;
  fieldsToShow: Array<keyof IProjectPropelSizingData> | null;
  motorType: MotorType;
}

const Propel = ({ data, fieldsToShow, motorType }: IPropelProps) => {
  const { formatMessage, formatNumber } = useIntl();
  const { formatMeasuringUnit } = useMeasuringUnitsIntl();
  const { convertor } = useMeasuringValueConvertor();
  const formatTractiveForce = useTractiveForceFormatter();

  const tractiveForce = formatTractiveForce(data.tractive_force);
  const allFieldsToShow =
    fieldsToShow || (Object.keys(data) as Array<keyof IProjectPropelSizingData>);

  const workSpeed =
    motorType === MotorType.VariablePiston ? data.max_work_speed : data.max_travel_speed;

  const isPumpDriveRatioAvailable = Number.isFinite(data.pump_drive_ratio);
  const isPumpSpeedAvailable = Number.isFinite(data.pump_speed);
  const isDriveEfficiencyAvailable = Number.isFinite(data.pump_drive_efficiency);
  const isMaxWorkSpeedAvailable = Number.isFinite(workSpeed);
  const isMaxTravelSpeedAvailable = Number.isFinite(data.max_travel_speed);
  const isTractiveForceAvailable = Number.isFinite(data.tractive_force);
  const isPullRatioAvailable = Number.isFinite(data.pull_ratio);

  return (
    <div data-testid="propel-data">
      {isPumpDriveRatioAvailable && allFieldsToShow.includes('pump_drive_ratio') && (
        <Parameter
          field={<FormattedMessage id="specs_pump_drive_ratio" />}
          value={`${data.pump_drive_ratio!}${formatMessage({
            id: 'measurements_unit_ratio_one',
          })}`}
        />
      )}
      {isPumpSpeedAvailable && allFieldsToShow.includes('pump_speed') && (
        <Parameter
          field={<FormattedMessage id="specs_pump_speed" />}
          value={`${formatNumber(data.pump_speed!)} ${formatMessage({
            id: 'measurements_unit_rpm',
          })}`}
        />
      )}
      {isDriveEfficiencyAvailable && allFieldsToShow.includes('pump_drive_efficiency') && (
        <Parameter
          field={<FormattedMessage id="specs_pump_drive_efficiency" />}
          value={`${data.pump_drive_efficiency!}${formatMessage({
            id: 'measurements_unit_percentage',
          })}`}
        />
      )}
      {isMaxWorkSpeedAvailable && allFieldsToShow.includes('max_work_speed') && (
        <Parameter
          data-testid="propel_parameter_work_speed"
          field={<FormattedMessage id="specs_maximum_work_speed" />}
          value={`${convertor('km/h', 'mph', workSpeed!)} ${formatMeasuringUnit({
            metric: 'measurements_unit_kmh',
            imperial: 'measurements_unit_mph',
          })}`}
        />
      )}
      {isMaxTravelSpeedAvailable && allFieldsToShow.includes('max_travel_speed') && (
        <Parameter
          field={<FormattedMessage id="specs_maximum_travel_speed" />}
          value={`${convertor('km/h', 'mph', data.max_travel_speed!)} ${formatMeasuringUnit({
            metric: 'measurements_unit_kmh',
            imperial: 'measurements_unit_mph',
          })}`}
        />
      )}
      {isTractiveForceAvailable && allFieldsToShow.includes('tractive_force') && (
        <Parameter field={<FormattedMessage id="specs_tractive_force" />} value={tractiveForce} />
      )}
      {isPullRatioAvailable && allFieldsToShow.includes('pull_ratio') && (
        <Parameter field={<FormattedMessage id="specs_pull_ratio" />} value={data.pull_ratio} />
      )}
    </div>
  );
};

export default memo(Propel);
