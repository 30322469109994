import axios from 'axios';

import { IProject, ISelectedProducts } from 'views/shared/types';

export interface IComparison {
  project: IProject;
  products: ISelectedProducts;
}

export interface IComparisonResponse {
  projects: IComparison[];
  invalid_ids: string[];
}

export const getComparison = async (ids: string[]) => {
  const params = new URLSearchParams();

  ids.forEach(id => params.append('projects_ids', id));

  const { data } = await axios.get<IComparisonResponse>('/projects/comparisons', { params });

  return data;
};
