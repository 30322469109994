import { ReactComponent as SvgPlus } from '@danfoss/mosaic-icons/dist/svg/plus.svg';
import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppSelector } from 'configs/store';
import Button from 'views/shared/components/Button/Button';
import { ISelectedMotor, ISelectedPump } from 'views/shared/types';
import { getMotorsPrices, getPumpsPrices } from 'views/wizard/shared/store/projectSlice';

import Motor from '../Motor/Motor';
import Pump from '../Pump/Pump';
import styles from './MultipleMotors.module.scss';

export interface MultipleMotorsProps {
  projectId: string;
  className?: string;
  pump?: ISelectedPump;
  motors: ISelectedMotor[];
  hasWidgetsErrors: boolean;
  onAttributesUpdate: (isUpdating: boolean, isValid: boolean) => void;
  areFDEsEqual?: boolean;
  onRemove: () => void;
  onAdd: () => void;
  onKeepPumpSelectedChange: React.FormEventHandler<HTMLInputElement>;
}

export function MultipleMotors({
  projectId,
  className,
  pump,
  motors,
  hasWidgetsErrors,
  onAttributesUpdate,
  areFDEsEqual,
  onAdd,
  onRemove,
  onKeepPumpSelectedChange,
}: MultipleMotorsProps) {
  const motorsPrices = useAppSelector(getMotorsPrices);
  const pumpsPrices = useAppSelector(getPumpsPrices);

  const pumpPrice = pump ? pumpsPrices[pump.product_id] : undefined;

  const [motor1, motor2, motor3, motor4] = motors;
  const numberOfMotors = motors.length;

  return (
    <div className={cn(className, styles.grid)}>
      <div className={cn(styles.motor, styles.motor1)}>
        <Motor
          projectId={projectId}
          motor={motor1}
          price={motorsPrices[motor1.product_id]}
          isReadOnly={hasWidgetsErrors}
          onAttributesUpdate={onAttributesUpdate}
          hideFinalDriveEfficiency={areFDEsEqual}
          numberOfMotors={numberOfMotors}
        />
      </div>
      <div className={cn(styles.motor, styles.motor2)}>
        <Motor
          projectId={projectId}
          motor={motor2}
          price={motorsPrices[motor2.product_id]}
          isReadOnly={hasWidgetsErrors}
          onAttributesUpdate={onAttributesUpdate}
          hideFinalDriveEfficiency={areFDEsEqual}
          numberOfMotors={numberOfMotors}
        />
      </div>
      <div
        className={cn(styles['additional-motor'], styles['additional-motor-border'], styles.motor3)}
      >
        {motor3 ? (
          <Motor
            projectId={projectId}
            motor={motor3}
            price={motorsPrices[motor3?.product_id]}
            isReadOnly={hasWidgetsErrors}
            onRemove={onRemove}
            onAttributesUpdate={onAttributesUpdate}
            hideFinalDriveEfficiency={areFDEsEqual}
            numberOfMotors={numberOfMotors}
          />
        ) : (
          <div className={cn(core.flex, core.flexCenter, styles['add-motor'])}>
            <Button
              className={cn(button.textBtn, button.textBtnPrimary)}
              onClick={onAdd}
              data-testid="add-motor-button"
            >
              <SvgPlus className={core.icon} />
              <FormattedMessage id="wizard_product_add_motor" />
            </Button>
          </div>
        )}
      </div>
      <div className={cn(styles['additional-motor'], styles.motor4)}>
        {motor4 ? (
          <Motor
            projectId={projectId}
            motor={motor4}
            price={motorsPrices[motor4?.product_id]}
            isReadOnly={hasWidgetsErrors}
            onRemove={onRemove}
            onAttributesUpdate={onAttributesUpdate}
            hideFinalDriveEfficiency={areFDEsEqual}
            numberOfMotors={numberOfMotors}
          />
        ) : (
          <div className={cn(core.flex, core.flexCenter, styles['add-motor'])}>
            <Button
              className={cn(button.textBtn, button.textBtnPrimary)}
              onClick={onAdd}
              data-testid="add-motor-button"
            >
              <SvgPlus className={core.icon} />
              <FormattedMessage id="wizard_product_add_motor" />
            </Button>
          </div>
        )}
      </div>
      <div className={cn(utils.pl6, styles.pump)}>
        {pump && (
          <Pump
            pump={pump}
            price={pumpPrice}
            isReadOnly={hasWidgetsErrors}
            onKeepPumpSelectedChange={onKeepPumpSelectedChange}
          />
        )}
      </div>
    </div>
  );
}
