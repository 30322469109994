import button from '@danfoss/mosaic/css/components/button.module.css';
import select from '@danfoss/mosaic/css/components/select.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfModal } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { useState } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from 'views/shared/components/Button/Button';
import Fieldset from 'views/shared/components/form/Fieldset';
import Label from 'views/shared/components/form/Label/Label';
import { IPullRatioCalculation } from 'views/shared/types';

import calculationValues from '../pullRatioCalculationValues';
import styles from './PullRatioCalculationModal.module.scss';
import { getPullRatio } from './api/pullRatioCalculation';

interface IPullRatioCalculationModalProps {
  form: UseFormReturn<IPullRatioCalculation>;
  onClose: () => void;
  onSuccess: (data: IPullRatioCalculation) => void;
}

const PullRatioCalculationModal = ({
  form,
  onClose,
  onSuccess,
}: IPullRatioCalculationModalProps) => {
  const { register, getValues } = form;
  const { formatMessage } = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const getOptions = (list: { value: number; label: string }[]) =>
    list.map(({ value, label }) => (
      <option key={label} value={value}>
        {label}
      </option>
    ));

  const handleApply = async () => {
    try {
      setIsLoading(true);

      const data = getValues();
      const pullRatio = await getPullRatio(data);

      onSuccess({ ...data, pull_ratio: pullRatio });
    } finally {
      setIsLoading(false);
    }

    onClose();
  };

  return (
    <DfModal
      heading={formatMessage({ id: 'wizard_requirements_pull_ratio_calculation_modal_heading' })}
      isOpen
      onModalClose={onClose}
      data-testid="pull-ratio-calculation-modal"
    >
      <div slot="modal-body" className={styles.container}>
        <Fieldset className={cn(utils.mb2, styles.row)}>
          <Label>
            <FormattedMessage id="wizard_requirements_pull_ratio_calculation_modal_machine_function" />
          </Label>
          <select
            className={select.select}
            {...register('machine_function', {
              valueAsNumber: true,
            })}
            data-testid="pull-ratio-calculation-machine-function"
          >
            {getOptions(calculationValues.machineFunction)}
          </select>
        </Fieldset>
        <Fieldset className={cn(utils.mb2, styles.row)}>
          <Label>
            <FormattedMessage id="wizard_requirements_pull_ratio_calculation_modal_drive_configuration" />
          </Label>
          <select
            className={select.select}
            {...register('drive_configuration', {
              valueAsNumber: true,
            })}
            data-testid="pull-ratio-calculation-drive-configuration"
          >
            {getOptions(calculationValues.driveConfiguration)}
          </select>
        </Fieldset>
        <Fieldset className={cn(utils.mb2, styles.row)}>
          <Label>
            <FormattedMessage id="wizard_requirements_pull_ratio_calculation_modal_rolling_resistance" />
          </Label>
          <select
            className={select.select}
            {...register('rolling_resistance', {
              valueAsNumber: true,
            })}
            data-testid="pull-ratio-calculation-rolling-resistance"
          >
            {getOptions(calculationValues.rollingResistance)}
          </select>
        </Fieldset>
        <Fieldset className={cn(utils.mb4, styles.row)}>
          <Label>
            <FormattedMessage id="wizard_requirements_pull_ratio_calculation_modal_gradeability" />
          </Label>
          <select
            className={select.select}
            {...register('grade_of_slope', {
              valueAsNumber: true,
            })}
            data-testid="pull-ratio-calculation-grade-of-slope"
          >
            {getOptions(calculationValues.gradeability)}
          </select>
        </Fieldset>
      </div>
      <div slot="modal-footer">
        <Button className={cn(button.textBtn, button.textBtnSecondary)} onClick={onClose}>
          <FormattedMessage id="wizard_requirements_pull_ratio_calculation_modal_cancel" />
        </Button>
        <Button
          className={button.btnPrimary}
          onClick={handleApply}
          type="button"
          loading={isLoading}
          data-testid="pull-ratio-calculation-modal-apply-button"
        >
          <FormattedMessage id="wizard_requirements_pull_ratio_calculation_modal_apply" />
        </Button>
      </div>
    </DfModal>
  );
};

export default PullRatioCalculationModal;
