import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfLoader } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { FormEventHandler, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import helpers from 'styles/helpers.module.scss';

import { useAppDispatch, useAppSelector } from 'configs/store';
import { ISelectedMotor, MotorType } from 'views/shared/types';

import { showOverSpeedingWarning } from '../../../../shared/helpers/showOverSpeedingWarning';
import {
  autoUpdateProducts,
  getCalculations,
  getCurrent,
  getMotorDetailsAndCalculations,
  getMotorsPrices,
  getPropelSizingData,
  getPumpsPrices,
  getSelectedMotors,
  getSelectedPumps,
  setKeepPumpSelected,
} from '../../../../shared/store/projectSlice';
import { useMotorTypeContext } from '../../../motor-type-context/motorTypeContext';
import MotorTypeSelection from '../../Motors/components/MotorTypeSelection';
import styles from '../OneMotorPropelSystem/OneMotorPropelSystem.module.scss';
import { FinalDriveEfficiency } from '../components/EditableAttributes/FinalDriveEfficiency';
import Motor from '../components/Motor/Motor';
import Pump from '../components/Pump/Pump';
import { ISummaryProps, Summary } from '../components/Summary/Summary';

export const DualPathPropelSystem = () => {
  const dispatch = useAppDispatch();

  const project = useAppSelector(getCurrent);
  const calculations = useAppSelector(getCalculations);
  const selectedMotors = useAppSelector(getSelectedMotors)!;
  const selectedPumps = useAppSelector(getSelectedPumps)!;
  const pumpsPrices = useAppSelector(getPumpsPrices);
  const motorsPrices = useAppSelector(getMotorsPrices);
  const propelSizing = useAppSelector(getPropelSizingData)!;

  const { motorType, setMotorType } = useMotorTypeContext();

  const [isSectionUpdating, setIsSectionUpdating] = useState(false);
  const [hasWidgetsErrors, setHasWidgetsErrors] = useState(false);

  const [motor1, motor2] = selectedMotors;
  const [pump1, pump2] = selectedPumps;

  const { id: projectId } = project;
  const finalDriveEfficiency = motor1.final_drive_efficiency;
  const { recommended_motor_type } = propelSizing;

  const motor1Price = motorsPrices[motor1.product_id];
  const motor2Price = motorsPrices[motor2.product_id];
  const pump1Price = pumpsPrices[pump1.product_id];
  const pump2Price = pumpsPrices[pump2.product_id];

  const [tractiveForceActual, tractiveForceRequired] = selectedMotors.reduce(
    ([actual, required], { tractive_force_actual, tractive_force_required }) => {
      return [actual + (tractive_force_actual ?? 0), required + tractive_force_required];
    },
    [0, 0],
  );
  const tractiveForce: ISummaryProps['tractiveForce'] = {
    actual: tractiveForceActual,
    required: tractiveForceRequired,
  };

  const clearMotors = async (newMotorType?: MotorType) => {
    try {
      setIsSectionUpdating(true);

      const { pumps, warning } = await dispatch(
        autoUpdateProducts({ projectId, displacementType: newMotorType }),
      ).unwrap();
      showOverSpeedingWarning(warning, projectId);

      const pumpsId = pumps.map(pumpItem => pumpItem.selected_pump.product_id);

      await dispatch(getMotorDetailsAndCalculations({ projectId, pumpsId })).unwrap();
    } finally {
      setIsSectionUpdating(false);
    }
  };
  const onAttributesUpdate = (isUpdating: boolean, isValid: boolean) => {
    setIsSectionUpdating(isUpdating);
    setHasWidgetsErrors(!isValid);
  };
  const onKeepPumpSelectedChange: FormEventHandler<HTMLInputElement> = ({ target }) => {
    const getMotorDisplacement = ({ id, min_displacement }: ISelectedMotor) => ({
      id,
      min_displacement,
    });
    const displacements =
      motorType === MotorType.VariablePiston ? selectedMotors.map(getMotorDisplacement) : [];

    dispatch(
      setKeepPumpSelected({
        pump: { id: pump1.id, product_id: pump1.product_id },
        keepPump: (target as HTMLInputElement).checked,
        displacements,
      }),
    );
  };

  useEffect(() => {
    if (!motorType) {
      setMotorType(selectedMotors[0].type);
    }
  }, [selectedMotors, motorType, setMotorType]);

  return (
    <section
      className={cn(utils.mt10, helpers.loaderWrapper)}
      data-testid="dual-path-propel-system"
    >
      <DfLoader isVisible={isSectionUpdating}>
        <h3>
          <FormattedMessage id="wizard_product_selected_propel_system" />
        </h3>
        <div className={utils.mb5}>
          <MotorTypeSelection
            motorTypeWarning={recommended_motor_type?.warning}
            isCustomFlow={false}
            onMotorTypeForcedChange={clearMotors}
            isMotorSelected
          />
        </div>
        <FinalDriveEfficiency
          className={utils.my5}
          finalDriveEfficiency={finalDriveEfficiency}
          compact
        />
        <div className={cn(utils.row, utils.mb6)}>
          <div
            className={cn(utils.col3, utils.colMd4, utils.colSm8, utils.mb3, utils.pb4)}
            data-testid="motor-widget"
          >
            <Motor
              projectId={projectId}
              motor={motor1}
              price={motor1Price}
              isReadOnly={hasWidgetsErrors}
              onAttributesUpdate={onAttributesUpdate}
              numberOfMotors={2}
              hideFinalDriveEfficiency
            />
          </div>
          <div
            className={cn(
              utils.col3,
              utils.colMd4,
              utils.colSm8,
              utils.mb3,
              utils.mt10,
              utils.pb4,
              styles['motor-widget'],
            )}
            data-testid="motor-widget"
          >
            <Motor
              projectId={projectId}
              motor={motor2}
              price={motor2Price}
              isReadOnly={hasWidgetsErrors}
              onAttributesUpdate={onAttributesUpdate}
              isReplaceable={false}
              numberOfMotors={2}
              hideFinalDriveEfficiency
            />
          </div>
          <div className={cn(utils.col3, utils.colMd4, utils.colSm8)} data-testid="pump-widget">
            <Pump
              pump={pump1}
              price={pump1Price}
              isReadOnly={hasWidgetsErrors}
              onKeepPumpSelectedChange={onKeepPumpSelectedChange}
              numberOfPumps={2}
            />
          </div>
          <div
            className={cn(utils.col3, utils.colMd4, utils.colSm8, utils.mt10)}
            data-testid="pump-widget"
          >
            <Pump
              pump={pump2}
              price={pump2Price}
              isReadOnly={hasWidgetsErrors}
              numberOfPumps={2}
              isReplaceable={false}
            />
          </div>
        </div>
        <Summary className={utils.mt6} calculations={calculations} tractiveForce={tractiveForce} />
      </DfLoader>
    </section>
  );
};
