import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  ISelectedSteeringProduct,
  ISteeringUnitAttributes,
  SteeringFunction,
} from 'views/shared/types';

import Header from '../../components/widgets/components/Header/Header';
import StaticAttribute from '../../components/widgets/components/StaticAttributes/StaticAttribute';
import { getMainSpec } from '../helpers';

interface SteeringWidgetProps {
  data: ISelectedSteeringProduct;
  unitAttributes: ISteeringUnitAttributes;
  type: SteeringFunction;
  onChange: () => void;
  onRemove?: () => void;
}

const SteeringWidget = ({
  data,
  unitAttributes,
  type,
  onChange,
  onRemove,
}: SteeringWidgetProps) => {
  const { formatMessage, formatNumber } = useIntl();
  const { id, name, series, photo_url } = data;
  const { required_l2l_turns, actual_l2l_turns, required_l2l_sec, actual_l2l_sec } = unitAttributes;
  const isConventionalSteering = type === SteeringFunction.Conventional;

  const l2lLabel = isConventionalSteering
    ? formatMessage({ id: 'specs_l2l_turns' })
    : formatMessage({ id: 'specs_l2l_seconds' });
  const l2lUnits = isConventionalSteering
    ? formatMessage({ id: 'measurements_unit_turns' })
    : formatMessage({ id: 'measurements_unit_seconds' });
  const requiredL2LValue = formatNumber(
    isConventionalSteering ? required_l2l_turns! : required_l2l_sec!,
  );
  const actualL2LValue = formatNumber(isConventionalSteering ? actual_l2l_turns! : actual_l2l_sec!);
  const requiredL2L = `${requiredL2LValue} ${l2lUnits}`;
  const actualL2L = `${actualL2LValue} ${l2lUnits}`;
  const mainSpec = getMainSpec(data);

  return (
    <div data-testid="steering-widget">
      <Header
        id={id}
        photoUrl={photo_url}
        photoAlt={formatMessage({ id: 'wizard_product_steering_unit_widget_image_alt' })}
        name={name}
        series={series}
        spec={mainSpec}
        isReadOnly={false}
        onChange={onChange}
        onRemove={onRemove}
      />
      <div>
        <p className={cn(core.textBold, utils.my2)}>{l2lLabel}</p>
        <div>
          <StaticAttribute
            name={<FormattedMessage id="wizard_product_steering_unit_widget_required" />}
            value={requiredL2L}
            testId="steering-widget-required-l2l"
          />
          <StaticAttribute
            name={<FormattedMessage id="wizard_product_steering_unit_widget_actual" />}
            value={actualL2L}
            testId="steering-widget-actual-l2l"
          />
        </div>
      </div>
    </div>
  );
};

export default SteeringWidget;
