import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { useEffect, useMemo } from 'react';
import { ControllerRenderProps, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import helpers from 'styles/helpers.module.scss';

import { useAppDispatch } from 'configs/store';
import debounce from 'utils/debounce';
import { ISelectedMotor } from 'views/shared/types';

import {
  getMotorDetailsAndCalculations,
  getSelectedProducts,
  updateMotor,
} from '../../../../../shared/store/projectSlice';
import styles from './EditableAttributes.module.scss';
import { FinalDriveEfficiency } from './FinalDriveEfficiency';
import { FinalDriveRatioField } from './FinalDriveRatioField';
import { RollingRadiusField } from './RollingRadiusField';
import { IEditableAttributesForm } from './types';

interface IEditableAttributesProps {
  motor: ISelectedMotor;
  projectId: string;
  onUpdate: (isUpdating: boolean, isValid: boolean) => void;
  compact?: boolean;
  hideFinalDriveEfficiency?: boolean;
  hasGearbox?: boolean;
}

function useEditableAttributesForm({
  motor,
  projectId,
  onUpdate,
}: {
  motor: ISelectedMotor;
  projectId: string;
  onUpdate: (isUpdating: boolean, isValid: boolean) => void;
}) {
  const { id, product_id, rolling_radius, final_drive_ratio, final_drive_ratio2 } = motor;
  const dispatch = useAppDispatch();
  const form = useForm<IEditableAttributesForm>({
    mode: 'onChange',
    defaultValues: {
      rolling_radius,
      final_drive_ratio,
      final_drive_ratio2,
    },
  });

  const {
    formState: { isValid },
    handleSubmit,
    setValue,
  } = form;

  const onSubmit = async (values: IEditableAttributesForm) => {
    try {
      onUpdate(true, true);

      await dispatch(
        updateMotor({
          motor: {
            id,
            product_id,
            ...values,
            final_drive_ratio: values.final_drive_ratio ?? undefined,
            final_drive_ratio2: values.final_drive_ratio2 ?? undefined,
          },
        }),
      ).unwrap();
      const products = await dispatch(getSelectedProducts(projectId)).unwrap();
      const pumpsId = products.pumps.map(pump => pump.selected_pump.product_id);

      await dispatch(getMotorDetailsAndCalculations({ projectId, pumpsId })).unwrap();
    } finally {
      onUpdate(false, true);
    }
  };

  const onSubmitDebounced = useMemo(
    () => debounce(handleSubmit(onSubmit), 1000),
    [id, product_id, projectId],
  );

  const onChange =
    (field: ControllerRenderProps) => (event: { target: { value: string | null } }) => {
      field.onChange(event);

      onSubmitDebounced();
    };

  useEffect(() => {
    setValue('rolling_radius', rolling_radius!, { shouldValidate: true });
    setValue('final_drive_ratio', final_drive_ratio!, { shouldValidate: true });
    setValue('final_drive_ratio2', final_drive_ratio2!, { shouldValidate: true });
  }, [rolling_radius, final_drive_ratio, final_drive_ratio2]);

  useEffect(() => {
    onUpdate(false, isValid);
  }, [isValid]);

  return {
    onChange,
    form,
  };
}

export default function EditableAttributes({
  motor,
  projectId,
  onUpdate,
  compact,
  hideFinalDriveEfficiency,
  hasGearbox,
}: IEditableAttributesProps) {
  const { final_drive_efficiency, recommended_final_drive_ratio, recommended_final_drive_ratio2 } =
    motor;
  const { form, onChange } = useEditableAttributesForm({ motor, projectId, onUpdate });

  const efficiency = !hideFinalDriveEfficiency && (
    <FinalDriveEfficiency
      compact={compact}
      finalDriveEfficiency={final_drive_efficiency}
      className={cn({ [utils.mt5]: compact, [utils.ml5]: !compact })}
    />
  );

  return (
    <div className={helpers.loaderWrapper}>
      <div className={cn(!compact && styles.wrapper, core.flex)}>
        <RollingRadiusField form={form} onChange={onChange} />
        <FinalDriveRatioField
          form={form}
          field="final_drive_ratio"
          onChange={onChange}
          recommendedFinalDriveRatio={recommended_final_drive_ratio}
          title={
            hasGearbox ? (
              <FormattedMessage id="wizard_product_motor_widget_final_drive_ratio1" />
            ) : null
          }
        />
        {hasGearbox && (
          <FinalDriveRatioField
            form={form}
            field="final_drive_ratio2"
            onChange={onChange}
            recommendedFinalDriveRatio={recommended_final_drive_ratio2}
            title={<FormattedMessage id="wizard_product_motor_widget_final_drive_ratio2" />}
          />
        )}
        {!compact && efficiency}
      </div>
      {compact && efficiency}
    </div>
  );
}
