import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ScaleOptionsByType,
  Legend,
  LegendOptions,
} from 'chart.js';
import { useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { useIntl } from 'react-intl';

import { IMotorPerformanceCase } from 'views/shared/api/performance';
import {
  MeasuringSystemContext,
  MeasuringUnits,
} from 'views/shared/helpers/measuringSystem/MeasuringSystemContext';
import useMeasuringUnitsIntl from 'views/shared/helpers/measuringSystem/useMeasuringUnitsIntl';
import { usePerformancePointsHelper } from 'views/shared/helpers/performance';

import styles from './PerformanceChart.module.scss';

interface IPerformanceChartProps {
  data: IMotorPerformanceCase[];
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Legend);

const PerformanceChart = ({ data }: IPerformanceChartProps) => {
  const { units } = useContext(MeasuringSystemContext);
  const isImperialSystem = units === MeasuringUnits.Imperial;
  const { formatMessage } = useIntl();
  const { formatMeasuringUnit } = useMeasuringUnitsIntl();
  const extractPoints = usePerformancePointsHelper();

  const datasetConfig = [
    {
      label: formatMessage({ id: 'wizard_summary_performance_prediction_legend_work' }),
      color: styles.red,
    },
    {
      label: formatMessage({ id: 'wizard_summary_performance_prediction_legend_travel' }),
      color: styles.black,
    },
  ];

  const createDataset = (motorCase: IMotorPerformanceCase) => {
    const { color, label } = datasetConfig[motorCase.case_number];
    const points = extractPoints(motorCase.total_performance);

    return {
      label,
      data: points,
      backgroundColor: color,
      borderColor: color,
      tension: 0.25,
    };
  };

  const tractiveForceLabel = formatMessage(
    { id: 'performance_prediction_label_tractive_force' },
    {
      units: formatMessage({
        id: isImperialSystem ? 'measurements_unit_lbf' : 'measurements_unit_kn',
      }),
    },
  );
  const vehicleSpeedLabel = formatMessage(
    { id: 'performance_prediction_label_vehicle_speed' },
    {
      units: formatMeasuringUnit({
        metric: 'measurements_unit_kmh',
        imperial: 'measurements_unit_mph',
      }),
    },
  );

  const title = {
    display: true,
    color: styles.black,
    font: {
      family: styles.fontFamily,
      size: 16,
    },
  };

  const chartOptions = {
    animation: {
      duration: 0,
    },
    scales: {
      y: {
        title: {
          ...title,
          text: tractiveForceLabel,
        },
        beginAtZero: true,
      } as ScaleOptionsByType<'linear'>,
      x: {
        type: 'linear',
        title: {
          ...title,
          text: vehicleSpeedLabel,
        },
        grid: {
          display: false,
        },
        beginAtZero: true,
      } as ScaleOptionsByType<'linear'>,
    },
    plugins: {
      legend: {
        display: data.length > 1,
        position: 'left',
        align: 'start',
        title: {
          display: true,
          text: formatMessage({ id: 'wizard_summary_performance_prediction_legend_title' }),
          font: {
            family: styles.fontFamily,
            weight: '700',
            size: 16,
          },
          color: styles.black,
        },
        labels: {
          boxHeight: 18,
          boxWidth: 18,
          usePointStyle: true,
          padding: 16,
          font: {
            size: 14,
            family: styles.fontFamily,
          },
        },
      } as LegendOptions<'line'>,
    },
  };

  const chartData = {
    datasets: data.map(createDataset),
  };

  return <Line data={chartData} options={chartOptions} />;
};

export default PerformanceChart;
