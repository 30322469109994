import button from '@danfoss/mosaic/css/components/button.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfModal } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import styles from './TrasmissionRatioInfoDialog.module.scss';

interface ITransmissionRatioProps {
  onClose: () => void;
}

export function TransmissionRatioInfoDialog({ onClose }: ITransmissionRatioProps) {
  const { formatMessage } = useIntl();

  return (
    <DfModal
      heading={formatMessage({
        id: 'wizard_requirements_transmission_ratio_info_header',
      })}
      onModalClose={onClose}
      data-testid="transmission-ratio-info-dialog"
      isOpen
    >
      <div slot="modal-body">
        <p className={cn(utils.mb3, styles.body)}>
          <FormattedMessage id="wizard_requirements_transmission_ratio_info_body" />
        </p>
      </div>
      <div slot="modal-footer">
        <button
          type="button"
          className={button.btnPrimary}
          onClick={onClose}
          data-testid="transmission-ratio-info-dialog-ok"
        >
          <FormattedMessage id="wizard_requirements_transmission_ratio_info_ok" />
        </button>
      </div>
    </DfModal>
  );
}
