import { ReactComponent as SvgPlus } from '@danfoss/mosaic-icons/dist/svg/plus.svg';
import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppSelector } from 'configs/store';
import Button from 'views/shared/components/Button/Button';
import { MachineExampleCode, MotorType, SystemArchitecture } from 'views/shared/types';
import ConfirmationModal from 'views/wizard/shared/components/ConfirmationModal';
import useConfirmationModal from 'views/wizard/shared/helpers/useConfirmationModal';
import {
  getCurrent,
  getSelectedMotors,
  getSelectedPumps,
} from 'views/wizard/shared/store/projectSlice';

import styles from '../../ProductSelection.module.scss';
import NumberOfProducts from '../components/NumberOfProducts';
import { SelectionModalAction } from '../components/modals/SelectionModal/SelectionModal';
import MotorModal from './components/MotorModal/MotorModal';
import MotorTypeSelection from './components/MotorTypeSelection';
import MotorWidget from './components/MotorWidget/MotorWidget';
import TotalTractiveForce from './components/TotalTractiveForce/TotalTractiveForce';

interface IMotorsProps {
  systemArchitecture?: SystemArchitecture;
  clearMotors: (motorType?: MotorType) => Promise<void>;
  removeMotorById: (id: string) => Promise<void>;
  onAvailableMotorsChange: (numberOfMotorsLeft: number) => void;
  onValidation: (id: string, hasError: boolean) => void;
}

const motorsSystemArchitecture = {
  [SystemArchitecture.OnePumpOneMotor]: 1,
  [SystemArchitecture.OnePumpOneMotorGearBox]: 1,
  [SystemArchitecture.OnePumpTwoMotors]: 2,
  [SystemArchitecture.DualPath]: 2,
  [SystemArchitecture.Custom]: 1,
};

const Motors = ({
  systemArchitecture,
  clearMotors,
  removeMotorById,
  onAvailableMotorsChange,
  onValidation,
}: IMotorsProps) => {
  const { formatMessage } = useIntl();
  const project = useAppSelector(getCurrent);
  const { propel_sizing } = project;
  const { recommended_motor_type } = propel_sizing!;
  const selectedPumps = useAppSelector(getSelectedPumps);
  const selectedMotors = useAppSelector(getSelectedMotors);
  const isMotorSelected = selectedMotors && selectedMotors.length > 0;
  const isPumpSelected = Boolean(selectedPumps && selectedPumps.length > 0);
  const isCustomFlow = project.machine_example_code === MachineExampleCode.Custom;
  const isAddingAvailable =
    isCustomFlow || project.machine_example_code === MachineExampleCode.OnePumpOneMotor;

  const [selectedNumberOfMotors, setSelectedNumberOfMotors] = useState(
    motorsSystemArchitecture[systemArchitecture || SystemArchitecture.Custom],
  );
  const availableMotorsToAdd = selectedNumberOfMotors - (selectedMotors?.length || 0);

  const isGearBoxAvailable =
    Boolean(selectedMotors?.length === 1 && availableMotorsToAdd === 0) && isCustomFlow;

  useEffect(() => {
    if (isMotorSelected) {
      setSelectedNumberOfMotors(selectedMotors.length);
    }
  }, [project.id]);

  useEffect(() => {
    onAvailableMotorsChange(availableMotorsToAdd);
  }, [availableMotorsToAdd]);

  const {
    props: numberOfMotorsConfirmationModalProps,
    open: openNumberOfMotorsConfirmationModal,
    isOpen: isNumberOfMotorsConfirmationModalOpen,
  } = useConfirmationModal<number>(async lastSelectedNumber => {
    await clearMotors();

    setSelectedNumberOfMotors(lastSelectedNumber);
  });

  const onNumberOfMotorsChange = (newNumber: number) => {
    if (!isMotorSelected) {
      setSelectedNumberOfMotors(newNumber);
      return;
    }

    openNumberOfMotorsConfirmationModal(newNumber);
  };

  const [motorModalMode, setMotorModalMode] = useState<SelectionModalAction>(null);
  const openAddMotorModal = () => setMotorModalMode('add');
  const openReplaceMotorModal = useCallback(() => setMotorModalMode('replace'), []);
  const motorToReplace = useRef<string | null>(null);

  const {
    props: replaceMotorConfirmationModalProps,
    open: openReplaceMotorConfirmationModal,
    isOpen: isReplaceMotorConfirmationModalOpen,
  } = useConfirmationModal<string>(openReplaceMotorModal);

  const handleReplaceMotor = useCallback(
    (selectedMotor: string) => {
      const isConfirmationNeeded = isCustomFlow && isPumpSelected;

      motorToReplace.current = selectedMotor;

      if (isConfirmationNeeded) {
        openReplaceMotorConfirmationModal();
        return;
      }

      openReplaceMotorModal();
    },
    [openReplaceMotorConfirmationModal, openReplaceMotorModal, isPumpSelected, isCustomFlow],
  );

  const handleMotorModalClose = () => {
    motorToReplace.current = null;
    setMotorModalMode(null);
  };

  const {
    props: removeMotorConfirmationModalProps,
    open: openRemoveMotorConfirmationModal,
    isOpen: isRemoveMotorConfirmationModalOpen,
  } = useConfirmationModal<string>(id => {
    removeMotorById(id);
  });

  const onRemoveMotor = useCallback(
    (selectedItem: string) => {
      if (!isPumpSelected) {
        removeMotorById(selectedItem);
        return;
      }

      openRemoveMotorConfirmationModal(selectedItem);
    },
    [openRemoveMotorConfirmationModal, removeMotorById, isPumpSelected],
  );

  return (
    <section className={utils.mt20}>
      <h3>
        <FormattedMessage id="wizard_product_selected_motor" />
      </h3>
      <MotorTypeSelection
        motorTypeWarning={recommended_motor_type?.warning}
        isMotorSelected={Boolean(isMotorSelected)}
        isCustomFlow={isCustomFlow}
        onMotorTypeForcedChange={clearMotors}
      />
      {isCustomFlow && (
        <NumberOfProducts
          title={formatMessage({ id: 'wizard_product_number_of_motors_label' })}
          type="motor"
          hasProducts={Boolean(isMotorSelected)}
          number={selectedNumberOfMotors}
          onChange={onNumberOfMotorsChange}
        />
      )}
      <div className={cn(utils.row, utils.mt8)}>
        {isMotorSelected &&
          selectedMotors.map(motor => (
            <div
              key={`${motor.id}-${motor.product_id}`}
              className={cn(
                !isGearBoxAvailable && cn(utils.col3, utils.colMd4, utils.colSm8, utils.pt2),
                !isGearBoxAvailable && styles['product-item'],
              )}
            >
              <MotorWidget
                data={motor}
                onRemove={isCustomFlow ? onRemoveMotor : undefined}
                onChange={handleReplaceMotor}
                isPumpAdded={isPumpSelected}
                isCustomFlow={isCustomFlow}
                isSpeedGearboxAvailable={isGearBoxAvailable}
                onValidation={onValidation}
                projectId={project.id}
              />
            </div>
          ))}
        {availableMotorsToAdd > 0 &&
          isAddingAvailable &&
          new Array(availableMotorsToAdd).fill(null).map((value, index) => (
            <div
              key={index}
              className={cn(utils.col3, utils.colMd4, utils.colSm8, styles['add-item'])}
            >
              <Button
                className={cn(button.textBtn, button.textBtnPrimary)}
                onClick={openAddMotorModal}
                data-testid="product-selection-add-motor-button"
              >
                <SvgPlus className={core.icon} />
                <FormattedMessage id="wizard_product_add_motor" />
              </Button>
            </div>
          ))}
      </div>
      {isMotorSelected && selectedNumberOfMotors > 1 && (
        <TotalTractiveForce selectedNumberOfMotors={selectedNumberOfMotors} />
      )}
      {motorModalMode && (
        <MotorModal
          isOpen={!!motorModalMode}
          action={motorModalMode}
          numberOfMotors={selectedNumberOfMotors}
          focusedItem={motorToReplace}
          onModalClose={handleMotorModalClose}
        />
      )}
      {isNumberOfMotorsConfirmationModalOpen && (
        <ConfirmationModal
          headingLabel={formatMessage({ id: 'wizard_confirmation_modal_heading' })}
          confirmLabel={formatMessage({
            id: 'wizard_product_motor_number_confirmation_modal_change_button',
          })}
          {...numberOfMotorsConfirmationModalProps}
        >
          <FormattedMessage id="wizard_product_motor_number_confirmation_modal_text" />
        </ConfirmationModal>
      )}
      {isRemoveMotorConfirmationModalOpen && (
        <ConfirmationModal
          headingLabel={formatMessage({
            id: 'wizard_product_remove_motor_confirmation_modal_heading',
          })}
          confirmLabel={formatMessage({
            id: 'wizard_product_remove_motor_confirmation_modal_change_button',
          })}
          {...removeMotorConfirmationModalProps}
        >
          <FormattedMessage id="wizard_product_remove_motor_confirmation_modal_text" />
        </ConfirmationModal>
      )}
      {isReplaceMotorConfirmationModalOpen && (
        <ConfirmationModal
          headingLabel={formatMessage({
            id: 'wizard_product_replace_motor_confirmation_modal_heading',
          })}
          confirmLabel={formatMessage({
            id: 'wizard_product_replace_motor_confirmation_modal_change_button',
          })}
          {...replaceMotorConfirmationModalProps}
        >
          <FormattedMessage id="wizard_product_replace_motor_confirmation_modal_text" />
        </ConfirmationModal>
      )}
    </section>
  );
};

export default Motors;
