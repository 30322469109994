import tabs from '@danfoss/mosaic/css/components/tabs.module.css';
import { useEffect, useState } from 'react';

import debounce from 'utils/debounce';

import styles from './useTabMode.module.scss';

export const TabsMode = {
  Horizontal: tabs.horizontal,
  Vertical: tabs.vertical,
};

const useTabMode = () => {
  const [tabMode, setTabMode] = useState<string>(TabsMode.Vertical);

  useEffect(() => {
    const breakpoint = parseFloat(styles.md);
    const adjustTabsMode = debounce(() => {
      const windowSize = window.screen.width;

      if (windowSize < breakpoint) {
        setTabMode(TabsMode.Horizontal);
      } else {
        setTabMode(TabsMode.Vertical);
      }
    }, 300);

    adjustTabsMode();
    window.addEventListener('resize', adjustTabsMode);

    return () => window.removeEventListener('resize', adjustTabsMode);
  }, []);

  return tabMode;
};

export default useTabMode;
