import { useTreatments } from '@splitsoftware/splitio-react';
import { useEffect, useMemo } from 'react';

import { useAppDispatch, useAppSelector } from 'configs/store';
import {
  AvailableFeatureFlags,
  FeatureFlagState,
} from 'views/shared/helpers/featureFlags/constants';
import { FunctionCode, MachineExampleCode } from 'views/shared/types';

import { architectureExamples, machineExamples } from './helpers/examplesData';
import { fetchMachineExamples, getMachineExamples } from './store/machineExamplesSlice';
import { IMachineExample } from './types';

export function useExampleOptions(selectedFunctions: FunctionCode[] | null) {
  const dispatch = useAppDispatch();
  const featureFlags = useTreatments([AvailableFeatureFlags.CustomArchitecture]);
  const isCustomOptionEnabled =
    featureFlags[AvailableFeatureFlags.CustomArchitecture]?.treatment === FeatureFlagState.On;
  const examples = useAppSelector(getMachineExamples);
  const isPageLoading = !examples;

  useEffect(() => {
    if (!examples) {
      dispatch(fetchMachineExamples());
    }
  }, [examples]);

  return useMemo(() => {
    const examplesByCode = new Map(examples?.map(example => [example.code, example]));

    function getExamples(codes: MachineExampleCode[]) {
      return codes
        .map(example => examplesByCode.get(example))
        .filter((example): example is IMachineExample => Boolean(example));
    }

    const compareByWeight = (a: MachineExampleCode, b: MachineExampleCode) => {
      const weightB = examplesByCode.get(b)?.loaded_weight ?? 0;
      const weightA = examplesByCode.get(a)?.loaded_weight ?? 0;

      return weightA - weightB;
    };

    if (!selectedFunctions?.includes(FunctionCode.PropelSizing)) {
      let availableExamples = [...machineExamples].sort(compareByWeight);

      if (isCustomOptionEnabled) {
        availableExamples = [MachineExampleCode.Custom, ...availableExamples];
      }

      return {
        isPageLoading,
        architectureExamples: null,
        machineExamples: getExamples(availableExamples),
      };
    }

    let availableArchitectureExamples = architectureExamples;

    const sortedMachineExamples = [...machineExamples].sort(compareByWeight);

    if (isCustomOptionEnabled) {
      availableArchitectureExamples = [MachineExampleCode.Custom, ...architectureExamples];
    }

    return {
      isPageLoading,
      architectureExamples: getExamples(availableArchitectureExamples),
      machineExamples: getExamples(sortedMachineExamples),
    };
  }, [examples, isPageLoading, isCustomOptionEnabled]);
}
