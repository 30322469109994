import axios from 'axios';

import {
  ISharedLink,
  ISharedLinkResponse,
  ISharedPrivateProject,
  ISharedPublicProject,
} from '../types';

export const getLink = async (projectId: string) => {
  const { data } = await axios.get<ISharedLinkResponse>(
    `/shared-projects/project-links/${projectId}`,
  );

  return data.current;
};

export const createLink = async (projectId: string) => {
  const { data } = await axios.post<ISharedLink>('/shared-projects/links', {
    project_id: projectId,
  });

  return data;
};

export const updateLink = async (linkId: string, title: string) => {
  const { data } = await axios.put<ISharedLink>(`/shared-projects/links/${linkId}`, {
    title,
  });

  return data;
};

export const deleteLink = (linkId: string) => {
  return axios.delete(`/shared-projects/links/${linkId}`);
};

export const getSharedPublicProject = async (linkId: string) => {
  const { data } = await axios.get<ISharedPublicProject>(`/shared-projects/public-infos/${linkId}`);

  return data;
};

export const getSharedPrivateProject = async (linkId: string) => {
  const { data } = await axios.get<ISharedPrivateProject>(`/shared-projects/${linkId}`);

  return data;
};
