import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfLoader } from '@danfoss/mosaic/react';
import { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch, useAppSelector } from 'configs/store';

import {
  getCalculations,
  getCurrent,
  getPropelSizingData,
  getSelectedMotors,
  getSelectedPumps,
  removeAllMotors,
  removeAllPumps,
  removeMotor,
  removePump,
} from '../../shared/store/projectSlice';
import { useMotorTypeContext } from '../motor-type-context/motorTypeContext';
import styles from './CustomPropelSystem.module.scss';
import Motors from './Motors/Motors';
import Pumps from './Pumps/Pumps';
import VehicleSpeed from './VehicleSpeed/VehicleSpeed';

const CustomPropelSystem = () => {
  const dispatch = useAppDispatch();

  const project = useAppSelector(getCurrent);
  const propelSizing = useAppSelector(getPropelSizingData);
  const selectedMotors = useAppSelector(getSelectedMotors);
  const selectedPumps = useAppSelector(getSelectedPumps);
  const calculations = useAppSelector(getCalculations);

  const { motorType, setMotorType } = useMotorTypeContext();

  useEffect(() => {
    if (!motorType) {
      const initialMotorType =
        selectedMotors?.[0]?.type ?? propelSizing?.recommended_motor_type?.motor_type;

      setMotorType(initialMotorType!);
    }
  }, [motorType, setMotorType, selectedMotors, propelSizing]);

  const [isSectionUpdating, setIsSectionUpdating] = useState(false);
  const [availableMotorsToAdd, setAvailableMotorsToAdd] = useState(0);
  const [motorsValidations, setMotorsValidations] = useState<Record<string, boolean>>({});

  const isAddingPumpsAvailable = availableMotorsToAdd === 0;
  const hasMotorsValidationError = Object.values(motorsValidations).some(hasError => hasError);
  const isPumpSelected = selectedPumps && selectedPumps.length > 0;

  const clearMotors = async () => {
    try {
      setIsSectionUpdating(true);

      await dispatch(removeAllMotors()).unwrap();
    } finally {
      setIsSectionUpdating(false);
    }
  };
  const onMotorsValidation = (id: string, hasError: boolean) => {
    setMotorsValidations(validation => {
      return {
        ...validation,
        [id]: hasError,
      };
    });
  };
  const removeMotorById = useCallback(
    async (motorId: string) => {
      try {
        setIsSectionUpdating(true);

        await dispatch(removeMotor(motorId)).unwrap();
      } finally {
        setIsSectionUpdating(false);
      }
    },
    [dispatch],
  );

  const clearPumps = async () => {
    try {
      setIsSectionUpdating(true);

      await dispatch(removeAllPumps()).unwrap();
    } finally {
      setIsSectionUpdating(false);
    }
  };

  const removePumpById = async (pumpId: string) => {
    try {
      setIsSectionUpdating(true);
      await dispatch(removePump(pumpId)).unwrap();
    } finally {
      setIsSectionUpdating(false);
    }
  };

  return (
    <section className={styles.section} data-testid="custom-propel-system">
      <DfLoader isVisible={isSectionUpdating}>
        <Motors
          systemArchitecture={project.propel_sizing!.initial_system_architecture}
          clearMotors={clearMotors}
          removeMotorById={removeMotorById}
          onAvailableMotorsChange={setAvailableMotorsToAdd}
          onValidation={onMotorsValidation}
        />
        <Pumps
          clearPumps={clearPumps}
          removePumpById={removePumpById}
          systemArchitecture={project.propel_sizing!.initial_system_architecture}
          isAddingPumpAvailable={isAddingPumpsAvailable}
          hasMotorsError={hasMotorsValidationError}
        />
        {isPumpSelected && calculations && (
          <section className={utils.mt20} data-testid="vehicle-speed">
            <h3 className={utils.mb6}>
              <FormattedMessage id="wizard_product_vehicle_speed_widget_title" />
            </h3>
            <VehicleSpeed calculations={calculations} />
          </section>
        )}
      </DfLoader>
    </section>
  );
};

export default CustomPropelSystem;
