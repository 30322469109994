import { FormattedMessage, useIntl } from 'react-intl';

import FormatMeasureUnit from 'views/shared/helpers/measuringSystem/FormatMeasureUnit';
import FormatMeasureValue from 'views/shared/helpers/measuringSystem/FormatMeasureValue';
import { MachineExampleCode, SystemArchitecture } from 'views/shared/types';

import { examplesData } from '../helpers/examplesData';
import { IMachineExample } from '../types';
import ExampleOption from './ExampleOptions';
import { ExamplesSection } from './ExamplesList/ExamplesSection';

interface MachineExamplesProps {
  examples: IMachineExample[];
  selectedExample?: MachineExampleCode;
  onSelectExample: (code: MachineExampleCode) => void;
  onSelectArchitecture?: (architecture: SystemArchitecture) => void;
  onCancelSelection: () => void;
}

export function MachineExamples({
  examples,
  selectedExample,
  onSelectArchitecture,
  onSelectExample,
  onCancelSelection,
}: MachineExamplesProps) {
  const { formatMessage } = useIntl();

  return (
    <>
      <ExamplesSection
        testId="machine-examples-list-title"
        title={<FormattedMessage id="wizard_example_machine_selection_sub_title" />}
        description={<FormattedMessage id="wizard_example_machine_selection_text" />}
      >
        {examples.map(({ code, loaded_weight, max_travel_speed }) => {
          const { title, description, icon, tooltip } = examplesData[code];
          const isSelected = code === selectedExample;

          const values = {
            weight: (
              <FormatMeasureValue
                value={loaded_weight}
                metric="kg"
                imperial="lb"
                precision={{ metric: 1, imperial: 1 }}
              />
            ),
            weight_units: (
              <FormatMeasureUnit metric="measurements_unit_kg" imperial="measurements_unit_lbs" />
            ),
            speed: (
              <FormatMeasureValue
                value={max_travel_speed}
                metric="km/h"
                imperial="mph"
                precision={{ metric: 1, imperial: 1 }}
              />
            ),
            speed_units: (
              <FormatMeasureUnit metric="measurements_unit_kmh" imperial="measurements_unit_mph" />
            ),
          };

          return (
            <ExampleOption
              key={code}
              code={code}
              icon={icon}
              title={formatMessage({ id: title })}
              description={description && formatMessage({ id: description }, values)}
              tooltip={tooltip && formatMessage({ id: tooltip })}
              onSelect={onSelectExample}
              selected={isSelected}
              onSelectArchitecture={onSelectArchitecture}
              onCancelSelection={onCancelSelection}
            />
          );
        })}
      </ExamplesSection>
    </>
  );
}
