import components from '@danfoss/mosaic/css/components.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfTooltip } from '@danfoss/mosaic/react';
import cn from 'classnames';
import React, { useEffect } from 'react';
import { UseFormReturn, Controller, useWatch, ValidationRule } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import Fieldset from 'views/shared/components/form/Fieldset';
import InputAddon from 'views/shared/components/form/InputAddon';
import InputGroup from 'views/shared/components/form/InputGroup';
import Label from 'views/shared/components/form/Label/Label';
import RadioInput from 'views/shared/components/form/RadioInput';
import { IValidationRules } from 'views/shared/components/form/types';
import ConverterInput from 'views/shared/helpers/measuringSystem/ConverterInput';
import FormatMeasureUnit from 'views/shared/helpers/measuringSystem/FormatMeasureUnit';
import { IProjectPropelSizingData, MotorType } from 'views/shared/types';
import { IRequirementsForm } from 'views/wizard/requirements/types';

import { getError } from '../../../../helpers/getError';
import FormError from '../../../FormError';
import { useOverrideMotorTypeContext } from '../OverrideMotorType.context';

export interface SpeedProps {
  form: UseFormReturn<IRequirementsForm>;
  formPart: 'propelSizingData' | 'propelSizingData.two_speed_gear_box';
  validation?: {
    [field in keyof IProjectPropelSizingData]?: IValidationRules;
  };
}

export function Speed({ form, validation = {}, formPart }: SpeedProps) {
  const { formatMessage } = useIntl();
  const overrideMotorType = useOverrideMotorTypeContext();

  const {
    formState: { errors },
    control,
    setValue,
  } = form;

  const transmissionRatioField =
    formPart === 'propelSizingData.two_speed_gear_box'
      ? 'transmissionRatioGear2'
      : 'transmissionRatio';
  const [isWorkSpeedKnown, maxTravelSpeed, transmissionRatio] = useWatch({
    name: [
      `${formPart}.is_work_speed_known`,
      `${formPart}.max_travel_speed`,
      transmissionRatioField,
    ],
    control,
  });

  const motorType =
    overrideMotorType.motorType || transmissionRatio?.recommended_motor_type.motor_type;
  const isFixedMotor = motorType
    ? [MotorType.FixedOrbital, MotorType.FixedPiston].includes(motorType)
    : false;
  const isVariableMotor = motorType === MotorType.VariablePiston;

  useEffect(() => {
    const speedConversionFactor = isFixedMotor ? 1 : 0.25;

    if (!isWorkSpeedKnown && maxTravelSpeed) {
      setValue(`${formPart}.max_work_speed`, maxTravelSpeed * speedConversionFactor);
    }
  }, [isWorkSpeedKnown, maxTravelSpeed, isFixedMotor]);

  useEffect(() => {
    if (isFixedMotor) {
      setValue(`${formPart}.is_work_speed_known`, false);
    } else {
      setValue(`${formPart}.is_work_speed_known`, true);
    }
  }, [isFixedMotor, setValue]);

  const workSpeedValidation: Partial<{
    min: ValidationRule<number>;
    max: ValidationRule<number>;
    required: ValidationRule<boolean>;
  }> = {
    ...validation.max_work_speed,
  };

  const workSpeedLimitations = {
    ...validation.max_work_speed,
  };

  if (isWorkSpeedKnown && typeof maxTravelSpeed === 'number' && isVariableMotor) {
    let minValue = Math.ceil(maxTravelSpeed * 0.2 * 1000) / 1000;
    const mv = validation.max_work_speed?.min;

    if (typeof mv === 'number') {
      minValue = Math.max(mv, minValue);
    }

    workSpeedLimitations.min = minValue;
    workSpeedValidation.min = {
      value: minValue,
      message: formatMessage(
        { id: 'wizard_requirements_work_speed_min_value_error' },
        { min: minValue },
      ),
    };
  }

  return (
    <div data-testid={`${formPart}.speed`}>
      <Fieldset>
        <Label>
          <FormattedMessage id="wizard_requirements_travel_speed_maximum_label" />
        </Label>
        <InputGroup>
          <Controller
            control={form.control}
            name={`${formPart}.max_travel_speed`}
            rules={validation.max_travel_speed}
            render={({ field }) => (
              <ConverterInput
                type="number"
                metric="km/h"
                imperial="mph"
                data-testid="max_travel_speed"
                value={field.value ?? ''}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!getError(errors, `${formPart}.max_travel_speed`)}
                {...validation.max_travel_speed}
              />
            )}
          />
          <InputAddon>
            <FormatMeasureUnit metric="measurements_unit_kmh" imperial="measurements_unit_mph" />
          </InputAddon>
        </InputGroup>
        <FormError
          errors={errors}
          rules={validation}
          field={`${formPart}.max_travel_speed`}
          metric="km/h"
          imperial="mph"
        />
      </Fieldset>

      <Fieldset>
        <Label className={utils.mt6}>
          <FormattedMessage id="wizard_requirements_work_speed_maximum_label" />
        </Label>

        <Controller
          control={control}
          name={`${formPart}.is_work_speed_known`}
          defaultValue={false}
          render={({ field: { onChange, value, name } }) => (
            <Fieldset
              className={cn(components.radioGroup, components.inline, utils.my3)}
              data-testid="is-work-speed-known-fieldset"
            >
              <DfTooltip disabled={!isFixedMotor} trigger="hover" placement="top">
                <span slot="tooltip-reference">
                  <RadioInput
                    id={`${formPart}-is_work_speed_known`}
                    name={name}
                    data-testid="is-work-speed-known"
                    onChange={() => onChange(true)}
                    checked={Boolean(value)}
                    disabled={isFixedMotor}
                  >
                    <FormattedMessage id="wizard_requirements_work_speed_custom" />
                  </RadioInput>
                </span>
                <span slot="tooltip-content">
                  <FormattedMessage id="wizard_requirements_work_speed_known_disabled_tooltip" />
                </span>
              </DfTooltip>

              <RadioInput
                id={`${formPart}-is_work_speed_unknown`}
                name={name}
                data-testid="is-work-speed-unknown"
                onChange={() => onChange(false)}
                checked={!value}
              >
                <FormattedMessage id="wizard_requirements_work_speed_recommended" />
              </RadioInput>
            </Fieldset>
          )}
        />
        <InputGroup>
          <Controller
            control={form.control}
            name={`${formPart}.max_work_speed`}
            rules={workSpeedValidation}
            render={({ field }) => (
              <ConverterInput
                type="number"
                metric="km/h"
                imperial="mph"
                data-testid="max_work_speed"
                readOnly={!isWorkSpeedKnown}
                value={field.value ?? ''}
                onChange={field.onChange}
                onBlur={field.onBlur}
                error={!!getError(errors, `${formPart}.max_work_speed`)}
                {...workSpeedLimitations}
              />
            )}
          />
          <InputAddon data-testid="work-speed-unit">
            <FormatMeasureUnit metric="measurements_unit_kmh" imperial="measurements_unit_mph" />
          </InputAddon>
        </InputGroup>
        <FormError
          errors={errors}
          rules={validation}
          field={`${formPart}.max_work_speed`}
          metric="km/h"
          imperial="mph"
        />
      </Fieldset>
    </div>
  );
}
