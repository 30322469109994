import core from '@danfoss/mosaic/css/core.module.css';
import cn from 'classnames';

interface ILabel extends React.HTMLProps<HTMLLabelElement> {}

const Label = ({ className, children, ...props }: ILabel) => {
  return (
    <label className={cn(className, core.fieldLabel)} {...props}>
      {children}
    </label>
  );
};

export default Label;
