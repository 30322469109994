import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { getAppConfig } from './global';

export const NOT_FOUND_STATUS = 404;
export const NOT_AUTHORIZED_STATUS = 401;

export const setupBaseURL = () => {
  axios.defaults.baseURL = getAppConfig().API_URL;
};

export const configAxios = async (token: string) => {
  Object.assign(axios.defaults.headers, {
    Authorization: `Bearer ${token}`,
  });
};

export interface IInterceptorCallback {
  (error: AxiosError): void;
}
interface IApplyInterceptor {
  (callback: IInterceptorCallback): number;
}

export interface AxiosConfigWithFlags extends AxiosRequestConfig {
  disableErrorInterceptor?: boolean;
}

export const applyInterceptor: IApplyInterceptor = callback => {
  return axios.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
      callback(error);

      throw error;
    },
  );
};

export const ejectInterceptor = (id: number) => {
  axios.interceptors.response.eject(id);
};
