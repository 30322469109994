import { useState } from 'react';

import { MeasuringSystemContext, MeasuringUnits, storage } from './MeasuringSystemContext';

interface IMeasuringSystemProviderProps {
  children: React.ReactNode;
}

const MeasuringSystemProvider = ({ children }: IMeasuringSystemProviderProps) => {
  const [units, setUnits] = useState(storage.getUnits() || MeasuringUnits.Metric);

  return (
    <MeasuringSystemContext.Provider value={{ units, setUnits }}>
      {children}
    </MeasuringSystemContext.Provider>
  );
};

export default MeasuringSystemProvider;
