import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { UseFormReturn } from 'react-hook-form';

import { IValidationRules } from 'views/shared/components/form/types';
import {
  IProjectPropelSizingData,
  IPullRatioCalculation,
  SystemArchitecture,
} from 'views/shared/types';

import { IRequirementsForm } from '../../../../types';
import { Speed } from '../Speed/Speed';
import { TractiveForceGroup } from '../TractiveForceGroup/TractiveForceGroup';
import styles from './Gearbox.module.scss';

interface IGearboxProps {
  form: UseFormReturn<IRequirementsForm>;
  onUpdate: (isUpdating: boolean, isError?: boolean) => void;
  validation?: {
    [field in keyof IProjectPropelSizingData]?: IValidationRules;
  };
  dependsOn: {
    loadedWeight: number;
    hydraulicDedicatedPower: number;
    pullRatioCalculation: IPullRatioCalculation | null;
    initialArchitecture: SystemArchitecture;
  };
}

export const Gearbox = ({ form, onUpdate, dependsOn, validation }: IGearboxProps) => {
  return (
    <div className={cn(styles.wrapper, utils.mt2, utils.p4)} data-testid="gearbox">
      <div className={cn(styles.section)}>
        <TractiveForceGroup
          form={form}
          formPart="propelSizingData.two_speed_gear_box"
          onUpdate={onUpdate}
          dependsOn={dependsOn}
          validation={validation}
        />
      </div>
      <div className={cn(styles.section)}>
        <Speed form={form} formPart="propelSizingData.two_speed_gear_box" validation={validation} />
      </div>
    </div>
  );
};
