import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import { showWarning } from 'utils/ToastService';
import { useToggle } from 'utils/useToggle';
import Button from 'views/shared/components/Button/Button';
import Fieldset from 'views/shared/components/form/Fieldset';
import Input from 'views/shared/components/form/Input';
import Addon from 'views/shared/components/form/InputAddon';
import InputGroup from 'views/shared/components/form/InputGroup';
import Label from 'views/shared/components/form/Label/Label';
import { IValidationRules } from 'views/shared/components/form/types';
import { round } from 'views/shared/helpers/formatters';
import {
  IProjectPropelSizingData,
  MotorType,
  MotorTypeWarning,
  SystemArchitecture,
} from 'views/shared/types';
import { TractiveForceValidation } from 'views/wizard/requirements/helpers/types';

import { getError } from '../../../helpers/getError';
import { IRequirementsForm } from '../../../types';
import FormError from '../../FormError';
import { Gearbox } from './Gearbox/Gearbox';
import { useOverrideMotorTypeContext } from './OverrideMotorType.context';
import PumpDriveModal from './PumpDriveModal/PumpDriveModal';
import { RollingRadius } from './RollingRadius/RollingRadius';
import { Speed } from './Speed/Speed';
import { SystemArchitectureSection } from './SystemArchitectureSection/SystemArchitectureSection';
import styles from './TractiveForce.module.scss';
import { TractiveForceGroup } from './TractiveForceGroup/TractiveForceGroup';
import { ITransmissionRatio } from './TransmissionRatio/api/transmissionRatio';
import VehicleSpeedModal from './VehicleSpeedModal/VehicleSpeedModal';

interface ITractiveForceProps {
  form: UseFormReturn<IRequirementsForm>;
  onClose: () => void;
  data: IProjectPropelSizingData;
  dependsOn: {
    loadedWeight: number;
    engineSpeed: number;
    hydraulicDedicatedPower: number;
  };
  onUpdate: (isUpdating: boolean, isError?: boolean) => void;
  validation?: {
    [field in keyof IProjectPropelSizingData]?: IValidationRules;
  };
  isCustomFlow: boolean;
}

const TractiveForce = ({
  form,
  data,
  onClose,
  dependsOn,
  onUpdate,
  validation = {},
  isCustomFlow,
}: ITractiveForceProps) => {
  const overrideMotorType = useOverrideMotorTypeContext();
  const { formatMessage } = useIntl();
  const { loadedWeight, hydraulicDedicatedPower, engineSpeed } = dependsOn;
  const { control, trigger, setValue, formState, register } = form;
  const { errors } = formState;

  const { pull_ratio_calculation, two_speed_gear_box, initial_system_architecture } = data;
  const gearboxPullRatioCalculations = two_speed_gear_box?.pull_ratio_calculation ?? null;
  const hasSavedGearbox = !!two_speed_gear_box;
  const [isGearboxActive, addGearbox, removeGearbox] = useToggle(hasSavedGearbox);
  const isGearboxAddingAvailable =
    (initial_system_architecture === SystemArchitecture.OnePumpOneMotor ||
      initial_system_architecture === SystemArchitecture.OnePumpOneMotorGearBox) &&
    !isGearboxActive;
  const [isPumpDriveModalOpen, openPumpDriveModal, closePumpDriveModal] = useToggle(false);
  const [isVehicleSpeedModalOpen, openVehicleSpeedModal, closeVehicleSpeedModal] = useToggle(false);
  const [isTabUpdating, setIsTabUpdating] = useState(false);
  const isTransmissionRatioReceived = useRef<null | ITransmissionRatio>(null);
  const pumpDriveRatio = useWatch({
    name: 'propelSizingData.pump_drive_ratio',
    control,
  });
  const pumpSpeed = useWatch({
    name: 'propelSizingData.pump_speed',
    control,
  });
  const tractiveForceOption = useWatch({
    name: 'propelSizingData.is_tractive_force_known',
    control,
  });
  const transmissionRatio = useWatch({
    name: 'transmissionRatio',
    disabled: !!isTransmissionRatioReceived.current,
    control,
  });
  const gear1MotorType = useWatch({
    name: 'transmissionRatio.recommended_motor_type.motor_type',
    control,
  });
  const gear2MotorType = useWatch({
    name: 'transmissionRatioGear2.recommended_motor_type.motor_type',
    control,
  });

  const pumpSpeedLabelValue = pumpSpeed
    ? `${pumpSpeed} ${formatMessage({ id: 'measurements_unit_rpm' })}`
    : '-';

  const onTabUpdate = (isUpdating: boolean, isError?: boolean) => {
    setIsTabUpdating(isUpdating);
    onUpdate(isUpdating, isError);
  };
  const addGearboxWithDefaults = () => {
    if (hasSavedGearbox) {
      setValue('propelSizingData.two_speed_gear_box', two_speed_gear_box);
    } else {
      setValue('propelSizingData.two_speed_gear_box.is_tractive_force_known', tractiveForceOption);
    }

    addGearbox();
  };
  const removeGearboxWidget = () => {
    overrideMotorType.setMotorType(null);
    removeGearbox();
  };

  useEffect(() => {
    if (data && Object.keys(data).length) {
      form.reset({
        propelSizingData: data,
      });
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (pumpDriveRatio && (await trigger('propelSizingData.pump_drive_ratio'))) {
        setValue('propelSizingData.pump_speed', round(+(pumpDriveRatio * engineSpeed), 2), {
          shouldValidate: true,
        });
      } else {
        setValue('propelSizingData.pump_speed', undefined, { shouldValidate: true });
      }
    })();
  }, [pumpDriveRatio]);

  useEffect(() => {
    const isGearboxRecommended =
      transmissionRatio?.recommended_motor_type.warning ===
      MotorTypeWarning.MultiRatioGearBoxRequired;

    if (isTransmissionRatioReceived.current) {
      return;
    }

    if (transmissionRatio) {
      isTransmissionRatioReceived.current = transmissionRatio;
    }

    if (isTransmissionRatioReceived.current && isGearboxAddingAvailable && isGearboxRecommended) {
      addGearboxWithDefaults();
    }
  }, [transmissionRatio]);

  useEffect(() => {
    if (!isGearboxActive || !gear1MotorType || !gear2MotorType) {
      return;
    }

    const fixedMotorTypes = [MotorType.FixedOrbital, MotorType.FixedPiston];
    const isRecommendedMotorTypeFixed =
      fixedMotorTypes.includes(gear1MotorType) || fixedMotorTypes.includes(gear2MotorType);

    if (
      gear1MotorType !== gear2MotorType &&
      !overrideMotorType.motorType &&
      isRecommendedMotorTypeFixed
    ) {
      overrideMotorType.setMotorType(MotorType.VariablePiston);

      showWarning(
        null,
        <FormattedMessage id="wizard_requirements_gearbox_motor_type_difference_text" />,
      );
    } else {
      overrideMotorType.setMotorType(null);
    }
  }, [gear1MotorType, gear2MotorType]);

  if (validation.pump_speed) {
    const errorMessage = formatMessage(
      { id: 'wizard_requirements_pump_speed_error' },
      {
        linebreak: <br />,
        ...validation.pump_speed,
      },
    ) as string;

    register('propelSizingData.pump_speed', {
      min: {
        value: validation.pump_speed.min!,
        message: errorMessage,
      },
      max: {
        value: validation.pump_speed.max!,
        message: errorMessage,
      },
    });
  }

  return (
    <>
      <div className={utils.row} data-testid="tractive-force-tab-content">
        <div
          className={cn(
            utils.offset1,
            utils.col11,
            utils.colMd7,
            core.flex,
            core.flexBetween,
            styles.title,
          )}
        >
          <h3>
            <FormattedMessage id="wizard_requirements_tractive_force_tab" />
          </h3>
          <Button
            type="button"
            onClick={onClose}
            disabled={isTabUpdating}
            className={cn(button.textBtn, button.textBtnPrimary)}
            data-testid="propel-sizing-close-draft"
          >
            <FormattedMessage id="wizard_requirements_close_draft" />
          </Button>
        </div>
      </div>
      <div className={cn(utils.row, utils.mt8)}>
        <div className={cn(utils.offset1, utils.col4, utils.colMd4, utils.colSm7)}>
          <h5>
            <FormattedMessage id="wizard_requirements_pump_drive" />
            <button
              className={cn(utils.ml3, button.textBtn, button.textBtnPrimary)}
              type="button"
              onClick={openPumpDriveModal}
              data-testid="pump-drive-details"
            >
              <FormattedMessage id="wizard_requirements_view_details" />
            </button>
          </h5>
          <Fieldset>
            <Label className={utils.mt6}>
              <span>
                <FormattedMessage id="wizard_requirements_pump_drive_ratio_label" />
              </span>
            </Label>
            <InputGroup>
              <Input
                type="number"
                step="0.1"
                data-testid="pump_drive_ratio"
                error={!!getError(errors, 'propelSizingData.pump_drive_ratio')}
                {...form.register('propelSizingData.pump_drive_ratio', {
                  ...validation.pump_drive_ratio,
                  valueAsNumber: true,
                })}
                {...validation.pump_drive_ratio}
              />
              <Addon>
                <FormattedMessage id="measurements_unit_ratio_one" />
              </Addon>
            </InputGroup>
            <FormError
              errors={errors}
              rules={validation}
              field="propelSizingData.pump_drive_ratio"
            />
            <label className={cn(core.helperText, styles['pump-speed-label'])}>
              <FormattedMessage id="wizard_requirements_pump_speed_label" />{' '}
              <span data-testid="pump-speed-value">{pumpSpeedLabelValue}</span>
            </label>
            <FormError errors={errors} rules={validation} field="propelSizingData.pump_speed" />
          </Fieldset>
          <Fieldset>
            <Label className={utils.mt6}>
              <span>
                <FormattedMessage id="wizard_requirements_pump_efficiency_label" />
              </span>
            </Label>
            <InputGroup>
              <Input
                type="number"
                data-testid="pump_drive_efficiency"
                error={!!getError(errors, 'propelSizingData.pump_drive_efficiency')}
                {...form.register('propelSizingData.pump_drive_efficiency', {
                  ...validation.pump_drive_efficiency,
                  valueAsNumber: true,
                })}
                {...validation.pump_drive_efficiency}
              />
              <Addon>
                <FormattedMessage id="measurements_unit_percentage" />
              </Addon>
            </InputGroup>
            <FormError
              errors={errors}
              rules={validation}
              field="propelSizingData.pump_drive_efficiency"
            />
          </Fieldset>
          <div className={utils.mt10}>
            <TractiveForceGroup
              form={form}
              formPart="propelSizingData"
              onUpdate={onTabUpdate}
              validation={validation}
              dependsOn={{
                loadedWeight,
                hydraulicDedicatedPower,
                pullRatioCalculation: pull_ratio_calculation,
                initialArchitecture: initial_system_architecture,
              }}
            />
          </div>
        </div>
        <div className={cn(utils.offset1, utils.col4, utils.colMd4, utils.colSm7)}>
          <h5>
            <FormattedMessage id="wizard_requirements_vehicle_speed" />
            <button
              className={cn(utils.ml3, button.textBtn, button.textBtnPrimary)}
              type="button"
              onClick={openVehicleSpeedModal}
              data-testid="vehicle-speed-details"
            >
              <FormattedMessage id="wizard_requirements_view_details" />
            </button>
          </h5>
          <div className={utils.mt6}>
            <Speed form={form} formPart="propelSizingData" validation={validation} />
          </div>

          {isCustomFlow && <SystemArchitectureSection form={form} isTabUpdating={isTabUpdating} />}

          {!isCustomFlow && (
            <RollingRadius
              form={form}
              initialArchitecture={initial_system_architecture}
              validation={validation as TractiveForceValidation}
            />
          )}
        </div>
      </div>
      <div className={utils.offset1}>
        {isGearboxActive && (
          <button
            onClick={removeGearboxWidget}
            className={cn(button.textBtn, button.textBtnPrimary, utils.mt2)}
            data-testid="remove-gearbox"
          >
            - <FormattedMessage id="wizard_requirements_gearbox_remove_button" />
          </button>
        )}
        {isGearboxAddingAvailable && (
          <button
            onClick={addGearboxWithDefaults}
            className={cn(button.textBtn, button.textBtnPrimary, utils.mt2)}
            data-testid="add-gearbox"
          >
            + <FormattedMessage id="wizard_requirements_gearbox_add_button" />
          </button>
        )}
        {isGearboxActive && (
          <Gearbox
            form={form}
            onUpdate={onTabUpdate}
            validation={validation}
            dependsOn={{
              loadedWeight,
              hydraulicDedicatedPower,
              pullRatioCalculation: gearboxPullRatioCalculations,
              initialArchitecture: initial_system_architecture,
            }}
          />
        )}
      </div>
      {isPumpDriveModalOpen && <PumpDriveModal onClose={closePumpDriveModal} />}
      {isVehicleSpeedModalOpen && <VehicleSpeedModal onClose={closeVehicleSpeedModal} />}
    </>
  );
};

export default TractiveForce;
