import utils from '@danfoss/mosaic/css/utils.module.css';
import { CustomSelectOptionBaseType, SelectChangeEventArgsType } from '@danfoss/mosaic/dist/types';
import { DfSelect } from '@danfoss/mosaic/react';
import cn from 'classnames';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { UseFormReturn, useWatch } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';

import Checkbox from 'views/shared/components/form/Checkbox';
import Fieldset from 'views/shared/components/form/Fieldset';
import Label from 'views/shared/components/form/Label/Label';
import { SystemArchitecture } from 'views/shared/types';
import { IRequirementsForm } from 'views/wizard/requirements/types';

export interface SystemArchitectureSectionProps {
  form: UseFormReturn<IRequirementsForm>;
  isTabUpdating: boolean;
}

function useSystemArchitectureValue({
  control,
  setValue,
  register,
}: UseFormReturn<IRequirementsForm>) {
  const [lastSystemArchitectureChoice, setLastSystemArchitectureChoice] =
    useState<SystemArchitecture>();

  const initialSystemArchitecture = useWatch({
    name: 'propelSizingData.initial_system_architecture',
    control,
  });

  register('propelSizingData.initial_system_architecture');

  const isDualPathSelected = initialSystemArchitecture === SystemArchitecture.DualPath;

  const onSystemArchitectureChange = (event: CustomEvent<SelectChangeEventArgsType<false>>) => {
    const option = event.detail.value;

    setValue('propelSizingData.initial_system_architecture', option.value as SystemArchitecture);
  };

  const onDualPathChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const { checked } = target;

    if (checked) {
      setLastSystemArchitectureChoice(initialSystemArchitecture);
      setValue('propelSizingData.initial_system_architecture', SystemArchitecture.DualPath);
    } else {
      setValue(
        'propelSizingData.initial_system_architecture',
        lastSystemArchitectureChoice as SystemArchitecture,
      );
    }
  };

  return {
    isDualPathSelected,
    initialSystemArchitecture,
    onSystemArchitectureChange,
    onDualPathChange,
  };
}

function useRecommendedSystemArchitectureAutoSelect({
  form: { setValue, control },
  initialSystemArchitecture,
}: {
  form: UseFormReturn<IRequirementsForm>;
  initialSystemArchitecture: SystemArchitecture | undefined;
}) {
  const recommendedSystemArchitecture = useWatch({
    name: 'transmissionRatio.recommended_system_architecture',
    control,
  });

  const hasSetInitialValue = useRef(false);

  useEffect(() => {
    if (!recommendedSystemArchitecture) {
      return;
    }

    if (!hasSetInitialValue.current) {
      hasSetInitialValue.current = true;

      if (initialSystemArchitecture) {
        return;
      }
    }

    setValue('propelSizingData.initial_system_architecture', recommendedSystemArchitecture);
  }, [recommendedSystemArchitecture, setValue]);
}

export function SystemArchitectureSection({ form, isTabUpdating }: SystemArchitectureSectionProps) {
  const { formatMessage } = useIntl();

  const systemArchitectureOptions: CustomSelectOptionBaseType[] = [
    {
      value: SystemArchitecture.OnePumpOneMotor,
      label: formatMessage({ id: 'wizard_requirements_system_arch_one_pump_one_motor' }),
    },
    {
      value: SystemArchitecture.OnePumpOneMotorGearBox,
      label: formatMessage({ id: 'wizard_requirements_system_arch_one_pump_one_motor_gearbox' }),
    },
    {
      value: SystemArchitecture.OnePumpTwoMotors,
      label: formatMessage({ id: 'wizard_requirements_system_arch_one_pump_two_motors' }),
    },
    {
      value: SystemArchitecture.Custom,
      label: formatMessage({ id: 'wizard_requirements_system_arch_custom' }),
    },
  ];

  const {
    initialSystemArchitecture,
    isDualPathSelected,
    onDualPathChange,
    onSystemArchitectureChange,
  } = useSystemArchitectureValue(form);

  useRecommendedSystemArchitectureAutoSelect({ form, initialSystemArchitecture });

  const selectedOption = systemArchitectureOptions.find(
    ({ value }) => value === initialSystemArchitecture,
  );

  return (
    <Fieldset>
      <Label className={utils.mt6}>
        <FormattedMessage id="wizard_requirements_system_arch_label" />
      </Label>
      <div className={utils.my3}>
        <Checkbox
          id="dual_path"
          checked={isDualPathSelected}
          onChange={onDualPathChange}
          data-testid="dual-path-architecture"
        >
          <FormattedMessage id="wizard_requirements_system_arch_dual_path" />
        </Checkbox>
        {isDualPathSelected && (
          <p
            className={cn(utils.mt2, utils.colorNeutralStronger)}
            data-testid="dual-path-architecture-description"
          >
            <FormattedMessage id="wizard_requirements_system_arch_dual_path_description" />
          </p>
        )}
      </div>
      {!isDualPathSelected && (
        <DfSelect
          options={systemArchitectureOptions}
          isLoading={isTabUpdating}
          isReadonly={isTabUpdating}
          value={selectedOption}
          onSelectChange={onSystemArchitectureChange}
          data-testid="system-architecture-select"
        />
      )}
    </Fieldset>
  );
}
