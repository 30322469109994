import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfModal } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as SvgVehicleSpeed } from 'assets/images/wizard/requirements/vehicle_speed_modal.svg';

import styles from './VehicleSpeedModal.module.scss';

interface IVehicleSpeedModalProps {
  onClose: () => void;
}

const VehicleSpeedModal = ({ onClose }: IVehicleSpeedModalProps) => {
  const { formatMessage } = useIntl();

  return (
    <DfModal
      heading={formatMessage({ id: 'wizard_requirements_vehicle_speed_modal_title' })}
      onModalClose={onClose}
      data-testid="vehicle-speed-modal"
      isOpen
    >
      <div slot="modal-body">
        <p className={utils.mb3}>
          <FormattedMessage id="wizard_requirements_vehicle_speed_modal_text_1" />
        </p>
        <p className={utils.mb3}>
          <FormattedMessage id="wizard_requirements_vehicle_speed_modal_text_2" />
        </p>
        <p>
          <FormattedMessage id="wizard_requirements_vehicle_speed_modal_text_3" />
        </p>
        <div
          className={cn(
            core.flex,
            core.flexCenter,
            core.alignCenter,
            utils.my10,
            styles['chart-wrapper'],
          )}
        >
          <div className={styles['chart-legend-y']}>
            <FormattedMessage id="wizard_requirements_vehicle_speed_modal_legend_y" />
          </div>
          <div className={styles['chart-legend-x']}>
            <FormattedMessage id="wizard_requirements_vehicle_speed_modal_legend_x" />
          </div>
          <SvgVehicleSpeed />
        </div>
      </div>
      <div slot="modal-footer">
        <button className={button.btnPrimary} type="button" onClick={() => onClose()}>
          <FormattedMessage id="wizard_requirements_vehicle_speed_modal_close_button" />
        </button>
      </div>
    </DfModal>
  );
};

export default VehicleSpeedModal;
