import { ReactComponent as SvgWarning } from '@danfoss/mosaic-icons/dist/svg/error-outline.svg';
import components from '@danfoss/mosaic/css/components.module.css';
import alert from '@danfoss/mosaic/css/components/alert.module.css';
import buttonGroup from '@danfoss/mosaic/css/components/button-group.module.css';
import button from '@danfoss/mosaic/css/components/button.module.css';
import checkbox from '@danfoss/mosaic/css/components/checkbox.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { Controller, UseFormReturn, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import ContactModal from 'views/base/ContactUs/ContactModal';
import Button from 'views/shared/components/Button/Button';
import Checkbox from 'views/shared/components/form/Checkbox';
import Fieldset from 'views/shared/components/form/Fieldset';
import Input from 'views/shared/components/form/Input';
import Addon from 'views/shared/components/form/InputAddon';
import InputGroup from 'views/shared/components/form/InputGroup';
import Label from 'views/shared/components/form/Label/Label';
import RadioInput from 'views/shared/components/form/RadioInput';
import { IValidationRules } from 'views/shared/components/form/types';
import ConverterInput from 'views/shared/helpers/measuringSystem/ConverterInput';
import FormatMeasureUnit from 'views/shared/helpers/measuringSystem/FormatMeasureUnit';
import {
  IProjectSteeringData,
  SteeringArchitecture,
  SteeringFunction,
  SteeringPriority,
  SteeringSystem,
} from 'views/shared/types';

import { IRequirementsForm } from '../../../types';
import FormError from '../../FormError';
import styles from './Steering.module.scss';

interface ISteeringViewProps {
  form: UseFormReturn<IRequirementsForm>;
  onClose: () => void;
  data?: IProjectSteeringData;
  validation?: {
    [field in keyof IProjectSteeringData]?: IValidationRules;
  };
  projectId: string;
}

const SteeringView = ({ form, data, onClose, validation = {}, projectId }: ISteeringViewProps) => {
  const { register, control, formState } = form;
  const { errors } = formState;
  const formErrors = errors.steeringData || {};
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const steeringFunction = useWatch({
    control,
    name: 'steeringData.function',
  });
  const isSteerByWire = steeringFunction === SteeringFunction.SteerByWire;
  const steeringSystems = useWatch({
    control,
    name: 'steeringData.systems',
  });
  const isEmergencySteering = steeringSystems?.includes(SteeringSystem.Emergency);

  const handleFunctionButtonClick =
    (onChange: (value: string) => void) =>
    ({ target }: MouseEvent<HTMLButtonElement>) => {
      const newValue = (target as HTMLButtonElement).dataset.value!;

      if (newValue === SteeringFunction.SteerByWire) {
        form.setValue(
          'steeringData.systems',
          (steeringSystems || []).filter(
            system => system === SteeringSystem.LoadSensing || system === SteeringSystem.OpenCenter,
          ),
        );
      }

      onChange(newValue);
    };

  const handleSystemCheckboxChange =
    (onChange: (value: string[]) => void) =>
    ({ target }: ChangeEvent<HTMLInputElement>) => {
      const { value, checked } = target as HTMLInputElement & {
        checked: boolean;
        value: SteeringSystem;
      };
      const steeringSystemSet = new Set(steeringSystems || []);
      const isLoadSensing = value === SteeringSystem.LoadSensing;

      if (checked) {
        steeringSystemSet.add(value);

        if (isLoadSensing) {
          steeringSystemSet.delete(SteeringSystem.OpenCenter);
        }
      } else {
        steeringSystemSet.delete(value);

        if (isLoadSensing) {
          steeringSystemSet.add(SteeringSystem.OpenCenter);
        }
      }

      onChange(Array.from(steeringSystemSet));
    };

  const onContactModalOpen = () => {
    setIsContactModalOpen(true);
  };
  const onContactModalClose = () => {
    setIsContactModalOpen(false);
  };

  useEffect(() => {
    if (data && Object.keys(data).length) {
      form.reset({ steeringData: data });
    }

    if (
      !data?.systems?.includes(SteeringSystem.LoadSensing) &&
      !data?.systems?.includes(SteeringSystem.OpenCenter)
    ) {
      form.setValue('steeringData.systems', [...(data?.systems || []), SteeringSystem.OpenCenter]);
    }
  }, []);

  return (
    <div data-testid="steering-tab-content">
      <div className={utils.row}>
        <div
          className={cn(
            utils.offset1,
            utils.col11,
            utils.colMd7,
            core.flex,
            core.flexBetween,
            styles.title,
          )}
        >
          <h3>
            <FormattedMessage id="wizard_requirements_steering_tab" />
          </h3>
          <Button
            type="button"
            onClick={onClose}
            className={cn(button.textBtn, button.textBtnPrimary)}
            data-testid="steering-close-draft"
          >
            <FormattedMessage id="wizard_requirements_close_draft" />
          </Button>
        </div>
      </div>
      <div className={cn(utils.row, utils.mt8)}>
        <div className={cn(utils.offset1, utils.col11, utils.colMd7)}>
          <h5>
            <FormattedMessage id="specs_steering_architecture_label" />
          </h5>
          <Fieldset
            className={cn(
              components.radioGroup,
              components.inline,
              utils.mt3,
              styles['radio-group'],
            )}
            data-testid="steering-data-architecture-fieldset"
          >
            <RadioInput
              id="radio-architecture-front-wheel"
              data-testid="front-wheel"
              value={SteeringArchitecture.FrontWheel}
              {...register('steeringData.architecture')}
            >
              <FormattedMessage id="specs_steering_architecture_front_wheel" />
            </RadioInput>
            <RadioInput
              id="radio-architecture-rear-wheel"
              data-testid="rear-wheel"
              value={SteeringArchitecture.RearWheel}
              {...register('steeringData.architecture')}
            >
              <FormattedMessage id="specs_steering_architecture_rear_wheel" />
            </RadioInput>
          </Fieldset>
        </div>
      </div>
      <div className={cn(utils.row, utils.mt4)}>
        <div className={cn(utils.offset1, utils.col11, utils.colMd7)}>
          <h5>
            <FormattedMessage id="specs_steering_functions_label" />
          </h5>
          <Controller
            control={control}
            name="steeringData.function"
            render={({ field: { onChange, value } }) => {
              const handleButtonClick = handleFunctionButtonClick(onChange);

              return (
                <Fieldset
                  className={cn(buttonGroup.buttonGroup, utils.mt2, styles['buttons-group'])}
                  data-testid="steering-data-function-fieldset"
                >
                  <Button
                    type="button"
                    data-value={SteeringFunction.Conventional}
                    className={cn(core.textOverflow, {
                      [buttonGroup.selected]: value === SteeringFunction.Conventional,
                    })}
                    onClick={handleButtonClick}
                    data-testid="button-function-conventional"
                  >
                    <FormattedMessage id="specs_steering_functions_conventional" />
                  </Button>
                  <Button
                    type="button"
                    data-value={SteeringFunction.Hybrid}
                    className={cn(core.textOverflow, {
                      [buttonGroup.selected]: value === SteeringFunction.Hybrid,
                    })}
                    onClick={handleButtonClick}
                    data-testid="button-function-hybrid"
                  >
                    <FormattedMessage id="specs_steering_functions_hybrid" />
                  </Button>
                  <Button
                    type="button"
                    data-value={SteeringFunction.SteerByWire}
                    className={cn(core.textOverflow, {
                      [buttonGroup.selected]: value === SteeringFunction.SteerByWire,
                    })}
                    onClick={handleButtonClick}
                    data-testid="button-function-steer-by-wire"
                  >
                    <FormattedMessage id="specs_steering_functions_steer_by_wire" />
                  </Button>
                </Fieldset>
              );
            }}
          />
          <Controller
            control={control}
            name="steeringData.systems"
            render={({ field: { onChange, value } }) => {
              const handleCheckBoxClick = handleSystemCheckboxChange(onChange);
              const valueSet = new Set(value);

              return (
                <Fieldset
                  className={cn(utils.mt4, core.flex, checkbox.checkboxGroup, checkbox.inline)}
                  data-testid="steering-data-systems-fieldset"
                >
                  <Checkbox
                    id="checkbox-system-reaction"
                    value={SteeringSystem.Reaction}
                    className={cn({
                      [core.hidden]: isSteerByWire,
                    })}
                    onChange={handleCheckBoxClick}
                    checked={valueSet.has(SteeringSystem.Reaction)}
                    data-testid="checkbox-system-reaction"
                  >
                    <FormattedMessage id="specs_steering_system_reaction" />
                  </Checkbox>
                  <Checkbox
                    id="checkbox-system-load-sensing"
                    value={SteeringSystem.LoadSensing}
                    onChange={handleCheckBoxClick}
                    checked={valueSet.has(SteeringSystem.LoadSensing)}
                    data-testid="checkbox-system-load-sensing"
                  >
                    <FormattedMessage id="specs_steering_system_load_sensing" />
                  </Checkbox>
                  <Checkbox
                    id="checkbox-system-emergency"
                    value={SteeringSystem.Emergency}
                    className={cn({
                      [core.hidden]: isSteerByWire,
                    })}
                    onChange={handleCheckBoxClick}
                    checked={valueSet.has(SteeringSystem.Emergency)}
                    data-testid="checkbox-system-emergency"
                  >
                    <FormattedMessage id="specs_steering_system_emergency" />
                  </Checkbox>
                </Fieldset>
              );
            }}
          />
        </div>
        {isEmergencySteering && (
          <div className={cn(utils.offset1, utils.col9, utils.colMd7)}>
            <div
              className={cn(alert.alert, alert.alertWarning, utils.mt8)}
              data-testid="warning-emergency-steering"
            >
              <SvgWarning className={cn(core.icon, alert.alertIcon)} />
              <div className={cn(alert.alertMessage, core.flexColumn)}>
                <p className={alert.alertHeading}>
                  <FormattedMessage id="wizard_requirements_steering_emergency_warning_title" />
                </p>
                <p>
                  <FormattedMessage
                    id="wizard_requirements_steering_emergency_warning_text"
                    values={{
                      linebreak: <br />,
                    }}
                  />
                  <button
                    className={cn(button.textBtn, button.textBtnPrimary, utils.ml2)}
                    data-testid="contact-modal-button"
                    type="button"
                    onClick={onContactModalOpen}
                  >
                    <FormattedMessage id="wizard_requirements_steering_emergency_warning_button" />
                  </button>
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className={cn(utils.row, utils.mt4)}>
        <div className={cn(utils.offset1, utils.col4, utils.colMd4)}>
          <div>
            <Fieldset data-testid="max-steering-pressure-fieldset">
              <Label>
                <FormattedMessage id="specs_steering_max_pressure" />
              </Label>
              <InputGroup>
                <Controller
                  control={control}
                  name="steeringData.max_steering_pressure"
                  rules={validation.max_steering_pressure}
                  render={({ field }) => (
                    <ConverterInput
                      type="number"
                      metric="bar"
                      imperial="psi"
                      data-testid="max_steering_pressure"
                      value={field.value ?? ''}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      error={!!formErrors.max_steering_pressure}
                      {...validation.max_steering_pressure}
                    />
                  )}
                />
                <Addon>
                  <FormatMeasureUnit
                    metric="measurements_unit_bar"
                    imperial="measurements_unit_psi"
                  />
                </Addon>
              </InputGroup>
              <FormError
                errors={errors}
                rules={validation}
                field="steeringData.max_steering_pressure"
                metric="bar"
                imperial="psi"
              />
            </Fieldset>
          </div>
        </div>
        {!isSteerByWire && (
          <div
            className={cn(utils.offset1, utils.col4, utils.colMd4)}
            data-testid="max-steering-wheel-speed-section"
          >
            <div>
              <Fieldset data-testid="max-steering-wheel-speed-fieldset">
                <Label>
                  <FormattedMessage id="specs_steering_max_wheel_speed" />
                </Label>
                <InputGroup>
                  <Input
                    type="number"
                    data-testid="max_steering_wheel_speed"
                    error={!!formErrors.max_steering_wheel_speed}
                    {...register('steeringData.max_steering_wheel_speed', {
                      ...validation.max_steering_wheel_speed,
                      valueAsNumber: true,
                    })}
                    {...validation.max_steering_wheel_speed}
                  />
                  <Addon>
                    <FormattedMessage id="measurements_unit_rpm" />
                  </Addon>
                </InputGroup>
                <FormError
                  errors={errors}
                  rules={validation}
                  field="steeringData.max_steering_wheel_speed"
                />
              </Fieldset>
            </div>
          </div>
        )}
      </div>
      <div className={cn(utils.row, utils.mt4)}>
        <div className={cn(utils.offset1, utils.col11, utils.colMd7)}>
          <h5>
            <FormattedMessage id="specs_steering_priority_label" />
          </h5>
          <Fieldset
            className={cn(
              components.radioGroup,
              components.inline,
              utils.mt4,
              styles['radio-group'],
            )}
            data-testid="steering-data-priority-fieldset"
          >
            <RadioInput
              id="radio-priority-inline"
              data-testid="inline"
              value={SteeringPriority.Inline}
              {...register('steeringData.priority_valve')}
            >
              <FormattedMessage id="specs_steering_priority_inline" />
            </RadioInput>
            <RadioInput
              id="radio-priority-pump-mounted"
              data-testid="pump-mounted"
              value={SteeringPriority.PumpMounted}
              {...register('steeringData.priority_valve')}
            >
              <FormattedMessage id="specs_steering_priority_pump_mounted" />
            </RadioInput>
            <RadioInput
              id="radio-priority-flanged"
              data-testid="flanged"
              value={SteeringPriority.Flanged}
              {...register('steeringData.priority_valve')}
            >
              <FormattedMessage id="specs_steering_priority_flanged" />
            </RadioInput>
            <RadioInput
              id="radio-priority-none"
              data-testid="none"
              value={SteeringPriority.None}
              {...register('steeringData.priority_valve')}
            >
              <FormattedMessage id="specs_steering_priority_none" />
            </RadioInput>
          </Fieldset>
          <Checkbox
            id="checkbox-share-pump"
            {...register('steeringData.share_pump')}
            className={utils.mt4}
            data-testid="checkbox-share-pump"
          >
            <FormattedMessage id="specs_steering_share_pump" />
          </Checkbox>
        </div>
      </div>
      <div className={cn(utils.row, utils.mt4)}>
        <div className={cn(utils.offset1, utils.col4, utils.colMd4)}>
          <div>
            <Fieldset data-testid="cylinder-size-fieldset">
              <Label>
                <FormattedMessage id="specs_steering_cylinder_size" />
              </Label>
              <InputGroup>
                <Controller
                  control={control}
                  name="steeringData.cylinder_size"
                  rules={validation.cylinder_size}
                  render={({ field }) => (
                    <ConverterInput
                      type="number"
                      metric="cm3"
                      imperial="in3"
                      value={field.value ?? ''}
                      onChange={field.onChange}
                      onBlur={field.onBlur}
                      error={!!formErrors.cylinder_size}
                      data-testid="cylinder_size"
                      {...validation.cylinder_size}
                    />
                  )}
                />
                <Addon>
                  <FormatMeasureUnit
                    metric="measurements_unit_ccm"
                    imperial="measurements_unit_cinch"
                  />
                </Addon>
              </InputGroup>
              <FormError
                errors={errors}
                rules={validation}
                field="steeringData.cylinder_size"
                metric="cm3"
                imperial="in3"
              />
            </Fieldset>
          </div>
        </div>
      </div>
      {isContactModalOpen && <ContactModal onClose={onContactModalClose} projectId={projectId} />}
    </div>
  );
};

export default SteeringView;
