import axios from 'axios';

import { getAppConfig } from '../configs/global';

export type ITranslations = Record<string, string>;

export const getTranslations = async (locale: string) => {
  const functionsUrl = getAppConfig().AZURE_FUNCTIONS_URL;
  const { data } = await axios.get<ITranslations>(`${functionsUrl}/api/TranslationsFunction`, {
    params: { locale },
  });

  return data;
};
