import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfLoader } from '@danfoss/mosaic/react';
import { useTreatments } from '@splitsoftware/splitio-react';
import cn from 'classnames';
import { useContext, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { ReactComponent as CompareSvg } from 'assets/icons/compare-arrows.svg';
import { ReactComponent as SvgDownload } from 'assets/icons/download.svg';
import { ReactComponent as SvgShare } from 'assets/icons/share-link.svg';
import { useAppDispatch, useAppSelector } from 'configs/store';
import { GTMEvent, trackEvent } from 'utils/analytics';
import { useToggle } from 'utils/useToggle';
import ContactUs from 'views/base/ContactUs/ContactUs';
import Button from 'views/shared/components/Button/Button';
import DocumentList from 'views/shared/components/DocumentList/DocumentList';
import EnteredData from 'views/shared/components/EnteredData/EnteredData';
import ProductList from 'views/shared/components/ProductList';
import SummaryTabs from 'views/shared/components/SummaryTabs/SummaryTabs';
import { AuthContext, AuthType } from 'views/shared/helpers/auth/AuthContext';
import { sendToDC } from 'views/shared/helpers/designCenterService';
import {
  AvailableFeatureFlags,
  FeatureFlagState,
} from 'views/shared/helpers/featureFlags/constants';
import { FunctionCode, IProject, ProjectStatus } from 'views/shared/types';
import ShareModal from 'views/sharing/ShareModal/ShareModal';

import WizardStep from '../shared/components/WizardStep';
import { useProject } from '../shared/helpers/useProject';
import useRedirectByStatus from '../shared/helpers/useRedirectByStatus';
import { useSelectedProducts } from '../shared/helpers/useSelectedProducts';
import {
  getCalculations,
  getMotorsPrices,
  getPumpsPrices,
  getSteeringProductsPrices,
  updateProject,
} from '../shared/store/projectSlice';
import styles from './Summary.module.scss';
import PerformanceTab from './components/PerformanceTab';
import RenameSelectionModal from './components/RenameSelectionModal';
import { PDFTemplate, PDFTemplateForwardedRef } from './components/pdf/PDFTemplate';
import { GeneratePDFModal } from './components/pdf/components/GeneratePDFModal/GeneratePDFModal';

const Summary = () => {
  const { formatMessage } = useIntl();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { authType } = useContext(AuthContext);
  const { projectId } = useParams<{ projectId: string }>();
  const { isLoading: isProjectLoading, project } = useProject(projectId);
  const calculations = useAppSelector(getCalculations);
  const { isLoading: isProductsLoading, products } = useSelectedProducts(projectId);
  const motorsPrices = useAppSelector(getMotorsPrices);
  const pumpsPrices = useAppSelector(getPumpsPrices);
  const steeringProductsPrices = useAppSelector(getSteeringProductsPrices);
  const prices = { ...motorsPrices, ...pumpsPrices, ...steeringProductsPrices };
  const [isShareModalOpen, openShareModal, closeShareModal] = useToggle(false);
  const [isRenameModalOpen, openRenameModal, closeRenameModal] = useToggle(false);
  const [isGeneratePDFModalOpen, openGeneratePDFModal, closeGeneratePDFModal] = useToggle(false);
  const [isPDFGenerating, turnOnGeneratingPDF, turnOffGeneratingPDF] = useToggle(false);
  const motorsType = products.motors?.[0]?.type;
  const isPageLoading = isProjectLoading || isProductsLoading;
  const isSendToDCAvailable = authType === AuthType.SF;
  const pdfTemplateRef = useRef<PDFTemplateForwardedRef>(null);
  const hasPropelSizing = project.function_codes?.includes(FunctionCode.PropelSizing);

  const featureFlags = useTreatments([AvailableFeatureFlags.PerformanceGraph]);
  const isPerformanceGraphEnabled =
    featureFlags[AvailableFeatureFlags.PerformanceGraph]?.treatment === FeatureFlagState.On;
  const isPerformanceAvailable = isPerformanceGraphEnabled && hasPropelSizing;

  const onCloseSelectionClick = () => {
    history.push('/');
  };

  const [isProjectUpdating, setIsProjectUpdating] = useState(false);
  const [isSendingToDC, setIsSendingToDC] = useState(false);

  useRedirectByStatus(project.id, project.project_status, [ProjectStatus.Configured]);

  const handleRename = async (newName: string) => {
    await dispatch(
      updateProject({
        id: project.id,
        project: {
          project_name: newName,
        },
      }),
    ).unwrap();
    closeRenameModal();
  };
  const handleSendToDC = async () => {
    try {
      setIsSendingToDC(true);
      await sendToDC(projectId);
    } finally {
      setIsSendingToDC(false);
    }
  };
  const setProjectStatusCalculationsComplete = async () => {
    try {
      setIsProjectUpdating(true);

      await dispatch(
        updateProject({
          id: project.id,
          project: {
            project_status: ProjectStatus.CalculationsCompleted,
          },
        }),
      ).unwrap();
    } finally {
      setIsProjectUpdating(false);
    }
  };
  const changeProducts = async () => {
    await setProjectStatusCalculationsComplete();
    history.push(`/product-selection/${project.id}`);
  };
  const onCompareButtonClick = () => {
    const gtmEvent: GTMEvent = {
      event: 'add_to_compare',
    };

    if (hasPropelSizing) {
      Object.assign(gtmEvent, { architecture: project.propel_sizing?.initial_system_architecture });
    }

    trackEvent(gtmEvent);
    history.push(`/compare/${projectId}`);
  };
  const onExportPDFClick = async () => {
    if (hasPropelSizing) {
      openGeneratePDFModal();

      return;
    }

    try {
      trackEvent({
        event: 'export_pdf',
        selectionId: project.id,
        functionType: project.function_codes,
      });
      turnOnGeneratingPDF();
      await pdfTemplateRef.current?.render();
    } finally {
      turnOffGeneratingPDF();
    }
  };
  const trackPropelSizingPDFExportEvent = () => {
    trackEvent({
      event: 'generate_pdf',
      selectionId: project.id,
      functionType: project.function_codes,
      architectureType: project.propel_sizing?.initial_system_architecture,
      motorType: motorsType,
    });
  };
  const onShareClick = () => {
    trackEvent({ event: 'share_selection', selectionId: project.id, pageType: 'summary' });
    openShareModal();
  };

  if (isPageLoading) {
    return <DfLoader isVisible isGlobal data-testid="summary-page-loader" />;
  }

  const { motors, pumps, steeringProducts } = products;
  const productsList = [...(motors ?? []), ...(pumps ?? []), ...(steeringProducts ?? [])];
  const productIds = productsList.map(({ product_id }) => product_id);

  return (
    <div data-testid="summary-page" className={cn(core.flex, core.flexColumn, core.fullHeight)}>
      <WizardStep
        progress={4}
        breadcrumb={<FormattedMessage id="wizard_breadcrumbs_summary" />}
        setLoading={setIsProjectUpdating}
      />
      <PDFTemplate
        ref={pdfTemplateRef}
        project={project as IProject}
        products={products}
        calculations={calculations!}
      />
      <h2 className={utils.mb4} data-testid="project-name">
        {project.project_name}
      </h2>
      <DfLoader isGlobal isVisible={isProjectUpdating}>
        <EnteredData
          className={utils.mb4}
          project={project}
          calculations={calculations}
          onLinkClick={changeProducts}
          linkLabel={formatMessage({ id: 'wizard_summary_change_products' })}
          motorType={motorsType}
        />
        <div className={cn(utils.mb6, core.flex)} data-testid="top-action-bar">
          <button
            className={cn(button.btnPrimary, utils.mr3)}
            onClick={openRenameModal}
            data-testid="summary-rename-button"
          >
            <FormattedMessage id="wizard_summary_rename_selection" />
          </button>
          <Button
            className={cn(button.textBtn, button.textBtnSecondary, utils.mr3)}
            data-testid="export-pdf"
            disabled={isPDFGenerating}
            onClick={onExportPDFClick}
          >
            <SvgDownload className={cn(core.icon, utils.mr2)} data-testid="export-pdf-icon" />
            {isPDFGenerating ? (
              <FormattedMessage id="button_loading" />
            ) : (
              <FormattedMessage id="wizard_summary_export_pdf_button" />
            )}
          </Button>
          {isSendToDCAvailable && (
            <Button
              className={cn(button.btnSecondary)}
              onClick={handleSendToDC}
              loading={isSendingToDC}
              data-testid="summary-send-to-dc-button"
            >
              <FormattedMessage id="transfer_selection_button" />
            </Button>
          )}
          <button
            className={cn(button.textBtn, button.textBtnSecondary, core.end, styles.button)}
            onClick={onShareClick}
            data-testid="summary-share-button"
          >
            <SvgShare className={cn(core.icon, utils.mr2)} />
            <FormattedMessage id="wizard_summary_share_selection_label" />
          </button>
        </div>
        <SummaryTabs
          products={<ProductList products={products} prices={prices} />}
          documents={<DocumentList productIds={productIds} />}
          performance={
            isPerformanceAvailable ? (
              <PerformanceTab projectId={projectId} motorType={motorsType!} />
            ) : undefined
          }
        />
        <div
          className={cn(utils.mt8, core.flex, core.end, styles.buttons_wrapper)}
          data-testid="bottom-action-bar"
        >
          <button
            className={cn(button.textBtn, button.textBtnPrimary, styles.button)}
            onClick={onCompareButtonClick}
            data-testid="compare-button"
          >
            <span className={utils.mr2}>
              <CompareSvg className={core.icon} />
            </span>
            <FormattedMessage id="wizard_summary_comparison_button" />
          </button>
          <button
            className={cn(button.btnPrimary, utils.ml6)}
            onClick={onCloseSelectionClick}
            data-testid="close-selection"
          >
            <FormattedMessage id="wizard_summary_close_selection" />
          </button>
        </div>
      </DfLoader>
      <ContactUs projectId={projectId} />
      {isRenameModalOpen && (
        <RenameSelectionModal
          projectName={project.project_name}
          onSubmit={handleRename}
          onCancel={closeRenameModal}
        />
      )}
      {isShareModalOpen && <ShareModal projectId={projectId} onClose={closeShareModal} />}
      {isGeneratePDFModalOpen && (
        <GeneratePDFModal
          projectId={projectId}
          pdfTemplateRef={pdfTemplateRef}
          motorType={motorsType!}
          onClose={closeGeneratePDFModal}
          onExport={trackPropelSizingPDFExportEvent}
        />
      )}
    </div>
  );
};

export default Summary;
