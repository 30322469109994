export const validation = {
  requiredLockToLockTurns: {
    min: 2,
    max: 9,
    required: true,
  },
  requiredLockToLockSeconds: {
    min: 1,
    max: 60,
    required: true,
  },
};
