import { TableColumnType, TableRowSelectedEventType } from '@danfoss/mosaic';
import { ReactComponent as SvgWarning } from '@danfoss/mosaic-icons/dist/svg/error-outline.svg';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfTable } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { memo, useContext } from 'react';
import { renderToString } from 'react-dom/server';
import { useIntl } from 'react-intl';

import { round } from 'views/shared/helpers/formatters';
import {
  MeasuringSystemContext,
  MeasuringUnits,
} from 'views/shared/helpers/measuringSystem/MeasuringSystemContext';
import { convertAvailablePump } from 'views/shared/helpers/measuringSystem/convertors';
import useMeasuringUnitsIntl from 'views/shared/helpers/measuringSystem/useMeasuringUnitsIntl';
import { IAvailablePump } from 'views/wizard/product-selection/api/products';

import { IPrice } from '../../../../../../shared/types';
import productsTableStyles from '../../../components/tables/ProductTable.module.scss';
import {
  capacityCell,
  nameCell,
  rpmCell,
  usePriceCellFormatter,
} from '../../../components/tables/helpers/formatters';
import { useScrollIntoRow } from '../../../components/tables/helpers/useScrollIntoRow';
import styles from './PumpTable.module.scss';

interface PumpTableProps {
  list: IAvailablePump[];
  onRowSelected?: (e: CustomEvent<TableRowSelectedEventType>) => void;
  tableClassName?: string;
  requiredPumpPressure: number;
}

const PumpTable = ({
  list,
  onRowSelected,
  tableClassName,
  requiredPumpPressure,
}: PumpTableProps) => {
  const { formatMessage } = useIntl();
  const { formatMeasuringUnit } = useMeasuringUnitsIntl();
  const { units } = useContext(MeasuringSystemContext);
  const defaultPriceFormatter = usePriceCellFormatter(useIntl());

  const priceFormatter = (price: IPrice) => {
    const isPriceAvailable = price && price.value !== 0;

    return defaultPriceFormatter(price?.value, isPriceAvailable);
  };
  const maxDeltaPressureCell = (value: number) => {
    const helperText = (
      <span
        className={cn(core.textItalic, utils.colorNeutralStrong, styles['max-pressure-hint'])}
        data-testid="pump-max-pressure-hint-text"
      >
        {formatMessage(
          { id: 'wizard_selection_modal_header_low_max_pressure' },
          { value: requiredPumpPressure },
        )}
      </span>
    );
    const icon = (
      <span className={cn(core.icon, utils.ml1)}>
        <SvgWarning width={16} height={16} />
      </span>
    );
    const isLowerThanRequired = value < requiredPumpPressure;

    return renderToString(
      <div data-testid="pump-max-pressure" className={cn(core.flex, core.flexColumn)}>
        <div className={core.flex}>
          <span>{round(value)}</span>
          {isLowerThanRequired && icon}
        </div>
        {isLowerThanRequired && helperText}
      </div>,
    );
  };
  const pumpsConvertor = (pumps: IAvailablePump[]): IAvailablePump[] => {
    return pumps.map(data => ({
      ...data,
      pump: convertAvailablePump(data.pump, units),
    }));
  };
  const convertedList = units === MeasuringUnits.Imperial ? pumpsConvertor(list) : list;

  const getRowId = (originalRow: Record<string, any>) => (originalRow as IAvailablePump).pump.id;
  const { ref, getRowProps } = useScrollIntoRow();

  const columns: TableColumnType[] = [
    {
      accessor: 'pump.name',
      id: 'name',
      Header: formatMessage({ id: 'wizard_selection_modal_header_name' }),
      Cell: nameCell(formatMessage),
      getCellProps: () => ({ className: core.flexWrap }),
    },
    {
      accessor: 'capacity',
      id: 'capacity',
      Header: formatMessage({ id: 'wizard_selection_modal_header_capacity' }),
      Cell: capacityCell(formatMessage),
    },
    {
      accessor: 'pump.max_pressure',
      id: 'max_pressure',
      Header: formatMessage(
        { id: 'wizard_selection_modal_header_max_pressure' },
        {
          units: formatMeasuringUnit({
            metric: 'measurements_unit_bar',
            imperial: 'measurements_unit_psi',
          }),
        },
      ),
      Cell: maxDeltaPressureCell,
      width: 115,
    },
    {
      accessor: 'pump.rated_pressure',
      id: 'rated_pressure',
      Header: formatMessage(
        { id: 'wizard_selection_modal_header_rated_pressure' },
        {
          units: formatMeasuringUnit({
            metric: 'measurements_unit_bar',
            imperial: 'measurements_unit_psi',
          }),
        },
      ),
      getCellProps: () => ({ className: core.flexEnd }),
      Cell: value =>
        value ? renderToString(<span data-testid="pump-rated-pressure">{round(value)}</span>) : '',
      width: 90,
    },
    {
      accessor: 'pump.continuous_pressure',
      id: 'continuous_pressure',
      Header: formatMessage(
        { id: 'wizard_selection_modal_header_continuous_pressure' },
        {
          units: formatMeasuringUnit({
            metric: 'measurements_unit_bar',
            imperial: 'measurements_unit_psi',
          }),
        },
      ),
      getCellProps: () => ({ className: core.flexEnd }),
      Cell: value =>
        value ? renderToString(<span data-testid="continuous-pressure">{round(value)}</span>) : '',
      width: 110,
    },
    {
      accessor: 'pump.speed_limit_max',
      id: 'speed_limit_max',
      Header: formatMessage({ id: 'wizard_selection_modal_header_speed_limit_max' }),
      getCellProps: () => ({ className: core.flexEnd }),
      width: 100,
      Cell: rpmCell('pump_speed_limit_max'),
    },
    {
      accessor: 'pump.speed_limit_rated',
      id: 'speed_limit_rated',
      Header: formatMessage({ id: 'wizard_selection_modal_header_speed_limit_rated' }),
      getCellProps: () => ({ className: core.flexEnd }),
      width: 120,
      Cell: rpmCell('pump_speed_limit_rated'),
    },
    {
      accessor: 'price',
      id: 'price',
      Header: formatMessage({ id: 'wizard_selection_modal_header_price' }),
      Cell: priceFormatter,
      width: 150,
    },
  ];

  return (
    <DfTable
      ref={ref}
      className={cn(productsTableStyles.table, tableClassName)}
      columns={columns}
      data={convertedList}
      isSelectable
      isSortable={false}
      isOneRowSelection
      isSelectableOnClick
      selectedRows={[convertedList.find(({ recommended }) => recommended)?.pump.id as string]}
      getRowId={getRowId}
      onRowSelected={onRowSelected}
      getRowProps={getRowProps}
    />
  );
};

export default memo(PumpTable);
