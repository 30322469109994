import { useEffect, useRef, useState, FunctionComponent } from 'react';

import { applyInterceptor, ejectInterceptor, IInterceptorCallback } from 'configs/axios';

export interface IErrorInterceptorProviderProps {
  interceptors: IInterceptorCallback[];
}

const ErrorInterceptorProvider: FunctionComponent<IErrorInterceptorProviderProps> = ({
  children,
  interceptors,
}) => {
  const appliedInterceptors = useRef<number[]>([]);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    interceptors.forEach(interceptorCallback => {
      const interceptorId = applyInterceptor(interceptorCallback);

      appliedInterceptors.current.push(interceptorId);
    });

    setIsLoaded(true);

    return () => {
      appliedInterceptors.current.forEach(interceptorId => {
        ejectInterceptor(interceptorId);
      });

      appliedInterceptors.current = [];
    };
  }, []);

  if (!isLoaded) {
    return null;
  }

  return children as JSX.Element;
};

export default ErrorInterceptorProvider;
