import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import {
  ScaleOptionsByType,
  ChartOptions,
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Legend,
} from 'chart.js';
import cn from 'classnames';
import { useContext } from 'react';
import { Line } from 'react-chartjs-2';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as SvgExpand } from 'assets/icons/expand.svg';
import { useToggle } from 'utils/useToggle';
import { IPerformanceCalculations } from 'views/shared/api/performance';
import {
  MeasuringSystemContext,
  MeasuringUnits,
} from 'views/shared/helpers/measuringSystem/MeasuringSystemContext';
import useMeasuringUnitsIntl from 'views/shared/helpers/measuringSystem/useMeasuringUnitsIntl';
import { usePerformancePointsHelper } from 'views/shared/helpers/performance';

import ExpandedModal from '../ExpandedPerformanceGraph/ExpandedModal';
import styles from './SmallPerformanceGraph.module.scss';

interface ISmallPerformanceGraphProps {
  currentProjectId: string;
  data: IPerformanceCalculations[];
}

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Legend);

const SmallPerformanceGraph = ({ data, currentProjectId }: ISmallPerformanceGraphProps) => {
  const { units } = useContext(MeasuringSystemContext);
  const isImperialSystem = units === MeasuringUnits.Imperial;
  const { formatMessage } = useIntl();
  const { formatMeasuringUnit } = useMeasuringUnitsIntl();
  const extractPoints = usePerformancePointsHelper();
  const [isExpandedModalOpen, openExpandedModal, closeExpandedModal] = useToggle();

  const colors = [styles.grey, styles.black, styles.orange];
  const currentPerfItemIndex = data.findIndex(perfItem => perfItem.project_id === currentProjectId);

  colors.splice(currentPerfItemIndex, 0, styles.red);

  const getColorsForExpandedChart = (_projectId: string, index: number) => {
    return colors[index];
  };
  const getColorsForSmallChart = (projectId: string) => {
    return projectId === currentProjectId ? styles.red : styles.grey;
  };
  const createDataset = (
    performance: IPerformanceCalculations[],
    getColor: (projectId: string, index: number) => string,
  ) => {
    return performance.flatMap((perfItem, index) => {
      const { motors_cases, project_id } = perfItem;
      const motorCase = motors_cases[0];
      const points = extractPoints(motorCase.total_performance);

      return {
        label: formatMessage(
          {
            id: 'compare_page_performance_cell_modal_graph_name',
          },
          { number: index + 1 },
        ),
        data: points,
        borderColor: getColor(project_id, index),
        backgroundColor: getColor(project_id, index),
        tension: 0.25,
      };
    });
  };

  const tractiveForceLabel = formatMessage(
    { id: 'performance_prediction_label_tractive_force' },
    {
      units: formatMessage({
        id: isImperialSystem ? 'measurements_unit_lbf' : 'measurements_unit_kn',
      }),
    },
  );
  const vehicleSpeedLabel = formatMessage(
    { id: 'performance_prediction_label_vehicle_speed' },
    {
      units: formatMeasuringUnit({
        metric: 'measurements_unit_kmh',
        imperial: 'measurements_unit_mph',
      }),
    },
  );
  const title = {
    display: true,
    color: styles.black,
    font: {
      family: styles.fontFamily,
    },
  };
  const chartOptions: ChartOptions<'line'> = {
    animation: {
      duration: 0,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        title: {
          ...title,
          text: tractiveForceLabel,
        },
        beginAtZero: true,
      } as ScaleOptionsByType<'linear'>,
      x: {
        type: 'linear',
        title: {
          ...title,
          text: vehicleSpeedLabel,
        },
        grid: {
          display: false,
        },
        beginAtZero: true,
      } as ScaleOptionsByType<'linear'>,
    },
  };
  const smallChartData = {
    datasets: createDataset(data, getColorsForSmallChart),
  };
  const expandedChartData = {
    datasets: createDataset(data, getColorsForExpandedChart),
  };

  return (
    <div>
      <div
        className={styles.graphWrapper}
        onClick={openExpandedModal}
        data-testid="small_performance_graph"
      >
        <Line data={smallChartData} options={chartOptions} />
      </div>
      <button
        className={cn(button.textBtn, button.textBtnPrimary)}
        onClick={openExpandedModal}
        data-testid="open_expended_graph"
      >
        <SvgExpand className={cn(core.icon, utils.mr2)} />
        <FormattedMessage id="compare_page_performance_cell_expand" />
      </button>
      {isExpandedModalOpen && (
        <ExpandedModal
          onClose={closeExpandedModal}
          chartData={expandedChartData}
          chartOptions={chartOptions}
        />
      )}
    </div>
  );
};

export default SmallPerformanceGraph;
