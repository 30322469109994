import { ReactElement, ReactNode, ComponentProps } from 'react';
import { FormattedMessage } from 'react-intl';

interface IProjectSpecItemProps extends ComponentProps<'div'> {
  value?: number | string | ReactElement;
  actual?: number | string | ReactElement;
  suffix?: ReactNode;
  'data-testid'?: string;
}

export const ProjectSpecItem = ({
  value,
  suffix,
  children,
  actual,
  ...rest
}: IProjectSpecItemProps) => {
  return (
    <div
      data-testid={rest['data-testid'] ? `${rest['data-testid']}-specs-row` : 'specs-row'}
      style={{
        display: 'flex',
        fontSize: '22px',
        marginBottom: '20px',
      }}
    >
      <div
        data-testid="specs-row-title"
        style={{
          fontWeight: 700,
          width: '60%',
          paddingRight: '10px',
        }}
      >
        {children}
      </div>
      <div data-testid={rest['data-testid']}>
        <span>
          {value} {suffix}
        </span>
        {actual && (
          <div
            data-testid={rest['data-testid'] ? `${rest['data-testid']}-actual` : 'actual'}
            style={{ fontStyle: 'italic' }}
          >
            <FormattedMessage id="specs_data_actual" /> {actual} {suffix}
          </div>
        )}
      </div>
    </div>
  );
};
