import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from 'configs/store';
import { FunctionCode } from 'views/shared/types';

import { getSizingFunctions as getSizingFunctionsAPI } from '../api/sizing-functions';

export interface ISizingFunctionsState {
  list: FunctionCode[] | null;
  selectedFunctions: FunctionCode[] | null;
}

export const initialState: ISizingFunctionsState = {
  list: null,
  selectedFunctions: null,
};

export const fetchSizingFunctions = createAsyncThunk('sizingFunctions/get', () => {
  return getSizingFunctionsAPI();
});

const getSlice = (state: RootState) => state.sizingFunctions;
export const getSizingFunctions = (state: RootState) => getSlice(state).list;
export const getSelectedFunctions = (state: RootState) => getSlice(state).selectedFunctions;

const sizingFunctionsSlice = createSlice({
  name: 'sizingFunctions',
  initialState,
  reducers: {
    setSelectedFunctions(state, action: PayloadAction<ISizingFunctionsState['selectedFunctions']>) {
      state.selectedFunctions = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchSizingFunctions.fulfilled, (state, { payload }) => {
      state.list = payload;
    });
  },
});

export const { setSelectedFunctions } = sizingFunctionsSlice.actions;
export default sizingFunctionsSlice.reducer;
