import button from '@danfoss/mosaic/css/components/button.module.css';
import tabs from '@danfoss/mosaic/css/components/tabs.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfLoader } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { MouseEvent, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'configs/store';
import { showError, showUnknownError, showWarning } from 'utils/ToastService';
import ContactUs from 'views/base/ContactUs/ContactUs';
import Button from 'views/shared/components/Button/Button';
import {
  AutoUpdateProductsWarning,
  FunctionCode,
  MachineExampleCode,
  ProjectStatus,
} from 'views/shared/types';
import ConfirmationModal from 'views/wizard/shared/components/ConfirmationModal';
import WizardStep from 'views/wizard/shared/components/WizardStep';
import { useProject } from 'views/wizard/shared/helpers/useProject';
import useRedirectByStatus from 'views/wizard/shared/helpers/useRedirectByStatus';
import {
  autoUpdateProducts,
  getProject as refreshProjectData,
  getPropelSizingData,
  getSteeringData,
  getSystemData,
  updateProject,
  updatePropelSizing,
  updateSteeringSystem,
  updateSystemData,
} from 'views/wizard/shared/store/projectSlice';

import { showOverSpeedingWarning } from '../shared/helpers/showOverSpeedingWarning';
import styles from './Requirements.module.scss';
import { ContactUsButton } from './components/ContactUsButton';
import SteeringView from './components/tabs/Steering/Steering';
import SystemDataView from './components/tabs/SystemData/SystemData';
import { OverrideMotorTypeProvider } from './components/tabs/TractiveForce/OverrideMotorType.context';
import TractiveForceView from './components/tabs/TractiveForce/TractiveForce';
import useCustomUnloadWarning from './helpers/useCustomUnloadWarning';
import useNativeUnloadWarning from './helpers/useNativeUnloadWarning';
import useTabMode from './helpers/useTabMode';
import { useValidation } from './helpers/useValidation';
import { IRequirementsForm } from './types';

enum Tabs {
  SystemData = 'system-data',
  Steering = 'steering',
  TractiveForce = 'tractive-force',
}

const tabsText: Record<Tabs, string> = {
  [Tabs.SystemData]: 'wizard_requirements_system_data_tab',
  [Tabs.Steering]: 'wizard_requirements_steering_tab',
  [Tabs.TractiveForce]: 'wizard_requirements_tractive_force_tab',
};

const Requirements = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const dispatch = useAppDispatch();
  const { formatMessage } = useIntl();
  const history = useHistory();
  const tabMode = useTabMode();
  const { isLoading: isProjectLoading, project } = useProject(projectId);
  const { isLoading: isValidationLoading, validation } = useValidation();
  const isPageLoading = isProjectLoading || isValidationLoading;
  const [isPageUpdating, setIsPageUpdating] = useState(false);
  const [isTabUpdating, setIsTabUpdating] = useState(false);
  const systemData = useAppSelector(getSystemData);
  const steeringData = useAppSelector(getSteeringData);
  const propelSizingData = useAppSelector(getPropelSizingData);
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.SystemData);
  const [isValidNewName, setIsValidNewName] = useState(true);
  const filledTabs = useRef(new Set<Tabs>());
  const form = useForm<IRequirementsForm>({
    shouldUnregister: true,
  });
  const { handleSubmit, formState } = form;

  const tabList = useMemo(() => {
    const result = [Tabs.SystemData];

    const isTractiveForceAvailable = project.function_codes?.includes(FunctionCode.PropelSizing);
    const isSteeringAvailable = project.function_codes?.includes(FunctionCode.SteeringSystem);

    if (isSteeringAvailable) {
      result.push(Tabs.Steering);
    }

    if (isTractiveForceAvailable) {
      result.push(Tabs.TractiveForce);
    }

    return result;
  }, [project.function_codes]);

  const {
    isOpen: isUnloadWarningModalOpen,
    props: unloadWarningModalProps,
    unblock: unblockNavigation,
  } = useCustomUnloadWarning(formState.isDirty);

  useNativeUnloadWarning(formState.isDirty);
  useRedirectByStatus(project.id, project.project_status, [ProjectStatus.Draft]);

  const saveTab = async (tabId: Tabs, data: IRequirementsForm) => {
    try {
      setIsPageUpdating(true);

      if (tabId === Tabs.SystemData) {
        await dispatch(
          updateSystemData({
            id: project.id,
            systemData: data.systemData,
          }),
        ).unwrap();
        filledTabs.current.add(Tabs.SystemData);
      }

      if (tabId === Tabs.Steering) {
        await dispatch(
          updateSteeringSystem({
            id: project.id,
            steeringData: data.steeringData,
          }),
        ).unwrap();
        filledTabs.current.add(Tabs.Steering);
      }

      if (tabId === Tabs.TractiveForce) {
        await dispatch(
          updatePropelSizing({
            id: project.id,
            propelSizingData: data.propelSizingData,
          }),
        ).unwrap();
        filledTabs.current.add(Tabs.TractiveForce);
      }
    } finally {
      setIsPageUpdating(false);
    }
  };
  const onTabChange = ({ target }: MouseEvent<HTMLAnchorElement>) => {
    const newTabId = (target as HTMLAnchorElement).dataset.tabid as Tabs;

    if (newTabId === activeTab) {
      return;
    }

    handleSubmit(async data => {
      if (!isValidNewName) {
        return;
      }

      await saveTab(activeTab, data);
      setActiveTab(newTabId);
    })();
  };
  const onTabUpdate = (isUpdating: boolean) => {
    setIsTabUpdating(isUpdating);
  };

  async function autoUpdate() {
    try {
      const { warning } = await dispatch(
        autoUpdateProducts({ projectId: project.id, disableErrorInterceptor: true }),
      ).unwrap();

      if (warning.includes(AutoUpdateProductsWarning.OutOfRequirement)) {
        showWarning(
          <FormattedMessage id="wizard_requirements_autofill_warning_header" />,
          <>
            <FormattedMessage id="wizard_requirements_autofill_warning_text" />
            <ContactUsButton projectId={project.id} />
          </>,
        );
      }

      showOverSpeedingWarning(warning, projectId);
    } catch (error) {
      const { message } = error as Error;

      if (!message) {
        showUnknownError();
      } else {
        showError(
          <>
            {message}
            <ContactUsButton projectId={project.id} />
          </>,
        );
      }

      throw error;
    }
  }

  const onNextClick = () => {
    handleSubmit(async data => {
      if (!isValidNewName) {
        return;
      }

      await saveTab(activeTab, data);

      const currentTabIndex = tabList.indexOf(activeTab);
      const isLastTab = currentTabIndex === tabList.length - 1;

      if (!isLastTab) {
        setActiveTab(tabList[currentTabIndex + 1]);
        return;
      }

      const unfilledTab = tabList.find(tab => !filledTabs.current.has(tab));

      if (unfilledTab) {
        setActiveTab(unfilledTab);
        return;
      }

      try {
        setIsPageUpdating(true);

        await dispatch(
          updateProject({
            id: project.id,
            project: {
              project_status: ProjectStatus.CalculationsCompleted,
            },
          }),
        ).unwrap();

        if (project.machine_example_code !== MachineExampleCode.Custom) {
          try {
            await autoUpdate();
          } catch {
            await dispatch(
              updateProject({
                id: project.id,
                project: {
                  project_status: ProjectStatus.Draft,
                },
              }),
            ).unwrap();
          }

          await dispatch(refreshProjectData(project.id)).unwrap();
        }

        unblockNavigation();
        history.push(`/product-selection/${project.id}`);
      } catch {
        setIsPageUpdating(false);
      }
    })();
  };
  const onCloseDraftClick = () => {
    handleSubmit(async data => {
      if (!isValidNewName) {
        return;
      }

      await saveTab(activeTab, data);
      unblockNavigation();
      history.push('/');
    })();
  };

  if (isPageLoading) {
    return <DfLoader isGlobal isVisible data-testid="requirements-page-loader" />;
  }

  const isCustomFlow = project.machine_example_code === MachineExampleCode.Custom;

  return (
    <>
      <WizardStep
        progress={1}
        breadcrumb={<FormattedMessage id="wizard_breadcrumbs_composing" />}
      />
      <div className={cn(utils.mt4, styles['tabs-wrapper'])}>
        <DfLoader isVisible={isPageUpdating} data-testid="requirements-updating-loader">
          <form noValidate>
            <div className={cn(tabs.tabs, utils.row, tabMode)}>
              <nav className={cn(tabs.tabsNav, utils.col3, utils.colMd8)}>
                <ul className={tabs.tabsList}>
                  {tabList.map(tab => (
                    <li
                      key={tab}
                      className={cn(tabs.tabHeader, activeTab === tab && tabs.selected)}
                    >
                      <a
                        className={cn(
                          tabs.tab,
                          tabs.linkPrimary,
                          styles['tab-links'],
                          isTabUpdating && tabs.disabled,
                        )}
                        data-tabid={tab}
                        onClick={onTabChange}
                        data-testid={`${tab}-tab`}
                      >
                        <FormattedMessage id={tabsText[tab]} />
                      </a>
                    </li>
                  ))}
                </ul>
              </nav>
              <div className={cn(utils.col9, utils.colMd8)}>
                {activeTab === Tabs.SystemData && (
                  <div data-testid="system-data-page">
                    <SystemDataView
                      form={form}
                      data={systemData}
                      onClose={onCloseDraftClick}
                      validation={validation!.system_data}
                      projectId={project.id}
                      projectName={project.project_name}
                      setIsValidNewName={setIsValidNewName}
                    />
                  </div>
                )}
                {activeTab === Tabs.Steering && (
                  <SteeringView
                    form={form}
                    data={steeringData}
                    onClose={onCloseDraftClick}
                    validation={validation!.steering_system}
                    projectId={project.id}
                  />
                )}
                {activeTab === Tabs.TractiveForce && (
                  <OverrideMotorTypeProvider>
                    <TractiveForceView
                      form={form}
                      data={propelSizingData!}
                      onClose={onCloseDraftClick}
                      onUpdate={onTabUpdate}
                      validation={validation!.propel_sizing}
                      isCustomFlow={isCustomFlow}
                      dependsOn={{
                        loadedWeight: systemData.loaded_weight!,
                        engineSpeed: systemData.engine_speed!,
                        hydraulicDedicatedPower: systemData.hydraulic_dedicated_power!,
                      }}
                    />
                  </OverrideMotorTypeProvider>
                )}
              </div>
            </div>
            <div className={cn(utils.row, utils.mt8)}>
              <div className={cn(utils.col12, core.flex)}>
                <Button
                  onClick={onNextClick}
                  disabled={isTabUpdating}
                  type="button"
                  className={cn(button.btnPrimary, core.end)}
                  data-testid="next-button"
                >
                  <FormattedMessage id="wizard_step_next" />
                </Button>
              </div>
            </div>
          </form>
        </DfLoader>
      </div>
      <ContactUs projectId={projectId} />
      {isUnloadWarningModalOpen && (
        <ConfirmationModal
          confirmLabel={formatMessage({
            id: 'wizard_requirements_navigation_confirmation_confirm',
          })}
          headingLabel={formatMessage({
            id: 'wizard_requirements_navigation_confirmation_heading',
          })}
          {...unloadWarningModalProps}
        >
          <FormattedMessage id="wizard_requirements_navigation_confirmation_content" />
        </ConfirmationModal>
      )}
    </>
  );
};

export default Requirements;
