import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import { DfLoader, DfModal } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { toPng } from 'html-to-image';
import { RefObject, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useToggle } from 'utils/useToggle';
import Button from 'views/shared/components/Button/Button';
import { MotorType } from 'views/shared/types';

import Performance from '../../../Performance/Performance';
import { PDFTemplateForwardedRef } from '../../PDFTemplate';
import styles from './GeneratePDFModal.module.scss';

interface IGeneratePDFModalProps {
  onClose: () => void;
  projectId: string;
  motorType: MotorType;
  pdfTemplateRef: RefObject<PDFTemplateForwardedRef>;
  onExport?: () => void;
}

export function GeneratePDFModal({
  onClose,
  projectId,
  motorType,
  pdfTemplateRef,
  onExport,
}: IGeneratePDFModalProps) {
  const performanceContent = useRef<HTMLDivElement>(null);
  const { formatMessage } = useIntl();
  const [isPDFGenerating, turnOnGeneratingPDF, turnOffGeneratingPDF] = useToggle(false);
  const [isGraphLoading, setIsGraphLoading] = useState(false);

  const onGenerateClick = async () => {
    if (onExport) {
      onExport();
    }

    if (!performanceContent.current || !pdfTemplateRef.current) {
      throw new Error('Refs for modal window or pdf template are not specified');
    }

    try {
      const base64Screenshot = await toPng(performanceContent.current);

      turnOnGeneratingPDF();
      await pdfTemplateRef.current.render(base64Screenshot, performanceContent.current.clientWidth);
    } finally {
      turnOffGeneratingPDF();
    }
  };

  return (
    <DfModal
      onModalClose={onClose}
      heading={formatMessage({ id: 'wizard_summary_export_pdf_modal_title' })}
      size="large"
      data-testid="export-pdf-modal"
      isOpen
    >
      <div slot="modal-body" ref={performanceContent}>
        <DfLoader
          isVisible={isPDFGenerating}
          className={styles.loader_wrapper}
          data-testid="pdf-generating-loader"
        >
          <Performance projectId={projectId} motorType={motorType} onUpdate={setIsGraphLoading} />
        </DfLoader>
        {isPDFGenerating && (
          <div className={core.textRight} data-testid="pdf-generating-text">
            <FormattedMessage id="wizard_summary_export_pdf_modal_generating_text" />
          </div>
        )}
      </div>
      <div slot="modal-footer">
        <button
          className={cn(button.textBtn, button.textBtnSecondary)}
          onClick={onClose}
          data-testid="close-button"
        >
          <FormattedMessage id="wizard_summary_export_pdf_modal_close_button" />
        </button>
        <Button
          className={button.btnPrimary}
          onClick={onGenerateClick}
          loading={isPDFGenerating}
          disabled={isGraphLoading}
          data-testid="generate-pdf"
        >
          <FormattedMessage id="wizard_summary_export_pdf_modal_generate_button" />
        </Button>
      </div>
    </DfModal>
  );
}
