import button from '@danfoss/mosaic/css/components/button.module.css';
import staticPages from '@danfoss/mosaic/css/components/static-pages.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as SvgBigGears } from 'assets/icons/big-gears.svg';

export default function ErrorPage() {
  const onReloadPageClick = () => {
    window.location.reload();
  };

  const reloadButton = (
    <button
      className={cn(button.textBtn, button.textBtnPrimary)}
      onClick={onReloadPageClick}
      data-testid="reload-button"
    >
      <FormattedMessage id="error_page_button" />
    </button>
  );

  return (
    <div className={cn(core.container, utils.my24)} data-testid="error-page">
      <div className={staticPages.maintenancePageMain}>
        <div>
          <SvgBigGears />
        </div>
        <div className={staticPages.maintenanceInfo}>
          <h3 className={utils.mb3}>
            <FormattedMessage id="error_page_headline" />
          </h3>
          <p className={utils.mb0}>
            <FormattedMessage
              id="error_page_text"
              values={{
                button: reloadButton,
                linebreak: <br />,
              }}
            />
          </p>
        </div>
      </div>
    </div>
  );
}
