import components from '@danfoss/mosaic/css/components.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import cn from 'classnames';
import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import Fieldset from 'views/shared/components/form/Fieldset';
import { SystemArchitecture } from 'views/shared/types';

import { ArchitectureSelectForm } from './types';

export interface ArchitectureSelectProps {
  form: UseFormReturn<ArchitectureSelectForm>;
}

export function ArchitectureSelect({ form }: ArchitectureSelectProps) {
  return (
    <Fieldset className={components.radioGroup}>
      <div className={components.radio}>
        <input
          type="radio"
          id="architecture-one-pump-one-motor-radio"
          value={SystemArchitecture.OnePumpOneMotor}
          data-testid="architecture-one-pump-one-motor-radio"
          {...form.register('architecture')}
        />
        <label
          className={cn(core.textNowrap, core.textBold)}
          htmlFor="architecture-one-pump-one-motor-radio"
        >
          <FormattedMessage id="wizard_example_selection_example_one_pump_one_motor_title" />
        </label>
      </div>
      <div className={components.radio}>
        <input
          type="radio"
          id="architecture-one-pump-multiple-motors-radio"
          value={SystemArchitecture.OnePumpTwoMotors}
          data-testid="architecture-one-pump-multiple-motors-radio"
          {...form.register('architecture')}
        />
        <label
          className={cn(core.textNowrap, core.textBold)}
          htmlFor="architecture-one-pump-multiple-motors-radio"
        >
          <FormattedMessage id="wizard_example_selection_example_one_pump_multiple_motors_title" />
        </label>
      </div>
    </Fieldset>
  );
}
