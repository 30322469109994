import axios from 'axios';

import { IValidationRules } from 'views/shared/components/form/types';
import {
  FunctionCode,
  IProjectPropelSizingData,
  IProjectSteeringData,
  IProjectSystemData,
} from 'views/shared/types';

export interface IRequirementsValidation {
  system_data: {
    [field in keyof IProjectSystemData]: IValidationRules;
  };
  steering_system?: {
    [field in keyof IProjectSteeringData]: IValidationRules;
  };
  propel_sizing?: {
    [field in keyof IProjectPropelSizingData]: IValidationRules;
  };
}

export const getRequirementsValidation = async () => {
  const allCodes = Object.keys(FunctionCode);
  const params = new URLSearchParams();

  allCodes.forEach(code => {
    params.append('function_codes', code);
  });

  const { data } = await axios.get<IRequirementsValidation>('/validation-rules', {
    params,
  });

  return data;
};
