import { TooltipPlacementType } from '@danfoss/mosaic';
import { ReactComponent as InfoSvg } from '@danfoss/mosaic-icons/dist/svg/info-outline.svg';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfTooltip } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { FC, ReactNode } from 'react';

import styles from './TooltipInfo.module.scss';

interface ITooltipInfo {
  trigger?: ReactNode;
  placement?: TooltipPlacementType;
  className?: string;
  triggerClassName?: string;
}

const TooltipInfo: FC<ITooltipInfo> = ({
  children,
  trigger,
  placement = 'top',
  className = '',
  triggerClassName = '',
}) => {
  return (
    <DfTooltip trigger="hover" placement={placement} className={className}>
      <span slot="tooltip-reference" className={cn(styles.trigger, triggerClassName)}>
        {trigger || <InfoSvg className={cn(core.icon, utils.ml1)} />}
      </span>
      <span slot="tooltip-content">{children}</span>
    </DfTooltip>
  );
};

export default TooltipInfo;
