import { useCallback, useContext } from 'react';

import { round } from 'views/shared/helpers/formatters';

import { MeasuringSystemContext, MeasuringUnits } from './MeasuringSystemContext';
import { convert, Units } from './convertors';

const useMeasuringValueConvertor = () => {
  const { units } = useContext(MeasuringSystemContext);

  const convertor = useCallback(
    (metric: Units, imperial: Units, value: number) => {
      if (units === MeasuringUnits.Imperial) {
        return round(convert(value).from(metric).to(imperial));
      }

      return round(value);
    },
    [units],
  );

  return { convertor };
};

export default useMeasuringValueConvertor;
