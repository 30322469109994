import switchComponent from '@danfoss/mosaic/css/components/switch.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfLoader } from '@danfoss/mosaic/react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory, useParams } from 'react-router-dom';

import { showWarning } from 'utils/ToastService';
import BreadcrumbStep from 'views/base/Breadcrumbs/BreadcrumbStep';
import Breadcrumbs from 'views/base/Breadcrumbs/Breadcrumbs';
import ContactUs from 'views/base/ContactUs/ContactUs';
import SubHeader from 'views/base/SubHeader/SubHeader';
import { IProject } from 'views/shared/types';

import { getComparison, IComparison } from './api';
import ComparisonTable from './components/ComparisonTable/ComparisonTable';

const Compare = () => {
  const history = useHistory();
  const { ids } = useParams<{ ids: string }>();
  const parsedIds = ids.split(',');
  const [isOnlyDifference, setIsOnlyDifference] = useState(true);
  const [selections, setSelections] = useState<IComparison[]>([]);
  const [isLoading, setIsLoading] = useState(!selections.length);
  const maxSelectedProjects = 4;
  const isCompareLimitExceeded = parsedIds.length === maxSelectedProjects;

  const onAddSelection = (id: string) => {
    const newIdsToCompare = [...parsedIds, id];

    history.push(`/compare/${newIdsToCompare.join(',')}`);
  };
  const onRemoveSelection = (project: IProject) => {
    const newIdsList = parsedIds.filter(item => item !== project.id);

    setSelections(allSelections => allSelections.filter(item => item.project.id !== project.id));

    if (selections.length === 1) {
      history.push('/');
      return;
    }

    history.replace(`/compare/${newIdsList.join(',')}`);
  };

  const onSwitchShowMode = () => {
    setIsOnlyDifference(isChecked => !isChecked);
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);

        const response = await getComparison(parsedIds);

        setSelections(response.projects);

        if (response.invalid_ids.length) {
          showWarning(
            <FormattedMessage id="compare_page_invalid_id_warning_header" />,
            <FormattedMessage id="compare_page_invalid_id_warning_text" />,
          );
        }
      } finally {
        setIsLoading(false);
      }
    })();
  }, [ids]);

  return (
    <>
      <SubHeader className={utils.mb6} separator />
      <Breadcrumbs className={utils.mb4}>
        <BreadcrumbStep>
          <FormattedMessage id="compare_page_breadcrumb" />
        </BreadcrumbStep>
      </Breadcrumbs>
      {isLoading && <DfLoader data-testid="compare-page-loader" isGlobal />}
      <h2 className={utils.mb4}>
        <FormattedMessage id="compare_page_title" />
      </h2>
      <div className={utils.mb8}>
        <div className={utils.mb2}>
          <FormattedMessage
            id="compare_page_count_of_projects"
            values={{ count: selections.length }}
          />
        </div>
        <fieldset className={switchComponent.switch}>
          <input
            type="checkbox"
            id="show-difference"
            checked={isOnlyDifference}
            onChange={onSwitchShowMode}
          />
          <label htmlFor="show-difference">
            <FormattedMessage id="compare_page_show_difference" />
          </label>
        </fieldset>
      </div>
      <div className={core.flex}>
        <ComparisonTable
          selections={selections}
          onRemoveSelection={onRemoveSelection}
          onAddSelection={onAddSelection}
          showOnlyDifference={isOnlyDifference}
          isCompareLimitExceeded={isCompareLimitExceeded}
        />
      </div>
      <ContactUs />
    </>
  );
};

export default Compare;
