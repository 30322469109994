import { useContext, FunctionComponent } from 'react';
import { Redirect } from 'react-router-dom';

import { AuthContext } from './AuthContext';

interface IGuardedRouteProps {
  unauthorizedRedirectTo?: string;
}

const GuardedRoute: FunctionComponent<IGuardedRouteProps> = ({
  children,
  unauthorizedRedirectTo,
}) => {
  const { isAuthenticated } = useContext(AuthContext);
  const redirect = unauthorizedRedirectTo ? <Redirect to={unauthorizedRedirectTo} /> : null;

  return isAuthenticated ? (children as JSX.Element) : redirect;
};

export default GuardedRoute;
