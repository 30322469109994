import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import useMeasuringUnitsIntl from 'views/shared/helpers/measuringSystem/useMeasuringUnitsIntl';
import useMeasuringValueConvertor from 'views/shared/helpers/measuringSystem/useMeasuringValueConvertor';
import { IProjectSystemData, OperationalEnvironment } from 'views/shared/types';

import Parameter from './Parameter';

interface ISystemProps {
  data: IProjectSystemData;
  fieldsToShow: Array<keyof IProjectSystemData> | null;
}

const System = ({ data, fieldsToShow }: ISystemProps) => {
  const { formatMessage, formatNumber } = useIntl();
  const { formatMeasuringUnit } = useMeasuringUnitsIntl();
  const { convertor } = useMeasuringValueConvertor();
  const allFieldsToShow = fieldsToShow || (Object.keys(data) as Array<keyof IProjectSystemData>);

  const isHydraulicPowerAvailable = Number.isFinite(data.hydraulic_dedicated_power);
  const isEngineSpeedAvailable = Number.isFinite(data.engine_speed);
  const isPressureLimitAvailable = Number.isFinite(data.pressure_limit);
  const isLoadedWeightAvailable = Number.isFinite(data.loaded_weight);
  const isUnloadedWeightAvailable = Number.isFinite(data.unloaded_weight);

  return (
    <div data-testid="system-data">
      {isHydraulicPowerAvailable && allFieldsToShow.includes('hydraulic_dedicated_power') && (
        <Parameter
          field={<FormattedMessage id="specs_power_for_propel_system" />}
          value={`${convertor('kW', 'hp', data.hydraulic_dedicated_power!)} ${formatMeasuringUnit({
            metric: 'measurements_unit_kw',
            imperial: 'measurements_unit_horsepower',
          })}`}
        />
      )}
      {isEngineSpeedAvailable && allFieldsToShow.includes('engine_speed') && (
        <Parameter
          field={<FormattedMessage id="specs_engine_speed" />}
          value={`${formatNumber(data.engine_speed!)} ${formatMessage({
            id: 'measurements_unit_rpm',
          })}`}
        />
      )}
      {isPressureLimitAvailable && allFieldsToShow.includes('pressure_limit') && (
        <Parameter
          field={<FormattedMessage id="specs_system_pressure_limit" />}
          value={`${convertor('bar', 'psi', data.pressure_limit!)} ${formatMeasuringUnit({
            metric: 'measurements_unit_bar',
            imperial: 'measurements_unit_psi',
          })}`}
        />
      )}
      {isLoadedWeightAvailable && allFieldsToShow.includes('loaded_weight') && (
        <Parameter
          field={<FormattedMessage id="specs_loaded_machine_weight_label" />}
          value={`${formatNumber(convertor('kg', 'lb', data.loaded_weight!))} ${formatMeasuringUnit(
            {
              metric: 'measurements_unit_kg',
              imperial: 'measurements_unit_lbs',
            },
          )}`}
        />
      )}
      {isUnloadedWeightAvailable && allFieldsToShow.includes('unloaded_weight') && (
        <Parameter
          field={<FormattedMessage id="specs_unloaded_machine_weight_label" />}
          value={`${formatNumber(
            convertor('kg', 'lb', data.unloaded_weight!),
          )} ${formatMeasuringUnit({
            metric: 'measurements_unit_kg',
            imperial: 'measurements_unit_lbs',
          })}`}
        />
      )}
      {data.operation_environment && allFieldsToShow.includes('operation_environment') && (
        <Parameter
          field={<FormattedMessage id="specs_operation_environment_label" />}
          value={
            data.operation_environment === OperationalEnvironment.OffHighway
              ? formatMessage({
                  id: 'specs_operation_environment_off_highway_option',
                })
              : formatMessage({ id: 'specs_operation_environment_highway_option' })
          }
        />
      )}
    </div>
  );
};

export default memo(System);
