import axios from 'axios';

export interface ITractiveForce {
  value: number;
}

export interface ITractiveForceCalculationValues {
  weight: number;
  pull_ratio: number;
}

export const getTractiveForce = async (params: ITractiveForceCalculationValues) => {
  const { data } = await axios.get<ITractiveForce>('/physics-calculations/tractive-force', {
    params,
  });

  return data.value;
};
