import { ReactComponent as SvgMinus } from '@danfoss/mosaic-icons/dist/svg/remove.svg';
import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import styles from './Header.module.scss';
import ProductInfo from './ProductInfo';
import { IProductDetails } from './types';

interface IHeaderProps extends IProductDetails {
  id: string;
  hideBorder?: boolean;
  isReadOnly: boolean;
  onRemove?: (id: string) => void;
  onChange?: (id: string) => void;
}

const Header = ({
  id,
  name,
  series,
  spec,
  photoUrl,
  photoAlt,
  hideBorder,
  isReadOnly,
  onChange,
  onRemove,
}: IHeaderProps) => {
  const onRemoveClick = () => {
    onRemove?.(id);
  };
  const onChangeClick = () => {
    onChange?.(id);
  };

  return (
    <div
      className={cn(utils.mb3, {
        [utils.pb3]: !hideBorder,
        [styles.container]: !hideBorder,
      })}
      data-testid={`widget-header-${id}`}
    >
      {(onChange || onRemove) && (
        <div className={cn(core.flex, utils.mb3)} data-testid="widget-actions">
          {onChange && (
            <button
              onClick={onChangeClick}
              disabled={isReadOnly}
              className={cn(button.textBtn, button.textBtnPrimary)}
              data-testid="widget-header-change-button"
            >
              <FormattedMessage id="wizard_product_widget_change" />
            </button>
          )}
          {onRemove && (
            <button
              onClick={onRemoveClick}
              disabled={isReadOnly}
              className={cn(button.textBtn, button.textBtnPrimary, core.end)}
              data-testid="widget-header-remove-button"
            >
              <SvgMinus className={core.icon} />
              <FormattedMessage id="wizard_product_widget_remove" />
            </button>
          )}
        </div>
      )}
      <ProductInfo
        name={name}
        spec={spec}
        series={series}
        photoAlt={photoAlt}
        photoUrl={photoUrl}
      />
    </div>
  );
};

export default Header;
