import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfLoader } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import Fieldset from 'views/shared/components/form/Fieldset';
import Input from 'views/shared/components/form/Input';
import Addon from 'views/shared/components/form/InputAddon';
import InputGroup from 'views/shared/components/form/InputGroup';
import Label from 'views/shared/components/form/Label/Label';
import { IGearBoxValues, ISelectedMotor } from 'views/shared/types';

import styles from './GearBox.module.scss';

interface ITwoSpeedGearBoxProps {
  data: ISelectedMotor;
  onRemove: () => void;
  onValuesChange: (data: IGearBoxValues, isValid: boolean) => void;
  onValidation: (hasError: boolean) => void;
  isUpdating: boolean;
  isReadonly: boolean;
}

type IGearBoxFormValidations = {
  [field in keyof IGearBoxValues]: {
    min: number;
    max: number;
    required: boolean;
  };
};

const validations: IGearBoxFormValidations = {
  gear_ratio_work: {
    min: 0.001,
    max: 10,
    required: true,
  },
  gear_ratio_travel: {
    min: 0.001,
    max: 10,
    required: true,
  },
  mechanical_reduction: {
    min: 0.001,
    max: 50,
    required: true,
  },
};

const GearBox = ({
  onRemove,
  onValuesChange,
  onValidation,
  data,
  isUpdating,
  isReadonly,
}: ITwoSpeedGearBoxProps) => {
  const { final_drive_ratio, final_drive_ratio_travel } = data;
  const { register, formState, getValues, trigger } = useForm<IGearBoxValues>({
    mode: 'onChange',
    defaultValues: {
      gear_ratio_work: data.gear_ratio_work,
      gear_ratio_travel: data.gear_ratio_travel,
      mechanical_reduction: data.mechanical_reduction,
    },
  });
  const { errors, isValid } = formState;

  const onInputChange = async () => {
    const isGearRatioTravelValid = await trigger('gear_ratio_travel');
    const isGearRatioWorkValid = await trigger('gear_ratio_work');
    const isMechanicalReductionValid = await trigger('mechanical_reduction');
    const areValid = isGearRatioTravelValid && isGearRatioWorkValid && isMechanicalReductionValid;

    onValuesChange(getValues(), areValid);
  };

  useEffect(() => {
    onValidation(!isValid);
  }, [isValid]);

  return (
    <div className={cn(utils.p3, cn(styles.wrapper))} data-testid="gear-box-widget">
      <DfLoader isVisible={isUpdating} data-testid="gear-box-widget-loader">
        <div className={cn(core.flex, core.flexEnd, utils.mb4)}>
          <button
            onClick={onRemove}
            className={cn(button.textBtn, button.textBtnPrimary)}
            data-testid="gear-box-remove-button"
          >
            - <FormattedMessage id="wizard_product_gear_box_widget_remove" />
          </button>
        </div>
        <Fieldset>
          <Label>
            1. <FormattedMessage id="wizard_product_gear_box_widget_gear_ratio_work" />
          </Label>
          <InputGroup>
            <Input
              type="number"
              {...register('gear_ratio_work', {
                ...validations.gear_ratio_work,
                valueAsNumber: true,
                onChange: onInputChange,
              })}
              {...validations.gear_ratio_work}
              error={!!errors.gear_ratio_work}
              data-testid="gear-ratio-work"
              readOnly={isReadonly}
            />
            <Addon>
              <FormattedMessage id="measurements_unit_ratio_one" />
            </Addon>
          </InputGroup>
          <label className={cn(core.helperText, core.error, utils.mt0)} data-testid="">
            {errors.gear_ratio_work?.type === 'min' && (
              <FormattedMessage id="default_min_error" values={validations.gear_ratio_work} />
            )}
            {errors.gear_ratio_work?.type === 'max' && (
              <FormattedMessage id="default_max_error" values={validations.gear_ratio_work} />
            )}
            {errors.gear_ratio_work?.type === 'required' && (
              <FormattedMessage id="default_required_error" />
            )}
          </label>
        </Fieldset>
        <Fieldset>
          <Label>
            2. <FormattedMessage id="wizard_product_gear_box_widget_gear_ratio_travel" />
          </Label>
          <InputGroup>
            <Input
              type="number"
              {...register('gear_ratio_travel', {
                ...validations.gear_ratio_travel,
                valueAsNumber: true,
                onChange: onInputChange,
              })}
              {...validations.gear_ratio_travel}
              error={!!errors.gear_ratio_travel}
              data-testid="gear-ratio-travel"
              readOnly={isReadonly}
            />
            <Addon>
              <FormattedMessage id="measurements_unit_ratio_one" />
            </Addon>
          </InputGroup>
          <label
            className={cn(core.helperText, core.error, utils.mt0, styles.error)}
            data-testid=""
          >
            {errors.gear_ratio_travel?.type === 'min' && (
              <FormattedMessage id="default_min_error" values={validations.gear_ratio_travel} />
            )}
            {errors.gear_ratio_travel?.type === 'max' && (
              <FormattedMessage id="default_max_error" values={validations.gear_ratio_travel} />
            )}
            {errors.gear_ratio_travel?.type === 'required' && (
              <FormattedMessage id="default_required_error" />
            )}
          </label>
        </Fieldset>
        <Fieldset>
          <Label>
            <FormattedMessage id="wizard_product_gear_box_widget_mechanical_reduction" />
            <br />
            <FormattedMessage id="wizard_product_gear_box_widget_mechanical_reduction_e_g" />
          </Label>
          <InputGroup>
            <Input
              type="number"
              {...register('mechanical_reduction', {
                ...validations.mechanical_reduction,
                valueAsNumber: true,
                onChange: onInputChange,
              })}
              {...validations.mechanical_reduction}
              error={!!errors.mechanical_reduction}
              data-testid="mechanical-reduction"
              readOnly={isReadonly}
            />
            <Addon>
              <FormattedMessage id="measurements_unit_ratio_one" />
            </Addon>
          </InputGroup>
          <label
            className={cn(core.helperText, core.error, utils.mt0, styles.error)}
            data-testid=""
          >
            {errors.mechanical_reduction?.type === 'min' && (
              <FormattedMessage id="default_min_error" values={validations.mechanical_reduction} />
            )}
            {errors.mechanical_reduction?.type === 'max' && (
              <FormattedMessage id="default_max_error" values={validations.mechanical_reduction} />
            )}
            {errors.mechanical_reduction?.type === 'required' && (
              <FormattedMessage id="default_required_error" />
            )}
          </label>
        </Fieldset>
        <div>
          <p className={cn(core.textBold, utils.p2)}>
            <FormattedMessage id="wizard_product_gear_box_widget_total_drive_ratios" />
          </p>
          <div className={cn(core.flex, core.flexBetween, utils.px2, utils.pb2, styles.footer)}>
            <div>
              <div className={cn(utils.my2, core.textBold)}>
                1. <FormattedMessage id="wizard_product_gear_box_widget_gear_ratio" />
              </div>
              <div className={utils.ml4} data-testid="final-gear-ratio-work">
                {final_drive_ratio || '-'}
              </div>
            </div>
            <div>
              <div className={cn(utils.my2, core.textBold)}>
                2. <FormattedMessage id="wizard_product_gear_box_widget_gear_ratio" />
              </div>
              <div className={utils.ml4} data-testid="final-gear-ratio-travel">
                {final_drive_ratio_travel || '-'}
              </div>
            </div>
          </div>
        </div>
      </DfLoader>
    </div>
  );
};

export default GearBox;
