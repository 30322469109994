import components from '@danfoss/mosaic/css/components.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { ChangeEvent } from 'react';

import Fieldset from 'views/shared/components/form/Fieldset';
import Label from 'views/shared/components/form/Label/Label';
import RadioInput from 'views/shared/components/form/RadioInput';

interface INumberOfProductsProps {
  onChange: (newNumberOfMotors: number) => void;
  hasProducts: boolean;
  title: string;
  type: string;
  number: number;
}

const NumberOfProducts = ({ onChange, title, type, number }: INumberOfProductsProps) => {
  const onNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newNumber = +event.target.value;
    onChange(newNumber);
  };

  return (
    <div className={utils.mt6}>
      <Label>{title}</Label>
      <Fieldset className={cn(components.radioGroup, components.inline, utils.mt3)}>
        <RadioInput
          id={`numbers-of-${type}-1`}
          data-testid={`numbers-of-${type}-1`}
          name={`numbers-of-${type}`}
          value="1"
          checked={number === 1}
          onChange={onNumberChange}
        >
          1
        </RadioInput>
        <RadioInput
          id={`numbers-of-${type}-2`}
          data-testid={`numbers-of-${type}-2`}
          name={`numbers-of-${type}`}
          value="2"
          checked={number === 2}
          onChange={onNumberChange}
        >
          2
        </RadioInput>
        <RadioInput
          id={`numbers-of-${type}-3`}
          data-testid={`numbers-of-${type}-3`}
          name={`numbers-of-${type}`}
          value="3"
          checked={number === 3}
          onChange={onNumberChange}
        >
          3
        </RadioInput>
        <RadioInput
          id={`numbers-of-${type}-4`}
          data-testid={`numbers-of-${type}-4`}
          name={`numbers-of-${type}`}
          value="4"
          checked={number === 4}
          onChange={onNumberChange}
        >
          4
        </RadioInput>
      </Fieldset>
    </div>
  );
};

export default NumberOfProducts;
