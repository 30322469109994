import { TableRowSelectedEventType } from '@danfoss/mosaic';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch, useAppSelector } from 'configs/store';
import { trackEvent } from 'utils/analytics';
import debounce from 'utils/debounce';
import { useToggle } from 'utils/useToggle';
import Fieldset from 'views/shared/components/form/Fieldset';
import Input from 'views/shared/components/form/Input';
import InputAddon from 'views/shared/components/form/InputAddon';
import InputGroup from 'views/shared/components/form/InputGroup';
import { AvailableProductsSearchMode } from 'views/shared/types';
import {
  getSteeringAuthorizedFilter,
  setSteeringAuthorizedFilter,
} from 'views/wizard/product-selection/store/productFilterSlice';
import {
  addSteeringProductsPrices,
  getCurrent,
  getProject,
  updateSteeringUnit,
} from 'views/wizard/shared/store/projectSlice';

import { getAvailableSteeringUnits, IAvailableSteeringUnit } from '../../../../api/products';
import ModalLoader from '../../../components/modals/ModalLoader/ModalLoader';
import NoProducts from '../../../components/modals/NoProducts/NoProducts';
import SelectionModal from '../../../components/modals/SelectionModal/SelectionModal';
import selectionModalStyles from '../../../components/modals/SelectionModal/SelectionModal.module.scss';
import ConventionalSteeringTable from '../SteeringTable/ConventionalSteeringTable';
import styles from './SteeringModal.module.scss';
import { SteeringModalSidebar } from './components/SteeringModalSidebar';
import { convertToIdsWithPrices } from './helpers/steeringUnitData';
import { validation } from './validation';

interface ConventionalSteeringModalProps {
  onModalClose: () => void;
}

interface IConventionalSteeringModalForm {
  requiredLockToLock: number;
}

const ConventionalSteeringModal = ({ onModalClose }: ConventionalSteeringModalProps) => {
  const project = useAppSelector(getCurrent);
  const dispatch = useAppDispatch();

  const [isModalUpdating, turnOnModalUpdate, turnOffModalUpdate] = useToggle(false);
  const [selectedSteeringUnitId, setSelectedSteeringUnitId] = useState<string>();

  const [isSteeringUnitsLoading, turnOnSteeringLoading, turnOffSteeringLoading] = useToggle(true);
  const [steeringUnits, setSteeringUnits] = useState<IAvailableSteeringUnit[]>([]);

  const { register, formState, control, trigger, getValues } =
    useForm<IConventionalSteeringModalForm>({
      mode: 'onChange',
      defaultValues: {
        requiredLockToLock: 5,
      },
    });
  const requiredLockToLock = useWatch({
    name: 'requiredLockToLock',
    control,
  });
  const { errors, isValid: isFormValid } = formState;

  const authOnly = useAppSelector(getSteeringAuthorizedFilter);
  const searchMode = authOnly
    ? AvailableProductsSearchMode.AuthedOnly
    : AvailableProductsSearchMode.All;

  const handleAuthCheckboxChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = target;

    dispatch(setSteeringAuthorizedFilter(checked));
  };

  const fetchSteeringUnits = useMemo(() => {
    return debounce(async (filterMode: AvailableProductsSearchMode) => {
      try {
        turnOnSteeringLoading();

        const requiredLockToLockValue = getValues('requiredLockToLock');
        const newSteeringUnits = await getAvailableSteeringUnits(project.id, {
          filter_required_l2l_turns: requiredLockToLockValue,
          filter_search_mode: filterMode,
        });

        setSteeringUnits(newSteeringUnits);
        setSelectedSteeringUnitId(
          newSteeringUnits.find(({ recommended }) => recommended)?.steering_unit.id,
        );
      } finally {
        turnOffSteeringLoading();
      }
    }, 1000);
  }, []);

  const onRowSelected = useCallback((event: CustomEvent<TableRowSelectedEventType>) => {
    const { detail } = event;
    setSelectedSteeringUnitId(detail.rowId);
  }, []);

  const createRequestDataFromSelection = () => {
    const selectedSteeringUnit = steeringUnits.find(
      ({ steering_unit }) => steering_unit.id === selectedSteeringUnitId,
    );

    if (!selectedSteeringUnit) {
      return;
    }

    const { productIDs, prices } = convertToIdsWithPrices(selectedSteeringUnit);

    const params = {
      required_l2l_turns: requiredLockToLock,
      actual_l2l_turns: selectedSteeringUnit.steering_unit.actual_l2l_turns,
    };

    return {
      steeringUnitData: {
        attributes: params,
        product_ids: productIDs,
      },
      prices,
    };
  };

  const onUpdateSteeringUnit = async () => {
    const requestParams = createRequestDataFromSelection();

    if (!requestParams) {
      return;
    }

    await dispatch(updateSteeringUnit(requestParams.steeringUnitData)).unwrap();
    dispatch(addSteeringProductsPrices(requestParams.prices));
    await dispatch(getProject(project.id)).unwrap();
  };

  const handleSubmit = async () => {
    try {
      const recommendedSteeringId = steeringUnits.find(unit => !!unit.recommended)?.steering_unit
        .id;

      trackEvent({
        event: 'product_change',
        productType: 'steering',
        selectionId: project.id,
        recommendedProduct: recommendedSteeringId === selectedSteeringUnitId,
      });
      turnOnModalUpdate();
      await onUpdateSteeringUnit();
      onModalClose();
    } catch {
      turnOffModalUpdate();
    }
  };

  useEffect(() => {
    (async () => {
      const isValid = await trigger('requiredLockToLock');
      /*
        formState.isDirty does not get updated on first input change, this is most probably
        a bug in the react-hook-form library. formstate.dirtyFields is empty when value
        is changed back to default value, hence both needs to be checked
        to acquire true "isDirty" flag
      */
      const isDirty = Boolean(Object.keys(formState.dirtyFields).length) || formState.isDirty;

      if (isValid && isDirty) {
        fetchSteeringUnits(searchMode);
      }

      if (!isValid) {
        fetchSteeringUnits.cancel();
      }
    })();
  }, [requiredLockToLock]);

  useEffect(() => {
    (async () => {
      if (await trigger('requiredLockToLock')) {
        fetchSteeringUnits.immediate(searchMode);
      }
    })();
  }, [authOnly]);

  const products = steeringUnits.length ? (
    <ConventionalSteeringTable
      list={steeringUnits}
      onRowSelected={onRowSelected}
      data-testid="conventional-steering-modal-table"
    />
  ) : (
    <NoProducts />
  );

  return (
    <SelectionModal
      isOpen={true}
      onModalClose={onModalClose}
      submitLabel={<FormattedMessage id="wizard_steering_modal_select_button" />}
      handleSubmit={handleSubmit}
      sidebar={
        <SteeringModalSidebar authOnly={authOnly} onAuthOnlyChange={handleAuthCheckboxChange} />
      }
      isLoading={isSteeringUnitsLoading}
      isUpdating={isModalUpdating}
      data-testid="conventional-steering-modal"
      isSubmitButtonDisabled={!isFormValid}
    >
      <div className={cn(core.flex, core.alignCenter, utils.mt2, utils.mb8)}>
        <h4 className={cn(utils.pr4, styles['right-border'])}>
          <FormattedMessage id="wizard_steering_conventional_steering_modal_title" />
        </h4>
        <strong className={cn(utils.ml4, utils.mt1)}>
          <FormattedMessage id="wizard_steering_conventional_steering_modal_required_l2l" />
        </strong>
        <Fieldset className={cn(utils.ml4, styles['fieldset-small'])}>
          <InputGroup>
            <Input
              type="number"
              error={!!errors.requiredLockToLock}
              disabled={isSteeringUnitsLoading}
              {...validation.requiredLockToLockTurns}
              {...register('requiredLockToLock', {
                ...validation.requiredLockToLockTurns,
                valueAsNumber: true,
              })}
              data-testid="requiredLockToLock"
            />
            <InputAddon>
              <FormattedMessage id="measurements_unit_turns" />
            </InputAddon>
          </InputGroup>
          <label className={cn(core.helperText, core.error, styles.error)}>
            {errors.requiredLockToLock?.type === 'min' && (
              <span data-testid="requiredLockToLock-min-error">
                <FormattedMessage
                  id="default_min_error"
                  values={validation.requiredLockToLockTurns}
                />
              </span>
            )}
            {errors.requiredLockToLock?.type === 'max' && (
              <span data-testid="requiredLockToLock-max-error">
                <FormattedMessage
                  id="default_max_error"
                  values={validation.requiredLockToLockTurns}
                />
              </span>
            )}
            {errors.requiredLockToLock?.type === 'required' && (
              <span data-testid="requiredLockToLock-required-error">
                <FormattedMessage
                  id="default_required_error"
                  values={validation.requiredLockToLockTurns}
                />
              </span>
            )}
          </label>
        </Fieldset>
      </div>
      <div
        className={cn(
          core.flex,
          core.alignCenter,
          core.flexCenter,
          selectionModalStyles['content-wrapper'],
        )}
      >
        {isSteeringUnitsLoading ? <ModalLoader /> : products}
      </div>
    </SelectionModal>
  );
};

export default ConventionalSteeringModal;
