import { ChartOptions, ChartData, LegendOptions } from 'chart.js';
import { Line } from 'react-chartjs-2';

interface IExpandedPerformanceGraphProps {
  chartData: ChartData<'line'>;
  chartOptions: ChartOptions<'line'>;
}

const ExpandedPerformanceGraph = ({ chartOptions, chartData }: IExpandedPerformanceGraphProps) => {
  const options = { ...chartOptions };

  options.plugins = {
    legend: {
      display: true,
      position: 'left',
      align: 'start',
      labels: {
        usePointStyle: true,
        padding: 25,
      },
    } as LegendOptions<'line'>,
  };

  return <Line data={chartData} options={options} />;
};

export default ExpandedPerformanceGraph;
