// eslint-disable-next-line import/no-extraneous-dependencies
import { History } from 'history';
import core from '@danfoss/mosaic/css/core.module.css';
import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import { Toaster } from 'react-hot-toast';
import { IntlProvider } from 'react-intl';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';

// These styles must be attached before others.
import '@danfoss/mosaic/css/common.css';
import globals from '@danfoss/mosaic/css/globals.module.css';
import 'styles/standalone.css';

import { ITranslations } from 'api/translations';
import { getAppConfig } from 'configs/global';
import { store } from 'configs/store';
import DIPLogin from 'views/DIPLogin';
import ErrorBoundary from 'views/ErrorBoundary';
import Routes from 'views/Routes';
import Footer from 'views/base/Footer';
import Header from 'views/base/Header/Header';
import PageLayout from 'views/base/PageLayout';
import ScrollToTop from 'views/shared/components/ScrollToTop';
import Translations from 'views/shared/components/Translations';
import StandaloneAuth from 'views/shared/helpers/auth/StandaloneAuth';
import StandaloneFeatureFlags from 'views/shared/helpers/featureFlags/StandaloneFeatureFlags';
import MeasuringSystemProvider from 'views/shared/helpers/measuringSystem/MeasuringSystemProvider';
import useAppVersion from 'views/shared/helpers/useAppVersion';

export default function App() {
  const { DIP_LOGIN_URL } = getAppConfig();
  const locale = navigator.language.toLocaleLowerCase();
  const [translations, setTranslations] = useState<ITranslations>();

  const handleUnauthorizedError = (history: History) => {
    history.push(DIP_LOGIN_URL);
  };

  useAppVersion();

  return (
    <IntlProvider defaultLocale="en-us" locale={locale} messages={translations}>
      <Translations onLoad={setTranslations} locale={locale}>
        <div className={globals.mosaic}>
          <Toaster />
          <ErrorBoundary>
            <ReduxProvider store={store}>
              <BrowserRouter>
                <ScrollToTop>
                  <StandaloneAuth unauthorizedCallback={handleUnauthorizedError}>
                    <StandaloneFeatureFlags>
                      <MeasuringSystemProvider>
                        <div className={core.site}>
                          <Header />
                          <PageLayout>
                            <Routes unauthorizedRedirectLocation={DIP_LOGIN_URL}>
                              <Route path={DIP_LOGIN_URL} component={DIPLogin} />
                            </Routes>
                          </PageLayout>
                          <Footer />
                        </div>
                        <div id="mfd-modal-root" />
                      </MeasuringSystemProvider>
                    </StandaloneFeatureFlags>
                  </StandaloneAuth>
                </ScrollToTop>
              </BrowserRouter>
            </ReduxProvider>
          </ErrorBoundary>
        </div>
      </Translations>
    </IntlProvider>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('mfd-app'),
);
