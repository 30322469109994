import components from '@danfoss/mosaic/css/components.module.css';
import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfModal } from '@danfoss/mosaic/react';
import cn from 'classnames';
import { useContext, useState, ChangeEvent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Button from 'views/shared/components/Button/Button';
import Fieldset from 'views/shared/components/form/Fieldset';
import Label from 'views/shared/components/form/Label/Label';
import RadioInput from 'views/shared/components/form/RadioInput';
import {
  MeasuringSystemContext,
  MeasuringUnits,
} from 'views/shared/helpers/measuringSystem/MeasuringSystemContext';

import styles from './PullRatioExampleModal.module.scss';
import PullRatioOption from './components/PullRatioOption';
import { americaPullRatioOptions, europePullRatioOptions } from './pullRatioOptions';

interface IPullRatioExampleModalProps {
  onSelect: (value: number) => void;
  onClose: () => void;
}

enum PullRatioValues {
  Europe = 'europe',
  America = 'america',
}

const PullRatioExampleModal = ({ onSelect, onClose }: IPullRatioExampleModalProps) => {
  const { units } = useContext(MeasuringSystemContext);
  const isMetricUnitSystem = units === MeasuringUnits.Metric;
  const { formatMessage } = useIntl();
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [selectedValue, setSelectedValue] = useState<number | null>(null);
  const [valuesToShow, setValuesToShow] = useState<PullRatioValues>(
    isMetricUnitSystem ? PullRatioValues.Europe : PullRatioValues.America,
  );
  const itemsToShow =
    valuesToShow === PullRatioValues.America ? americaPullRatioOptions : europePullRatioOptions;
  const isValueSelected = selectedValue !== null;

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;
    const { itemid } = e.target.dataset;

    setSelectedItemId(itemid!);
    setSelectedValue(value);
  };
  const handleValuesSwitch = (values: PullRatioValues) => {
    setSelectedValue(null);
    setSelectedItemId(null);
    setValuesToShow(values);
  };
  const handleApply = () => {
    if (isValueSelected) {
      onSelect(selectedValue);
      onClose();
    }
  };

  return (
    <DfModal
      heading={formatMessage({ id: 'wizard_requirements_pull_ratio_example_modal_heading' })}
      isOpen
      onModalClose={onClose}
      data-testid="pull-ratio-example-modal"
    >
      <div slot="modal-body" className={styles.container}>
        <p>
          <FormattedMessage id="wizard_requirements_pull_ratio_example_modal_text" />
        </p>
        <div className={utils.mt6}>
          <Label>
            <FormattedMessage id="wizard_requirements_pull_ratio_example_modal_values_type_title" />
          </Label>
          <Fieldset className={cn(components.radioGroup, components.inline, utils.my4)}>
            <RadioInput
              id="europe"
              name="europe-option"
              data-testid="europe-option"
              onChange={() => handleValuesSwitch(PullRatioValues.Europe)}
              checked={valuesToShow === 'europe'}
            >
              <FormattedMessage id="wizard_requirements_pull_ratio_example_modal_values_type_europe" />
            </RadioInput>
            <RadioInput
              id="america"
              name="america-option"
              data-testid="america-option"
              onChange={() => handleValuesSwitch(PullRatioValues.America)}
              checked={valuesToShow === 'america'}
            >
              <FormattedMessage id="wizard_requirements_pull_ratio_example_modal_values_type_americas" />
            </RadioInput>
          </Fieldset>
        </div>
        <div className={utils.mt1}>
          {itemsToShow.map(({ Icon, value, label }) => {
            const itemid = `${label}:${value}`;
            const isSelected = itemid === selectedItemId;

            return (
              <PullRatioOption
                key={itemid}
                Icon={Icon}
                value={value}
                data-itemid={itemid}
                className={cn(styles.option, { [styles.selected]: isSelected })}
                checked={isSelected}
                onChange={handleChange}
                data-testid="pull-ratio-example-modal-option"
              >
                {label} - <span className={core.textBold}>{value}</span>
              </PullRatioOption>
            );
          })}
        </div>
      </div>
      <div slot="modal-footer">
        <Button className={cn(button.textBtn, button.textBtnSecondary)} onClick={onClose}>
          <FormattedMessage id="wizard_requirements_pull_ratio_example_modal_cancel" />
        </Button>
        <Button
          className={button.btnPrimary}
          onClick={handleApply}
          disabled={!isValueSelected}
          data-testid="pull-ratio-example-modal-apply-button"
        >
          <FormattedMessage id="wizard_requirements_pull_ratio_example_modal_apply" />
        </Button>
      </div>
    </DfModal>
  );
};

export default PullRatioExampleModal;
