import { FormattedMessage, useIntl } from 'react-intl';

import { MachineExampleCode } from 'views/shared/types';

import { examplesData } from '../helpers/examplesData';
import { IMachineExample } from '../types';
import ExampleOption from './ExampleOptions';
import { ExamplesSection } from './ExamplesList/ExamplesSection';

interface ArchitectureExamplesProps {
  examples: IMachineExample[];
  selectedExample?: MachineExampleCode;
  onSelect: (code: MachineExampleCode) => void;
}

export function ArchitectureExamples({
  examples,
  selectedExample,
  onSelect,
}: ArchitectureExamplesProps) {
  const { formatMessage } = useIntl();

  return (
    <ExamplesSection
      testId="architecture-examples-list-title"
      title={<FormattedMessage id="wizard_example_architecture_selection_sub_title" />}
      description={<FormattedMessage id="wizard_example_architecture_selection_text" />}
    >
      {examples.map(({ code }) => {
        const { title, description, icon, tooltip } = examplesData[code];

        const isSelected = code === selectedExample;

        return (
          <ExampleOption
            key={code}
            code={code}
            icon={icon}
            title={formatMessage({ id: title })}
            description={description && formatMessage({ id: description })}
            tooltip={tooltip && formatMessage({ id: tooltip })}
            onSelect={onSelect}
            selected={isSelected}
          />
        );
      })}
    </ExamplesSection>
  );
}
