import { ReactComponent as LoadingSvg } from '@danfoss/mosaic-icons/dist/svg/loader-circle-outline.svg';
import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { forwardRef, ButtonHTMLAttributes } from 'react';

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  loading?: boolean;
}

export default forwardRef<HTMLButtonElement, IButton>(function Button(
  { className, loading, children, disabled, ...props },
  ref,
) {
  return (
    <button
      className={cn(className, { [button.loading]: loading })}
      disabled={loading || disabled}
      ref={ref}
      {...props}
    >
      {loading && (
        <LoadingSvg
          data-testid="button-component-icon"
          className={cn(button.icon, core.icon, core.iconM, utils.mr2)}
        />
      )}
      {children}
    </button>
  );
});
