import button from '@danfoss/mosaic/css/components/button.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';
import { FormattedMessage } from 'react-intl';

import ContactModal from 'views/base/ContactUs/ContactModal';

import styles from './ContactUsButton.module.scss';

interface ContactUsButtonProps {
  projectId: string;
}

export function ContactUsButton({ projectId }: ContactUsButtonProps) {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const onOpenContactModal = () => setIsContactModalOpen(true);
  const onCloseContactModal = () => setIsContactModalOpen(false);

  return (
    <>
      <button
        className={cn(button.textBtn, button.textBtnPrimary, utils.ml4, styles.button)}
        onClick={onOpenContactModal}
        data-testid="contact-us-button"
      >
        <FormattedMessage id="wizard_requirements_autofill_contact_us" />
      </button>

      {isContactModalOpen &&
        createPortal(
          <ContactModal onClose={onCloseContactModal} projectId={projectId} />,
          document.getElementById('mfd-modal-root')!,
        )}
    </>
  );
}
