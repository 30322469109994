import input from '@danfoss/mosaic/css/components/input.module.css';
import cn from 'classnames';

interface IInputGroup {
  className?: string;
  children: React.ReactNode;
}

const InputGroup = ({ className, children }: IInputGroup) => {
  return <div className={cn(className, input.inputGroup)}>{children}</div>;
};

export default InputGroup;
