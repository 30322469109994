import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from 'configs/store';

import { getCurrent, getProject } from '../store/projectSlice';

export const useProject = (requestedProjectId: string) => {
  const dispatch = useAppDispatch();
  const project = useAppSelector(getCurrent);
  const [error, setError] = useState(false);
  const isProjectNeeded = !project.id || project.id !== requestedProjectId;
  const [isLoading, setIsLoading] = useState(isProjectNeeded);

  useEffect(() => {
    async function getData() {
      setIsLoading(true);

      try {
        await dispatch(getProject(requestedProjectId)).unwrap();
      } catch (e) {
        setError(true);
      } finally {
        setIsLoading(false);
      }
    }

    if (isProjectNeeded) {
      getData();
    }
  }, [dispatch, project.id, requestedProjectId]);

  return { isLoading, error, project };
};
