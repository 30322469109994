import components from '@danfoss/mosaic/css/components.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import cn from 'classnames';
import { forwardRef } from 'react';

interface IRadioInputProps extends Omit<React.HTMLProps<HTMLInputElement>, 'type'> {
  labelClass?: string;
}

const RadioInput = forwardRef<HTMLInputElement, IRadioInputProps>(
  ({ children, id, labelClass, ...props }, ref) => {
    return (
      <div className={cn(core.flex, components.radio)}>
        <input type="radio" id={id} {...props} ref={ref} />
        <label className={labelClass} htmlFor={id}>
          {children}
        </label>
      </div>
    );
  },
);

export default RadioInput;
