import { TableRowSelectedEventType } from '@danfoss/mosaic';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

import { useAppDispatch, useAppSelector } from 'configs/store';
import { trackEvent } from 'utils/analytics';
import debounce from 'utils/debounce';
import Fieldset from 'views/shared/components/form/Fieldset';
import Input from 'views/shared/components/form/Input';
import InputAddon from 'views/shared/components/form/InputAddon';
import InputGroup from 'views/shared/components/form/InputGroup';
import Label from 'views/shared/components/form/Label/Label';
import { AvailableProductsSearchMode } from 'views/shared/types';
import {
  addSteeringProductsPrices,
  getCurrent,
  getProject,
  updateSteeringUnit,
} from 'views/wizard/shared/store/projectSlice';

import { getAvailableSteeringUnits, IAvailableSteeringUnit } from '../../../../api/products';
import {
  getSteeringAuthorizedFilter,
  setSteeringAuthorizedFilter,
} from '../../../../store/productFilterSlice';
import ModalLoader from '../../../components/modals/ModalLoader/ModalLoader';
import NoProducts from '../../../components/modals/NoProducts/NoProducts';
import SelectionModal from '../../../components/modals/SelectionModal/SelectionModal';
import selectionModalStyles from '../../../components/modals/SelectionModal/SelectionModal.module.scss';
import HybridTable from '../SteeringTable/HybridTable';
import styles from './SteeringModal.module.scss';
import { SteeringModalSidebar } from './components/SteeringModalSidebar';
import { convertToIdsWithPrices } from './helpers/steeringUnitData';
import { validation } from './validation';

interface HybridModalProps {
  onModalClose: () => void;
}

interface IHybridModalForm {
  requiredLockToLockTurns: number;
  requiredLockToLockSeconds: number;
}

const HybridSteeringModal = ({ onModalClose }: HybridModalProps) => {
  const project = useAppSelector(getCurrent);
  const dispatch = useAppDispatch();

  const { register, formState, control, trigger, getValues } = useForm<IHybridModalForm>({
    mode: 'onChange',
    defaultValues: {
      requiredLockToLockTurns: 5,
      requiredLockToLockSeconds: 5,
    },
  });
  const requiredLockToLockTurns = useWatch({
    name: 'requiredLockToLockTurns',
    control,
  });
  const requiredLockToLockSeconds = useWatch({
    name: 'requiredLockToLockSeconds',
    control,
  });
  const { errors, isValid: isFormValid } = formState;

  const authOnly = useAppSelector(getSteeringAuthorizedFilter);
  const searchMode = authOnly
    ? AvailableProductsSearchMode.AuthedOnly
    : AvailableProductsSearchMode.All;

  const handleAuthCheckboxChange = ({ target }: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = target;

    dispatch(setSteeringAuthorizedFilter(checked));
  };

  const [isModalUpdating, setIsModalUpdating] = useState(false);
  const [selectedSteeringUnitId, setSelectedSteeringUnitId] = useState<string>();

  const [isSteeringUnitsLoading, setIsSteeringUnitsLoading] = useState(true);
  const [steeringUnits, setSteeringUnits] = useState<IAvailableSteeringUnit[]>([]);

  const fetchSteeringUnits = useMemo(
    () =>
      debounce(async (filter_search_mode: AvailableProductsSearchMode) => {
        try {
          setIsSteeringUnitsLoading(true);

          const requiredLockToLockTurnsValue = getValues('requiredLockToLockTurns');
          const requiredLockToLockSecondsValue = getValues('requiredLockToLockSeconds');

          const units = await getAvailableSteeringUnits(project.id, {
            filter_required_l2l_turns: requiredLockToLockTurnsValue,
            filter_required_l2l_sec: requiredLockToLockSecondsValue,
            filter_search_mode,
          });

          setSteeringUnits(units);
          setSelectedSteeringUnitId(units.find(({ recommended }) => recommended)?.steering_unit.id);
        } finally {
          setIsSteeringUnitsLoading(false);
        }
      }, 1000),
    [],
  );

  const onRowSelected = useCallback((event: CustomEvent<TableRowSelectedEventType>) => {
    const { detail } = event;
    setSelectedSteeringUnitId(detail.rowId);
  }, []);

  const createRequestDataFromSelection = () => {
    const selectedSteeringUnit = steeringUnits.find(
      ({ steering_unit }) => steering_unit.id === selectedSteeringUnitId,
    );

    if (!selectedSteeringUnit) {
      return;
    }

    const { productIDs, prices } = convertToIdsWithPrices(selectedSteeringUnit);

    const attributes = {
      required_l2l_sec: requiredLockToLockSeconds,
      required_l2l_turns: requiredLockToLockTurns,
      actual_l2l_sec: selectedSteeringUnit.steering_unit.actual_l2l_sec,
      actual_l2l_turns: selectedSteeringUnit.steering_unit.actual_l2l_turns,
    };

    return {
      steeringUnitData: {
        attributes,
        product_ids: productIDs,
      },
      prices,
    };
  };

  const onUpdateSteeringUnit = async () => {
    const requestData = createRequestDataFromSelection();

    if (!requestData) {
      return;
    }

    await dispatch(updateSteeringUnit(requestData.steeringUnitData)).unwrap();
    dispatch(addSteeringProductsPrices(requestData.prices));
    await dispatch(getProject(project.id)).unwrap();
  };

  const handleSubmit = async () => {
    try {
      const recommendedSteeringId = steeringUnits.find(unit => !!unit.recommended)?.steering_unit
        .id;

      trackEvent({
        event: 'product_change',
        productType: 'steering',
        selectionId: project.id,
        recommendedProduct: recommendedSteeringId === selectedSteeringUnitId,
      });
      setIsModalUpdating(true);
      await onUpdateSteeringUnit();
      onModalClose();
    } catch (e) {
      setIsModalUpdating(false);
    }
  };

  useEffect(() => {
    (async () => {
      const isValid = await trigger(['requiredLockToLockSeconds', 'requiredLockToLockTurns']);
      /*
        formState.isDirty does not get updated on first input change, this is most probably
        a bug in the react-hook-form library. formstate.dirtyFields is empty when value
        is changed back to default value, hence both needs to be checked
        to acquire true "isDirty" flag
      */
      const isDirty = Boolean(Object.keys(formState.dirtyFields).length) || formState.isDirty;

      if (isValid && isDirty) {
        fetchSteeringUnits(searchMode);
      }

      if (!isValid) {
        fetchSteeringUnits.cancel();
      }
    })();
  }, [requiredLockToLockSeconds, requiredLockToLockTurns]);

  useEffect(() => {
    (async () => {
      if (await trigger(['requiredLockToLockSeconds', 'requiredLockToLockTurns'])) {
        fetchSteeringUnits.immediate(searchMode);
      }
    })();
  }, [authOnly]);

  const products = steeringUnits.length ? (
    <HybridTable
      list={steeringUnits}
      onRowSelected={onRowSelected}
      data-testid="steer-by-wire-modal-table"
    />
  ) : (
    <NoProducts />
  );

  return (
    <SelectionModal
      isOpen={true}
      onModalClose={onModalClose}
      submitLabel={<FormattedMessage id="wizard_steering_modal_select_button" />}
      handleSubmit={handleSubmit}
      sidebar={
        <SteeringModalSidebar authOnly={authOnly} onAuthOnlyChange={handleAuthCheckboxChange} />
      }
      isLoading={isSteeringUnitsLoading}
      isUpdating={isModalUpdating}
      data-testid="hybrid-modal"
      isSubmitButtonDisabled={!isFormValid}
    >
      <div className={cn(core.flex, core.alignCenter, utils.mt2, utils.mb8)}>
        <h4 className={cn(utils.pr24, styles['right-border'])}>
          <FormattedMessage
            id="wizard_steering_hybrid_modal_title"
            values={{
              linebreak: <br />,
            }}
          />
        </h4>
        <Fieldset className={cn(utils.ml8, utils.mr8, styles['fieldset-small'])}>
          <Label>
            <FormattedMessage id="wizard_steering_conventional_steering_modal_required_l2l" />
          </Label>
          <InputGroup>
            <Input
              type="number"
              error={!!errors.requiredLockToLockTurns}
              {...validation.requiredLockToLockTurns}
              {...register('requiredLockToLockTurns', {
                ...validation.requiredLockToLockTurns,
                valueAsNumber: true,
              })}
              data-testid="requiredLockToLockTurns"
            />
            <InputAddon>
              <FormattedMessage id="measurements_unit_turns" />
            </InputAddon>
          </InputGroup>
          <label className={cn(core.helperText, core.error, styles.error)}>
            {errors.requiredLockToLockTurns?.type === 'min' && (
              <span data-testid="requiredLockToLock-turns-min-error">
                <FormattedMessage
                  id="default_min_error"
                  values={validation.requiredLockToLockTurns}
                />
              </span>
            )}
            {errors.requiredLockToLockTurns?.type === 'max' && (
              <span data-testid="requiredLockToLock-turns-max-error">
                <FormattedMessage
                  id="default_max_error"
                  values={validation.requiredLockToLockTurns}
                />
              </span>
            )}
            {errors.requiredLockToLockTurns?.type === 'required' && (
              <span data-testid="requiredLockToLock-turns-required-error">
                <FormattedMessage
                  id="default_required_error"
                  values={validation.requiredLockToLockTurns}
                />
              </span>
            )}
          </label>
        </Fieldset>
        <Fieldset className={cn(utils.ml4, styles['fieldset-small'])}>
          <Label>
            <FormattedMessage id="wizard_steering_steer_by_wire_modal_l2l_time_limit" />
          </Label>
          <InputGroup>
            <Input
              type="number"
              error={!!errors.requiredLockToLockSeconds}
              {...validation.requiredLockToLockSeconds}
              {...register('requiredLockToLockSeconds', {
                ...validation.requiredLockToLockSeconds,
                valueAsNumber: true,
              })}
              data-testid="requiredLockToLockSeconds"
            />
            <InputAddon>
              <FormattedMessage id="measurements_unit_seconds" />
            </InputAddon>
          </InputGroup>
          <label className={cn(core.helperText, core.error, styles.error)}>
            {errors.requiredLockToLockSeconds?.type === 'min' && (
              <span data-testid="requiredLockToLock-seconds-min-error">
                <FormattedMessage
                  id="default_min_error"
                  values={validation.requiredLockToLockSeconds}
                />
              </span>
            )}
            {errors.requiredLockToLockSeconds?.type === 'max' && (
              <span data-testid="requiredLockToLock-seconds-max-error">
                <FormattedMessage
                  id="default_max_error"
                  values={validation.requiredLockToLockSeconds}
                />
              </span>
            )}
            {errors.requiredLockToLockSeconds?.type === 'required' && (
              <span data-testid="requiredLockToLock-seconds-required-error">
                <FormattedMessage
                  id="default_required_error"
                  values={validation.requiredLockToLockSeconds}
                />
              </span>
            )}
          </label>
        </Fieldset>
      </div>
      <div
        className={cn(
          core.flex,
          core.alignCenter,
          core.flexCenter,
          selectionModalStyles['content-wrapper'],
        )}
      >
        {isSteeringUnitsLoading ? <ModalLoader /> : products}
      </div>
    </SelectionModal>
  );
};

export default HybridSteeringModal;
