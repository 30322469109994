import { createContext } from 'react';

export enum AuthType {
  SF = 'salesforce',
  DIP = 'dip',
}
export interface IAuthContext {
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  authType?: AuthType;
}
export const AuthContext = createContext<IAuthContext>({
  isAuthenticated: false,
  setIsAuthenticated: () => {},
});
