import utils from '@danfoss/mosaic/css/utils.module.css';
import { DfModal } from '@danfoss/mosaic/react';
import { FormattedMessage } from 'react-intl';

const SharingNotFoundModal = () => {
  return (
    <DfModal data-testid="sharing-not-found-modal" isHeaderHidden isOpen>
      <div slot="modal-body">
        <h1 className={utils.mb4}>
          <FormattedMessage id="shared_page_selection_not_found_title" />
        </h1>
        <FormattedMessage id="shared_page_selection_not_found_text" />
      </div>
    </DfModal>
  );
};

export default SharingNotFoundModal;
