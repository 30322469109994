import axios from 'axios';

import { IProject } from 'views/shared/types';

export interface IProjectSelection
  extends Pick<
    IProject,
    'id' | 'project_name' | 'project_status' | 'function_codes' | 'created_at' | 'modified_at'
  > {}
export interface IAllSelectionsData {
  projects: IProjectSelection[];
  total_count: number;
}

export const getAllSelections = async () => {
  const { data } = await axios.get<IAllSelectionsData>('/projects');

  return data;
};

export const duplicateSelection = (id: string) => {
  return axios.post<IProject>(`/projects/${id}/duplicates`);
};

export const deleteSelection = (id: string) => {
  return axios.delete(`/projects/${id}`);
};
