import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';

import ProductImage from 'views/shared/components/ProductImage/ProductImage';

interface IProductProps {
  title: string;
  spec: string;
  image?: string;
  imageAlt: string;
}

const Product = ({ title, spec, image, imageAlt }: IProductProps) => {
  return (
    <div className={cn(core.flex, utils.mb4)} data-testid="product-item">
      <div className={utils.mr2}>
        <ProductImage photoUrl={image} width={60} height={60} alt={imageAlt} />
      </div>
      <div className={cn(core.flex, core.flexColumn)}>
        <h5 className={core.textBold}>{title}</h5>
        <div data-testid="product-spec">{spec}</div>
      </div>
    </div>
  );
};

export default Product;
