import { FormattedMessage } from 'react-intl';

import { showWarning } from 'utils/ToastService';
import { AutoUpdateProductsWarning } from 'views/shared/types';
import { ContactUsButton } from 'views/wizard/requirements/components/ContactUsButton';

export function showOverSpeedingWarning(warnings: AutoUpdateProductsWarning[], projectId: string) {
  if (warnings.includes(AutoUpdateProductsWarning.MotorOverSpeeding)) {
    showWarning(
      <FormattedMessage id="wizard_auto_update_over_speeding_warning_title" />,
      <FormattedMessage id="wizard_auto_update_over_speeding_warning_description" />,
    );
  }

  if (warnings.includes(AutoUpdateProductsWarning.TwoSpeedGearBoxNotSupported)) {
    showWarning(
      <FormattedMessage id="wizard_auto_update_two_speed_gear_box_warning_title" />,
      <FormattedMessage id="wizard_auto_update_two_speed_gear_box_warning_description" />,
      <ContactUsButton projectId={projectId} />,
    );
  }
}
