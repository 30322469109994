import button from '@danfoss/mosaic/css/components/button.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';

import { IProject } from 'views/shared/types';

import styles from './Name.module.scss';

interface INameProps {
  project: IProject;
  onRemove: (project: IProject) => void;
  onView: (project: IProject) => void;
  className?: string;
}

const Name = ({ project, onView, onRemove, className }: INameProps) => {
  const { project_name } = project;

  return (
    <th className={cn(styles['cell-wrapper'], className)}>
      <div className={cn(core.flex, core.flexBetween, utils.mb3)}>
        <button
          onClick={() => onView(project)}
          className={cn(button.textBtn, button.textBtnPrimary)}
          data-testid="view-button"
        >
          <FormattedMessage id="compare_page_view_selection_button" />
        </button>
        <button
          onClick={() => onRemove(project)}
          className={cn(button.textBtn, button.textBtnPrimary, core.end)}
          data-testid="remove-button"
        >
          - <FormattedMessage id="compare_page_remove_selection_button" />
        </button>
      </div>
      <h4 className={core.textOverflow} data-testid="selection-name" title={project_name}>
        {project_name}
      </h4>
    </th>
  );
};

export default Name;
