export const getUniqueFields = <Data extends Record<string, any>>(data: Data[]) => {
  const uniqueFields: (keyof Data)[] = [];
  const [dataToCompareWith, ...restData] = data;

  Object.keys(dataToCompareWith).forEach((fieldName: keyof Data) => {
    const isSameValue = restData.every(item => {
      if (Array.isArray(dataToCompareWith[fieldName]) && Array.isArray(item[fieldName])) {
        const toCompare = (dataToCompareWith[fieldName] as string[]).sort().toString();
        const toCompareWith = (item[fieldName] as string[]).sort().toString();

        return toCompare === toCompareWith;
      }

      return dataToCompareWith[fieldName] === item[fieldName];
    });
    const isUnique = !isSameValue;

    if (isUnique) {
      uniqueFields.push(fieldName);
    }
  });

  return uniqueFields;
};
