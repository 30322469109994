import {
  createSlice,
  createAsyncThunk,
  createAction,
  CaseReducer,
  miniSerializeError,
  Draft,
} from '@reduxjs/toolkit';
import type { AsyncThunkFulfilledActionCreator } from '@reduxjs/toolkit/dist/createAsyncThunk';
import axios from 'axios';

import type { RootState } from 'configs/store';
import {
  IProject,
  ISelectedMotor,
  IMotorToUpdate,
  ISelectedPump,
  IUpdatedPumpsData,
  ICalculations,
  IMotorDetails,
  IPrice,
  FunctionCode,
  IProjectSystemData,
  IProjectPropelSizingData,
  ISelectedSteeringProduct,
  ISteeringUnitAttributes,
  ISelectedProducts,
  IMotorsDisplacementAndSpeed,
  CommonError,
  MotorType,
  MachineExampleCode,
  SystemArchitecture,
  AdditionalMotors,
} from 'views/shared/types';

import {
  getProject as getProjectAPI,
  createNewProject as createNewProjectAPI,
  updateProject as updateProjectAPI,
  addMotor as addMotorAPI,
  removeMotors as removeMotorsAPI,
  updateMotor as updateMotorAPI,
  addPump as addPumpAPI,
  removePumps as removePumpsAPI,
  replacePump as replacePumpAPI,
  setKeepPumpSelected as setKeepPumpSelectedAPI,
  updateSystemData as updateSystemDataAPI,
  updateSteeringSystem as updateSteeringSystemAPI,
  updatePropelSizing as updatePropelSizingAPI,
  updateSteeringUnit as updateSteeringUnitAPI,
  IProjectSteeringDataToUpdate,
  removeSteeringUnit as removeSteeringUnitAPI,
  getProducts,
  IProjectToUpdate,
  ISteeringUnitRequestData,
  autoUpdateProducts as autoUpdateProductsAPI,
  getMotorsDetailsAndSpeed,
  updateMotorMinDisplacement,
} from '../api/project';

type IProductsPrices = Partial<Record<string, IPrice>>;

export interface IProjectState {
  current: IProject | Record<string, never>;
  selectedMotors: ISelectedMotor[] | null;
  selectedPumps: ISelectedPump[] | null;
  selectedSteeringProducts: ISelectedSteeringProduct[] | null;
  calculations?: ICalculations;
  prices: {
    motors: IProductsPrices;
    pumps: IProductsPrices;
    steeringProducts: IProductsPrices;
  };
}

export const initialState: IProjectState = {
  current: {},
  selectedMotors: null,
  selectedPumps: null,
  selectedSteeringProducts: null,
  calculations: {},
  prices: {
    motors: {},
    pumps: {},
    steeringProducts: {},
  },
};

export const getMotorsPrices = (state: RootState) => state.project.prices.motors;
export const getPumpsPrices = (state: RootState) => state.project.prices.pumps;
export const getSteeringProductsPrices = (state: RootState) =>
  state.project.prices.steeringProducts;
export const getCurrent = (state: RootState) => state.project.current;
export const getSystemData = (state: RootState) => state.project.current.system_data;
export const getSteeringData = (state: RootState) => state.project.current.steering_system;
export const getPropelSizingData = (state: RootState) => state.project.current.propel_sizing;
export const getSelectedMotors = (state: RootState) => state.project.selectedMotors;
export const getSelectedPumps = (state: RootState) => state.project.selectedPumps;
export const getSelectedSteeringProducts = (state: RootState) =>
  state.project.selectedSteeringProducts;
export const getCalculations = (state: RootState) => state.project.calculations;
export const clearCurrentProject = createAction('project/clear');

export const addMotorsPrices = createAction<IProductsPrices>('project/motor/prices/add');
export const addPumpsPrices = createAction<IProductsPrices>('project/pump/prices/add');
export const addSteeringProductsPrices = createAction<IProductsPrices>(
  'project/steeringProducts/prices/add',
);

export const clearProducts = createAction('project/products/clear');

export const createNewProject = createAsyncThunk(
  'functionSelection/createProject',
  async ({
    functionCodes,
    exampleCode,
    architecture,
  }: {
    functionCodes: FunctionCode[];
    exampleCode: MachineExampleCode;
    architecture?: SystemArchitecture;
  }) => {
    const { data } = await createNewProjectAPI(functionCodes, exampleCode, architecture);

    return data;
  },
);

export const updateProject = createAsyncThunk(
  'project/update',
  async ({ id, project }: { id: string; project: Partial<IProjectToUpdate> }) => {
    const { data } = await updateProjectAPI(id, project);

    return data;
  },
);

export const getProject = createAsyncThunk<IProject, string>('project/get', async projectId => {
  const { data } = await getProjectAPI(projectId);

  return data;
});

export const updateSystemData = createAsyncThunk(
  'project/systemData/update',
  async ({ id, systemData }: { id: string; systemData: Partial<IProjectSystemData> }) => {
    const { data } = await updateSystemDataAPI(id, systemData);

    return data;
  },
);

export const updateSteeringSystem = createAsyncThunk(
  'project/steeringSystem/update',
  async ({ id, steeringData }: { id: string; steeringData: IProjectSteeringDataToUpdate }) => {
    const { data } = await updateSteeringSystemAPI(id, steeringData);

    return data;
  },
);

export const updatePropelSizing = createAsyncThunk(
  'project/propelSizing/update',
  async ({
    id,
    propelSizingData,
  }: {
    id: string;
    propelSizingData: Partial<IProjectPropelSizingData>;
  }) => {
    const { data } = await updatePropelSizingAPI(id, propelSizingData);

    return data;
  },
);

export const getSelectedProducts = createAsyncThunk<ISelectedProducts, string>(
  'project/getSelectedProducts',
  async projectId => {
    const response = await getProducts(projectId);

    return response.data;
  },
);

export const autoUpdateProducts = createAsyncThunk(
  'project/autoUpdateProducts',
  async ({
    projectId,
    currency,
    displacementType,
    disableErrorInterceptor = false,
    additionalMotors,
  }: {
    projectId: string;
    currency?: string;
    displacementType?: MotorType;
    disableErrorInterceptor?: boolean;
    additionalMotors?: AdditionalMotors;
  }) => {
    const { data } = await autoUpdateProductsAPI(
      projectId,
      currency,
      displacementType,
      disableErrorInterceptor,
      additionalMotors,
    );

    return data;
  },
  {
    serializeError: error => {
      if (axios.isAxiosError(error)) {
        const errorData: CommonError = error.response?.data;
        const message = Object.values(errorData.errors ?? {})[0]?.[0];

        return { message };
      }

      return miniSerializeError(error);
    },
  },
);

export const addMotor = createAsyncThunk<
  ISelectedMotor[],
  { motorId: string; numberOfSelectedMotors: number },
  { state: RootState }
>('project/newMotor', async ({ motorId, numberOfSelectedMotors }, { getState }) => {
  const { data } = await addMotorAPI(getCurrent(getState()).id, motorId, numberOfSelectedMotors);

  return data.items;
});

export const updateMotor = createAsyncThunk<
  ISelectedMotor[],
  { motor: IMotorToUpdate },
  { state: RootState }
>('project/updateMotor', async ({ motor }, { getState }) => {
  const { data } = await updateMotorAPI(getCurrent(getState()).id, motor);

  return data.items;
});

export const getMotorDetailsAndCalculations = createAsyncThunk(
  'project/get/motorDetailsAndCalculations',
  async ({ projectId, pumpsId }: { projectId: string; pumpsId: string[] }) => {
    const { data } = await getMotorsDetailsAndSpeed(projectId, pumpsId);

    return data;
  },
);

export const updateMotorDetailsAndCalculations = createAsyncThunk<
  IMotorsDisplacementAndSpeed,
  { motors: Pick<IMotorDetails, 'id' | 'min_displacement'>[] },
  { state: RootState }
>('project/update/motorDetailsAndCalculations', async ({ motors }, { getState }) => {
  const project = getCurrent(getState());
  const { data } = await updateMotorMinDisplacement(project.id, motors);

  return data;
});

export const removeMotor = createAsyncThunk<ISelectedMotor[], string, { state: RootState }>(
  'project/removeMotor',
  async (motorId, { getState }) => {
    const { data } = await removeMotorsAPI(getCurrent(getState()).id, [motorId]);

    return data.items;
  },
);

export const removeAllMotors = createAsyncThunk<ISelectedMotor[], void, { state: RootState }>(
  'project/removeAllMotors',
  async (_, { getState }) => {
    const { id } = getCurrent(getState());
    const motors = getSelectedMotors(getState()) || [];
    const motorsIds = motors.map(motor => motor.id);

    const response = await removeMotorsAPI(id, motorsIds);

    return response.data.items;
  },
);

export const addPump = createAsyncThunk<
  IUpdatedPumpsData,
  {
    pumpId: string;
    displacements?: Pick<ISelectedMotor, 'id' | 'min_displacement'>[];
  },
  { state: RootState }
>('project/newPump', async ({ pumpId, displacements }, { getState }) => {
  const { data } = await addPumpAPI(getCurrent(getState()).id, pumpId, displacements);

  return data;
});

export const replacePump = createAsyncThunk<
  IUpdatedPumpsData,
  {
    newPumpId: string;
    oldPump: Pick<ISelectedMotor, 'id'>;
    displacements?: Pick<ISelectedMotor, 'id' | 'min_displacement'>[];
  },
  { state: RootState }
>('project/replacePump', async ({ newPumpId, oldPump, displacements }, { getState }) => {
  const { data } = await replacePumpAPI(
    getCurrent(getState()).id,
    newPumpId,
    oldPump,
    displacements,
  );

  return data;
});

export const setKeepPumpSelected = createAsyncThunk<
  IUpdatedPumpsData,
  {
    pump: Pick<ISelectedMotor, 'id' | 'product_id'>;
    keepPump: boolean;
    displacements: Pick<ISelectedMotor, 'id' | 'min_displacement'>[];
  },
  { state: RootState }
>('project/setKeepPumpSelected', async ({ pump, keepPump, displacements }, { getState }) => {
  const { data } = await setKeepPumpSelectedAPI(
    getCurrent(getState()).id,
    pump,
    keepPump,
    displacements,
  );

  return data;
});

export const removePump = createAsyncThunk<IUpdatedPumpsData, string, { state: RootState }>(
  'project/removePump',
  async (pumpId, { getState }) => {
    const { data } = await removePumpsAPI(getCurrent(getState()).id, [pumpId]);

    return data;
  },
);

export const removeAllPumps = createAsyncThunk<IUpdatedPumpsData, void, { state: RootState }>(
  'project/removeAllPumps',
  async (_, { getState }) => {
    const { id } = getCurrent(getState());
    const pumps = getSelectedPumps(getState()) || [];
    const pumpsIds = pumps.map(pump => pump.id);

    const response = await removePumpsAPI(id, pumpsIds);

    return response.data;
  },
);

export const updateSteeringUnit = createAsyncThunk<
  { products: ISelectedSteeringProduct[]; attributes: ISteeringUnitAttributes },
  ISteeringUnitRequestData,
  { state: RootState }
>('project/updateSteeringUnit', async (steeringUnitData, { getState }) => {
  const { data } = await updateSteeringUnitAPI(getCurrent(getState()).id, steeringUnitData);

  return data;
});

export const removeSteeringUnit = createAsyncThunk<void, void, { state: RootState }>(
  'project/removeSteeringUnit',
  async (_, { getState }) => {
    await removeSteeringUnitAPI(getCurrent(getState()).id);
  },
);

const projectSlice = createSlice({
  name: 'project',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(addMotorsPrices, (state, { payload }) => {
      Object.assign(state.prices.motors, payload);
    });
    builder.addCase(addPumpsPrices, (state, { payload }) => {
      Object.assign(state.prices.pumps, payload);
    });
    builder.addCase(addSteeringProductsPrices, (state, { payload }) => {
      Object.assign(state.prices.steeringProducts, payload);
    });
    builder.addCase(clearCurrentProject, state => {
      Object.assign(state, initialState);
    });
    builder.addCase(clearProducts, state => {
      state.selectedMotors = [];
      state.selectedPumps = [];
      state.selectedSteeringProducts = [];
      state.calculations = initialState.calculations;

      if (state.current.steering_system) {
        state.current.steering_system.steering_unit_attributes = {};
      }

      Object.assign(state.prices, initialState.prices);
    });
    builder.addCase(createNewProject.fulfilled, (state, { payload }) => {
      state.current = payload;
    });
    builder.addCase(getProject.fulfilled, (state, { payload }) => {
      const { results, ...project } = payload;

      state.calculations = results;
      state.current = project as IProject;
    });

    function applyDetailsToMotors(state: Draft<IProjectState>, details: IMotorDetails[]) {
      state.selectedMotors?.forEach(motor => {
        const detail = details.find(({ id }) => id === motor.id);

        if (!detail) {
          return;
        }

        const {
          min_displacement,
          speed,
          tractive_force_actual,
          speed_limit_rated_for_displacement,
          is_overspeeding,
        } = detail;

        const patch: Partial<ISelectedMotor> = {
          min_displacement,
          speed_limit_rated_for_displacement,
          motor_speed: speed,
          is_motor_overspeeding: is_overspeeding,
        };

        if (typeof tractive_force_actual === 'number') {
          Object.assign(patch, { tractive_force_actual });
        }

        Object.assign(motor, patch);
      });
    }

    const setMotorDetailsAndCalculations: CaseReducer<
      IProjectState,
      ReturnType<AsyncThunkFulfilledActionCreator<IMotorsDisplacementAndSpeed, unknown>>
    > = (state, { payload }) => {
      const { calculations, motors } = payload;

      Object.assign(state.calculations, calculations);

      applyDetailsToMotors(state, motors);
    };

    builder.addCase(getMotorDetailsAndCalculations.fulfilled, setMotorDetailsAndCalculations);
    builder.addCase(updateMotorDetailsAndCalculations.fulfilled, setMotorDetailsAndCalculations);

    builder.addCase(updateSystemData.fulfilled, (state, { payload }) => {
      state.current.system_data = payload;
    });
    builder.addCase(updateSteeringSystem.fulfilled, (state, { payload }) => {
      state.current.steering_system = payload;
    });
    builder.addCase(updatePropelSizing.fulfilled, (state, { payload }) => {
      state.current.propel_sizing = payload;
    });
    const setSelectedProducts: CaseReducer<
      IProjectState,
      ReturnType<AsyncThunkFulfilledActionCreator<ISelectedProducts, unknown>>
    > = (state, { payload }) => {
      const selectedMotors: ISelectedMotor[] = [];
      const selectedMotorsPrices: IProductsPrices = {};
      const selectedPumps: ISelectedPump[] = [];
      const selectedPumpsPrices: IProductsPrices = {};
      const selectedSteeringProducts: ISelectedSteeringProduct[] = [];
      const selectedSteeringProductsPrices: IProductsPrices = {};

      payload.motors.forEach(({ price, selected_motor }) => {
        selectedMotors.push(selected_motor);
        selectedMotorsPrices[selected_motor.product_id] = price;
      });

      state.selectedMotors = selectedMotors;
      state.prices.motors = selectedMotorsPrices;

      payload.pumps.forEach(({ price, selected_pump }) => {
        selectedPumps.push(selected_pump);
        selectedPumpsPrices[selected_pump.product_id] = price;
      });

      state.selectedPumps = selectedPumps;
      state.prices.pumps = selectedPumpsPrices;

      payload.steering_products.forEach(({ price, selected_steering_product }) => {
        selectedSteeringProducts.push(selected_steering_product);
        selectedSteeringProductsPrices[selected_steering_product.product_id] = price;
      });

      state.selectedSteeringProducts = selectedSteeringProducts;
      state.prices.steeringProducts = selectedSteeringProductsPrices;
    };
    builder.addCase(getSelectedProducts.fulfilled, setSelectedProducts);
    builder.addCase(autoUpdateProducts.fulfilled, setSelectedProducts);
    builder.addCase(updateProject.fulfilled, (state, { payload }) => {
      state.current = { ...state.current, ...payload };
    });
    builder.addCase(addMotor.fulfilled, (state, { payload }) => {
      state.selectedMotors = payload;
      state.selectedPumps = [];
      state.calculations = initialState.calculations;
    });
    builder.addCase(removeMotor.fulfilled, (state, { payload }) => {
      state.selectedMotors = payload;
      state.selectedPumps = [];
      state.calculations = initialState.calculations;
    });
    builder.addCase(removeAllMotors.fulfilled, (state, { payload }) => {
      state.selectedMotors = payload;
      state.selectedPumps = [];
      state.calculations = initialState.calculations;
    });
    builder.addCase(updateMotor.fulfilled, (state, { payload }) => {
      state.selectedMotors = payload;
      state.selectedPumps = [];
      state.calculations = initialState.calculations;
    });

    const setUpdatedPump: CaseReducer<
      IProjectState,
      ReturnType<AsyncThunkFulfilledActionCreator<IUpdatedPumpsData, unknown>>
    > = (state, { payload: { selected_motors, selected_pumps, calculations } }) => {
      state.selectedPumps = selected_pumps;
      state.calculations = calculations;

      applyDetailsToMotors(
        state,

        selected_motors,
      );
    };

    builder.addCase(addPump.fulfilled, setUpdatedPump);
    builder.addCase(replacePump.fulfilled, setUpdatedPump);
    builder.addCase(setKeepPumpSelected.fulfilled, setUpdatedPump);
    builder.addCase(removePump.fulfilled, setUpdatedPump);
    builder.addCase(removeAllPumps.fulfilled, setUpdatedPump);

    builder.addCase(updateSteeringUnit.fulfilled, (state, { payload }) => {
      state.selectedSteeringProducts = payload.products;
      state.current.steering_system!.steering_unit_attributes = payload.attributes;
    });
    builder.addCase(removeSteeringUnit.fulfilled, state => {
      state.selectedSteeringProducts = null;
      state.current.steering_system!.steering_unit_attributes = {};
    });
  },
});

export default projectSlice.reducer;
