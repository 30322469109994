import { ReactComponent as SvgPlus } from '@danfoss/mosaic-icons/dist/svg/plus.svg';
import button from '@danfoss/mosaic/css/components/button.module.css';
import tooltip from '@danfoss/mosaic/css/components/tooltip.module.css';
import core from '@danfoss/mosaic/css/core.module.css';
import utils from '@danfoss/mosaic/css/utils.module.css';
import cn from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useAppSelector } from 'configs/store';
import Button from 'views/shared/components/Button/Button';
import { MachineExampleCode, MotorType, SystemArchitecture } from 'views/shared/types';
import ConfirmationModal from 'views/wizard/shared/components/ConfirmationModal';
import useConfirmationModal from 'views/wizard/shared/helpers/useConfirmationModal';
import {
  getCurrent,
  getPumpsPrices,
  getSelectedMotors,
  getSelectedPumps,
} from 'views/wizard/shared/store/projectSlice';

import styles from '../../ProductSelection.module.scss';
import { useMotorTypeContext } from '../../motor-type-context/motorTypeContext';
import NumberOfProducts from '../components/NumberOfProducts';
import { SelectionModalAction } from '../components/modals/SelectionModal/SelectionModal';
import PumpModal from './components/PumpModal/PumpModal';
import PumpWidget from './components/PumpWidget';

interface IPumpsProps {
  clearPumps: () => Promise<void>;
  removePumpById: (pumpId: string) => Promise<void>;
  systemArchitecture?: SystemArchitecture;
  hasMotorsError: boolean;
  isAddingPumpAvailable: boolean;
}

const pumpSystemArchitecture = {
  [SystemArchitecture.OnePumpOneMotor]: 1,
  [SystemArchitecture.OnePumpOneMotorGearBox]: 1,
  [SystemArchitecture.OnePumpTwoMotors]: 1,
  [SystemArchitecture.DualPath]: 2,
  [SystemArchitecture.Custom]: 1,
};

const Pumps = ({
  clearPumps,
  removePumpById,
  systemArchitecture,
  isAddingPumpAvailable,
  hasMotorsError,
}: IPumpsProps) => {
  const { formatMessage } = useIntl();
  const project = useAppSelector(getCurrent);
  const selectedPumps = useAppSelector(getSelectedPumps);
  const selectedMotors = useAppSelector(getSelectedMotors);
  const prices = useAppSelector(getPumpsPrices);
  const { motorType } = useMotorTypeContext();

  const isPumpSelected = selectedPumps && selectedPumps.length > 0;
  const isMotorSelected = selectedMotors && selectedMotors.length > 0;
  const isCustomFlow = project.machine_example_code === MachineExampleCode.Custom;
  const isAddingAvailable =
    isCustomFlow || project.machine_example_code === MachineExampleCode.OnePumpOneMotor;

  const isMotorWidgetMissingRollingRadius = Boolean(
    isMotorSelected && selectedMotors.some(({ rolling_radius }) => !rolling_radius),
  );

  const [selectedNumberOfPumps, setSelectedNumberOfPumps] = useState(
    pumpSystemArchitecture[systemArchitecture || SystemArchitecture.Custom],
  );
  const availablePumpsToAdd = selectedNumberOfPumps - (selectedPumps?.length || 0);

  useEffect(() => {
    if (isPumpSelected) {
      setSelectedNumberOfPumps(selectedPumps.length);
    }
  }, [project.id]);

  const isMotorTypeVariable = motorType === MotorType.VariablePiston;

  const {
    props: numberOfPumpsConfirmationModalProps,
    open: openNumberOfPumpsConfirmationModal,
    isOpen: isNumberOfPumpsConfirmationModalOpen,
  } = useConfirmationModal<number>(async lastSelectedNumber => {
    await clearPumps();
    setSelectedNumberOfPumps(lastSelectedNumber);
  });

  const onNumberOfPumpsChange = (newNumber: number) => {
    if (!isPumpSelected) {
      setSelectedNumberOfPumps(newNumber);
      return;
    }

    openNumberOfPumpsConfirmationModal(newNumber);
  };

  const [pumpModalMode, setPumpModalMode] = useState<SelectionModalAction>(null);
  const pumpToReplace = useRef<string | null>(null);

  const openAddPumpModal = () => setPumpModalMode('add');
  const openReplacePumpModal = (selectedItem: string) => {
    pumpToReplace.current = selectedItem;
    setPumpModalMode('replace');
  };

  const handlePumpModalClose = () => {
    pumpToReplace.current = null;
    setPumpModalMode(null);
  };

  return (
    <section className={utils.mt20}>
      <h3>
        <FormattedMessage id="wizard_product_selected_pump" />
      </h3>
      {isCustomFlow && (
        <NumberOfProducts
          title={formatMessage({ id: 'wizard_product_number_of_pumps_label' })}
          type="pump"
          number={selectedNumberOfPumps}
          hasProducts={Boolean(isPumpSelected)}
          onChange={onNumberOfPumpsChange}
        />
      )}
      <div className={cn(utils.row, utils.mt8)}>
        {isPumpSelected &&
          selectedPumps.map(pump => (
            <div
              key={pump.id}
              className={cn(
                utils.col3,
                utils.colMd4,
                utils.colSm8,
                utils.pt2,
                styles['product-item'],
              )}
            >
              <PumpWidget
                data={pump}
                price={prices[pump.product_id]}
                onChange={openReplacePumpModal}
                onRemove={isCustomFlow ? removePumpById : undefined}
              />
            </div>
          ))}
        {availablePumpsToAdd > 0 &&
          isAddingAvailable &&
          new Array(availablePumpsToAdd).fill(null).map((value, index) => (
            <div
              key={index}
              className={cn(utils.col3, utils.colMd4, utils.colSm8, styles['add-item'])}
            >
              <div
                data-tooltip
                data-placement="auto"
                data-tooltip-arrow-placement="end"
                className={cn(tooltip.tooltip, styles['tooltip-wrapper'])}
              >
                <Button
                  className={cn(button.textBtn, button.textBtnPrimary)}
                  onClick={openAddPumpModal}
                  disabled={
                    !isMotorSelected ||
                    hasMotorsError ||
                    isMotorWidgetMissingRollingRadius ||
                    !isAddingPumpAvailable
                  }
                  data-testid="product-selection-add-pump-button"
                >
                  <SvgPlus className={core.icon} />
                  <FormattedMessage id="wizard_product_add_pump" />
                </Button>
                {!isAddingPumpAvailable && (
                  <span className={cn(tooltip.tooltipContent, styles.tooltip)}>
                    <FormattedMessage id="wizard_product_motors_availability_tooltip" />
                  </span>
                )}
                {hasMotorsError ? (
                  <span className={cn(tooltip.tooltipContent, styles.tooltip)}>
                    <FormattedMessage id="wizard_product_motor_widgets_validation_tooltip" />
                  </span>
                ) : (
                  isMotorWidgetMissingRollingRadius && (
                    <span className={cn(tooltip.tooltipContent, styles.tooltip)}>
                      <FormattedMessage id="wizard_product_rolling_radius_missing_tooltip" />
                    </span>
                  )
                )}
              </div>
            </div>
          ))}
      </div>
      {pumpModalMode && (
        <PumpModal
          isOpen={!!pumpModalMode}
          action={pumpModalMode}
          numberOfPumps={selectedNumberOfPumps}
          focusedItem={pumpToReplace}
          onModalClose={handlePumpModalClose}
          isMotorTypeVariable={isMotorTypeVariable}
        />
      )}
      {isNumberOfPumpsConfirmationModalOpen && (
        <ConfirmationModal
          headingLabel={formatMessage({ id: 'wizard_confirmation_modal_heading' })}
          confirmLabel={formatMessage({
            id: 'wizard_product_pump_number_confirmation_modal_change_button',
          })}
          {...numberOfPumpsConfirmationModalProps}
        >
          <FormattedMessage id="wizard_product_pump_number_confirmation_modal_text" />
        </ConfirmationModal>
      )}
    </section>
  );
};

export default Pumps;
